import { Box, Button, Divider, Paper, Stack, SvgIcon, Typography, useTheme } from "@mui/material"
import axios from "axios"
import { useQuery, useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { ReactComponent as faImageSlash } from "icons/faImageSlash-regular.svg"
import { useEffect, useState } from "react"
import { DragonTab, DragonTabs } from "Components/DragonTabs"
import Icon from "Components/Icon"

import {
    faTh,
    faThLarge,
    faSquare,
    faArrowLeft,
    faArrowRight
} from "@fortawesome/pro-regular-svg-icons"
import {
    faTh as faThSolid,
    faThLarge as faThLargeSolid,
    faSquare as faSquareSolid
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getFilterSelectionFromLocalStorage, saveFilterSelectionToLocalStorage } from "utils/filterSelection"
import DragonTitle from "Components/DragonTitle"
import { IPosition } from "Types/IPosition"
import { yellow } from "App/colors"
import DragonPageWrapper from "Components/DragonPageWrapper"
import { assertUnreachable } from "utils/assertUnrechable"

interface ICamera {
    id: string
    name: string
    position: IPosition | null
    deviceStateId: "Unknown" | "Ok" | "NotOk"
    eventAssets: {
        assetTypeId: "Undefined" | "Snapshot" | "Video"
        uri: string
    }[]
}

interface IGroup {
    id: string
    name: string
    cameras: ICamera[]
}

async function getDeviceStatus() {
    const response = await axios.get<IGroup[]>("/api/event/v1/camera-groups/device-status")
    return response.data
}


enum Size {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large"
}

const LOCAL_STORAGE_KEY = `camera-overview-size-${process.env.REACT_APP_LOCAL_STORAGE_KEY_SUFFIX}`
//const EMPTY_CAMERA_IMAGE_URI = "/placeholder-images/empty_camera_overview.png"

export default function CameraOverview() {
    const { t } = useTranslation()
    const { palette } = useTheme()
    const queryClient = useQueryClient()

    const sizeFromLocalStorage = getFilterSelectionFromLocalStorage<Size>(LOCAL_STORAGE_KEY)
    const saveSizeToLocalStorage = (size: Size) => saveFilterSelectionToLocalStorage(LOCAL_STORAGE_KEY, size)

    const { data: groups, isLoading, isError, isSuccess } = useQuery("camera-overview", getDeviceStatus)

    const [size, setSize] = useState(sizeFromLocalStorage || Size.SMALL)
    const [step, setStep] = useState(0)

    const handleSizeChange = (_: React.SyntheticEvent, newSize: Size) => {
        setSize(newSize)
        saveSizeToLocalStorage(newSize)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            queryClient.refetchQueries("camera-overview")
        }, 60000)
        return () => clearInterval(interval)
    }, [queryClient])

    return (
        <DragonPageWrapper>
            <DragonTitle title={`${t("camera-overview")}${groups?.length ? ` / ${groups[step].name}` : ""}`} />
            {(() => {
                if (isLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <Typography>{t("camera-overview-page.failed-to-load")}</Typography>

                if (isSuccess && groups.length === 0)
                    return <Typography>{t("device-status-page.no-camera-groups")}</Typography>

                if (isSuccess && groups.length > 0)
                    return <Paper sx={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "auto"
                    }}>
                        <Box display="flex">
                            <DragonTabs
                                value={size}
                                onChange={handleSizeChange}
                            >
                                <DragonTab
                                    label={t("camera-overview-page.small")}
                                    value={Size.SMALL}
                                    icon={
                                        <Icon
                                            isSelected={size === Size.SMALL}
                                            icon={faTh}
                                            selectedIcon={faThSolid}
                                        />
                                    }
                                />
                                <DragonTab
                                    label={t("camera-overview-page.medium")}
                                    value={Size.MEDIUM}
                                    icon={
                                        <Icon
                                            isSelected={size === Size.MEDIUM}
                                            icon={faThLarge}
                                            selectedIcon={faThLargeSolid}
                                        />
                                    }
                                />
                                <DragonTab
                                    label={t("camera-overview-page.large")}
                                    value={Size.LARGE}
                                    icon={
                                        <Icon
                                            isSelected={size === Size.LARGE}
                                            icon={faSquare}
                                            selectedIcon={faSquareSolid}
                                        />
                                    }
                                />
                            </DragonTabs>
                            <Box display="flex" padding="13px" gap="15px">
                                <Button
                                    onClick={() => setStep(step - 1)}
                                    startIcon={<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "1.1em", color: palette.text.primary }} />}
                                    disabled={step === 0}
                                >
                                    {groups[step - 1]?.name ?? t("camera-overview-page.no-previous")}
                                </Button>
                                <Button
                                    onClick={() => setStep(step + 1)}
                                    endIcon={<FontAwesomeIcon icon={faArrowRight} style={{ fontSize: "1.1em", color: palette.text.primary }} />}
                                    disabled={step === groups.length - 1}
                                >
                                    {groups[step + 1]?.name ?? t("camera-overview-page.no-next")}
                                </Button>
                            </Box>
                        </Box>

                        <Divider flexItem />

                        <CameraGrid cameraGroup={groups[step]} size={size} />
                    </Paper>
            })()}
        </DragonPageWrapper>
    )
}



function CameraGrid({ cameraGroup, size }: { cameraGroup: IGroup, size: Size }) {
    const { palette } = useTheme()

    const width = ((): number => {
        switch (size) {
            case Size.SMALL:
                return 135
            case Size.MEDIUM:
                return 218
            case Size.LARGE:
                return 377
            default:
                assertUnreachable(size)
        }
    })()

    return (
        <Box
            sx={{
                display: "grid",
                gap: 2,
                gridTemplateColumns: `repeat(auto-fill, minmax(${width}px, 1fr))`,
                overflow: "auto",
                p: "21px 23px"
            }}
        >
            {cameraGroup.cameras.map((camera) => {
                const snapshotUrl = `/api/event/v1/cameras/${camera.id}/snapshot/overview`
                return (
                    <Paper
                        key={camera.id}
                        elevation={0}
                        sx={{
                            backgroundColor: camera.deviceStateId === "Ok" ? palette.success.main : yellow[500],
                            border: `2px solid ${camera.deviceStateId === "Ok" ? palette.success.main : yellow[500]}`,
                            borderRadius: "4px",
                            color: camera.deviceStateId === "Ok" ? palette.success.contrastText : palette.error.contrastText
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                aspectRatio: "16 / 9",
                                backgroundImage: snapshotUrl && `url(${snapshotUrl})`,
                                backgroundPosition: "center center",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                borderRadius: "4px 4px 0 0",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            {!snapshotUrl && <SvgIcon component={faImageSlash} viewBox="0 0 640 512" style={{ fontSize: 48, color: palette.text.secondary }} />}
                        </Box>
                        <Stack
                            direction="column"
                            divider={<Divider orientation="horizontal" flexItem />}
                            spacing={1}
                            p={2}
                            sx={{ flexGrow: 1 }}
                        >
                            <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                {camera.name}
                            </Typography>
                        </Stack>
                    </Paper>
                )
            })}
        </Box>
    )
}