import { useQueries } from "react-query"

import { useEffect, useState } from "react"

import {
    Box
} from "@mui/material"
import { ITraceFilterSelection } from "../types/IDataAnalyticsFilterSelection"

import DragonChartCard from "../DragonChartCard"
import { useTranslation } from "react-i18next"
import { getVehicleTrace } from "./getTraceData"
import { defaultSelection } from "./defaultSelection"
import VehicleTraceFilter from "./Filter"
import VehicleTracingCanvas from "./VehicleTracingCanvas"

//const EMPTY_CAMERA_IMAGE_URI = "/placeholder-images/empty_camera_overview.png"

export default function VehicleTracingWrapper() {
    const [selection, setSelection] = useState<ITraceFilterSelection>(defaultSelection)
    function onSubmit(selection: ITraceFilterSelection) {
        setSelection(selection)
    }

    return <>
        <Box mx={3} mt={3} >
            <VehicleTraceFilter onSubmit={onSubmit} />
        </Box>
        <VehicleTracing
            filter={selection}
        />
    </>
}

export function VehicleTracing({ filter }: { filter: ITraceFilterSelection }) {
    const { t } = useTranslation()
    const [imageUrl,setImageUrl] = useState<string>("")
    const [
        { data, status }
    ] = useQueries([
        { queryKey: ["vehicle-trace", filter], queryFn: () => getVehicleTrace(filter) }
    ])

    useEffect(()=>{
        const camID = filter.cameraIds.at(0)
        if(camID){
            setImageUrl(`/api/event/v1/cameras/${camID}/snapshot`)
        }
    },[filter])
    return (
        <Box sx={{ p: 3, boxSizing: "content-box", overflow:"auto"}}>
                <DragonChartCard
                    title={t("data-analytics-page.vehicle-trace")}
                    data={data}
                    status={status}
                    height={"auto"}
                >
                        <VehicleTracingCanvas 
                        image={imageUrl}
                        timeFrame={filter.timeFrame}
                        traces={data} />
                </DragonChartCard>
        </Box>
    )
}