import DataSourceSelector from "./filters/DataSourceSelector"
import WidgetTypeSelector from "./filters/WidgetTypeSelector"
import IWidgetSelection from "./types/IWidgetSelection"
import StepWrapper from "./StepWrapper"

interface ISourceAndType {
    selection: IWidgetSelection
    setSelection: React.Dispatch<React.SetStateAction<IWidgetSelection>>,
}

export default function SourceAndType({ selection, setSelection }: ISourceAndType) {
    return (
        <StepWrapper>
            <DataSourceSelector
                selectedValue={selection.dataSource}
                widgetType={selection.widgetType}
                onChange={newDataSource =>
                    setSelection({ ...selection, dataSource: newDataSource })
                }
            />
            <WidgetTypeSelector
                selectedValue={selection.widgetType}
                dataSource={selection.dataSource}
                onChange={newWidgetType =>
                    setSelection({ ...selection, widgetType: newWidgetType })
                }
            />
        </StepWrapper>
    )
}