import React, { useState } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { OnChangeHandlerFunc, MentionsInputProps } from "react-mentions"
import { useMutation, useQueryClient } from "react-query"

import {
    Button,
    FilledInput,
    InputBaseComponentProps
} from "@mui/material"

import InputWithMentions from "./InputWithMentions"
import { IEvent } from "./EventTypes"
import { IDataWithEtag } from "utils/queryHelpers"

type InputMentionComponentProps = Omit<InputBaseComponentProps, keyof MentionsInputProps> & Partial<MentionsInputProps>
const FilledInputMentionSupport = FilledInput as unknown as React.FC<InputMentionComponentProps>

export default function CommentBox({ eventId }: { eventId: string }) {
    const { t } = useTranslation()

    const [value, setValue] = useState<string>("")
    const [mentions, setMentions] = useState<Set<string>>(new Set())
    const handleChange: OnChangeHandlerFunc = (_event, newValue, _newPlainTextValue, newMentions) => {
        const uniqueMentionIds = new Set(newMentions.map(mention => mention.id))
        setMentions(uniqueMentionIds)
        setValue(newValue)
    }

    const queryClient = useQueryClient()
    const { mutate: postComment, isLoading: postingComment } = useMutation((value: string) => {
        return axios.post("/api/event/v1/event-comments", { comment: value, userIds: Array.from(mentions), eventId })
    }, {
        onSuccess: ({ data, headers }) => {
            queryClient.setQueryData<IDataWithEtag<IEvent> | undefined>(
                ["event", eventId],
                (event: IDataWithEtag<IEvent> | undefined) => {
                    if (!event) throw new Error(`Cached event with id ${eventId} was not found!`)
                    event.data.eventComments.push({
                        entity: data,
                        eTag: headers.etag
                    })
                    return event
                }
            )
            setValue("")
        }
    })

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        postComment(value)
    }

    return (
        <form
            style={{
                width: "100%"
            }}
            onSubmit={handleSubmit}
            onKeyDown={(e) => (e.key === "Enter" && e.ctrlKey) && handleSubmit(e)}
        >
            <FilledInputMentionSupport
                value={value}
                onChange={handleChange}
                inputComponent={InputWithMentions}
                endAdornment={
                    <Button
                        variant="contained"
                        disabled={!value || postingComment}
                        type="submit"
                    >
                        {t("event-details.comment")}
                    </Button>
                }
                sx={{
                    alignItems: "end",
                    p: "11px",
                    flexDirection: "column",
                    minHeight: "117px",
                    justifyContent: "space-between"
                }}
                fullWidth
                disableUnderline
            />
        </form>
    )
}