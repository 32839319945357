import { FC, useCallback, useMemo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate, Routes, Route, useLocation } from "react-router-dom"
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "react-query"
import { Stack, Button, useTheme, Box, Divider, Paper } from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight, faShapes, faVideo } from "@fortawesome/pro-regular-svg-icons"
import { faShapes as faShapesSolid, faVideo as faVideoSolid } from "@fortawesome/pro-solid-svg-icons"

import ErrorBoundary from "utils/ErrorBoundary"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { IDataWithEtag } from "utils/queryHelpers"
import { isLatitude, isLongitude } from "utils/validatePosition"
import GoBackButton from "Components/DragonGoBackButton"
import { DragonConfirmationDialog, DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import { ICameraReadDto, ICameraUpdateDto } from "Types/admin"
import DragonTitle from "Components/DragonTitle"

import Details from "./Details"
import { DragonTab, DragonTabs } from "Components/DragonTabs"
import Icon from "Components/Icon"
import WidgetsWrapper from "./Widgets"
import DragonPageWrapper from "Components/DragonPageWrapper"

export type ICameraDetails = ICameraReadDto
export type ICameraUpdate = ICameraUpdateDto
export type ICameraDetailsWithEtag = IDataWithEtag<ICameraDetails>
const STALE_TIME = 120 * 1000

const getCameraDetails = async ({ queryKey }: QueryFunctionContext<[string, string]>): Promise<ICameraDetailsWithEtag> => {
    const [, id] = queryKey
    const response = await axios.get<ICameraDetails>(`/api/admin/v1/cameras/${id}`)
    return {
        data: response.data,
        eTag: response.headers.etag
    }
}

const getCameras = async (): Promise<ICameraDetails[]> => {
  const response = await axios.get<ICameraDetails[]>("/api/admin/v1/cameras", {
    params: { limit: 2000, offset:0, name: undefined }
  })
  return response.data
}

const putCameraDetails = async (id: string, eTag: string, payload: ICameraUpdate): Promise<AxiosResponse> => {
    return await axios.put(`/api/admin/v1/cameras/${id}`, payload, { headers: { "If-Match": eTag } })
}

const deleteCameraDetails = async (id: string, eTag: string): Promise<AxiosResponse> => {
    return await axios.delete(`/api/admin/v1/cameras/${id}`, { headers: { "If-Match": eTag } })
}
interface Props {
  camera: ICameraDetailsWithEtag
  previousCameraId: string | undefined
  nextCameraId: string | undefined
  cameraList: ICameraDetails[] | undefined
}

enum Tab {
    DETAILS = "details",
    WIDGETS = "widgets"
}



export const CameraDetails: FC<Props> = ({ camera, previousCameraId, nextCameraId, cameraList }) => {
  const { t } = useTranslation()
  const { palette } = useTheme()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const location = useLocation()

    const initialState = useMemo<ICameraUpdate>(() => ({
        description: camera.data.description,
        latitude: camera.data.latitude,
        longitude: camera.data.longitude,
        widgetTemplateIds: camera.data.widgetTemplates.map(({ id }) => id)
    }), [
        camera.data.description,
        camera.data.latitude,
        camera.data.longitude,
        camera.data.widgetTemplates
    ])
    const [draft, setDraft] = useState<ICameraUpdate>(initialState)
    const [saveDialog, setSaveDialog] = useState<boolean>(false)
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
    const [cancelDialog, setCancelDialog] = useState<boolean>(false)
    const [unsavedDialog, setUnsavedDialog] = useState<{ open: boolean, nav: string }>({ open: false, nav: "" })

    useEffect(()=>{
        setDraft(initialState)
    },[initialState])

    const isChanged = useMemo<boolean>(
        () => (
            Object.entries(draft).some(
                ([key, value]) => initialState[key as keyof ICameraUpdate] !== value
            )
        ),
        [draft, initialState]
    )

    const validation = useMemo<Partial<Record<keyof ICameraUpdate, boolean>>>(
        () => (
            {
                latitude: isLatitude(draft.latitude),
                longitude: isLongitude(draft.longitude)
            }
        ),
        [draft.latitude, draft.longitude]
    )

    const { mutateAsync: handleSaveChangesAsync, isLoading } = useMutation(() => putCameraDetails(camera.data.id, camera.eTag, draft),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("cameras")
                queryClient.invalidateQueries(["camera", camera.data.id])
            }
        }
    )
    const { mutateAsync: handleDeleteAsync, isLoading: isDeleteLoading } = useMutation(() => deleteCameraDetails(camera.data.id, camera.eTag),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("cameras")
                queryClient.invalidateQueries(["camera", camera.data.id])
                navigate("..")
            }
        }
    )

    let activeTab: Tab | null = null

    if (location.pathname.endsWith(`camera/${camera.data.id}`))
        activeTab = Tab.DETAILS
    else if (location.pathname.endsWith("/widgets"))
        activeTab = Tab.WIDGETS

    if (!activeTab)
        return null

    return (
        <>
            <GoBackButton />
            <DragonTitle
                title={camera.data.name}
                action={
                    <Box display="flex" gap="15px">
                        <Button
                            onClick={() => {
                                if (isChanged) setUnsavedDialog({ open: true, nav: `../camera/${previousCameraId}` })
                                else navigate(`../camera/${previousCameraId}`)
                            }}
                            startIcon={<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "1.1em", color: palette.text.primary }} />}
                            disabled={!previousCameraId}
                        >
                            {t("previous")}
                        </Button>
                        <Button
                            onClick={() => {
                                if (isChanged) setUnsavedDialog({ open: true, nav: `../camera/${nextCameraId}` })
                                else navigate(`../camera/${nextCameraId}`)
                            }}
                            endIcon={<FontAwesomeIcon icon={faArrowRight} style={{ fontSize: "1.1em", color: palette.text.primary }} />}
                            disabled={!nextCameraId}
                        >
                            {t("next")}
                        </Button>
                    </Box>
                }
            />
            <Paper sx={{ display: "flex", flexDirection: "column", overflow: "hidden", flexGrow: 1 }}>
                <DragonTabs value={activeTab} sx={{ flexGrow: 0 }}>
                    <DragonTab
                        label={t("camera-details-page.details-tab")}
                        value={Tab.DETAILS}
                        icon={
                            <Icon
                                isSelected={activeTab === Tab.DETAILS}
                                icon={faVideo}
                                selectedIcon={faVideoSolid}
                            />
                        }
                        to="."
                    />
                    <DragonTab
                        label={t("camera-details-page.widgets-tab")}
                        value={Tab.WIDGETS}
                        icon={
                            <Icon
                                isSelected={activeTab === Tab.WIDGETS}
                                icon={faShapes}
                                selectedIcon={faShapesSolid}
                            />
                        }
                        to="widgets"
                    />
                </DragonTabs>
                <Divider />
                <Routes>
                    <Route
                        index
                        element={
                            <Details
                                camera={camera.data}
                                cameraList={cameraList ?? []}
                                draft={draft}
                                validation={validation}
                                updateDraft={updated => setDraft({ ...draft, ...updated })}
                            />
                        }
                    />
                    <Route
                        path="widgets"
                        element={
                            <WidgetsWrapper
                                selectedWidgetIds={draft.widgetTemplateIds}
                                onChangeSelectedWidgetIds={updated => setDraft({ ...draft, widgetTemplateIds: updated })}
                                cameraId={camera.data.id}
                            />
                        }
                    />
                </Routes>
            </Paper>

            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: 150 }}
                    onClick={() => setSaveDialog(true)}
                    disabled={!isChanged || Object.values(validation).some(v => !v)}
                >
                    {t("save-changes")}
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    sx={{ minWidth: 150 }}
                    onClick={() => setDeleteDialog(true)}
                >
                    {t("delete-camera")}
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ minWidth: 150 }}
                    onClick={() => {
                        if (isChanged) setCancelDialog(true)
                        else navigate("..")
                    }}
                >
                    {t("cancel")}
                </Button>
            </Stack>
            <DragonConfirmationDialogWithPromise
                open={saveDialog}
                variant="save"
                title={t("camera-details-page.save-changes_confirmation")}
                onClose={() => setSaveDialog(false)}
                onConfirm={async () => {
                    await handleSaveChangesAsync()
                }}
                isLoading={isLoading}
                errorText={t("camera-details-page.save-changes_failed")}
            />
            <DragonConfirmationDialogWithPromise
                open={deleteDialog}
                variant="warning"
                title={t("camera-details-page.delete_confirmation")}
                onClose={() => setDeleteDialog(false)}
                onConfirm={async () => {
                    await handleDeleteAsync()
                }}
                isLoading={isDeleteLoading}
                errorText={t("camera-details-page.delete_failed")}
            />
            <DragonConfirmationDialog
                open={cancelDialog}
                variant="warning"
                title={t("camera-details-page.discard-changes_confirmation")}
                onClose={() => setCancelDialog(false)}
                onConfirm={() => {
                    setDraft(initialState)
                    navigate("..")
                }}
            />
            <DragonConfirmationDialog
                open={unsavedDialog.open}
                title={t("camera-details-page.discard-changes_confirmation")}
                onClose={() => setUnsavedDialog({ open: false, nav: "" })}
                onConfirm={() => {
                    navigate(unsavedDialog.nav)
                }}
            />
        </>
    )
}




const getPreviousCameraId = (cameraIds: string[], cameraId: string) => {
    if (cameraIds.length === 0)
        return undefined

    const index = cameraIds.findIndex(id => id === cameraId)
    if (index === -1)
        return undefined

    if (index === 0)
        return undefined

    return cameraIds[index - 1]
}

const getNextCameraId = (cameraIds: string[], cameraId: string) => {
    if (cameraIds.length === 0)
        return undefined

    const index = cameraIds.findIndex(id => id === cameraId)
    if (index === -1)
        return undefined

    if (index === cameraIds.length - 1)
        return undefined

    return cameraIds[index + 1]
}

export const CameraDetailsContainer: FC<{ cameraIds?: string[] }> = ({ cameraIds = [] }) => {
    const { t } = useTranslation()
    const { cameraId } = useParams<"cameraId">()
    const queryClient = useQueryClient()
    const [error, setError] = useState("")

    if (!cameraId)
        throw new Error("Camera id is not valid")

    const {
        isLoading,
        isError,
        isSuccess,
        data: cameraDetails
    } = useQuery<ICameraDetailsWithEtag, AxiosError, ICameraDetailsWithEtag, [string, string]>(
        ["camera", cameraId],
        getCameraDetails,
        {
            onError: (error) => {
                if (error.response?.status === 404)
                    return setError(t("camera-details.camera-does-not-exist"))
                return setError(t("something-went-wrong"))
            },
            staleTime: STALE_TIME
        }
    )

  const {
    isLoading: isCameraListLoading,
    isError: isCameraListError,
    isSuccess: isCameraListSuccess,
    data: cameraList
  } = useQuery<ICameraDetails[], AxiosError, ICameraDetails[], string>(
    "cameras",
    getCameras,
    {
      onError: () => {
        return setError(t("something-went-wrong"))
      },
      staleTime: STALE_TIME
    }
  )

  const prefetchCameraDetails = useCallback(
    (cameraId: string) => queryClient.prefetchQuery(
      ["camera", cameraId],
      getCameraDetails,
      {
        staleTime: STALE_TIME
      }
    ),
    [queryClient]
  )

    const previousCameraId = useMemo(
        () => getPreviousCameraId(cameraIds, cameraId),
        [cameraIds, cameraId]
    )

    const nextCameraId = useMemo(
        () => getNextCameraId(cameraIds, cameraId),
        [cameraIds, cameraId]
    )

    useEffect(() => {
        if (previousCameraId)
            prefetchCameraDetails(previousCameraId)

        if (nextCameraId)
            prefetchCameraDetails(nextCameraId)

    }, [previousCameraId, nextCameraId, prefetchCameraDetails])

    return (
        <DragonPageWrapper>
            {(() => {
                if (isLoading || isCameraListLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError || isCameraListError)
                    return <h2>{error}</h2>

                if (isSuccess && isCameraListSuccess)
                    return (
                        <ErrorBoundary>
                            <CameraDetails
                                camera={cameraDetails}
                                cameraList={cameraList?.filter(c=>c.id !== cameraDetails.data.id)}
                                previousCameraId={previousCameraId}
                                nextCameraId={nextCameraId}
                            />
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>
    )
}

export default CameraDetailsContainer