import { useQueries } from "react-query"

import {
    Box,
    Stack
} from "@mui/material"

import DragonChartCard from "../DragonChartCard"
import { useTranslation } from "react-i18next"
import { getEventsCount } from "./getEventsData"
import { IEventFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import { defaultSelection } from "./defaultSelection"
import { useMemo, useState } from "react"
import EventFilter from "./Filter"
import EventLineChart from "./EventLineChart"
import { convertDatePartsToDateString, generateTimestampsForTimeframeAndResolution } from "utils/generateTimestampsForTimeframeAndResolution"
import { IChartZoomArea } from "../DetailedLineChart"
import DragonChartCardActions, { IDragonChartCardActions } from "../DragonChartCardActions"

export default function EventCountingWrapper() {
    const [selection, setSelection] = useState<IEventFilterSelection>(defaultSelection)
    function onSubmit(selection: IEventFilterSelection) {
        setSelection(selection)
    }

    return <>
        <Box mx={3} mt={3}>
            <EventFilter onSubmit={onSubmit} />
        </Box>
        <EventCounting filter={selection} />
    </>
}


export function EventCounting({ filter }: { filter: IEventFilterSelection }) {
    const { t } = useTranslation()

    const [
        { data: eventCountData, status: eventCountStatus }
    ] = useQueries([
        { queryKey: ["event-count", filter], queryFn: () => getEventsCount(filter) }
    ])

    const timestamps = useMemo(
        () => generateTimestampsForTimeframeAndResolution(filter.timeFrame, filter.resolution),
        [filter.timeFrame, filter.resolution]
    )
    const [zoom,setZoom] = useState<IChartZoomArea>({leftLimit:"",rightLimit:""})
    const resetZoom= () =>{
        if(timestamps){
            setZoom({leftLimit:convertDatePartsToDateString(timestamps[0]),rightLimit:convertDatePartsToDateString(timestamps[timestamps.length-1])})
        }
    }
    const [eventCountRef,setEventCountRef] = useState<IDragonChartCardActions|null>(null)
    return (
        <Box sx={{ overflow: "auto", p: 3 }}>
            <Stack gap="20px">
                <DragonChartCard
                    title={t("data-analytics-page.event-count")}
                    data={eventCountData}
                    status={eventCountStatus}
                    actions={<DragonChartCardActions reference={eventCountRef} name={t("data-analytics-page.event-count")} />}
                    cardActionsRef={setEventCountRef}
                >
                    <EventLineChart
                        data={eventCountData ?? []}
                        zoom={zoom}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        resolution={filter.resolution}
                        cardActionsRef={setEventCountRef}
                        timestamps={timestamps}
                        insertZeroIfNoDataAtTimeStamp
                    />
                </DragonChartCard>
            </Stack>
        </Box>
    )
}
