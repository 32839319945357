import { FC, useMemo, useState } from "react"
import { Route, Routes } from "react-router-dom"
import axios from "axios"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { Paper, Button } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faEllipsisV } from "@fortawesome/pro-regular-svg-icons"

import { DragonTableWithSearchField, HeaderCell, BodyRow } from "Components/DragonTable"
import CreateServerDialog from "Pages/Servers/CreateServerDialog"
import ServerContextMenu from "Pages/Servers/ServerContextMenu"
import { IServerListDto, IServerSelectDto } from "Types/admin"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"

type IServerListItem = IServerListDto
type IServerSelect = IServerSelectDto

const getServers = async ({ pageParam: offset = 0, queryKey }: QueryFunctionContext): Promise<IServerListItem[]> => {
    const [, searchName] = queryKey
    const response = await axios.get<IServerListItem[]>("/api/admin/v1/servers", {
        params: { limit: 25, offset, name: searchName || undefined }
    })
    return response.data
}


export const Servers: FC = () => {
    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useState<string>("")
    const [createServerDialog, setCreateServerDialog] = useState<boolean>(false)
    const [contextMenu, setContextMenu] = useState<IServerSelect>()
    const [contextMenuAnchor, setContextMenuAnchor] = useState<HTMLButtonElement | null>(null)

    const {
        data, hasNextPage = false, fetchNextPage, isFetching
    } = useInfiniteQuery(["servers", searchValue], getServers, {
        getNextPageParam: (lastPage, allPages) => (
            lastPage.length === 0 ? undefined : allPages.reduce((amount, page) => amount + page.length, 0)
        ),
        keepPreviousData: true
    })

    const tableColumns = useMemo<HeaderCell[]>(() => [
        { value: t("name") },
        { value: t("cameras") },
        { value: null }
    ], [t])

    const tableData = useMemo<BodyRow[]>(() => {
        const list = data?.pages.flat() ?? []
        return list.map(({ id, name, cameras }) => (
            {
                row: [
                    { value: name },
                    { value: `${cameras.slice(0, 2).map(({ name }) => name).join(", ")}${cameras.length > 2 ? t("and-more-with-amount", { amount: cameras.length - 2 }) : ""}` },
                    {
                        value: (
                            <Button color="primary" onClick={(event) => {
                                setContextMenu({ id, name })
                                setContextMenuAnchor(event.currentTarget)
                            }}>
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </Button>
                        ),
                        padding: "checkbox"
                    }
                ],
                hover: true
            }
        ))

    }, [data?.pages, t])

    return (
        <>
            <DragonPageWrapper>
                <DragonTitle title={t("servers")} />
                <Paper sx={{ display: "flex", overflow: "hidden" }}>
                    <DragonTableWithSearchField
                        columns={tableColumns}
                        data={tableData}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetching={isFetching}
                        noDataText={t("no-server-found")}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        searchPlaceholder={t("search-for-server")}
                        toolbarAction={
                            <Button
                                variant="outlined"
                                startIcon={<FontAwesomeIcon icon={faPlus} name={faPlus.iconName} />}
                                onClick={() => setCreateServerDialog(true)}
                            >
                                {t("create-server")}
                            </Button>
                        }
                    />
                </Paper>
            </DragonPageWrapper>
            <CreateServerDialog
                open={createServerDialog}
                onClose={() => setCreateServerDialog(false)}
            />
            {contextMenu && <ServerContextMenu
                server={contextMenu}
                anchor={contextMenuAnchor}
                onClose={() => {
                    setContextMenu(undefined)
                    setContextMenuAnchor(null)
                }}
            />}
            <Routes>
                <Route path="server/:serverId" element={null} />
            </Routes>
        </>
    )
}

export default Servers
