import { assertUnreachable } from "utils/assertUnrechable"
import { ITimeFrame } from "../Filters/TimeFrameSelector/ITimeFrame"

export default function getStartAndEndDateFromTimeFrame(timeframe: ITimeFrame): [Date, Date | null] {
    const now = new Date(Date.now())

    switch (timeframe.type) {
        case "today":
            return [new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
            ), null]
        case "yesterday":
            const startOfYesterday = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() -1
            )

            const endOfYesterday = new Date(
                new Date(
                    now.getFullYear(), 
                    now.getMonth(), 
                    now.getDate()
                ).setMilliseconds(-1)
            )

            return [startOfYesterday, endOfYesterday]
        case "this-week":
            return [new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() - (now.getDay() - 1) // getDay() starts on sunday, so monday is 1
            ), null]
        case "custom":
            return [
                timeframe.from,
                timeframe.to
            ]
        default:
            assertUnreachable(timeframe)
    }
}