import i18next from "i18next"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { IResolution } from "Types/IResolution"
import { convertDatePartsToDateString, convertTimestampToParts, IDateParts } from "utils/generateTimestampsForTimeframeAndResolution"
import DetailedLineChart, { IChartData, IChartZoomArea } from "../DetailedLineChart"
import { IDragonChartCardActions } from "../DragonChartCardActions"
import { IEventData } from "./getEventsData"

interface IEventLineChart {
    data: IEventData[]
    resolution: IResolution
    timestamps: IDateParts[]
    onZoomChange: (zoom: IChartZoomArea)=>void
    onZoomReset:()=>void
    zoom:IChartZoomArea
    insertZeroIfNoDataAtTimeStamp?: boolean
    cardActionsRef?: React.Dispatch<React.SetStateAction<IDragonChartCardActions | null>>
}

export default function EventLineChart({
    data,
    resolution,
    onZoomChange,
    onZoomReset,
    zoom,
    timestamps,
    insertZeroIfNoDataAtTimeStamp = false,
    cardActionsRef = undefined
}: IEventLineChart){
    const { t } = useTranslation()

    const formattedData = transformData(
        data,
        timestamps,
        resolution,
        insertZeroIfNoDataAtTimeStamp
    )

    return <DetailedLineChart
        data={formattedData}
        resolution={resolution}
        zoom={zoom}
        onZoomChange={onZoomChange}
        onZoomReset={onZoomReset}
        cardActionsRef={cardActionsRef}
        legendFormatter={serie => formatSerieName(serie, t)}
        csvFormatter={(name)=>formatCSV(name,data,t)}
        tooltipFormatter={(value, serie) => {
            return [
                value,
                formatSerieName(serie, t)
            ]
        }}
    />
}

function formatSerieName(serie: string, t: typeof i18next.t) {
    const { camera, zone, eventTypeId }: IEventData = JSON.parse(serie)

    const parts = []

    parts.push(camera.name)

    if (zone)
        parts.push(zone.name)

    parts.push(t(`event-types.${eventTypeId}`))

    return parts.join(" > ")
}

function formatCSV(name:string,data:IEventData[], t: typeof i18next.t):Record<string,unknown>[]{
    return data.map(d=>{
        const record = {} as Record<string,unknown>
        record[t("data-analytics-page.csv-export.time")] = new Date(d.timespanGroup).toLocaleString()
        record[t("data-analytics-page.csv-export.epoch")] = new Date(new Date(d.timespanGroup).toUTCString()).getTime()
        record[t("data-analytics-page.csv-export.camera")] = d.camera.name
        record[t("data-analytics-page.csv-export.zone")] = d.zone?.name || "-"
        record[t("data-analytics-page.csv-export.event-type")] = t(`event-types.${d.eventTypeId}`)
        record[name] = d.count
        return record
    })
}

interface ISerie {
    camera: {
        id: string
        name: string
    }
    zone: {
        id: string
        name: string
    } | null
    eventTypeId: string
}

function transformData(
    data: IEventData[],
    timestamps: IDateParts[],
    resolution: IResolution,
    insertZeroIfNoDataAtTimeStamp: boolean
): IChartData[] {
    const allSeries = data.reduce<ISerie[]>((acc, entry) => {
        if (!acc.some(x =>
            x.camera.id === entry.camera.id
            && x.zone?.id === entry.zone?.id
            && x.eventTypeId === entry.eventTypeId
        ))
            acc.push({
                camera: {
                    id: entry.camera.id,
                    name: entry.camera.name
                },
                zone: entry.zone,
                eventTypeId: entry.eventTypeId
            })

        return acc
    }, [])

    const emptySeries = allSeries.reduce<{ [serie: string]: number | null }>((acc, serie) => {
        acc[JSON.stringify(serie)] = insertZeroIfNoDataAtTimeStamp
            ? 0
            : null

        return acc
    }, {})

    const groupedByTimestamp = data
        .map(entry => ({
            camera: {
                id: entry.camera.id,
                name: entry.camera.name
            },
            zone: entry.zone,
            eventTypeId: entry.eventTypeId,
            value: parseFloat(entry.count.toFixed(2)),
            dateParts: convertTimestampToParts(new Date(entry.timespanGroup), resolution)
        }))
        .reduce<{ [key: string]: (ISerie & { value: number })[] }>((acc, { dateParts, ...rest }) => {
            const dateString = convertDatePartsToDateString(dateParts)

            acc[dateString] = [
                ...(acc[dateString] || []),
                rest
            ]

            return acc
        }, {})

    return timestamps.reduce<IChartData[]>((acc, timestamp) => {
        const dateString = convertDatePartsToDateString(timestamp)
        const values = groupedByTimestamp[dateString]

        if (!values) {
            acc.push({
                timestamp: moment(dateString).valueOf(),
                ...emptySeries
            })
        }
        else {
            acc.push({
                timestamp: moment(dateString).valueOf(),
                ...emptySeries,
                ...values.reduce<{ [serie: string]: number | null }>((acc, { value, ...serie }) => {
                    acc[JSON.stringify(serie)] = value

                    return acc
                }, {})
            })
        }

        return acc
    }, [])
}

