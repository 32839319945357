import DragonChartCard from "../DragonChartCard"
import { IPeopleOccupancyData } from "./getPeopleOccupancyData"
import PeopleOccupancyCameraPieChart,{ IPeopleOccupancyCamera }  from "./PeopleOccupancyCameraPieChart"
import Grid from "@mui/material/Grid"

interface IPeopleOccupancyCameraList {
    data: IPeopleOccupancyData[]
}

export default function PeopleOccupancyCameraList({
    data
}: IPeopleOccupancyCameraList) {
    const formattedData = transformData(data)
    
    if(formattedData.length === 0) return null

    return <Grid container spacing={2}>
        {formattedData.map(cam=>(
            <Grid item xs={12} sm={12} md={6} key={cam.name}>
                <DragonChartCard
                    title={cam.name}
                    data={cam.zones}
                    status={"success"}
                    height={"auto"}
                >
                    <PeopleOccupancyCameraPieChart camera={cam} />
                </DragonChartCard>
            </Grid>
        ))}

    </Grid>
}

function transformData(
    data: IPeopleOccupancyData[]
) {
    return data.reduce<IPeopleOccupancyCamera[]>((acc, entry) => {
        const camName = entry.camera.name
        const zoneName = entry.zone?.name 
        let cam = acc.find(x=>x.name === camName)
        if (!cam){
            cam = {
                id: entry.camera.id,
                name: camName,
                zones: []
            }
            acc.push(cam)
        } 
        const zone = cam!.zones.find(z => z.name === zoneName)
        if(!zone){
            cam!.zones.push({
                name: zoneName,
                occupancy: entry.occupancy,
                pointCloud: [...entry.pointCloud]
            })
        } else{
            zone!.occupancy += entry.occupancy
            zone!.pointCloud = [...entry.pointCloud,...zone.pointCloud]
        }
         return acc.sort((a,b)=> a.name>b.name? 1: -1)
    }, [])
}