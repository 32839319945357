import { FC, useState, useEffect } from "react"
import { faSearch, faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TextField, InputAdornment, SxProps, IconButton } from "@mui/material"

interface ISearchFieldWithDebouncer {
    value: string
    onValueChange: (newSearchValue: string) => void
    placeholder?: string
    debounceMs?: number
    sx?: SxProps
}

export const SearchFieldWithDebouncer: FC<ISearchFieldWithDebouncer> = ({ value, onValueChange, placeholder, debounceMs = 500, sx = {} }) => {
    const [search, setSearch] = useState<string>(value ?? "")

    useEffect(() => {
        const timeout = setTimeout(() => {
            onValueChange(search)
        }, debounceMs)
        return () => {
            clearTimeout(timeout)
        }
    }, [onValueChange, search, debounceMs])

    const clearSearchValue = () => {
        setSearch("")
    }

    return (
        <TextField
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            InputProps={{
                disableUnderline: true,
                endAdornment: (
                    <InputAdornment position="end">
                        {search.length > 0
                            ? <IconButton
                                size="small"
                                onClick={clearSearchValue}
                                edge="end"
                                disableRipple
                            >
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: -6 }} />
                            </IconButton>
                            : <FontAwesomeIcon icon={faSearch} data-testid={faSearch.iconName} />
                        }
                    </InputAdornment>
                )
            }}
            placeholder={placeholder}
            sx={sx}
        />
    )
}

export default SearchFieldWithDebouncer