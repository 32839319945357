import { useQueries } from "react-query"

import {
    Box,
    Stack
} from "@mui/material"
import { IPeopleOccupancyFilterSelection } from "../types/IDataAnalyticsFilterSelection"

import { getPeopleOccupancy} from "./getPeopleOccupancyData"
import DragonChartCard from "../DragonChartCard"
import { useTranslation } from "react-i18next"
import { useMemo, useState } from "react"
import { defaultSelection } from "./defaultSelection"
import PeopleOccupancyFilter from "./Filter"
import { convertDatePartsToDateString, generateTimestampsForTimeframeAndResolution } from "utils/generateTimestampsForTimeframeAndResolution"
import { IChartZoomArea } from "../DetailedLineChart"
import DragonChartCardActions, { IDragonChartCardActions } from "../DragonChartCardActions"
import PeopleOccupancyLineChart from "./PeopleOccupancyLineChart"
import PeopleOccupancyCameraList from "./PeopleOccupancyCameraList"

const SYNC_ID = "PeopleOccupancy_Data_Sync"
export default function PeopleOccupancyWrapper() {
    const [selection, setSelection] = useState<IPeopleOccupancyFilterSelection>(defaultSelection)
    function onSubmit(selection: IPeopleOccupancyFilterSelection) {
        setSelection(selection)
    }

    return <>
        <Box mx={3} mt={3}>
            <PeopleOccupancyFilter onSubmit={onSubmit} />
        </Box>
        <PeopleOccupancy filter={selection} />
    </>
}


export function PeopleOccupancy({ filter }: { filter: IPeopleOccupancyFilterSelection }) {
    const { t } = useTranslation()

    const [
        { data: peopleOccupancyData, status: peopleOccupancyStatus }
    ] = useQueries([
        { queryKey: ["peopleOccupancy", filter], queryFn: () => getPeopleOccupancy(filter) }
    ])

    const timestamps = useMemo(
        () => generateTimestampsForTimeframeAndResolution(filter.timeFrame, filter.resolution),
        [filter.timeFrame, filter.resolution]
    )
    const [zoom,setZoom] = useState<IChartZoomArea>({leftLimit:convertDatePartsToDateString(timestamps[0]),rightLimit:convertDatePartsToDateString(timestamps[timestamps.length-1])})
    const resetZoom= () =>{
        if(timestamps){
            setZoom({leftLimit:convertDatePartsToDateString(timestamps[0]),rightLimit:convertDatePartsToDateString(timestamps[timestamps.length-1])})
        }
    }
    const [peopleOccupancyRef,setPeopleOccupancyRef] = useState<IDragonChartCardActions|null>(null)
    return (
        <Box sx={{ overflow: "auto", p: 3 }}>
            <Stack gap="20px">
                <DragonChartCard
                    title={t("data-analytics-page.people-occupancy")}
                    data={peopleOccupancyData}
                    status={peopleOccupancyStatus}
                    actions={<DragonChartCardActions reference={peopleOccupancyRef} name={t("data-analytics-page.people-occupancy")} />}
                >
                    <PeopleOccupancyLineChart
                        data={peopleOccupancyData ?? []}
                        resolution={filter.resolution}
                        zoom={zoom}
                        syncID={SYNC_ID}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        timestamps={timestamps}
                        insertZeroIfNoDataAtTimeStamp
                        cardActionsRef={setPeopleOccupancyRef}
                    />
                </DragonChartCard>
                <PeopleOccupancyCameraList
                    data={peopleOccupancyData ?? []}
                />
            </Stack>
        </Box>
    )
}