import { Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material"
import InputWithMentions from "Pages/EventDetails/InputWithMentions"
import { Link } from "react-router-dom"
import { relativeTimeDisplay } from "utils/timedisplay"
import { IEventComment } from "./types"

export default function MentionsWidget({ data, onClick }: { data: IEventComment[], onClick?: () => void }) {
    return (
        <List
            disablePadding
            sx={{ height: 1, overflow: "auto" }}
        >
            {data.map(mention =>
                <ListItem
                    key={mention.id}
                    disablePadding
                    divider
                >
                    <ListItemButton
                        disableRipple
                        component={Link}
                        to={`event/${mention.eventId}#comments`}
                        onClick={onClick}
                    >
                        <Box flexGrow={1}>
                            <ListItemText
                                primary={mention.createdName}
                                secondary={relativeTimeDisplay(new Date(mention.mentionedAt))}
                                primaryTypographyProps={{ fontWeight: "bold", fontSize: "1em" }}
                                secondaryTypographyProps={{ fontWeight: "bold", fontSize: "1em" }}
                            />
                            <InputWithMentions
                                value={mention.comment}
                                readOnly
                                ControlStyle={{
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: "vertical",
                                    display: "-webkit-box",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    pointerEvents: "none"
                                }}
                            />
                        </Box>
                    </ListItemButton>
                </ListItem>
            )}
        </List >
    )
}