import { faCheck, faTimes, faExclamationTriangle, faInfo, faTrashAlt, faSave } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog, DialogActions, DialogContent, useTheme, IconButton, Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import { DelayedLinearProgress } from "utils/DelayedProgress"

export interface IDragonDialogBase {
    open: boolean
    onClose: () => void
    title?: string
    description?: string
    actions?: React.ReactNode
    hideCloseButton?: boolean
    variant?: "success" | "error" | "warning" | "info" | "delete" | "save"
    children?: React.ReactNode
    isLoading?: boolean
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
}

export default function DragonDialogBase({
    open,
    onClose,
    title,
    description,
    actions,
    hideCloseButton = false,
    variant,
    children,
    isLoading,
    maxWidth = "sm"
}: IDragonDialogBase) {
    const { palette } = useTheme()

    const icon = {
        success: <FontAwesomeIcon icon={faCheck} style={{ color: palette.success.main }} />,
        error: <FontAwesomeIcon icon={faTimes} style={{ color: palette.error.main }} />,
        warning: <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: palette.warning.main }} />,
        info: <FontAwesomeIcon icon={faInfo} />,
        delete: <FontAwesomeIcon icon={faTrashAlt} style={{ color: palette.error.main }} />,
        save: <FontAwesomeIcon icon={faSave} style={{ color: palette.info.light }} />
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ sx: { border: `1px solid ${palette.background.default}` } }}
            maxWidth={maxWidth}
            sx={{
                ".MuiBackdrop-root": {
                    backgroundColor: ({ palette }) => `${palette.background.default}7A`
                }
            }}
            fullWidth
        >
            {!hideCloseButton &&
                <IconButton size="small" onClick={onClose} sx={{ alignSelf: "flex-end", position: "absolute", m: "5px" }}>
                    <FontAwesomeIcon icon={faTimes} />
                </IconButton>
            }
            <DialogContent sx={{ p: "36px 40px", display: "flex", flexDirection: "column", gap: "24px" }}>
                {(title || description || variant) && <ListItem disablePadding alignItems="flex-start">
                    {variant &&
                        <ListItemAvatar sx={{ mr: "24px", mt: 0 }}>
                            <Avatar variant="rounded" sx={{ bgcolor: "background.default", fontSize: "16px", height: 56, width: 56 }}>
                                {icon[variant]}
                            </Avatar>
                        </ListItemAvatar>
                    }
                    <ListItemText sx={{ mt: 0 }}
                        primary={<Typography fontSize="20px" fontWeight={600}>
                            {title}
                        </Typography>}
                        secondary={description && <Typography fontSize="14px" color="text.secondary">
                            {description}
                        </Typography>}
                    />
                </ListItem>}

                {children}

                {actions && (
                    <DialogActions sx={{ p: 0, gap: "24px" }}>
                        {actions}
                    </DialogActions>
                )}
            </DialogContent>
            {isLoading && <DelayedLinearProgress delay={250} />}
        </Dialog>
    )
}
