import { Box, Popover } from "@mui/material"
import { DragonToggleButton, DragonToggleButtonGroup } from "Components/DragonToggleGroup"
import { useEffect, useRef, useState } from "react"

import CustomTimeFrameSelector from "./CustomTimeFrameSelector"
import FilterControlLabel from "Components/Filter/FilterControlLabel"
import { useTranslation } from "react-i18next"
import { ITimeFrame } from "./ITimeFrame"
import { formatDate, formatTime, SecondsDisplay } from "utils/timedisplay"
import { assertUnreachable } from "utils/assertUnrechable"
import CustomSingleDayTimeFrameSelector from "./CustomSingleDayTimeFrameSelector"

interface ITimeFrameSelector {
    timeFrame: ITimeFrame
    onUpdateTimeFrame: (timeFrame: ITimeFrame) => void
    includeCustom?: boolean
    includeWeek?: boolean
    customSingleDay?:boolean
}

export default function TimeFrameSelector({
    timeFrame,
    onUpdateTimeFrame,
    includeCustom = true,
    includeWeek = true,
    customSingleDay= false
}: ITimeFrameSelector) {
    const { t } = useTranslation()

    const [selectedTimeFrame, setSelectedTimeFrame] = useState<"today" | "yesterday" | "this-week" | "custom">(timeFrame.type)
    const [showCustomTimeSelector, setShowCustomTimeSelector] = useState(false)

    useEffect(() => {
        setSelectedTimeFrame(timeFrame.type)
    }, [timeFrame])

    const buttonRef = useRef<HTMLButtonElement | null>(null)

    const baseControlLabel = t("event-filter.time-frame.title")

    const controlLabel = timeFrame.type === "custom"
        ? `${baseControlLabel} (${timeframeDisplayString(timeFrame)})`
        : baseControlLabel

    return <Box>
        <FilterControlLabel text={controlLabel} />
        <DragonToggleButtonGroup
            fullWidth
            exclusive
            aria-label="time-frame"
            value={selectedTimeFrame}
            onChange={(_, newTimeFrame) => {
                if (newTimeFrame === null) {
                    if (selectedTimeFrame === "custom")
                        setShowCustomTimeSelector(true)
                    return
                }

                setSelectedTimeFrame(newTimeFrame)
                setShowCustomTimeSelector(newTimeFrame === "custom")
                if (newTimeFrame !== "custom")
                    onUpdateTimeFrame({ type: newTimeFrame })
            }}
        >
            <DragonToggleButton value="today" aria-label="today">
                {t("event-filter.today")}
            </DragonToggleButton>
            <DragonToggleButton value="yesterday" aria-label="yesterday">
                {t("event-filter.yesterday")}
            </DragonToggleButton>
            {includeWeek && <DragonToggleButton value="this-week" aria-label="this-week">
                {t("event-filter.this-week")}
            </DragonToggleButton>}
            {includeCustom &&
                <DragonToggleButton
                    value="custom"
                    aria-label="custom"
                    ref={buttonRef}
                >
                    {t("event-filter.custom-time-frame")}
                </DragonToggleButton>
            }
            <Popover
                open={showCustomTimeSelector}
                anchorEl={buttonRef.current}
                onClose={() => setShowCustomTimeSelector(false)}
            >
                {!customSingleDay &&<CustomTimeFrameSelector
                    from={timeFrame.type === "custom"
                        ? timeFrame.from
                        : undefined
                    }
                    to={timeFrame.type === "custom"
                        ? timeFrame.to
                        : undefined
                    }
                    onUpdateTimeFrame={(from, to) => {
                        onUpdateTimeFrame({
                            type: "custom",
                            from,
                            to
                        })

                        setShowCustomTimeSelector(false)
                    }}
                />}
                {customSingleDay && <CustomSingleDayTimeFrameSelector
                    day={timeFrame.type === "custom"
                        ? timeFrame.from
                        : undefined
                    }
                    onUpdateTimeFrame={(from, to) => {
                        onUpdateTimeFrame({
                            type: "custom",
                            from,
                            to
                        })

                        setShowCustomTimeSelector(false)
                    }}
                />}
            </Popover>
        </DragonToggleButtonGroup>
    </Box>
}

function timeframeDisplayString(timeframe: ITimeFrame) {
    switch (timeframe.type) {
        case "custom":
            return `${dateFormatString(timeframe.from)} - ${dateFormatString(timeframe.to)}`
        case "today":
        case "yesterday":
        case "this-week":
            return null
        default:
            assertUnreachable(timeframe)
    }
}

function dateFormatString(date: Date) {
    // const isAtMidnight =
    //     (date.getHours() === 0 && date.getMinutes() === 0) ||
    //     (date.getHours() === 23 && date.getMinutes() === 59)

    const dateString = formatDate(date)

    // if (isAtMidnight)
    //     return dateString

    return `${dateString} ${formatTime(date, SecondsDisplay.HIDE_SECONDS)}`
}