import { faEdit } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, Paper, Stack, Divider, Typography, Chip, FormControl, TextField } from "@mui/material"
import { Layer, LayerProps, Marker, Source } from "react-map-gl/maplibre"
import { useTranslation } from "react-i18next"
import { isLatitude, isLongitude } from "utils/validatePosition"
import { ICameraDetails, ICameraUpdate } from "./CameraDetails"
import Map from "Components/Map/Map"
import { ChangeEvent, useCallback } from "react"
import { red,green } from "App/colors"
import { useThemeContext } from "Contexts/ThemeContext"
import { createUnclusteredCamerasNameLayer } from "Components/Map/layers/clusterLayers"
import { Feature, GeoJsonProperties, Geometry } from "geojson"

const RED_MARKER = red[500]
const GREEN_MARKER = green[500]

export const unclusteredCamerasPointLayer: LayerProps = {
    id: "unclustered-camera-point",
    type: "circle",
    paint: {
        "circle-color": GREEN_MARKER,
        "circle-radius": 8,
        "circle-stroke-width": 0
    }
}


interface IDetails {
    camera: ICameraDetails
    draft: ICameraUpdate
    validation: Partial<Record<keyof ICameraUpdate, boolean>>
    updateDraft: (updated: { [key: string]: unknown }) => void
    cameraList: ICameraDetails[]
}

export default function Details({ camera, draft, validation, cameraList, updateDraft }: IDetails) {
    const { t } = useTranslation()
    const { mode } = useThemeContext()
    const handleChangePosition = useCallback<
        (key: "latitude" | "longitude") => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
            >((key) => (event) => {
                const value = parseFloat(event.target.value)
                updateDraft(({ [key]: !isNaN(value) ? value : null }))
            }, [updateDraft])

    const mapPosition = isLatitude(draft.latitude) && isLongitude(draft.longitude)
        ? { latitude: draft.latitude, longitude: draft.longitude }
        : { latitude: camera.defaultLatitude, longitude: camera.defaultLongitude }

    const unclusteredCamerasNameLayer = createUnclusteredCamerasNameLayer(mode)

    return <Grid container component={Paper} overflow="auto" flex={1}>
        <Grid item xs={12} md={4} p={2}>
            <Stack spacing={2} divider={<Divider flexItem />}>
                <Stack spacing={1}>
                    <Typography variant="h6" fontWeight="bold">{t("camera-details-page.zones")}</Typography>
                    <Stack direction="row" flexWrap="wrap">
                        {camera.zones.map(({ id, name }) => (<Chip key={id} label={name} color="primary" sx={{ m: 1 }} />))}
                    </Stack>
                </Stack>
                {camera.server &&<Stack spacing={1}>
                    <Typography variant="h6" fontWeight="bold">{t("camera-details-page.server")}</Typography>
                    <Stack direction="row" flexWrap="wrap">
                        <Chip label={camera.server.name} color="secondary" sx={{ m: 1 }} />
                    </Stack>
                </Stack>}
                <Stack spacing={2}>
                    <FormControl>
                        <Stack spacing={1}>
                            <Typography variant="h6" fontWeight="bold">{t("camera-details-page.latitude")}</Typography>
                            <TextField
                                variant="filled"
                                type="number"
                                InputProps={{
                                    disableUnderline: camera.latitude === draft.latitude,
                                    endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                }}
                                value={draft.latitude ?? ""}
                                onChange={handleChangePosition("latitude")}
                                error={!validation.latitude}
                                fullWidth
                            />
                        </Stack>
                    </FormControl>
                    <FormControl>
                        <Stack spacing={1}>
                            <Typography variant="h6" fontWeight="bold">{t("camera-details-page.longitude")}</Typography>
                            <TextField
                                variant="filled"
                                type="number"
                                InputProps={{
                                    disableUnderline: camera.longitude === draft.longitude,
                                    endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                }}
                                value={draft.longitude ?? ""}
                                onChange={handleChangePosition("longitude")}
                                error={!validation.longitude}
                                fullWidth
                            />
                        </Stack>
                    </FormControl>
                </Stack>
            </Stack>
        </Grid>
        <Grid item xs={12} md={8} minHeight={250} p={2}>
            <Map
                position={mapPosition}
                defaultZoomLevel={15}
                allowInteraction
                
            >
                <Source
                id="othercameras"
                type="geojson"
                data={{
                    type: "FeatureCollection",
                    features: cameraList.filter(cam => cam.latitude && cam.longitude)
                        .map((entry): Feature<Geometry, GeoJsonProperties> => ({
                            type: "Feature",
                            properties: {
                                id: entry.id,
                                name: entry.name
                            },
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    entry.longitude!,
                                    entry.latitude!
                                ]
                            }
                        }))
                }}
            >
                <Layer {...unclusteredCamerasPointLayer} />
                <Layer {...unclusteredCamerasNameLayer} />
            </Source>
                <Marker
                    longitude={draft.longitude ?? camera.defaultLongitude}
                    latitude={draft.latitude ?? camera.defaultLatitude}
                    draggable
                    onDragEnd={evt => {
                        const [longitude, latitude] = evt.lngLat.toArray()

                        updateDraft({ latitude, longitude })
                    }}
                >
                    <div style={{
                        width: 20,
                        height: 20,
                        backgroundColor: RED_MARKER,
                        borderRadius: "50%",
                        border: "2px solid black",
                        outline: "2px solid white"
                    }} />
                </Marker>
            </Map>
        </Grid>
    </Grid>
}