import { useState } from "react"
import axios from "axios"
import { useMutation, useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"

import {
    Box,
    Avatar,
    Stack,
    ListItemText,
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    Button,
    FilledInput,
    InputBaseComponentProps,
    useTheme
} from "@mui/material"

import InputWithMentions from "./InputWithMentions"
import { MentionsInputProps, OnChangeHandlerFunc } from "react-mentions"
import { IDataWithEtag } from "utils/queryHelpers"
import { IEvent, IEventComment, IEventCommentReaction } from "./EventTypes"
import getMaxTwoInitialsFromName from "utils/getMaxTwoInitalsFromName"
import { relativeTimeDisplay } from "utils/timedisplay"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisV, faThumbsUp } from "@fortawesome/pro-regular-svg-icons"

type InputMentionComponentProps = Omit<InputBaseComponentProps, keyof MentionsInputProps> & Partial<MentionsInputProps>
const FilledInputMentionSupport = FilledInput as unknown as React.FC<InputMentionComponentProps>

function CommentReactions({ reactions }: { reactions: IEventCommentReaction[] }) {
    if (reactions.find(reaction => reaction.entity.reactionTypeId !== "ThumbsUp"))
        throw new Error("Unknown comment reaction")

    const { palette } = useTheme()
    const limit = 3
    const thumbsUpReactions = reactions.filter(reaction => reaction.entity.reactionTypeId === "ThumbsUp")

    if (thumbsUpReactions.length === 0)
        return null

    const names = thumbsUpReactions
        .slice(0, limit)
        .map(reaction => reaction.entity.createdName)
        .join(", ")

    const additionalReactionCount = thumbsUpReactions.length - limit

    return (
        <span style={{ color: palette.text.secondary }}>
            {names}
            {additionalReactionCount > 0 && `, +${additionalReactionCount}`}
        </span>
    )
}

export default function Comment({ comment: { entity: comment, eTag }, eventId }: { comment: IEventComment, eventId: string }) {
    const { t } = useTranslation()

    const initialValue = comment.comment
    const [value, setValue] = useState<string>(comment.comment)
    const [mentions, setMentions] = useState<Set<string>>(new Set())
    const handleChange: OnChangeHandlerFunc = (_event, newValue, _newPlainTextValue, newMentions) => {
        const uniqueMentionIds = new Set(newMentions.map(mention => mention.id))
        setMentions(uniqueMentionIds)
        setValue(newValue)
    }

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    function onClose() {
        setAnchorEl(null)
    }
    const isOpen = !!anchorEl
    const id = "comment-popover"

    const [editmode, setEditmode] = useState<boolean>(false)

    const initals = getMaxTwoInitialsFromName(comment.createdName)

    const queryClient = useQueryClient()
    const { mutate: deleteComment } = useMutation(() => {
        return axios.delete(`/api/event/v1/event-comments/${comment.id}`, { headers: { "If-Match": eTag } })
    }, {
        onSuccess: () => {
            queryClient.setQueryData<IDataWithEtag<IEvent> | undefined>(
                ["event", eventId],
                (event: IDataWithEtag<IEvent> | undefined) => {
                    if (!event) throw new Error(`Cached event with id ${eventId} was not found!`)
                    return {
                        ...event,
                        data: {
                            ...event.data,
                            eventComments: event.data.eventComments.filter(comm => comm.entity.id !== comment.id)
                        }
                    }
                }
            )
        }
    })

    const { mutate: updateComment, isLoading: updatingComment } = useMutation(({ value, mentions }: { value: string, mentions: Set<string> }) => {
        return axios.put(`/api/event/v1/event-comments/${comment.id}`, { comment: value, userIds: Array.from(mentions), eventId }, { headers: { "If-Match": eTag } })
    }, {
        onSuccess: ({ data, headers }) => {
            queryClient.setQueryData<IDataWithEtag<IEvent> | undefined>(
                ["event", eventId],
                (event: IDataWithEtag<IEvent> | undefined) => {
                    if (!event) throw new Error(`Cached event with id ${eventId} was not found!`)
                    event.data.eventComments[event.data.eventComments.findIndex(comm => comm.entity.id === comment.id)] = {
                        entity: data,
                        eTag: headers.etag
                    }
                    return event
                }
            )
            setEditmode(false)
        }
    })

    const yourReaction = comment.eventCommentReactions.find(reaction => reaction.entity.createdByCurrentUser)

    const { mutate: like } = useMutation(() => {
        return axios.post("/api/event/v1/event-comment-reactions", {
            eventCommentId: comment.id,
            reactionTypeId: "ThumbsUp"
        })
    }, {
        onSuccess: ({ data, headers }) => {
            queryClient.setQueryData<IDataWithEtag<IEvent> | undefined>(
                ["event", eventId],
                (event: IDataWithEtag<IEvent> | undefined) => {
                    if (!event) throw new Error(`Cached event with id ${eventId} was not found!`)

                    const comments = event.data.eventComments.find(comm => comm.entity.id === comment.id)

                    if (!comments)
                        throw new Error(`Comment with id ${comment.id} was not found on event with id ${event.data.id}`)

                    comments.entity.eventCommentReactions.push({
                        entity: data,
                        eTag: headers.etag
                    })

                    return event
                }
            )
        }
    })

    const { mutate: removeLike } = useMutation(() => {
        if (!yourReaction)
            throw new Error("Tried to remove like on non existant reaction")

        return axios.delete(`/api/event/v1/event-comment-reactions/${yourReaction.entity.id}`, { headers: { "If-Match": yourReaction.eTag } })
    }, {
        onSuccess: () => {
            queryClient.setQueryData<IDataWithEtag<IEvent> | undefined>(
                ["event", eventId],
                (event: IDataWithEtag<IEvent> | undefined) => {
                    if (!event) throw new Error(`Cached event with id ${eventId} was not found!`)
                    const yourReactionIndex = comment
                        .eventCommentReactions
                        .findIndex(reaction =>
                            reaction.entity.createdByCurrentUser
                        )

                    const commentToRemoveReactionOn = event
                        .data
                        .eventComments
                        .find(comm => comm.entity.id === comment.id)

                    if (!commentToRemoveReactionOn)
                        throw new Error(`Failed to find comment with id ${comment.id}`)

                    commentToRemoveReactionOn.entity.eventCommentReactions.splice(yourReactionIndex, 1)

                    return event
                }
            )
        }
    })

    const handleCancel = () => {
        setValue(initialValue)
        setEditmode(false)
    }

    return (
        <Stack direction="row">
            <Avatar sx={{ width: 47, height: 47, m: "5px", mr: "15px" }}>
                <p style={{ margin: "4px 0 0 0" }}>
                    {initals}
                </p>
            </Avatar>
            <Box flexGrow={1}>
                <ListItemText
                    primary={comment.createdName}
                    secondary={relativeTimeDisplay(new Date(comment.createdAt))}
                    primaryTypographyProps={{ fontWeight: "bold", fontSize: "1em" }}
                    secondaryTypographyProps={{ fontWeight: "bold", fontSize: "1em" }}
                />
                {editmode ?
                    <FilledInputMentionSupport
                        value={value}
                        onChange={handleChange}
                        inputComponent={InputWithMentions}
                        endAdornment={
                            <Stack gap="10px" direction="row">
                                <Button
                                    variant="contained"
                                    onClick={handleCancel}
                                >
                                    {t("event-details.cancel")}
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => updateComment({ value, mentions })}
                                    disabled={!value || updatingComment}
                                >
                                    {t("event-details.edit-comment")}
                                </Button>
                            </Stack>
                        }
                        sx={{ alignItems: "end", p: "11px", flexDirection: "column" }}
                        fullWidth
                        disableUnderline
                    /> :
                    <InputWithMentions value={value} readOnly />
                }
                <Checkbox
                    icon={<FontAwesomeIcon icon={faThumbsUp} style={{ fontSize: "1.2em" }} />}
                    checkedIcon={<FontAwesomeIcon icon={faThumbsUp} style={{ fontSize: "1.2em" }} />}
                    checked={!!yourReaction}
                    onChange={() => yourReaction ? removeLike() : like()}
                    size="small"
                />
                <span style={{ fontWeight: "bold", margin: "0 17px 0 5px" }}>{comment.eventCommentReactions.length}</span>
                <CommentReactions reactions={comment.eventCommentReactions} />
            </Box>
            {comment.createdByCurrentUser &&
                < >
                    <IconButton onClick={event => setAnchorEl(event.currentTarget)} sx={{ height: "fit-content", m: 1 }}>
                        <FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: ".85em" }} />
                    </IconButton>
                    <Menu
                        id={id}
                        open={isOpen}
                        anchorEl={anchorEl}
                        onClose={onClose}
                        onClick={onClose}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                        <MenuItem onClick={() => setEditmode(true)}>
                            {t("event-details.edit-comment")}
                        </MenuItem>
                        <MenuItem onClick={() => deleteComment()}>
                            {t("event-details.delete-comment")}
                        </MenuItem>
                    </Menu>
                </>
            }
        </Stack>
    )
}