export const widgetTypes = [
    {
        id: "Donut",
        dataSources: ["EventCount", "VehicleCount", "PeopleCount","BikeCount","PeopleOccupancy"]
    },
    {
        id: "Line",
        dataSources: ["EventCount", "VehicleCount", "VehicleSpeed", "TrafficInfoDensity", "TrafficInfoSpeed", "PeopleCount","BikeCount","PeopleOccupancy"]
    },
    {
        id: "Bar",
        dataSources: ["EventCount","VehicleCount"]
    },
    {
        id: "Heatmap",
        dataSources: ["GateCount"]
    },
    {
        id: "Table",
        dataSources: ["EventJournal", "EventComment"]
    }
]