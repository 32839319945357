import { FormControl, FilledInput, InputBaseComponentProps, List, ListItemButton, ListItemText, ListItem } from "@mui/material"
import FilterControlLabel from "Components/Filter/FilterControlLabel"
import { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import IWidgetSelection from "../types/IWidgetSelection"
import { widgetTypes } from "../utils/widgetTypes"

interface IWidgetTypeSelector {
    selectedValue: IWidgetSelection["widgetType"]
    dataSource: IWidgetSelection["dataSource"]
    onChange: (newValue: IWidgetSelection["widgetType"]) => void
}

export default function WidgetTypeSelector({ selectedValue, dataSource, onChange }: IWidgetTypeSelector) {
    const { t } = useTranslation()

    function handleChange(value: IWidgetSelection["widgetType"]) {
        if (value === selectedValue) onChange(null)
        else onChange(value)
    }

    return (
        <FormControl fullWidth sx={{ height: "100%" }}>
            <FilterControlLabel text={t("widget-creator.widget-type")} />
            <FilledInput
                inputProps={{ selectedValue, dataSource, handleChange }}
                inputComponent={WidgetTypesList}
                disableUnderline
                sx={{ flexGrow: 1, alignItems: "start" }}
            />
        </FormControl>
    )
}

const WidgetTypesList = forwardRef(({ selectedValue, dataSource, handleChange }: IWidgetTypeSelector & InputBaseComponentProps, _ref) => {
    const { t } = useTranslation()

    return (
        <List sx={{ width: "100%" }}>
            {widgetTypes.map((widgetType) => (
                <ListItem
                    key={widgetType.id}
                    sx={{ backgroundColor: widgetType.id === selectedValue ? "primary.main" : undefined }}
                    disablePadding
                >
                    <ListItemButton
                        selected={widgetType.id === selectedValue}
                        onClick={() => handleChange(widgetType.id)}
                        disabled={!([...widgetType.dataSources, null].includes(dataSource))}
                        dense
                    >
                        <ListItemText primary={t(`widget-creator.widget-types.${widgetType.id}`)} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    )
})