import { CircularProgress, CircularProgressProps, LinearProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { assertUnreachable } from "./assertUnrechable"

interface IDelayedProgress {
    delay: number
    size?: number
    color?: CircularProgressProps["color"]
}

export function DelayedCircularProgress(props: IDelayedProgress) {
    return <DelayedProgress {...props} type="circular" />
}

export function DelayedLinearProgress(props: Omit<IDelayedProgress, "size">) {
    return <DelayedProgress {...props} type="linear" />
}

function DelayedProgress({ delay, size, color, type }: IDelayedProgress & { type: "circular" | "linear" }) {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setVisible(true)
        }, delay)

        return () => {
            clearTimeout(timeout)
        }
    }, [delay])

    if (!visible)
        return null

    switch (type) {
        case "circular":
            return <CircularProgress size={size} color={color} />
        case "linear":
            return <LinearProgress color={color} />
        default:
            assertUnreachable(type)
    }
}