import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Stack } from "@mui/material"
import axios from "axios"
import DragonDialog from "Components/DragonDialog"
import { DragonToggleButton, DragonToggleButtonGroup } from "Components/DragonToggleGroup"
import FilterControlLabel from "Components/Filter/FilterControlLabel"
import getStartAndEndDateFromTimeFrame from "Components/Filter/utils/getTimeFrameFromFilter"
import LanguageSelector from "Components/LanguageSelector"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import { IFilteredStateId } from "Types/event/IFilteredStateId"
import { ISimulatedStateId } from "Types/event/ISimulatedStateId"
import { IValidStateId } from "Types/event/IValidStateId"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import IEventJournalFilterSelection from "./types/IEventJournalFilterSelection"
import getFilteredStateIds from "./utils/getFilteredStateIds"
import getMostSpecificIdsFromFilter from "./utils/getMostSpecificIdsFromFilter"

interface IEventExport {
    eventTypeIds: string[]
    startedAtGe: Date
    endedAtLe: Date | null
    validStateIds: IValidStateId[] | null
    simulatedStateIds: ISimulatedStateId[] | null
    filteredStateIds: IFilteredStateId[] | null
    exportAsId: "EventExportFormatIdHtml" | "EventExportFormatIdCsv"
    includeVideo: boolean
    includeComments: boolean
    locale: string
    timeZone: string
    cameraGroupIds: string[]
    cameraIds: string[]
    zoneIds: string[]
}

type IDefaultExportConfig = Pick<IEventExport, "exportAsId" | "includeVideo" | "includeComments" | "locale" | "timeZone">

async function getDefaultConfig(): Promise<IDefaultExportConfig> {
    const response = await axios.get<IEventExport>("/api/event/v1/event-exports/default")
    const { exportAsId, includeVideo, includeComments, locale, timeZone } = response.data
    return { exportAsId, includeVideo, includeComments, locale, timeZone }
}

interface IExportConfiguratorModalWrapper {
    open: boolean
    handleClose: () => void
    timeOfFilterApplication: Date
    selection: IEventJournalFilterSelection
}

export default function ExportConfiguratorModalWrapper({ open, handleClose, timeOfFilterApplication, selection }: IExportConfiguratorModalWrapper) {

    const { data: defaultConfig, isLoading, isSuccess } = useQuery("defaultExportConfig", getDefaultConfig)

    return (
        <DragonDialog open={open} onClose={handleClose}>
            {(() => {
                if (isLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isSuccess)
                    return (
                        <ExportConfiguratorModal
                            timeOfFilterApplication={timeOfFilterApplication}
                            selection={selection}
                            defaultConfig={defaultConfig}
                            handleClose={handleClose}
                        />
                    )
            })()}
        </DragonDialog>
    )
}

interface IExportConfiguratorModal {
    timeOfFilterApplication: Date
    selection: IEventJournalFilterSelection
    defaultConfig: IDefaultExportConfig
    handleClose: () => void
}

function ExportConfiguratorModal({ timeOfFilterApplication, selection, defaultConfig, handleClose }: IExportConfiguratorModal) {
    const { t, i18n } = useTranslation()

    const [startedAtGe, endedAtLe] = getStartAndEndDateFromTimeFrame(selection.timeFrame)

    const [exportConfig, setExportConfig] = useState<IEventExport>({
        ...defaultConfig,
        ...getMostSpecificIdsFromFilter(selection),
        ...getFilteredStateIds(selection),
        startedAtGe,
        endedAtLe: endedAtLe ?? timeOfFilterApplication,
        eventTypeIds: selection.eventTypeIds,
        locale: i18n.language,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    } as IEventExport)

    const { mutate: submit } = useMutation((exportConfig: IEventExport) => {
        return axios.post("/api/event/v1/event-exports", exportConfig)
    }, {
        onSuccess: handleClose
    })

    return (
        <Box>
            <Stack spacing={2}>
                <Box>
                    <FilterControlLabel text={t("export-configurator.format")} />
                    <DragonToggleButtonGroup
                        fullWidth
                        exclusive
                        aria-label="format"
                        value={exportConfig.exportAsId}
                        onChange={(_, newFormat) => newFormat && setExportConfig(prevState => ({ ...prevState, exportAsId: newFormat }))}
                    >
                        <DragonToggleButton value="EventExportFormatIdHtml" aria-label="html">
                            {t("export-configurator.html")}
                        </DragonToggleButton>
                        <DragonToggleButton value="EventExportFormatIdCsv" aria-label="csv">
                            {t("export-configurator.csv")}
                        </DragonToggleButton>
                    </DragonToggleButtonGroup>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <FilterControlLabel text={t("export-configurator.locale")} />
                        <LanguageSelector
                            value={exportConfig.locale}
                            onChange={(e) => setExportConfig(prevState => ({ ...prevState, locale: e.target.value }))}
                        />
                    </FormControl>
                </Box>
                <Box>
                    <FilterControlLabel text={t("export-configurator.videos-comments")} />
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={exportConfig.includeVideo}
                                    onChange={() => setExportConfig(prevState => ({ ...prevState, includeVideo: !exportConfig.includeVideo }))}
                                />
                            }
                            label={t("export-configurator.videos") as string}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={exportConfig.includeComments}
                                    onChange={() => setExportConfig(prevState => ({ ...prevState, includeComments: !exportConfig.includeComments }))}
                                />
                            }
                            label={t("export-configurator.comments") as string}
                        />
                    </FormGroup>
                </Box>
            </Stack>

            <Stack direction="row" justifyContent="end" mt={3}>
                <Button variant="contained" onClick={() => submit(exportConfig)}>
                    {t("event-filter.generate-report")}
                </Button>
            </Stack>
        </Box>
    )
}