import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "react-query"
import {
    Stack,
    Paper,
    TextField,
    Typography,
    Divider,
    Grid,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button
} from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faFileAlt, faUsers, faVideo } from "@fortawesome/pro-regular-svg-icons"
import {
    faFileAlt as faFileAltSolid,
    faUsers as faUsersSolid,
    faVideo as faVideoSolid
} from "@fortawesome/pro-solid-svg-icons"

import ErrorBoundary from "utils/ErrorBoundary"
import { DelayedCircularProgress, DelayedLinearProgress } from "utils/DelayedProgress"
import { IDataWithEtag } from "utils/queryHelpers"
import GoBackButton from "Components/DragonGoBackButton"
import { DragonConfirmationDialog, DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import { DragonTab, DragonTabs } from "Components/DragonTabs"
import DragonTable, { BodyRow, HeaderCell } from "Components/DragonTable"
import CameraGroupAssignmentForm from "Pages/TeamDetails/CameraGroupAssignmentForm"
import { ITeamReadDto, ITeamCreateUpdateDto, IPermissionDto, IPermissionIdEnum } from "Types/admin"
import Icon from "Components/Icon"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"
import { useUserProfile } from "hooks/useUserProfile"

type ITeamDetails = ITeamReadDto
type ITeamUpdate = ITeamCreateUpdateDto & {
    description: NonNullable<ITeamCreateUpdateDto["description"]>
    cameraGroupIds: NonNullable<ITeamCreateUpdateDto["cameraGroupIds"]>
    scenarioGroupIds: NonNullable<ITeamCreateUpdateDto["scenarioGroupIds"]>
}
type ITeamDetailsWithEtag = IDataWithEtag<ITeamDetails>
type IPermission = IPermissionDto

enum Tab {
    DESCRIPTIONS = "descriptions",
    MEMBERS = "members",
    CAMERA_GROUPS = "camera-groups",
    SCENARIO_GROUPS = "scenario-groups"
}

const NAME_MIN_LENGTH = 1
const NAME_MAX_LENGTH = 100
const DESC_MAX_LENGTH = 1000

const getTeamDetails = async ({ queryKey }: QueryFunctionContext<[string, string]>): Promise<ITeamDetailsWithEtag> => {
    const [, id] = queryKey
    const response = await axios.get<ITeamDetails>(`/api/admin/v1/teams/${id}`)
    return {
        data: response.data,
        eTag: response.headers.etag
    }
}

const postTeamDetails = async (payload: ITeamUpdate): Promise<AxiosResponse> => {
    return await axios.post("/api/admin/v1/teams", payload)
}

const putTeamDetails = async (id: string, eTag: string, payload: ITeamUpdate): Promise<AxiosResponse> => {
    return await axios.put(`/api/admin/v1/teams/${id}`, payload, { headers: { "If-Match": eTag } })
}

const deleteTeam = async (id: string, eTag: string): Promise<AxiosResponse> => {
    return await axios.delete(`/api/admin/v1/teams/${id}`, { headers: { "If-Match": eTag } })
}

const getPermissions = async (): Promise<IPermission[]> => {
    const response = await axios.get<IPermission[]>("/api/admin/v1/permissions")
    return response.data
}

interface Props {
    team: ITeamDetailsWithEtag
    newTeam: boolean
}

export const TeamDetails: FC<Props> = ({ team, newTeam }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const queryClient = useQueryClient()
    const initialState = useMemo<ITeamUpdate>(() => ({
        name: team.data.name,
        description: team.data.description ?? "",
        permissionIds: team.data.permissionIds,
        cameraGroupIds: team.data.cameraGroups.map(({ id }) => id),
        scenarioGroupIds: team.data.scenarioGroups.map(({ id }) => id)
    }), [team.data])
    const [draft, setDraft] = useState<ITeamUpdate>(initialState)
    const [activeTab, setActiveTab] = useState<Tab>(Tab.DESCRIPTIONS)
    const [saveDialog, setSaveDialog] = useState<boolean>(false)
    const [cancelDialog, setCancelDialog] = useState<boolean>(false)
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)

    const [saveError, setSaveError] = useState<string | null>(null)

    const isChanged = useMemo<boolean>(
        () => (
            initialState.name !== draft.name ||
            initialState.description !== draft.description ||
            (
                initialState.permissionIds.length !== draft.permissionIds.length ||
                initialState.permissionIds.some(id => !draft.permissionIds.includes(id))
            ) || (
                initialState.cameraGroupIds.length !== draft.cameraGroupIds.length ||
                initialState.cameraGroupIds.some(id => !draft.cameraGroupIds.includes(id))
            ) || (
                initialState.scenarioGroupIds.length !== draft.scenarioGroupIds.length ||
                initialState.scenarioGroupIds.some(id => !draft.scenarioGroupIds.includes(id))
            )
        ),
        [draft, initialState]
    )

    const validations = useMemo<Partial<Record<keyof ITeamUpdate, boolean>>>(
        () => (
            {
                name: draft.name.length >= NAME_MIN_LENGTH && draft.name.length <= NAME_MAX_LENGTH,
                description: draft.description.length <= DESC_MAX_LENGTH
            }
        ),
        [draft.description.length, draft.name.length]
    )

    const { data: permissions, isFetching: isFetchingPermissions } = useQuery("permissions", getPermissions)

    const { mutateAsync: handleSaveChangesAsync, isLoading: putLoading } = useMutation(
        () => {
            if (newTeam) return postTeamDetails(draft)
            return putTeamDetails(team.data.id, team.eTag, draft)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("teams")
                if (!newTeam) queryClient.invalidateQueries(["team", team.data.id])

                // If the user has both administrator and operator roles
                // and changes team permissions on the team she belongs to
                // she would otherwise need to reload the page
                // before being able to use the updated team permissions
                queryClient.invalidateQueries("user-settings")
            },
            onError: (error: AxiosError<{errors:{validationRule:string}}>) => {
                if (error.response?.data?.errors?.validationRule.includes("TeamNameDuplicate"))
                    setSaveError(t("team-details-page.name-already-taken"))
                else
                    setSaveError(t("team-details-page.save-changes_failed"))
            }
        }
    )

    const { mutateAsync: handleDeleteTeam, isLoading: deleteLoading } = useMutation(
        () => deleteTeam(team.data.id, team.eTag),
        { onSuccess: () => { queryClient.invalidateQueries("teams") } }
    )

    const handleChange = useCallback<(key: keyof ITeamUpdate, value: unknown) => void>((key, value) => {
        setDraft(prevState => ({ ...prevState, [key]: value }))
    }, [])

    const handleChangePermission = useCallback<(permission: IPermissionIdEnum, checked: boolean) => void>(
        (permission, checked) => {
            setDraft((prevState) => ({
                ...prevState,
                permissionIds: checked ?
                    [...prevState.permissionIds, permission]
                    : prevState.permissionIds.filter((id => id !== permission))
            }))
        }, []
    )

    const memberTableColumns = useMemo<HeaderCell[]>(
        () => [{ value: t("name") }],
        [t]
    )

    const memberTableData = useMemo<BodyRow[]>(
        () => team.data.users.map(({ id, name }) => ({ key: id, row: [{ value: name }], hover: true })),
        [team.data.users]
    )

    const {features} = useUserProfile()
    const scenarioEnabled = features.some(f => f === "ScenarioEnabled")

    useEffect(() => {
        const hash = location.hash.replace("#", "")
        let selectedTab: Tab | undefined
        switch (hash) {
            case "":
                selectedTab = Tab.DESCRIPTIONS
                break
            case Tab.MEMBERS:
                selectedTab = Tab.MEMBERS
                break
            case Tab.CAMERA_GROUPS:
                selectedTab = Tab.CAMERA_GROUPS
                break
            case Tab.SCENARIO_GROUPS:
                selectedTab = Tab.SCENARIO_GROUPS
                break
            default:
                selectedTab = undefined
        }
        if (selectedTab && !((selectedTab === Tab.SCENARIO_GROUPS) && !scenarioEnabled)) {
            setActiveTab(selectedTab)
        } else {
            navigate(".")
        }
    }, [location.hash,scenarioEnabled, navigate])

    return (
        <>
            <GoBackButton />
            <DragonTitle title={team.data.name || t("create-team")} />
            <Paper sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <Stack direction="row">
                    <DragonTabs value={activeTab}>
                        <DragonTab
                            label={t("team-details-page.descriptions")}
                            value={Tab.DESCRIPTIONS}
                            icon={
                                <Icon
                                    isSelected={activeTab === Tab.DESCRIPTIONS}
                                    icon={faFileAlt}
                                    selectedIcon={faFileAltSolid}
                                />
                            }
                            to="."
                        />
                        <DragonTab
                            label={t("team-details-page.members")}
                            value={Tab.MEMBERS}
                            icon={
                                <Icon
                                    isSelected={activeTab === Tab.MEMBERS}
                                    icon={faUsers}
                                    selectedIcon={faUsersSolid}
                                />
                            }
                            to="#members"
                        />
                        <DragonTab
                            label={t("team-details-page.camera-groups")}
                            value={Tab.CAMERA_GROUPS}
                            icon={
                                <Icon
                                    isSelected={activeTab === Tab.CAMERA_GROUPS}
                                    icon={faVideo}
                                    selectedIcon={faVideoSolid}
                                />
                            }
                            to="#camera-groups"
                        />
                    </DragonTabs>
                </Stack>
                <Divider flexItem />
                {/* DESCRIPTIONS */}
                {activeTab === Tab.DESCRIPTIONS && (
                    <Stack padding={2} spacing={2} overflow="auto">
                        <FormControl fullWidth>
                            <Typography variant="h6" fontWeight="bold">
                                {t("team-details-page.name")}
                            </Typography>
                            <TextField
                                variant="filled"
                                InputProps={{
                                    disableUnderline: initialState.name === draft.name,
                                    endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                }}
                                value={draft.name}
                                onChange={(event) => handleChange("name", event.target.value)}
                                error={!validations.name}
                                fullWidth
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography variant="h6" fontWeight="bold">
                                {t("team-details-page.description")}
                            </Typography>
                            <TextField
                                variant="filled"
                                multiline
                                InputProps={{
                                    disableUnderline: initialState.description === draft.description,
                                    endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                }}
                                value={draft.description}
                                onChange={(event) => handleChange("description", event.target.value)}
                                error={!validations.description}
                                fullWidth
                            />
                        </FormControl>
                        <Stack>
                            <Typography variant="h6" fontWeight="bold">{t("team-details-page.permissions")}</Typography>
                            {isFetchingPermissions ? (
                                <DelayedLinearProgress delay={250} />
                            ) : (
                                <Grid container spacing={2} pt={2}>
                                    {permissions?.map(({ id: permission }) => (
                                        <Grid key={permission} item xs={12} md={6}>
                                            <FormControl sx={{ alignItems: "flex-start" }}>
                                                <FormControlLabel
                                                    label={t(`event-permissions.${permission}`) as string}
                                                    componentsProps={{ typography: { variant: "h6", sx: { userSelect: "none" } } }}
                                                    control={<Checkbox
                                                        checked={draft.permissionIds.includes(permission)}
                                                        onChange={(event) => handleChangePermission(permission, event.target.checked)}
                                                    />}
                                                />
                                            </FormControl>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Stack>
                    </Stack>
                )}
                {/* MEMBERS */}
                {activeTab === Tab.MEMBERS && (
                    <Stack pt={2} spacing={2} overflow="hidden">
                        <Typography px={2} variant="h6" fontWeight="bold">{t("team-details-page.members-overview")}</Typography>
                        {memberTableData.length === 0 ? (
                            <Typography px={2} pb={2}>{t("team-details-page.no-members", { team: team.data.name })}</Typography>
                        ) : (
                            <DragonTable
                                columns={memberTableColumns}
                                data={memberTableData}
                            />)}
                    </Stack>
                )}
                {/* CAMERA GROUPS */}
                {activeTab === Tab.CAMERA_GROUPS && (
                    <Stack flex={1} spacing={2} p={2} overflow="hidden">
                        <CameraGroupAssignmentForm
                            cameraGroups={team.data.cameraGroups ?? []}
                            onChange={(cameraGroupIds) => setDraft(prevState => ({ ...prevState, cameraGroupIds }))}
                        />
                    </Stack>
                )}
            </Paper>
            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                {!newTeam && <Button
                    variant="outlined"
                    color="error"
                    sx={{ minWidth: 150 }}
                    onClick={() => setDeleteDialog(true)}
                >
                    {t("team-details-page.delete-group")}
                </Button>}
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: 150 }}
                    onClick={() => setSaveDialog(true)}
                    disabled={!isChanged || Object.values(validations).some(v => !v)}
                >
                    {newTeam ? t("create-team") : t("save-changes")}
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ minWidth: 150 }}
                    onClick={() => {
                        if (isChanged) setCancelDialog(true)
                        else navigate("..")
                    }}
                >
                    {t("cancel")}
                </Button>
            </Stack>
            <DragonConfirmationDialogWithPromise
                open={saveDialog}
                variant="save"
                title={t("team-details-page.save-changes_confirmation")}
                onClose={() => {
                    setSaveDialog(false)
                    setSaveError(null)
                }}
                onConfirm={async () => {
                    await handleSaveChangesAsync()
                    navigate("..")
                }}
                isLoading={putLoading}
                errorText={saveError}
            />
            <DragonConfirmationDialog
                open={cancelDialog}
                variant="warning"
                title={t("team-details-page.discard-changes_confirmation")}
                onClose={() => setCancelDialog(false)}
                onConfirm={() => {
                    setDraft(initialState)
                    navigate("..")
                }}
            />
            <DragonConfirmationDialogWithPromise
                open={deleteDialog}
                variant="delete"
                title={t(
                    "team-details-page.delete-team_confirmation",
                    { team: team.data.name }
                )}
                onClose={() => setDeleteDialog(false)}
                onConfirm={async () => {
                    await handleDeleteTeam()
                    navigate("..")
                }}
                isLoading={deleteLoading}
                errorText={t("team-details-page.delete-team_failed")}
            />
        </>
    )
}

export const TeamDetailsContainer: FC = () => {
    const { t } = useTranslation()
    const { teamId } = useParams<"teamId">()
    const [error, setError] = useState("")

    const {
        isLoading,
        isError,
        isSuccess,
        data
    } = useQuery<ITeamDetailsWithEtag, AxiosError, ITeamDetailsWithEtag, [string, string]>(
        ["team", teamId ?? "default"],
        getTeamDetails,
        {
            onError: (error) => {
                if (error.response?.status === 404)
                    return setError(t("team-details.team-does-not-exist"))
                return setError(t("something-went-wrong"))
            }
        }
    )

    return (
        <DragonPageWrapper>
            {(() => {
                if (isLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{error}</h2>

                if (isSuccess)
                    return (
                        <ErrorBoundary>
                            <TeamDetails team={data} newTeam={!teamId} />
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>
    )
}

export default TeamDetailsContainer