import { ICameraSelectDto } from "."

export interface IServerSelectDto {
    id: string
    name: string
}

export interface IServerListDto {
    id: string
    name: string
    cameras: ICameraSelectDto[]
}

export interface IServerCreateDto {
    id: string
    name: string
    description: string | null
    apiUri: string
    serverIp: string | null
    username: string
    password: string
    confirmPassword: string
}

export interface IServerTestDto {
    apiUri: string
}

export interface IServerInfoSyncDto {
    id: string
    systemName: string
    systemIp: string | null

}
export interface IServerSyncCameraDto {
    serverSyncStateId: IServerSyncStateId
    id: string | null
    externalId: string | null
    name: string | null
    zones: IServerSyncZoneDto[] | null
}

export interface IServerSyncDto {
    serverInfo: IServerInfoSyncDto | null
    cameras: IServerSyncCameraDto[]
}
export interface IServerSyncZoneDto {
    serverSyncStateId: IServerSyncStateId
    id: string | null
    externalId: string | null
    name: string | null
}

export enum IServerSyncStateId {
    None = "None",
    Create = "Create",
    Update = "Update",
    Delete = "Delete"
}

export interface IServerReadDto {
    name: string
    description: string | null
    apiUri: string
    id: string
    username: string
    passwordHash1UpdatedAt: string | null
    passwordHash2UpdatedAt: string | null
    syncedAt: string | null
    createdAt: string | null
    createdName: string | null
    updatedAt: string | null
    updatedName: string | null
    cameras: ICameraSelectDto[]
}