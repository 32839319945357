import React from "react"
import { ThemeProvider } from "@mui/system"
import { ThemeContextConsumer, ThemeContextProvider } from "Contexts/ThemeContext"
import { createTheme, CssBaseline, ThemeOptions } from "@mui/material"
import { deepmerge } from "@mui/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"
import { teal, purple, orange, green, red, blue } from "./colors"
import {ThemeProvider as NivoThemeProvider, Theme as NivoTheme} from "@nivo/core"

declare module "@mui/material/styles/createPalette" {
    interface TypeBackground {
        frame: string
    }
}

const BASE_FONT_SIZE = 13

const baseThemeOptions: ThemeOptions = {
    typography: {
        fontFamily: "BasisGrotesqePro",
        button: {
            textTransform: "none"
        },
        fontSize: BASE_FONT_SIZE,
        h1: {
            fontSize: 34,
            fontWeight: "bold"
        },
        body1: {
            fontSize: BASE_FONT_SIZE
        }
    },
    spacing: 6,
    palette: {
        primary: {
            ...teal,
            main: teal[500]
        },
        secondary: {
            ...purple,
            main: purple[500]
        },
        error: {
            ...red,
            main: red[500]
        },
        success: {
            ...green,
            main: green[500]
        },
        info: {
            ...blue,
            main: blue[500]
        },
        warning: {
            ...orange,
            main: orange[500]
        }
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                "elevation": {
                    backgroundImage: "unset"
                },
                "elevation1": {
                    boxShadow: "0px 1px 4px -2px rgba(0, 0, 0, 0.2)"
                },
                "elevation8": {
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12),0px 7px 7px rgba(0, 0, 0, 0.08)"
                },
                "elevation24": {
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)"
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                "root": {
                    boxShadow: "none"
                }
            },
            defaultProps: {
                enableColorOnDark: true
            }
        },
        MuiButton: {
            styleOverrides: {
                "root": {
                    boxShadow: "none"
                },
                "containedPrimary": {
                    "&:hover": {
                        boxShadow: "none"
                    }
                },
                "containedSecondary": {
                    "&:hover": {
                        boxShadow: "none"
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                    fontSize: BASE_FONT_SIZE
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    marginTop: "3px"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                "stickyHeader": ({ theme }) => ({
                    fontWeight: "bold",
                    backgroundColor: theme.palette.background.paper
                })
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderRadius: 4
                },
                inputMultiline: {
                    paddingTop: "12px",
                    paddingBottom: "12px"
                },
                input: {
                    paddingTop: "12px",
                    paddingBottom: "12px"
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: (props) => (
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{ fontSize: "1.2em", right: "14px" }}
                        {...props}
                    />
                )
            },
            styleOverrides: {
                "select": {
                    paddingRight: "46px !important"
                }
            }
        },
        MuiAccordionSummary: {
            defaultProps: {
                expandIcon: <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ fontSize: "1.2em", margin: "6px" }}
                />
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "filled",
                InputProps: {
                    disableUnderline: true
                }
            }
        },
        MuiCollapse:{
            styleOverrides:{
                root:{
                    overflowY:"hidden",
                    display:"flex",
                    flex:"auto"
                },
                wrapperInner:{
                    display:"flex",
                    flexDirection:"column"
                }
            }
        }
    }
}

const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: "dark",
        divider: "#131314",
        background: {
            default: "#1F2021",
            paper: "#2C2F33",
            frame: "#1F2021"
        },
        text: {
            primary: "#CBCCCE",
            secondary: "#9DA0A3"
        }
    },
    components: {
        MuiCssBaseline:{
            styleOverrides: {
                "*::-webkit-scrollbar-thumb": {
                        backgroundColor: "#CBCCCE"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                "containedPrimary": {
                    backgroundColor: "#FFF",
                    color: "#000",
                    "&:hover": {
                        backgroundColor: teal[500],
                        boxShadow: "none"
                    }
                },
                "textPrimary": {
                    color: "#FFF",
                    ".MuiButton-startIcon": {
                        color: "#999A9B"
                    }
                },
                "outlinedSecondary": {
                    border: "1px solid #CBCCCE",
                    color: "#FFF",
                    "&:hover": {
                        color: teal[500],
                        borderColor: teal[500],
                        boxShadow: "none"
                    }
                }
            }
        }
    }
}

const lightThemeOptions: ThemeOptions = {
    palette: {
        mode: "light",
        divider: "#CCC",
        background: {
            default: "#F9FAFB",
            paper: "#FFF",
            frame: "#FFF"
        },
        text: {
            primary: "#333435",
            secondary: "#808386"
        }
    },
    components: {
        MuiCssBaseline:{
            styleOverrides: {
                "*::-webkit-scrollbar-thumb": {
                        backgroundColor: "#CCC"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                "containedPrimary": {
                    backgroundColor: "#000",
                    color: "#fff",
                    "&:hover": {
                        backgroundColor: teal[500],
                        boxShadow: "none"
                    }
                },
                "textPrimary": {
                    color: "#000",
                    ".MuiButton-startIcon": {
                        color: "#999A9B"
                    }
                },
                "outlinedSecondary": {
                    border: "1px solid #CBCCCE",
                    color: "#000",
                    "&:hover": {
                        color: teal[500],
                        backgroundColor: "#FFF",
                        borderColor: teal[500],
                        boxShadow: "none"
                    }
                }
            }
        }
    }
}

const darkTheme = createTheme(deepmerge(baseThemeOptions, darkThemeOptions))
const lightTheme = createTheme(deepmerge(baseThemeOptions, lightThemeOptions))

const nivoLight: NivoTheme ={
    fontFamily: "BasisGrotesqePro",
    axis:{
        ticks:{
            line: {stroke: "#131314"},
            text: {fill: "#131314"}
        }
    },
    legends:{
        text: {fill: "#131314"}
    },
    labels:{
        text:{
            fontSize:16,
            fontWeight:700
        }
    },
    tooltip:{
        container:{
            color:"#000000"
        }
    },
    textColor:"#131314",
    background:"#FFF"

}
const nivoDark: NivoTheme ={
    fontFamily: "BasisGrotesqePro",
    axis:{
        ticks:{
            line: {stroke: "#CBCCCE"},
            text: {fill: "#CBCCCE"}
        },
        legend:{
            text: {fill: "#CBCCCE"}
        },
        domain:{
            line:{stroke: "#CBCCCE"}
        }
    },
    labels:{
        text:{
            fontSize:16,
            fontWeight:700
        }
    },
    tooltip:{
        container:{
            color:"#000000"
        }
    },
    textColor:"#CBCCCE",
    background:"#2C2F33"
} 

export default function ThemeWrapper({ children }: { children: React.ReactNode }) {
    return <ThemeContextProvider>
        <ThemeContextConsumer>
            {({ mode }) => {
                const theme = mode === "dark"
                    ? darkTheme
                    : lightTheme
                const nivoTheme = mode === "dark" ? nivoDark: nivoLight 

                return <NivoThemeProvider theme={nivoTheme}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {children}
                    </ThemeProvider>
                </NivoThemeProvider>
            }}
        </ThemeContextConsumer>
    </ThemeContextProvider>
}
