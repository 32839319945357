import { faFileCsv, faImage, faSearchMinus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton } from "@mui/material"
import { useEffect, useState } from "react"

export interface IDragonChartCardActions {
    ResetZoom?: ()=>void
    DownloadPNG?:(filename:string)=>void
    DownloadCSV?:(filename:string)=>void
}

interface Props{
    reference: IDragonChartCardActions|null
    name: string
}

export default function DragonChartCardActions({ reference,name }:Props){
    const [zoomOut,setZoomOut] = useState(false)
    const [csvDownload,setCsvDownload] = useState(false)
    const [pngDownload,setPngDownload] = useState(false)

    useEffect(()=>{
            setZoomOut(!!reference?.ResetZoom)
            setCsvDownload(!!reference?.DownloadCSV)
            setPngDownload(!!reference?.DownloadPNG)
    },[reference])

    const onZoomOutClick = ()=>{
        if(reference?.ResetZoom) reference.ResetZoom()
    }

    const onDownloadCSVClick = ()=>{
        if(reference?.DownloadCSV) reference.DownloadCSV(name)
    }

    const onDownloadPngClick = ()=>{
        if(reference?.DownloadPNG) reference.DownloadPNG(name)
    }
    return <>
                {(() => {
                    if(zoomOut)
                     return <IconButton onClick={onZoomOutClick}>
                                <FontAwesomeIcon icon={faSearchMinus} style={{ fontSize: ".85em" }} />
                            </IconButton>
                })()}
                {(() => {
                    if(csvDownload)
                    return  <IconButton onClick={onDownloadCSVClick}>
                                <FontAwesomeIcon icon={faFileCsv} style={{ fontSize: ".85em" }} />
                            </IconButton>
                })()}
                {(() => {
                    if(pngDownload)
                    return  <IconButton onClick={onDownloadPngClick}>
                                <FontAwesomeIcon icon={faImage} style={{ fontSize: ".85em" }} />
                            </IconButton>
                })()}
            </>
}
