import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { IPosition } from "Types/IPosition"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { formatDate, formatTime } from "utils/timedisplay"

export default function EventExportDetails() {
    const { t } = useTranslation()
    const { exportId } = useParams<"exportId">()

    if (!exportId)
        throw new Error("Export id is not valid")

    const { data, isLoading, isError, isSuccess } = useQuery(
        ["event-export-details", exportId],
        () => getEventExportDetails(exportId)
    )

    if (isLoading)
        return <DelayedCircularProgress delay={0} />

    if (isError || !isSuccess)
        return <Typography>{t("failed-to-load-export-details")}</Typography>

    return <Table>
        <TableBody>
            <TableRow>
                <TableCell width={150}>{t("event-export-details.exported-by")}:</TableCell>
                <TableCell>{data.createdName}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("event-export-details.export-started")}:</TableCell>
                <TableCell>{formatDate(data.createdAt)} {formatTime(data.createdAt)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("event-export-details.export-range")}:</TableCell>
                <TableCell>{formatDate(data.startedAtGe)} {formatTime(data.startedAtGe)} - {formatDate(data.endedAtLe)} {formatTime(data.endedAtLe)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("event-export-details.cameras")}:</TableCell>
                <TableCell>{data.cameras.map(camera => camera.name).join(", ")}</TableCell>
            </TableRow>
            {data.zones.length > 0 &&
                <TableRow>
                    <TableCell>{t("event-export-details.zones")}:</TableCell>
                    <TableCell>{data.zones.map(zone => zone.name).join(", ")}</TableCell>
                </TableRow>
            }
            <TableRow>
                <TableCell>{t("event-export-details.event-types")}:</TableCell>
                <TableCell>{data.eventTypeIds.map(id => t(`event-types.${id}`)).sort().join(", ")}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("event-export-details.marked-as")}:</TableCell>
                <TableCell>{data.validStateIds.map(id => t(`event-export-details.${id}`)).join(", ")}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("event-export-details.include-comments")}:</TableCell>
                <TableCell>{t(`event-export-details.${data.includeComments ? "yes" : "no"}`)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("event-export-details.include-videos")}:</TableCell>
                <TableCell>{t(`event-export-details.${data.includeVideo ? "yes" : "no"}`)}</TableCell>
            </TableRow>
        </TableBody>
    </Table>
}

async function getEventExportDetails(exportId: string): Promise<IEventExport> {
    const response = await axios.get<EventExportReadDto>(`/api/event/v1/event-exports/${exportId}`)
    return mapEventExportDto(response.data)
}


interface EventExportReadDto {
    eventTypeIds: string[]
    startedAtGe: string
    endedAtLe: string
    validStateIds: string[]
    simulatedStateIds: string[]
    filteredStateIds: string[]
    exportAsId: string
    includeVideo: boolean
    includeComments: boolean
    locale: string
    timeZone: string
    id: string
    name: string
    cameraGroups: { id: string, name: string }[],
    cameras: ICamera[],
    zones: [
        {
            id: string
            name: string
            camera: ICamera
        }
    ],
    exportStates: {
        timestamp: string,
        exportStateId: string
    }[],
    uris: string[],
    createdAt: string,
    createdName: string,
    updatedAt: string,
    updatedName: string
}

type ICamera = {
    id: string
    name: string
    position: IPosition | null
}

interface IEventExport extends Omit<EventExportReadDto, "startedAtGe" | "endedAtLe" | "createdAt" | "updatedAt"> {
    startedAtGe: Date
    endedAtLe: Date
    createdAt: Date
    updatedAt: Date
}

function mapEventExportDto(dto: EventExportReadDto): IEventExport {
    return {
        ...dto,
        startedAtGe: new Date(dto.startedAtGe),
        endedAtLe: new Date(dto.endedAtLe),
        createdAt: new Date(dto.createdAt),
        updatedAt: new Date(dto.updatedAt)
    }
}