import { ReactNode } from "react"

import { QueryClient, QueryClientProvider } from "react-query"
import { useTranslation } from "react-i18next"

import { useEnqueueSnackbar } from "utils/useEnqueueSnackbar"


export default function QueryClientProviderWrapper({ children }: { children: ReactNode }) {
    const { t } = useTranslation()
    const enqueueSnackbar = useEnqueueSnackbar()

    const onError = () => enqueueSnackbar(t("something-went-wrong"), { variant: "error" })

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false,
                onError
            },
            mutations: {
                onError
            }
        }
    })

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
