import { Box, Button, Divider, Paper, Typography } from "@mui/material"
import { DragonTab, DragonTabs } from "Components/DragonTabs"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"

import { DelayedCircularProgress } from "utils/DelayedProgress"
import EventDetails from "Pages/EventDetails/EventDetails"
import EventJournalFilter from "./EventJournalFilter"
import EventList from "./EventList"
import IEventJournalFilterSelection from "./types/IEventJournalFilterSelection"
import { IOrderBy } from "./IOrderBy"
import MapView from "./MapView"
import { useTranslation } from "react-i18next"
import { faCaretDown, faCaretUp, faMap, faGrid } from "@fortawesome/pro-regular-svg-icons"
import { faMap as faMapSolid, faGrid as faGridSolid } from "@fortawesome/pro-solid-svg-icons"

import Icon from "Components/Icon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"
import { assertUnreachable } from "utils/assertUnrechable"
import { useUserProfile } from "hooks/useUserProfile"
import { useQueryClient } from "react-query"

enum Tab {
    GALLERY = "gallery",
    MAP = "map"
}

export default function EventJournal() {
    const navigate = useNavigate()
    const query = useQueryClient()
    const { t } = useTranslation()
    const [selection, setSelection] = useState<IEventJournalFilterSelection | undefined>(undefined)
    const [orderBy, setOrderBy] = useState<IOrderBy>(IOrderBy.DESCENDING)
    const [eventIds, setEventIds] = useState<string[]>([])
    const {features} = useUserProfile()

    const showEventDetails = useLocation().pathname.startsWith("/event-journal/event")

    const onSubmit = useCallback((newSelection: IEventJournalFilterSelection) => {
        query.invalidateQueries(["events",selection,orderBy])
        setSelection({...newSelection})
    }, [query,selection,orderBy])
    const updateEventIds = useCallback((eventIds: string[]) => setEventIds(eventIds), [])

    const mapEnabled = features.some(f => f === "MapEnabled")

    let selectedTab: Tab | undefined = undefined

    
    switch (useLocation().hash) {
        case "":
            selectedTab = Tab.GALLERY
            break
        case "#map":
            selectedTab = Tab.MAP
            break
    }

    useEffect(() => {
        if (showEventDetails)
            return

        if (!selectedTab)
            navigate(".")
    }, [selectedTab, navigate, showEventDetails])

    return <>
        <DragonPageWrapper hidden={showEventDetails}>
            <DragonTitle title={t("event-journal")} />
            <EventJournalFilter onSubmit={onSubmit} />
            <Paper sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "auto"
            }}>
                <Box display="flex">
                    <DragonTabs
                        value={selectedTab}
                    >
                        <DragonTab
                            label={t("event-list.gallery-view")}
                            value={Tab.GALLERY}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.GALLERY}
                                    icon={faGrid}
                                    selectedIcon={faGridSolid}
                                />
                            }
                            to="."
                        />
                        <DragonTab
                            label={t("event-list.map-view")}
                            value={Tab.MAP}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.MAP}
                                    icon={faMap}
                                    selectedIcon={faMapSolid}
                                />
                            }
                            to="#map"
                            disabled={!mapEnabled}
                        />
                    </DragonTabs>
                    {selectedTab === Tab.GALLERY &&
                        <Box display="flex" padding="13px" gap="15px">
                            <Button
                                startIcon={(() => {
                                    switch (orderBy) {
                                        case IOrderBy.ASCENDING:
                                            return <FontAwesomeIcon icon={faCaretUp} />
                                        case IOrderBy.DESCENDING:
                                            return <FontAwesomeIcon icon={faCaretDown} />
                                        default:
                                            assertUnreachable(orderBy)
                                    }
                                })()}
                                onClick={() => setOrderBy(orderBy === IOrderBy.ASCENDING
                                    ? IOrderBy.DESCENDING
                                    : IOrderBy.ASCENDING
                                )}
                            >
                                <Typography whiteSpace="nowrap">{t("order-by")}</Typography>
                            </Button>
                        </Box>
                    }
                </Box>
                <Divider />
                {!selection
                    ? <DelayedCircularProgress delay={500} />
                    : <>
                        {selectedTab === Tab.GALLERY &&
                            <EventList
                                selection={selection}
                                orderBy={orderBy}
                                onUpdateEventIds={updateEventIds}
                            />
                        }
                        {selectedTab === Tab.MAP && mapEnabled &&
                            <MapView filter={selection} />
                        }
                    </>
                }
            </Paper>
        </DragonPageWrapper>
        <Routes>
            <Route path="event/:eventId" element={<EventDetails eventIds={eventIds} />} />
        </Routes>
    </>
}
