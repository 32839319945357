import React from "react"
import { Grid } from "@mui/material"

export default function StepWrapper({ children }: { children: React.ReactNode[] }) {
    return (
        <Grid container spacing={2}>
            {React.Children.map(children, child =>
                child ? <Grid item xs={12} md={6}>
                    {child}
                </Grid>
                    : null
            )}
        </Grid>
    )
}