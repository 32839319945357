import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import axios, { AxiosError, AxiosResponse } from "axios"
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "react-query"
import { List, ListItemButton, ListItemIcon, ListItemText, Popover, useTheme } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSync, faTrashAlt } from "@fortawesome/pro-regular-svg-icons"

import { DragonConfirmationDialogWithPromise, DragonServerTestResultDialog } from "Components/DragonDialog"
import { IServerReadDto, IServerSelectDto, IServerSyncDto } from "Types/admin"
import { IDataWithEtag } from "utils/queryHelpers"

type IServerSelect = IServerSelectDto
type IServerDetails = IServerReadDto
type IServerDetailsWithEtag = IDataWithEtag<IServerDetails>

const getServerDetails = async ({ queryKey }: QueryFunctionContext<[string, string]>): Promise<IServerDetailsWithEtag> => {
    const [, id] = queryKey
    const response = await axios.get<IServerDetails>(`/api/admin/v1/servers/${id}`)
    return {
        data: response.data,
        eTag: response.headers.etag
    }
}

const deleteServer = async (id: string, eTag: string | undefined): Promise<AxiosResponse> => {
    if (!eTag) throw Error("requirement-not-met")
    return await axios.delete(`/api/admin/v1/servers/${id}`, { headers: { "If-Match": eTag } })
}

const testServer = async (id: string): Promise<IServerSyncDto> => {
    const response = await axios.get<IServerSyncDto>(`/api/admin/v1/servers/${id}/test`)
    return response.data
}


interface IServerContextMenu {
    server: IServerSelect
    anchor: HTMLButtonElement | null
    onClose: () => void
}

export const ServerContextMenu: FC<IServerContextMenu> = ({ server, anchor, onClose }) => {
    const { t } = useTranslation()
    const { palette } = useTheme()
    const queryClient = useQueryClient()
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [testResultDialog, setTestResultDialog] = useState(false)

    const {
        isLoading: isServerDetailsLoading,
        isError: isServerDetailsError,
        data: serverDetails
    } = useQuery<IServerDetailsWithEtag, AxiosError, IServerDetailsWithEtag, [string, string]>(
        ["server", server.id],
        getServerDetails
    )

    const { mutateAsync: handleDeleteServer, isLoading: isDeleteLoading } = useMutation(
        () => deleteServer(server.id, serverDetails?.eTag),
        { onSuccess: () => { queryClient.invalidateQueries("servers") } }
    )

    const {
        data: testResult,
        mutateAsync: onTestServer,
        isLoading: isTestLoading,
        isError: isTestError,
        isSuccess: isTestSuccess
    } = useMutation(() => testServer(server.id))

    const handleTestServer = useCallback<() => void>(() => {
        onTestServer()
            .then(() => {
                setTestResultDialog(true)
            })
    }, [onTestServer])

    const testButtonColor = useMemo<string>(() => {
        if (isTestError) return palette.error.main
        if (isTestSuccess) return palette.success.main
        return palette.text.primary
    }, [isTestError, isTestSuccess, palette])

    return (
        <>
            <Popover
                open
                onClose={onClose}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
            >
                <List sx={{ p: 0, minWidth: 150 }}>
                    <ListItemButton onClick={handleTestServer}>
                        <ListItemIcon>
                            <FontAwesomeIcon
                                icon={faSync}
                                style={{
                                    animation: isTestLoading ? "spin 4s linear infinite" : undefined,
                                    color: testButtonColor
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText sx={{ color: testButtonColor }}>
                            {t("server-context-menu.test")}
                        </ListItemText>
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => setDeleteDialog(true)}
                        disabled={isServerDetailsLoading || isServerDetailsError}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </ListItemIcon>
                        <ListItemText>
                            {t("server-context-menu.delete")}
                        </ListItemText>
                    </ListItemButton>
                </List>
            </Popover>
            <DragonServerTestResultDialog
                open={testResultDialog}
                onClose={() => setTestResultDialog(false)}
                data={testResult?.cameras}
                id={server.id}
            />
            <DragonConfirmationDialogWithPromise
                open={deleteDialog}
                variant="delete"
                title={t(
                    "server-context-menu.delete-server_confirmation",
                    { server: server.name }
                )}
                onClose={() => {
                    setDeleteDialog(false)
                    onClose()
                }}
                onConfirm={handleDeleteServer}
                isLoading={isDeleteLoading}
                errorText={t("server-context-menu.delete-server_failed")}
            />
        </>
    )
}

export default ServerContextMenu