import { ComputedCell, ResponsiveHeatMap } from "@nivo/heatmap"
import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ICountingVehicleData } from "../CountingVehicleDetail"
import { IGateData } from "./getGateData"
import { useTheme } from "@nivo/core"
import { IDragonChartCardActions } from "../DragonChartCardActions"
import FileSaver from "file-saver"
import {unparse} from "papaparse"

interface IGateLineChart {
    data: IGateData[]
    onChange: (values: ICountingVehicleData) => void
    csvFormatter?:(name:string)=>Record<string, unknown>[]
    cardActionsRef?: React.Dispatch<React.SetStateAction<IDragonChartCardActions | null>>
}

export default function GateHeatmapChart({
    data,
    onChange,
    csvFormatter=undefined,
    cardActionsRef = undefined
}: IGateLineChart) {
    const theme = useTheme()
    const { t } = useTranslation()
    const formattedData = transformData(
        data
    )

    const [maxCount, setMaxCount] = useState(3000)

    useEffect(()=>{
    const values = formattedData.flatMap(d =>d.data.flatMap(dd=>dd.y))
        setMaxCount(Math.max(...values))
    },[formattedData])


    const onCellClick = (cell: ComputedCell<IGateChartData>)=>{
        onChange(cell.data.vehicleClass)
    }
    const handleCSVDownload = useCallback(async (name:string)=>{
        if(!!csvFormatter){
            const blob = new Blob([unparse(csvFormatter(name),{quotes:true})], {type : "text/csv"})
            FileSaver.saveAs(blob, `${name}-${Date.now()}.csv`)
        }

    },[csvFormatter])

    const actionsRef = useRef<IDragonChartCardActions>({
        ResetZoom:undefined,
        DownloadPNG:undefined,
        DownloadCSV: !!csvFormatter ? handleCSVDownload : undefined
    })
    useEffect(() => {
        if(cardActionsRef && actionsRef?.current){
            cardActionsRef(actionsRef?.current)
        }
    },[cardActionsRef,actionsRef])

    return <ResponsiveHeatMap
        data={formattedData}
        margin={{ top: 60, bottom: 30,left:120}}
        onClick={onCellClick}
        axisLeft={{
            tickSize: 8,
            tickPadding: 5,
            tickRotation: -45,
            legend: t("data-analytics-page.origin").toUpperCase(),
            legendPosition:"end",
            legendOffset: -70

        }}
        axisTop={{
            tickSize: 8,
            tickPadding: 5,
            tickRotation: 0,
            legend:t("data-analytics-page.destination").toUpperCase(),
            legendPosition:"start",
            legendOffset: -40
        }}
        colors={{
            minValue:0,
            maxValue:maxCount+300,
            type:"sequential",
            scheme:"yellow_orange_red"
        }}
        theme={theme}
        emptyColor={"#555555"}
        labelTextColor={{
            from:"color",
            modifiers:[["darker",3]]
        }}
    />
}

interface IGateChartData{
    x:string
    y:number
    vehicleClass:{[vClass:string] :number}
}
interface IGateChartNode {
    id: string,
    data: IGateChartData[]
}
function transformData(
    data: IGateData[]
): IGateChartNode[] {
    const gateNames = data.reduce<string[]>((acc,curr)=>{
        if(!acc.some(name => name === curr.fromGate)) acc.push(curr.fromGate)
        if(!acc.some(name => name === curr.toGate)) acc.push(curr.toGate)
        return acc
    },[]).sort((a,b)=>{return a>b? 1: -1})
    const vehicleClasses = data.reduce<string[]>((acc,curr)=>{
        if(!acc.some(x=>x === curr.vehicleClassId)) acc.push(curr.vehicleClassId)
        return acc
    },[]).sort((a,b)=>{return a>b? 1: -1})
    const gateMap :{[id:string]:IGateChartData[]}={}
    gateNames.forEach(gn => {
        gateMap[gn] = gateNames.map<IGateChartData>((name) =>{
            return {
                x:name,
                y:0,
                vehicleClass:vehicleClasses.reduce<{[vClass:string] :number}>((prev,id) =>{
                    prev[id] = 0
                    return prev},{})
            } as IGateChartData
        })
    })
    data.forEach(gate =>{
        const prev = gateMap[gate.fromGate].find(d=>d.x === gate.toGate)
        if(prev){
            prev.y+=gate.count
            prev.vehicleClass[gate.vehicleClassId] ? prev.vehicleClass[gate.vehicleClassId]+=gate.count : prev.vehicleClass[gate.vehicleClassId] = gate.count
        } else {
            gateMap[gate.fromGate].push({x:gate.toGate,y:gate.count,vehicleClass:{[gate.vehicleClassId]:gate.count}})
        }
    })
    return Object.keys(gateMap).reduce<IGateChartNode[]>((acc,key)=>{
        acc.push({id:key,data:gateMap[key]})
        return acc
    },[])
}

