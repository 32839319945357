import { Radio } from "@mui/material"
import DragonTable, { BodyRow, HeaderCell } from "Components/DragonTable"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { IScenarioLevelEventTypeDto } from "Types/admin"

export interface IEventType {
    name: string
    isCameraEvent:boolean
}

interface IEventTypeConfig {
    eventTypeList: IEventType[]
    levelEventTypes: IScenarioLevelEventTypeDto[]
    disabled:boolean
    updateLevelEventTypes: (eventTypeId: string, enable?: boolean) => void
    variant: "Camera" | "Zone"
}

export default function EventTypeConfig({ eventTypeList, levelEventTypes, disabled, updateLevelEventTypes, variant }: IEventTypeConfig) {
    const { t } = useTranslation()

    const filteredList = eventTypeList.filter(eventTypes => variant === "Camera" ? eventTypes.isCameraEvent : !eventTypes.isCameraEvent)

    const combined = filteredList
        .map(({ name }) => ({ eventTypeId:name }))
        .reduce<IScenarioLevelEventTypeDto[]>((acc, eventType) => {
            acc.push({
                ...eventType,
                ...(levelEventTypes.find(levelEventTypes => levelEventTypes.eventTypeId === eventType.eventTypeId))
            })
            return acc
        }, [])


    const tableColumns = useMemo<HeaderCell[]>(() => [
        { value: t("scenario-details-page.event-type") },
        { value: t("scenario-details-page.unset") },
        { value: t("scenario-details-page.enable") },
        { value: t("scenario-details-page.disable") }
    ], [t])

    const tableData = useMemo<BodyRow[]>(() => {
        const list = combined ?? []
        return list.map(({ eventTypeId, enable }) => (
            {
                row: [
                    { value: t(`event-types.${eventTypeId}`) },
                    {
                        value: <Radio
                            value="unset"
                            checked={enable === undefined}
                            disabled={disabled}
                            onClick={() => updateLevelEventTypes(eventTypeId, undefined)}
                        />
                    },
                    {
                        value: <Radio
                            value="enabled"
                            checked={enable === true}
                            disabled={disabled}
                            onClick={() => updateLevelEventTypes(eventTypeId, true)}
                        />
                    },
                    {
                        value: <Radio
                            value="disabled"
                            checked={enable === false}
                            disabled={disabled}
                            onClick={() => updateLevelEventTypes(eventTypeId, false)}
                        />
                    }
                ]
            }
        )).sort((a,b) => a.row[0].value > b.row[0].value ? 1 : -1)
    }, [combined, updateLevelEventTypes, t, disabled])

    return <DragonTable
        stickyHeader={true}
        columns={tableColumns}
        data={tableData}
        size="small"
        
    />
}