import { FC, useState } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"
import {
  Typography,
  Button,
  Stack,
  FormControl,
  TextField,
  LinearProgress
} from "@mui/material"

import DragonDialog from "Components/DragonDialog"
import validatePassword from "utils/passwordValidation"
import { useMutation } from "react-query"

interface IChangePasswordRequest {
  password: string
  confirmPassword: string
}


interface IChangeAccountPasswordDialog {
  open: boolean
  onClose: () => void
  username: string
}

export const ChangeAccountPasswordDialog: FC<IChangeAccountPasswordDialog> = ({ open, onClose, username }) => {
  const { t } = useTranslation()

  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [passwordMessage, setPasswordMessage] = useState("")
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("")

  const [error, setError] = useState("")

  const { mutate: changePasswordRequest, isLoading } = useMutation((data: IChangePasswordRequest) => {
    return axios.put("/api/v1/user-administration/user/profile/password", data)
  }, {
    onSuccess: () => {
      setError("")
      onClose()
    },
    onError: () => {
      setError(t("failed-to-change-password"))
    }
  })

  function validateNewPassword() {
    const result = validatePassword(password, username, t)
    if (!result.valid && password)
      setPasswordMessage(result.errorMessage)
    else
      setPasswordMessage("")
  }

  function validateConfirmPassword() {
    if (confirmPassword !== password && confirmPassword)
      setConfirmPasswordMessage(t("password-does-not-match"))
    else
      setConfirmPasswordMessage("")
  }

  return (
    <DragonDialog
      open={open}
      onClose={onClose}
      title={t("change-password-dialog.change-password")}
      actions={
        <Stack direction="row" spacing={2} width={1}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => changePasswordRequest({ password, confirmPassword })}
            disabled={isLoading || !password || !confirmPassword || !!passwordMessage || !!confirmPasswordMessage || password !== confirmPassword}
          >
            {t("change-password-dialog.change-password")}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={onClose}
            disabled={isLoading}
          >
            {t("change-password-dialog.cancel")}
          </Button>
        </Stack>
      }
    >
      <Stack spacing={2}>
        <FormControl>
          <Typography variant="h6" fontWeight="bold">{t("change-password-dialog.password")}</Typography>
          <TextField
            id="password"
            variant="filled"
            required
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            onBlur={validateNewPassword}
            helperText={passwordMessage}
            error={!!passwordMessage}
          />
        </FormControl>
        <FormControl>
          <Typography variant="h6" fontWeight="bold">{t("change-password-dialog.confirm-password")}</Typography>
          <TextField
            id="confirm-password"
            variant="filled"
            required
            type="password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            onBlur={validateConfirmPassword}
            helperText={confirmPasswordMessage}
            error={!!confirmPasswordMessage}
          />
        </FormControl>
        {isLoading && <LinearProgress />}
        <Typography color="error">{error}</Typography>
      </Stack>
    </DragonDialog>
  )
}

export default ChangeAccountPasswordDialog