import { BodyRow, HeaderCell, InfiniteScrollDragonTable } from "Components/DragonTable"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useInfiniteQuery } from "react-query"
import { formatDate, formatTime } from "utils/timedisplay"
import { IAuditingFilterSelection } from "./AuditingFilter"
import { useNavigate } from "react-router-dom"
import { getInfiniteAuditLog, IAuditLogListItem } from "./getAuditLog"
import { assertUnreachable } from "utils/assertUnrechable"

export default function AuditingTable({ selection }: { selection: IAuditingFilterSelection }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const query = useInfiniteQuery(
        ["audit-log", selection],
        getInfiniteAuditLog,
        {
            getNextPageParam: (lastPage, _) => {
                if (lastPage.length === 0)
                    return undefined

                return lastPage[lastPage.length - 1].createdAt
            }
        }
    )

    const data = useMemo<BodyRow[]>(
        () => {
            const logs = query.data?.pages.flat() ?? []

            return logs.map(log => {
                const onClick = (() => {
                    switch (log.auditLogTypeId) {
                        case "EventAcknowledge":
                        case "EventValid":
                            return () => navigate(`event/${log.eventId}`)
                        case "EventComment":
                            return () => navigate(`event/${log.eventId}#comments`)
                        case "EventExport":
                            return () => navigate(`export/${log.eventExportId}`)
                        case "Undefined":
                        case "Identity":
                        case "Server":
                            return undefined
                        default:
                            assertUnreachable(log.auditLogTypeId)
                    }
                })()

                return {
                    row: [
                        { value: `${formatDate(log.createdAt)} ${formatTime(log.createdAt)}`, sx: { fontVariantNumeric: "tabular-nums" } },
                        { value: log.createdName },
                        { value: t(`auditing-page.${getLogEntryDiplayString(log)}`) }
                    ],
                    onClick: onClick,
                    hover: !!onClick,
                    sx: { ...(onClick ? { cursor: "pointer" } : {}) }
                }
            })
        },
        [navigate, query.data?.pages, t]
    )

    const columns: HeaderCell[] = [
        { value: t("auditing-page.date-col"), width: 180 },
        { value: t("auditing-page.user-name-col"), width: 400 },
        { value: t("auditing-page.action-col"), width: 300 }
    ]

    return <InfiniteScrollDragonTable
        columns={columns}
        data={data}
        isFetching={query.isFetching}
        fetchNextPage={query.fetchNextPage}
        hasNextPage={query.hasNextPage ?? false}
        noDataText={t("auditing-page.no-logs-for-given-filter")}
    />
}

export function getLogEntryDiplayString({ auditLogTypeId, auditLogActionId }: IAuditLogListItem) {
    return `${auditLogTypeId}.${auditLogActionId}`
}
