import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Typography } from "@mui/material"

import { DragonDialogBase, IDragonDialogBase } from "."

export interface IDragonConfirmationDialogWithPromise extends Omit<IDragonDialogBase, "children"> {
    onConfirm: () => Promise<unknown>
    isLoading: boolean
    errorText: string | null
}

export const DragonConfirmationDialogWithPromise: FC<IDragonConfirmationDialogWithPromise> = ({ open, title, onConfirm, onClose, isLoading, errorText, variant }) => {
    const { t } = useTranslation()
    const [error, setError] = useState<boolean>(false)

    return (
        <DragonDialogBase
            open={open}
            onClose={onClose}
            variant={variant}
            title={title}
            isLoading={isLoading}
            actions={
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        onClick={() => {
                            setError(false)
                            onConfirm().then(onClose).catch(() =>{
                                setError(true)})
                        }}
                        disabled={isLoading}
                    >
                        {t("yes-confirm")}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="large"
                        onClick={onClose}
                        disabled={isLoading}
                    >
                        {t("no-cancel")}
                    </Button>
                </>
            }
        >
            {error && errorText && <Typography color="error">{errorText}</Typography>}
        </DragonDialogBase>
    )
}

export default DragonConfirmationDialogWithPromise