import { ISavedTimeFrame, ITimeFrame } from "Components/Filter/Filters/TimeFrameSelector/ITimeFrame"

export default function parseSavedTimeFrame(timeFrame: ISavedTimeFrame) : ITimeFrame {
    if (timeFrame.type !== "custom")
        return timeFrame

    return {
        type: "custom",
        from: new Date(timeFrame.from),
        to: new Date(timeFrame.to)
    }
}