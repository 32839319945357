import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Typography, Button, Stack, FormControl, TextField, FormControlLabel, Switch } from "@mui/material"

import DragonDialog from "Components/DragonDialog"
export interface IFailoverClusterDetails{
    groupID: number,
    serverIP:string,
    port: number,
    isFailover: boolean
}

interface ICreateServerDialog {
    open: boolean
    data?:IFailoverClusterDetails
    onSave: (server: IFailoverClusterDetails) =>void
    onClose: () => void
}

export const CreateFailoverServerDialog: FC<ICreateServerDialog> = ({ open,data, onSave, onClose }) => {
    const { t } = useTranslation()
    const initialState = useMemo<IFailoverClusterDetails>(() => ({
        groupID: data?.groupID ?? 0,
        serverIP: data?.serverIP ?? "",
        port: data?.port ?? 34808,
        isFailover: data?.isFailover ?? false
    }), [data])
    const [draft, setDraft] = useState<IFailoverClusterDetails>(initialState)
    const [errorMessage, setErrorMessage] = useState<string>()

    useEffect(()=>{
        setDraft(initialState)
        setErrorMessage(undefined)
    },[initialState])
    
    const validation = useMemo<Partial<Record<keyof IFailoverClusterDetails, boolean>>>(
        () => (
            {
                groupID: !isNaN(draft.groupID) && typeof draft.groupID === "number" && draft.groupID >= 0,
                serverIP: draft.serverIP.length > 0,
                port: !isNaN(draft.port) && typeof draft.port === "number" && draft.port >= 0,
                isFailover: true
            }
        ),
        [draft]
    )

    const handleChange = useCallback<
        (key: keyof IFailoverClusterDetails) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    >((key) => (event) => {
        setErrorMessage(undefined)
        setDraft(prevState => ({ ...prevState, [key]: event.target.value }))
    }, [])
    
    const handleChangeBoolean = useCallback<
    (key: keyof IFailoverClusterDetails) => (event: ChangeEvent<HTMLInputElement>,checked:boolean) => void
        >((key) => (event,checked) => {
            setErrorMessage(undefined)
            const updateDraft = (updated :{ [key: string]: boolean }) => setDraft({ ...draft, ...updated })
            updateDraft(({ [key]: checked }))
        }, [draft])

    const handleConfirm = useCallback<() => void>(() => {
        setErrorMessage(undefined)
        onSave({...draft})
        setDraft(initialState)
    }, [initialState,draft,onSave])

    return (
        <>
            <DragonDialog
                open={open}
                onClose={onClose}
                title={t("failover-settings.servers.title")}
                actions={
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            onClick={handleConfirm}
                            disabled={!validation}
                        >
                            {t("failover-settings.servers.save")}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            size="large"
                            onClick={onClose}
                        >
                            {t("cancel")}
                        </Button>
                    </>
                }
            >
                <Stack spacing={2}>
                    <FormControl sx={{ flex: 1 }}>
                        <Typography variant="h6" fontWeight="bold">{t("failover-settings.servers.group-id")}</Typography>
                        <TextField
                            variant="filled"
                            type="number"
                            InputProps={{
                                disableUnderline: initialState.groupID === draft.groupID,
                                inputMode: "numeric",
                                type: "number"
                            }}
                            value={draft.groupID ?? 0}
                            onChange={handleChange("groupID")}
                            error={!validation.groupID}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                        <Typography variant="h6" fontWeight="bold">{t("failover-settings.servers.server-ip")}</Typography>
                        <TextField
                            variant="filled"
                            type="text"
                            InputProps={{
                                disableUnderline: initialState.serverIP === draft.serverIP,
                                inputMode: "text",
                                type: "text"
                            }}
                            value={draft.serverIP ?? ""}
                            onChange={handleChange("serverIP")}
                            error={!validation.serverIP}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                        <Typography variant="h6" fontWeight="bold">{t("failover-settings.servers.port")}</Typography>
                        <TextField
                            variant="filled"
                            type="number"
                            InputProps={{
                                disableUnderline: initialState.port === draft.port,
                                inputMode: "numeric",
                                type: "number"
                            }}
                            value={draft.port ?? 34808}
                            onChange={handleChange("port")}
                            error={!validation.port}
                            fullWidth
                        />
                    </FormControl>
                    <FormControlLabel 
                                    control={
                                        <Switch
                                        checked={draft.isFailover}
                                        onChange={handleChangeBoolean("isFailover")}
                                        />
                                    }
                                    label={t("failover-settings.servers.is-failover")}
                                />
                    {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                </Stack>
            </DragonDialog>
        </>
    )
}

export default CreateFailoverServerDialog