import { Box, Stack, Typography, Radio, Button, TextField } from "@mui/material"
import { DragonToggleButtonGroup, DragonToggleButton } from "Components/DragonToggleGroup"
import LanguageSelector from "Components/LanguageSelector"
import { Theme, useThemeContext } from "Contexts/ThemeContext"
import { useUserProfile } from "hooks/useUserProfile"

import { useTranslation } from "react-i18next"
import { assertUnreachable } from "utils/assertUnrechable"
import ChangePasswordModal from "Components/ChangeAccountPasswordDialog"
import { useState } from "react"
import { useUserSettings } from "App/UserSettingsProvider"

export default function AccountSettingsTab() {
    const { t } = useTranslation()
    const profile = useUserProfile()
    const {mode} = useThemeContext()
    const {setTheme,setLanguage} = useUserSettings()

    const [changePasswordDialog, setChangePasswordDialog] = useState<boolean>(false)
    return (
        <>
        <Box sx={{ padding: "20px 40px", overflow: "auto" }}>
            <Stack spacing={4}>
                <Typography
                    fontWeight="bold"
                    fontSize={24}
                >
                    {t("account-page.profile")}
                </Typography>
                <Box>
                    <Title
                        text={t("account-page.name")}
                    />
                    <CustomTextField text={profile.name} />
                </Box>
                <Box>
                    <Title
                        text={t("account-page.email")}
                    />
                    <CustomTextField text={profile.email} />
                </Box>
                <Box>
                    <Title
                        text={t("account-page.phone-number")}
                    />
                    <CustomTextField text={profile.phoneNumber} />
                </Box>
                <Box>
                    <Title
                        text={t("account-page.language")}
                    />
                    <LanguageSelector onChange={(event) => setLanguage(event.target.value)} />
                </Box>
                <Box>
                    <Title
                        text={t("theme.title")}
                    />
                    <DragonToggleButtonGroup
                        value={mode}
                        exclusive
                        onChange={(_, value: Theme) => {
                            switch (value) {
                                case Theme.dark:
                                case Theme.light:
                                    setTheme(value)
                                    break
                                default:
                                    assertUnreachable(value)
                            }
                        }}
                        sx={{
                            height: 44
                        }}
                    >
                        <DragonToggleButton value={Theme.light}>
                            <Radio
                                disabled
                                checked={mode === Theme.light}
                                sx={{ padding: "0 11px 0 0" }}
                            />
                            <Typography>{t("theme.light")}</Typography>
                        </DragonToggleButton>
                        <DragonToggleButton value={Theme.dark}>
                            <Radio
                                disabled
                                checked={mode === Theme.dark}
                                sx={{ padding: "0 11px 0 0" }}
                            />
                            <Typography>{t("theme.dark")}</Typography>
                        </DragonToggleButton>
                    </DragonToggleButtonGroup>
                </Box>
                <Box>
                    <Title text={t("user-admin-details-page.password")} />
                    <Button
                        onClick={() => setChangePasswordDialog(true)}
                        variant="contained"
                        >
                        {t("user-admin-details-page.change-password")}
                    </Button>
                </Box>
            </Stack>
        </Box>
        <ChangePasswordModal
            open={changePasswordDialog}
            onClose={() => setChangePasswordDialog(false)}
            username={""}
        />
        </>
    )
}

function Title({ text }: { text: string }) {
    return <Typography
        fontSize={16}
        fontWeight="bold"
        mb={1}
    >
        {text}
    </Typography>
}

function CustomTextField({ text }: { text?: string }) {
    return <TextField
        variant="filled"
        value={text}
        disabled
        fullWidth
        InputProps={{
            disableUnderline: true
        }}
        sx={{
            "& .MuiInputBase-input": {
                padding: "0 10px",
                height: "44px"
            }
        }}
    />
}