
import { getFilterSelectionFromLocalStorage, saveFilterSelectionToLocalStorage } from "utils/filterSelection"
import parseSavedTimeFrame from "utils/parseSavedTimeFrame"
import { validateSelectedCameraHierarchy } from "utils/validateSelectedCameraHierarchy"
import IEventJournalFilterSelection, { ISavedEventJournalFilterSelection } from "../types/IEventJournalFilterSelection"
import { defaultSelection } from "./defaultSelection"

const LOCAL_STORAGE_KEY = `event-filter-${process.env.REACT_APP_LOCAL_STORAGE_KEY_SUFFIX}`

export const saveSelectionToLocalStorage = (
    filter: IEventJournalFilterSelection
) => saveFilterSelectionToLocalStorage(LOCAL_STORAGE_KEY, filter)

interface ILoadInitalSelection {
    typeIds: string[]
    defaultTypeIds: string[]
    valid: {
        cameraGroupIds: string[]
        cameraIds: string[]
        zoneIds: string[]
    }
}

export function loadInitalSelection({
    typeIds,
    defaultTypeIds,
    valid
}: ILoadInitalSelection): IEventJournalFilterSelection {
    const savedFilterSelection = getFilterSelectionFromLocalStorage<ISavedEventJournalFilterSelection>(LOCAL_STORAGE_KEY)

    if (savedFilterSelection === null)
        return {
            ...defaultSelection,
            eventTypeIds: defaultTypeIds
        }

    return {
        ...savedFilterSelection,
        timeFrame: parseSavedTimeFrame(savedFilterSelection.timeFrame),
        eventTypeIds: savedFilterSelection.eventTypeIds.filter(id => typeIds.includes(id)),
        ...validateSelectedCameraHierarchy({
            valid,
            selected: savedFilterSelection
        })
    }
}