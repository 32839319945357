import { ReactNode, useState } from "react"
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"
import { Box, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useTranslation } from "react-i18next"
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import SprinxLogo from "logos/sprinx_logo.png"
import SprinxLogoInverted from "logos/sprinx_logo_inverted.png"
import { Theme, useThemeContext } from "Contexts/ThemeContext"
import { useUserProfile } from "hooks/useUserProfile"
import { assertUnreachable } from "utils/assertUnrechable"
import { getSidebarIcon } from "./menuIcons"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"

type ISidebarState = "collapsed" | "expanded"

export default function Sidebar() {
    const { t } = useTranslation()
    const [state, setState] = useState<ISidebarState>("collapsed")
    const [touch, setTouch] = useState<boolean>(false)
    const { mode } = useThemeContext()
    const { roles,features } = useUserProfile()
    //const links = useLinks()
    const navigate = useNavigate()
    return (
        <Box 
            component="aside"
            sx={{
            width: 76.5,
            gridArea: "sidebar",
            position: "relative"
        }}>
        <Box
            data-testid="sidebar"
            onMouseEnter={() => {
                if(touch) return
                setState("expanded")
            }}
            onTouchStart={
                () => {
                    setTouch(true)
                }
            }
            onTouchEnd={
                () => {
                    setTouch(true)
                    setState("collapsed")
                }
            }
            onMouseLeave={() => {
                setState("collapsed")
            }}
            sx={{
                borderRight: "1px solid",
                borderRightColor: theme => theme.palette.divider,
                backgroundColor: theme => theme.palette.background.frame,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                position: "absolute",
                height: "auto",
                top:0,
                bottom:0,
                zIndex: 1200
            }}
        >
            <Collapse orientation="horizontal" in={state !== "collapsed"} collapsedSize={76.5} >
            <List
                sx={{
                    p: 2,
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    overflowY: "auto",
                    overflowX: "hidden"
                }}
            >
                {roles.includes("Operator") &&
                <>
                    <CustomListItem 
                        key={"dashboard"}
                        icon={getSidebarIcon("dashboard").icon}
                        selectedIcon={getSidebarIcon("dashboard").solidIcon}
                        title={t("dashboard")}
                        showTitle={state === "expanded"}
                        href={"/"}
                        enabled={features.includes("DashboardEnabled")}
                    /> 
                    <CustomListItem 
                        key={"map"}
                        icon={getSidebarIcon("map").icon}
                        selectedIcon={getSidebarIcon("map").solidIcon}
                        title={t("map-overview-page.title")}
                        showTitle={state === "expanded"}
                        href={"/map-overview"}
                        enabled={features.includes("MapEnabled")}
                    />
                    <CustomListItem 
                        key={"event-journal"}
                        icon={getSidebarIcon("event-journal").icon}
                        selectedIcon={getSidebarIcon("event-journal").solidIcon}
                        title={t("event-journal")}
                        showTitle={state === "expanded"}
                        href={"/event-journal"}
                        enabled={features.includes("EventJournalEnabled")}
                    />
                    <CustomListItem 
                        key={"event-manager"}
                        icon={getSidebarIcon("event-manager").icon}
                        selectedIcon={getSidebarIcon("event-manager").solidIcon}
                        title={t("event-manager")}
                        showTitle={state === "expanded"}
                        href={"/event-manager"}
                        enabled={features.includes("RealtimeEventsEnabled")}
                    />
                    <CustomListItem 
                        key={"data-analytics"}
                        icon={getSidebarIcon("data-analytics").icon}
                        selectedIcon={getSidebarIcon("data-analytics").solidIcon}
                        title={t("data-analytics")}
                        showTitle={state === "expanded"}
                        href={"/data-analytics"}
                        enabled={features.includes("DataAnalyticsEnabled")}
                    />
                    <CustomListItem 
                        key={"device-status"}
                        icon={getSidebarIcon("device-status").icon}
                        selectedIcon={getSidebarIcon("device-status").solidIcon}
                        title={t("device-status")}
                        showTitle={state === "expanded"}
                        href={"/device-status"}
                        enabled={features.includes("DeviceStatusEnabled")}
                    />
                    <CustomListItem 
                        key={"camera-overview"}
                        icon={getSidebarIcon("camera-overview").icon}
                        selectedIcon={getSidebarIcon("camera-overview").solidIcon}
                        title={t("camera-overview")}
                        showTitle={state === "expanded"}
                        href={"/camera-overview"}
                        enabled={features.includes("CameraOverviewEnabled")}
                    />
                </>
                }
                {roles.includes("Scenarios") &&
                    <>
                        <CustomListItem 
                            key={"scenarios"}
                            icon={getSidebarIcon("scenarios").icon}
                            selectedIcon={getSidebarIcon("scenarios").solidIcon}
                            title={t("scenarios")}
                            showTitle={state === "expanded"}
                            href={"/scenarios"}
                            enabled={features.includes("ScenarioEnabled")}
                        />
                    </>
                }
                {roles.includes("Administrator") &&
                    <>
                        <CustomSubListItem 
                            key={"system-settings"}
                            icon={getSidebarIcon("system-settings").icon}
                            selectedIcon={getSidebarIcon("system-settings").solidIcon}
                            title={t("system-settings")}
                            showTitle={state === "expanded"}
                        >
                           <>
                                <CustomListItem 
                                    key={"servers"}
                                    icon={getSidebarIcon("servers").icon}
                                    selectedIcon={getSidebarIcon("servers").solidIcon}
                                    title={t("servers")}
                                    showTitle={state === "expanded"}
                                    href={"/servers"}
                                    enabled={true}
                                />
                                <CustomListItem 
                                    key={"camera-groups"}
                                    icon={getSidebarIcon("camera-groups").icon}
                                    selectedIcon={getSidebarIcon("camera-groups").solidIcon}
                                    title={t("camera-groups")}
                                    showTitle={state === "expanded"}
                                    href={"/camera-groups"}
                                    enabled={true}
                                />
                                <CustomListItem 
                                    key={"cameras"}
                                    icon={getSidebarIcon("cameras").icon}
                                    selectedIcon={getSidebarIcon("cameras").solidIcon}
                                    title={t("cameras")}
                                    showTitle={state === "expanded"}
                                    href={"/cameras"}
                                    enabled={true}
                                />
                                <CustomListItem 
                                    key={"team-management"}
                                    icon={getSidebarIcon("team-management").icon}
                                    selectedIcon={getSidebarIcon("team-management").solidIcon}
                                    title={t("team-management")}
                                    showTitle={state === "expanded"}
                                    href={"/team-management"}
                                    enabled={true}
                                />
                                <CustomListItem 
                                    key={"user-management"}
                                    icon={getSidebarIcon("user-management").icon}
                                    selectedIcon={getSidebarIcon("user-management").solidIcon}
                                    title={t("user-management")}
                                    showTitle={state === "expanded"}
                                    href={"/user-management"}
                                    enabled={true}
                                />
                                <CustomListItem 
                                    key={"scenario-management"}
                                    icon={getSidebarIcon("scenario-management").icon}
                                    selectedIcon={getSidebarIcon("scenario-management").solidIcon}
                                    title={t("scenario-management")}
                                    showTitle={state === "expanded"}
                                    href={"/scenario-groups"}
                                    enabled={features.includes("ScenarioEnabled")}
                                />
                                <CustomListItem 
                                    key={"event-rules"}
                                    icon={getSidebarIcon("event-rules").icon}
                                    selectedIcon={getSidebarIcon("event-rules").solidIcon}
                                    title={t("event-rules")}
                                    showTitle={state === "expanded"}
                                    href={"/event-rules"}
                                    enabled={features.includes("RulesEnabled")}
                                />
                                <CustomListItem 
                                    key={"failover-settings"}
                                    icon={getSidebarIcon("failover").icon}
                                    selectedIcon={getSidebarIcon("failover").solidIcon}
                                    title={t("failover-settings.title")}
                                    showTitle={state === "expanded"}
                                    href={"/failover-settings"}
                                    enabled={features.includes("FailoverEnabled")}
                                />
                                <CustomListItem 
                                    key={"smtp-settings"}
                                    icon={getSidebarIcon("smtp").icon}
                                    selectedIcon={getSidebarIcon("smtp").solidIcon}
                                    title={t("smtp-settings.title")}
                                    showTitle={state === "expanded"}
                                    href={"/smtp-settings"}
                                    enabled={features.includes("EmailEnabled")}
                                />
                            </> 
                        </CustomSubListItem>
                        <CustomSubListItem 
                            key={"output-components"}
                            icon={getSidebarIcon("output-components").icon}
                            selectedIcon={getSidebarIcon("output-components").solidIcon}
                            title={t("output-components")}
                            showTitle={state === "expanded"}
                        >
                            <CustomListItem 
                                    key={"milestone"}
                                    icon={getSidebarIcon("milestone").icon}
                                    selectedIcon={getSidebarIcon("milestone").solidIcon}
                                    title={t("milestone-settings.title")}
                                    showTitle={state === "expanded"}
                                    href={"/milestone-settings"}
                                    enabled={features.includes("MilestoneEnabled")}
                                />
                            <CustomListItem 
                                    key={"genetec"}
                                    icon={getSidebarIcon("genetec").icon}
                                    selectedIcon={getSidebarIcon("genetec").solidIcon}
                                    title={t("genetec-settings.title")}
                                    showTitle={state === "expanded"}
                                    href={"/genetec-settings"}
                                    enabled={features.includes("GenetecEnabled")}
                                />
                            <CustomListItem 
                                    key={"wave"}
                                    icon={getSidebarIcon("wave").icon}
                                    selectedIcon={getSidebarIcon("wave").solidIcon}
                                    title={t("wave-settings.title")}
                                    showTitle={state === "expanded"}
                                    href={"/wave-settings"}
                                    enabled={features.includes("WaveEnabled")}
                                />
                            <CustomListItem 
                                    key={"nxmeta"}
                                    icon={getSidebarIcon("nxmeta").icon}
                                    selectedIcon={getSidebarIcon("nxmeta").solidIcon}
                                    title={t("nxmeta-settings.title")}
                                    showTitle={state === "expanded"}
                                    href={"/nxmeta-settings"}
                                    enabled={features.includes("NXMetaEnabled")}
                                />
                            <CustomListItem 
                                    key={"usp"}
                                    icon={getSidebarIcon("usp").icon}
                                    selectedIcon={getSidebarIcon("usp").solidIcon}
                                    title={t("usp-settings.title")}
                                    showTitle={state === "expanded"}
                                    href={"/usp-settings"}
                                    enabled={features.includes("USPEnabled")}
                                />
                            <CustomListItem 
                                    key={"opc"}
                                    icon={getSidebarIcon("opc").icon}
                                    selectedIcon={getSidebarIcon("opc").solidIcon}
                                    title={t("opc-settings.title")}
                                    showTitle={state === "expanded"}
                                    href={"/opc-settings"}
                                    enabled={features.includes("OPCEnabled")}
                                />
                        </CustomSubListItem>
                        <CustomListItem 
                            key={"auditing"}
                            icon={getSidebarIcon("auditing").icon}
                            selectedIcon={getSidebarIcon("auditing").solidIcon}
                            title={t("auditing-page.title")}
                            showTitle={state === "expanded"}
                            href={"/auditing"}
                            enabled={true}
                        />
                    </>
                }
                {roles.includes("UserAdministrator") &&
                    <>
                        <CustomListItem 
                            key={"user-administration"}
                            icon={getSidebarIcon("user-administration").icon}
                            selectedIcon={getSidebarIcon("user-administration").solidIcon}
                            title={t("users-admin-page.title")}
                            showTitle={state === "expanded"}
                            href={"/user-administration"}
                            enabled={true}
                        />
                    </>
                }
            </List>
            <img onClick={()=>navigate("/version")}
                src={(() => {
                    switch (mode) {
                        case Theme.dark:
                            return SprinxLogoInverted
                        case Theme.light:
                            return SprinxLogo
                        default:
                            assertUnreachable(mode)
                    }
                })()}
                alt="Sprinx logo"
                width={75.5}
                style={{ padding: "12px", cursor: "pointer" }}
            />
            </Collapse>
            
        </Box>
        </Box>
    )
}

interface ICustomListItem {
    icon: IconDefinition
    selectedIcon: IconDefinition
    showTitle: boolean
    title: string
    href: string
    enabled: boolean
}

function CustomListItem({ icon, selectedIcon, showTitle, title, href, enabled }: ICustomListItem) {
    const location = useLocation()

    // We want the button to be selected even if we're on a subroute
    // Example: event-journal/event/xxxx/details should still have the event journal button selected
    // But since everything is a subroute of / we check that exclusively
    const isSelected = href === "/"
        ? location.pathname === "/"
        : location.pathname.startsWith(href)

    const i = isSelected
        ? selectedIcon
        : icon

    return <ListItem
        disableGutters
        sx={{
            color: theme => theme.palette.text.primary,
            width: showTitle ? "100%" : "inherit",
            p: 0
        }}

    >
        <ListItemButton
            component={RouterLink}
            to={href}
            selected={isSelected}
            sx={{ borderRadius: 1, py: "14px" }}
            disabled={!enabled}
        >
            <ListItemIcon
                sx={{
                    // Icons have a default minWidth of 56 to provide padding to the text
                    // We remove that so they are centered in the minimized view
                    minWidth: 0
                }}
            >
                <FontAwesomeIcon icon={i} data-testid={i.iconName} />
            </ListItemIcon>
            {showTitle &&
                <ListItemText
                    primary={title}
                    sx={{
                        paddingLeft: 2,
                        paddingRight: 2,
                        margin: 0,
                        whiteSpace: "nowrap"
                    }}
                />
            }
        </ListItemButton>
    </ListItem>
}

interface ICustomSubListItem {
    icon: IconDefinition
    selectedIcon: IconDefinition
    showTitle: boolean
    title: string
    children?: ReactNode
}

function CustomSubListItem({ icon, selectedIcon, showTitle, title, children }: ICustomSubListItem) {
    const [open, setOpen] = useState(false)
    const i = open
        ? selectedIcon
        : icon

    const handleClick = () => {
        setOpen(!open)
        }
    return <> 
    <ListItem
        disableGutters
        sx={{
            color: theme => theme.palette.text.primary,
            width: showTitle ? "100%" : "inherit",
            p: 0
        }}
        onClick={handleClick}

    >
        <ListItemButton
            sx={{ borderRadius: 1, py: "14px" }}
        >
            <ListItemIcon
                sx={{
                    // Icons have a default minWidth of 56 to provide padding to the text
                    // We remove that so they are centered in the minimized view
                    minWidth: 0
                }}
            >
                <FontAwesomeIcon icon={i} data-testid={i.iconName} />
            </ListItemIcon>
            {showTitle &&
                <ListItemText
                    primary={title}
                    sx={{
                        paddingLeft: 2,
                        paddingRight: 2,
                        margin: 0,
                        whiteSpace: "nowrap"
                    }}
                />
            }
            {open ? <FontAwesomeIcon icon={faChevronUp} data-testid={faChevronUp.iconName} style={{ fontSize: "0.85em" }} /> : <FontAwesomeIcon icon={faChevronDown} data-testid={faChevronDown.iconName} style={{ fontSize: "0.85em" }} />}
        </ListItemButton>
        
    </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit style={{flex: "none"}}>
        <List  disablePadding>
        {children}
        </List>
    </Collapse>
  </>
}
