/**
 * Get max two initals from name
 * @param name a persons name
 * @example 
 * // returns "BW"
 * getMaxTwoInitalsFromName("Bruce Wayne")
 * @example
 * // returns "CK"
 * getMaxTwoInitalsFromName("Clark Kent")
 * @returns {string} at most the first two initals from the persons name
 */

export default function getMaxTwoInitialsFromName(name: string): string {
    if (!name || name.length < 1)
        throw new Error("Name has to be a string with a length of at least one")

    const [firstName, secondName] = name.split(" ")

    if (!secondName)
        return firstName[0].toUpperCase()

    return `${firstName[0]}${secondName[0]}`.toUpperCase()
}