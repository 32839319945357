import { FC, useMemo, useState } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { Paper, Button } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"

import { DragonTableWithSearchField, HeaderCell, BodyRow } from "Components/DragonTable"
import ScenarioGroupDetails from "Pages/ScenarioManagement/ScenarioGroupDetails"
import { IScenarioGroupListDto } from "Types/admin"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"
import ScenarioDetails from "Pages/ScenarioManagement/ScenarioDetails/ScenarioDetails"

type IScenarioGroupListItem = IScenarioGroupListDto

const getScenarioGroups = async ({ pageParam: offset = 0, queryKey }: QueryFunctionContext): Promise<IScenarioGroupListItem[]> => {
    const [, searchName] = queryKey
    const response = await axios.get<IScenarioGroupListItem[]>("/api/scenario/v1/scenario-management/groups", {
        params: { limit: 25, offset, name: searchName || undefined }
    })
    return response.data
}

export const ScenarioGroups: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState<string>("")

    const showDetails = useLocation().pathname.startsWith("/scenario-groups/")

    const {
        data, hasNextPage = false, fetchNextPage, isFetching
    } = useInfiniteQuery(["scenario-groups", searchValue], getScenarioGroups, {
        getNextPageParam: (lastPage, allPages) => (
            lastPage.length === 0 ? undefined : allPages.reduce((amount, page) => amount + page.length, 0)
        ),
        keepPreviousData: true
    })

    const tableColumns = useMemo<HeaderCell[]>(() => [
        { value: t("name") },
        { value: t("team") },
        { value: t("scenarios") }
    ], [t])

    const tableData = useMemo<BodyRow[]>(() => {
        const list = data?.pages.flat() ?? []
        return list.map(({ id, name,teamName, scenarios }) => (
            {
                row: [
                    { value: name },
                    { value: teamName },
                    { value: `${scenarios.slice(0, 2).map(({ name }) => name).join(", ")}${scenarios.length > 2 ? t("and-more-with-amount", { amount: scenarios.length - 2 }) : ""}` }
                ],
                onClick: () => navigate(`${id}`),
                sx: { cursor: "pointer" },
                hover: true
            }
        ))
    }, [data?.pages, navigate, t])

    return (
        <>
            <DragonPageWrapper hidden={showDetails}>
                <DragonTitle title={t("scenario-groups")} />
                <Paper sx={{ display: "flex", overflow: "hidden" }}>
                    <DragonTableWithSearchField
                        columns={tableColumns}
                        data={tableData}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetching={isFetching}
                        noDataText={t("no-scenario-group-found")}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        searchPlaceholder={t("search-for-scenario-group")}
                        toolbarAction={
                            <Button
                                variant="outlined"
                                startIcon={<FontAwesomeIcon icon={faPlus} name={faPlus.iconName} />}
                                onClick={() => navigate("new")}
                            >
                                {t("create-scenario-group")}
                            </Button>
                        }
                    />
                </Paper>
            </DragonPageWrapper>
            <Routes>
                <Route path=":scenarioGroupId" element={<ScenarioGroupDetails />} >
                    <Route path="scenario/" element={<ScenarioDetails />} />
                    <Route path="scenario/:scenarioId" element={<ScenarioDetails />} />
                </Route>
                <Route path="new" element={<ScenarioGroupDetails />} />
            </Routes>
        </>
    )
}

export default ScenarioGroups