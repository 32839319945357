import { ITimeFrame } from "Components/Filter/Filters/TimeFrameSelector/ITimeFrame"
import getStartAndEndDateFromTimeFrame from "Components/Filter/utils/getTimeFrameFromFilter"
import { IResolution } from "Types/IResolution"
import { assertUnreachable } from "./assertUnrechable"
import { convertResolutionToSeconds } from "./convertResolutionToSeconds"

export interface IDateParts { 
    year?: number
    month?: number
    date?: number
    hour?: number
    minute?: number
}

export function generateTimestampsForTimeframeAndResolution(timeframe: ITimeFrame, resolution: IResolution): IDateParts[] {
    const resolutionInMs = convertResolutionToSeconds(resolution) * 1000

    const [from, maybeTo] = getStartAndEndDateFromTimeFrame(timeframe)

    const to = maybeTo ?? new Date()

    const allTimestamps = []
    
    for (let ts = from.valueOf(); ts <= to.valueOf(); ts += resolutionInMs) {
        const date = new Date(ts)

        allTimestamps.push(convertTimestampToParts(date, resolution))
    }

    const lastTimestampDateParts = convertTimestampToParts(new Date(to), resolution)

    // If the to timestamp is not found in the timestamps array
    // add it so we don't miss the end point of our data
    const lastTimestamp: IDateParts | undefined = allTimestamps[allTimestamps.length - 1]

    if (lastTimestamp && !compareDateParts(lastTimestampDateParts, lastTimestamp))
        allTimestamps.push(lastTimestampDateParts)

    return allTimestamps
}

export function convertTimestampToParts(date: Date, resolution: IResolution): IDateParts {
    switch (resolution) {
        case "m":
        case "1m":
        case "2m":
        case "3m":
        case "4m":
        case "5m":
        case "6m":
        case "10m":
        case "15m":
        case "20m":
        case "30m":
            return { year: date.getFullYear(), month: date.getMonth() + 1, date: date.getDate(), hour: date.getHours(), minute: date.getMinutes() }
        case "h":
        case "1h":
        case "2h":
        case "3h":
        case "4h":
        case "6h":
        case "12h":
            return { year: date.getFullYear(), month: date.getMonth() + 1, date: date.getDate(), hour: date.getHours() }
        case "d":
        case "w":
            return { year: date.getFullYear(), month: date.getMonth() + 1, date: date.getDate() }
        case "M":
            return { year: date.getFullYear(), month: date.getMonth() + 1 }
        case "y":
            return { year: date.getFullYear() }
        default:
            assertUnreachable(resolution)
    }
}

function compareDateParts(a: IDateParts, b: IDateParts): boolean {
    return a.year === b.year
        && a.month === b.month
        && a.date === b.date
        && a.hour === b.hour
        && a.minute === b.minute
}

export function convertDatePartsToDateString(parts: IDateParts): string {
    return `${parts.year}-${(parts.month ?? 1).toString().padStart(2, "0")}-${(parts.date ?? 1).toString().padStart(2, "0")}T${(parts.hour ?? 0).toString().padStart(2, "0")}:${(parts.minute ?? 0).toString().padStart(2, "0")}:00`
}