import { Box, FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import { useTranslation } from "react-i18next"
import FilterControlLabel from "../FilterControlLabel"

interface IPeopleDirectionSelector {
    fields: {
        includeGoing: boolean
        includeComing: boolean
    }
    onChange: (changed: { [key: string]: boolean }) => void
}

export default function PeopleDirectionSelector({
    fields: {
        includeComing,
        includeGoing
    },
    onChange
}: IPeopleDirectionSelector) {
    const { t } = useTranslation()

    return <Box>
        <FilterControlLabel text={t("event-filter.direction")} />
        <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={includeComing}
                        onChange={() => onChange({ includeComing: !includeComing })}
                    />
                }
                label={t("event-filter.coming") as string}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={includeGoing}
                        onChange={() => onChange({ includeGoing: !includeGoing })}
                    />
                }
                label={t("event-filter.going") as string}
            />
        </FormGroup>
    </Box>
}