import { faChevronDown, faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox, FormControlLabel, IconButton } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
interface ITreeSelector {
    tree: {
        id: string
        name: string
        children: {
            id: string
            name: string
        }[]
    }[]
    selectedIds: string[]
    searchValue?: string
    setSelectedIds: (ids: string[]) => void
}

type OnCheckedChange = {
    operation: "add" | "remove",
    ids: string[]
} | {
    operation: "select-all" | "deselect-all"
}

function nameIncludesSearchValue(name: string, searchValue: string) {
    return name.toLowerCase().includes(searchValue)
}

export default function TreeSelector({
    tree,
    selectedIds,
    setSelectedIds,
    searchValue
}: ITreeSelector) {
    const { t } = useTranslation()

    const filteredTreeNodes = !searchValue
        ? tree
        : tree
            .filter(({ name, children }) =>
                nameIncludesSearchValue(name, searchValue)
                || children.some(({ name }) => nameIncludesSearchValue(name, searchValue))
            )

    const childIds = Array.from(
        new Set(
            filteredTreeNodes
                .reduce<string[]>((acc, { children }) => {
                    if (!searchValue)
                        return [...acc, ...children.map(({ id }) => id)]

                    const matching = children.filter(({ name }) => nameIncludesSearchValue(name, searchValue))

                    if (matching.length > 0)
                        return [...acc, ...matching.map(({ id }) => id)]

                    // If no matching children were found, we must have matched the group name
                    // meaning all children should be selected as well
                    return [...acc, ...children.map(({ id }) => id)]
                }, [])
        )
    )

    const forcedNodeStates = tree.reduce<{ [nodeId: string]: "collapsed" | "expanded" }>(
        (acc, node) => {
            if (!searchValue) {
                acc[node.id] = "expanded"
                return acc
            }

            const hasMatchingChildren = node.children.some(child => nameIncludesSearchValue(child.name, searchValue))

            acc[node.id] = hasMatchingChildren
                ? "expanded"
                : "collapsed"

            return acc
        }, {}
    )

    function onCheckedChange(data: OnCheckedChange) {
        switch (data.operation) {
            case "select-all":
                setSelectedIds(childIds)
                return
            case "deselect-all":
                setSelectedIds([])
                return
            case "add":
                setSelectedIds([...selectedIds, ...data.ids])
                return
            case "remove":
                setSelectedIds(selectedIds.filter(id => !data.ids.includes(id)))
                return
        }
    }

    const allSelected = childIds.length > 0 && childIds.every(childId => selectedIds.includes(childId))
    const allIndeterminate = !allSelected && childIds.some(childId => selectedIds.includes(childId))

    return <ul style={{
        listStyle: "none",
        padding: 0,
        margin: 0
    }}>
        {childIds.length > 0
            ? <li
                style={{ marginLeft: 10 }}
            >
                <FormControlLabel
                    label={t("event-filter.select-all") as string}
                    control={
                        <Checkbox
                            checked={allSelected}
                            indeterminate={allIndeterminate}
                            color="secondary"
                            onChange={event => {
                                onCheckedChange({
                                    operation: event.target.checked
                                        ? "select-all"
                                        : "deselect-all"
                                })
                            }}
                        />
                    }
                />
            </li>
            : <li
                style={{ padding: 10 }}
            >
                {t("event-filter.no-matching-cameras")}
            </li>
        }
        {filteredTreeNodes
            .map(node =>
                <TreeNode
                    key={node.id}
                    node={node}
                    selectedIds={selectedIds}
                    isExpanded={forcedNodeStates[node.id] === "expanded"}
                    onCheckedChange={onCheckedChange}
                    searchValue={searchValue}
                />
            )}
    </ul>
}


interface ITreeNode {
    node: {
        id: string
        name: string
        children: {
            id: string
            name: string
        }[]
    }
    selectedIds: string[]
    isExpanded: boolean
    onCheckedChange: (data: OnCheckedChange) => void
    searchValue?: string
}

function TreeNode({
    node,
    selectedIds,
    onCheckedChange,
    isExpanded: initalExpanded,
    searchValue
}: ITreeNode) {
    const [isExpanded, setIsExpanded] = useState(initalExpanded)

    const childIds = node.children.map(child => child.id)

    const isSelected = childIds.every(childId => selectedIds.includes(childId))

    const isIndeterminate = !isSelected && childIds.some(childId => selectedIds.includes(childId))

    const matchingChildren = !searchValue
        ? node.children
        : node
            .children
            .filter(({ name }) => nameIncludesSearchValue(name, searchValue))

    useEffect(() => {
        setIsExpanded(initalExpanded)
    }, [initalExpanded, searchValue])

    return <li style={{ marginLeft: 10 }}>
        <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            disabled={matchingChildren.length === 0}
        >
            {isExpanded
                ? <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: ".75em" }} />
                : <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: ".75em" }} />
            }
        </IconButton>
        <FormControlLabel
            label={node.name}
            sx={{
                color: theme => (
                    searchValue
                    && !nameIncludesSearchValue(node.name, searchValue)
                )
                    ? theme.palette.text.disabled
                    : theme.palette.text.primary
            }}
            control={
                <Checkbox
                    checked={isSelected}
                    color="primary"
                    indeterminate={isIndeterminate}
                    onChange={event => {
                        if (event.target.checked)
                            onCheckedChange({ operation: "add", ids: childIds })
                        else
                            onCheckedChange({ operation: "remove", ids: childIds })
                    }}
                    inputProps={{
                        "aria-checked": (() => {
                            if (isSelected)
                                return "true"

                            if (isIndeterminate)
                                return "mixed"

                            return "false"
                        })()
                    }}
                />
            }
        />
        {isExpanded &&
            <Box
                component="ul"
                sx={{
                    listStyle: "none",
                    paddingLeft: "20px",
                    borderLeft: theme => `3px solid ${theme.palette.divider}`,
                    marginLeft: "15px"
                }}
            >
                {matchingChildren
                    .map(child =>
                        <LeafNode
                            key={child.id}
                            id={child.id}
                            name={child.name}
                            isChecked={selectedIds.includes(child.id)}
                            onCheckedChange={onCheckedChange}
                        />
                    )}
            </Box>
        }
    </li>
}

interface ILeafNode {
    id: string
    name: string
    isChecked: boolean
    onCheckedChange: (data: OnCheckedChange) => void
}

function LeafNode({
    id,
    name,
    isChecked,
    onCheckedChange
}: ILeafNode) {
    return <li style={{ marginLeft: 10 }}>
        <FormControlLabel
            label={name}
            control={
                <Checkbox
                    checked={isChecked}
                    color="secondary"
                    onChange={event => {
                        if (event.target.checked)
                            onCheckedChange({ operation: "add", ids: [id] })
                        else
                            onCheckedChange({ operation: "remove", ids: [id] })
                    }}
                />
            }
        />
    </li>
}