import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate } from "react-router-dom"
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "react-query"
import {
  Stack,
  Paper,
  TextField,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  LinearProgress
} from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"

import { DragonConfirmationDialog, DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import validatePassword from "utils/passwordValidation"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import GoBackButton from "Components/DragonGoBackButton"
import DragonTitle from "Components/DragonTitle"
import ChangePasswordModal from "Components/ChangePasswordDialog"
import { useAuth } from "oidc-react"
import DragonPageWrapper from "Components/DragonPageWrapper"
import ErrorBoundary from "utils/ErrorBoundary"

interface IDataWithEtag<T = unknown> {
  data: T
  eTag: string
}

interface IUserReadDto {
  id: string
  username: string
  name: string
  email: string | null
  mobilePhone: string | null
  enabled: boolean
  userRoleIds: string[]
  createdAt: string | null
  createdName: string | null
  updatedAt: string | null
  updatedName: string | null
}

interface IUserUpdateDto {
  username: string
  name: string
  email: string | null
  mobilePhone: string | null
  enabled: boolean
  userRoleIds: string[]
}

interface IUserCreateDto {
  username: string
  name: string
  email: string | null
  mobilePhone: string | null
  enabled: boolean
  userRoleIds: string[]
  password: string
  confirmPassword: string
}

type IUserDetails = IUserReadDto
type IUserUpdate = IUserUpdateDto
type IUserCreate = IUserCreateDto
type IUserCreateUpdate = IUserUpdate & IUserCreate
export type IUserDetailsWithEtag = IDataWithEtag<IUserDetails>
type IUserRole = {
  id: string
}

const EMAIL_REGEX = /\S+@\S+\.\S+/
const PHONE_REGEX = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/

const getUserDetails = async ({ queryKey }: QueryFunctionContext<[string, string]>): Promise<IUserDetailsWithEtag> => {
  const [, id] = queryKey
  const response = await axios.get<IUserDetails>(`/api/v1/user-administration/users/${id}`)
  return {
    data: response.data,
    eTag: response.headers.etag
  }
}

const postUserDetails = async (payload: IUserUpdate): Promise<AxiosResponse> => {
  return await axios.post("/api/v1/user-administration/users", payload)
}

const putUserDetails = async (id: string, eTag: string, payload: IUserUpdate): Promise<AxiosResponse> => {
  return await axios.put(`/api/v1/user-administration/users/${id}`, payload, { headers: { "If-Match": eTag } })
}

const deleteUser = async (id: string, eTag: string): Promise<AxiosResponse> => {
  return await axios.delete(`/api/v1/user-administration/users/${id}`, { headers: { "If-Match": eTag } })
}

const getUserRoles = async (): Promise<IUserRole[]> => {
  const response = await axios.get<IUserRole[]>("/api/v1/user-administration/roles")
  return response.data
}

interface Props {
  user: IUserDetailsWithEtag
  newUser: boolean
}

export const UserAdminDetails: FC<Props> = ({ user, newUser }) => {
  const { t } = useTranslation()
  const { userData, signOutRedirect } = useAuth()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const initialState = useMemo<IUserCreateUpdate>(() => ({
    username: user?.data.username,
    name: user?.data.name,
    email: user?.data.email,
    mobilePhone: user?.data.mobilePhone,
    enabled: user?.data.enabled,
    userRoleIds: user?.data.userRoleIds,
    password: "",
    confirmPassword: ""
  }), [user?.data])
  const [draft, setDraft] = useState<IUserCreateUpdate>(initialState)
  const [saveDialog, setSaveDialog] = useState<boolean>(false)
  const [cancelDialog, setCancelDialog] = useState<boolean>(false)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [changePasswordDialog, setChangePasswordDialog] = useState<boolean>(false)

  const [emailMessage, setEmailMessage] = useState("")
  const [phoneMessage, setPhoneMessage] = useState("")
  const [passwordMessage, setPasswordMessage] = useState("")
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("")

  const [deleteError, setDeleteError] = useState("")
  const [saveError, setSaveError] = useState("")

  const isChanged = useMemo<boolean>(
    () => (
      initialState.username !== draft.username ||
      initialState.name !== draft.name ||
      initialState.email !== draft.email ||
      initialState.mobilePhone !== draft.mobilePhone ||
      initialState.enabled !== draft.enabled ||
      initialState.userRoleIds.length !== draft.userRoleIds.length ||
      initialState.userRoleIds.some(id => !draft.userRoleIds.includes(id))
    ),
    [draft, initialState]
  )

  const validations = useMemo<Partial<Record<keyof IUserCreateUpdate, boolean>>>(
    () => (
      {
        name: !!draft.name,
        username: !!draft.username,
        ...(newUser && {
          password: !!draft.password && !passwordMessage,
          confirmPassword: !!draft.confirmPassword && !confirmPasswordMessage && draft.password === draft.confirmPassword
        }),
        ...(draft.email && {
          email: EMAIL_REGEX.test(draft.email)
        }),
        ...(draft.mobilePhone && {
          mobilePhone: PHONE_REGEX.test(draft.mobilePhone)
        })
      }
    ),
    [newUser, draft, passwordMessage, confirmPasswordMessage]
  )

  const { data: userRoles, isFetching: isFetchingUserRoles } = useQuery("userRolesAdmin", getUserRoles)

  const { mutateAsync: handleSaveChangesAsync, isLoading: putLoading } = useMutation(
    () => {
      if (newUser) return postUserDetails(draft)
      return putUserDetails(user.data.id, user.eTag, draft)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("usersAdmin")
        if (!newUser) queryClient.invalidateQueries(["userAdmin", user.data.id])
      },
      onError: (error: AxiosError<{errors:{validationRule:string}}>) => {
        const firstError = error.response?.data?.errors?.validationRule?.[0]
        if (firstError) {
          setSaveError(t(`valid-rules.${firstError}`))
          return
        }
        setSaveError(t("save-changes_failed"))
      }
    }
  )

  const { mutateAsync: handleDeleteUser, isLoading: deleteLoading } = useMutation(
    () => deleteUser(user.data.id, user.eTag),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("usersAdmin")
      },
      onError: (error: AxiosError<{errors:{validationRule:string}}>) => {
        const firstError = error.response?.data?.errors?.validationRule?.[0]
        if (firstError) {
          setDeleteError(t(`valid-rules.${firstError}`))
          return
        }
        setDeleteError(t("delete-user_failed"))
      }
    }
  )

  const handleChange = useCallback<
    (key: keyof IUserCreateUpdate, value: unknown) => void
  >((key, value) => {
    setDraft(prevState => ({ ...prevState, [key]: value }))
  }, [])

  const handleChangeUserRole = useCallback<(userRole: string, checked: boolean) => void>(
    (userRole, checked) => {
      setDraft((prevState) => ({
        ...prevState,
        userRoleIds: checked ?
          [...prevState.userRoleIds, userRole]
          : prevState.userRoleIds.filter((id => id !== userRole))
      }))
    },
    []
  )

  function validateEmail() {
    if (draft.email && !EMAIL_REGEX.test(draft.email))
      setEmailMessage(t("invalid-email"))
    else
      setEmailMessage("")
  }

  function validatePhone() {
    if (draft.mobilePhone && !PHONE_REGEX.test(draft.mobilePhone))
      setPhoneMessage(t("invalid-phone-number"))
    else
      setPhoneMessage("")
  }

  function validateNewPassword() {
    const result = validatePassword(draft.password, draft.username, t)
    if (!result.valid && draft.password)
      setPasswordMessage(t(result.errorMessage))
    else
      setPasswordMessage("")
  }

  function validateConfirmPassword() {
    if (draft.confirmPassword !== draft.password)
      setConfirmPasswordMessage(t("password-does-not-match"))
    else
      setConfirmPasswordMessage("")
  }

  useEffect(() => {
    // Update draft with latest values from API after put.
    setDraft(initialState)
  }, [initialState])

  return (
    <>
      <GoBackButton />
      <DragonTitle title={user?.data.name || t("user-admin-details-page.create-user")} />
      <Paper sx={{ display: "flex", flexDirection: "column", overflow: "auto" }}>

        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold">{t("user-admin-details-page.name")}</Typography>
            <TextField
              variant="filled"
              InputProps={{ disableUnderline: initialState.name === draft.name }}
              value={draft.name}
              onChange={(event) => handleChange("name", event.target.value)}
              error={!validations.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold">{t("user-admin-details-page.username")}</Typography>
            <TextField
              variant="filled"
              InputProps={{ disableUnderline: initialState.username === draft.username }}
              value={draft.username}
              onChange={(event) => handleChange("username", event.target.value)}
              error={!validations.username}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" fontWeight="bold">{t("user-admin-details-page.e-mail")}</Typography>
            <TextField
              variant="filled"
              InputProps={{ disableUnderline: initialState.email === draft.email }}
              value={draft.email}
              onChange={(event) => handleChange("email", event.target.value || null)}
              fullWidth
              onBlur={validateEmail}
              helperText={emailMessage}
              error={!!emailMessage}
              type="email"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" fontWeight="bold">{t("user-admin-details-page.phone")}</Typography>
            <TextField
              variant="filled"
              InputProps={{ disableUnderline: initialState.mobilePhone === draft.mobilePhone }}
              value={draft.mobilePhone}
              onChange={(event) => handleChange("mobilePhone", event.target.value || null)}
              fullWidth
              onBlur={validatePhone}
              helperText={phoneMessage}
              error={!!phoneMessage}
              type="tel"
            />
          </Grid>
          {newUser && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">{t("user-admin-details-page.password")}</Typography>
                <TextField
                  variant="filled"
                  InputProps={{ disableUnderline: initialState.password === draft.password }}
                  value={draft.password}
                  onChange={(event) => handleChange("password", event.target.value)}
                  fullWidth
                  onBlur={validateNewPassword}
                  helperText={passwordMessage}
                  error={!!passwordMessage}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">{t("user-admin-details-page.confirm-password")}</Typography>
                <TextField
                  variant="filled"
                  InputProps={{ disableUnderline: initialState.confirmPassword === draft.confirmPassword }}
                  value={draft.confirmPassword}
                  onChange={(event) => handleChange("confirmPassword", event.target.value)}
                  fullWidth
                  onBlur={validateConfirmPassword}
                  helperText={confirmPasswordMessage}
                  error={!!confirmPasswordMessage}
                  type="password"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold">{t("user-admin-details-page.enabled")}</Typography>
            <FormControl sx={{ alignItems: "flex-start" }}>
              <FormControlLabel
                label={t("user-admin-details-page.enabled") as string}
                componentsProps={{ typography: { variant: "h6", sx: { userSelect: "none" } } }}
                control={<Checkbox
                  checked={draft.enabled}
                  onChange={(event) => handleChange("enabled", event.target.checked)}
                />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold">{t("user-admin-details-page.roles")}</Typography>
            {isFetchingUserRoles ? (
              <LinearProgress />
            ) : (
              <Grid container spacing={2} pt={2}>
                {userRoles?.map(({ id: userRole }) => (
                  <Grid key={userRole} item xs={12} md={6}>
                    <FormControl sx={{ alignItems: "flex-start" }}>
                      <FormControlLabel
                        label={t(`roles.${userRole}`) as string}
                        componentsProps={{ typography: { variant: "h6", sx: { userSelect: "none" } } }}
                        control={<Checkbox
                          checked={draft.userRoleIds.includes(userRole)}
                          onChange={(event) => handleChangeUserRole(userRole, event.target.checked)}
                        />}
                      />
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          {!newUser && <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold">{t("user-admin-details-page.password")}</Typography>
            <Button
              onClick={() => setChangePasswordDialog(true)}
              variant="contained"
            >
              {t("user-admin-details-page.change-password")}
            </Button>
          </Grid>}
        </Grid>
      </Paper>
      <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
        {!newUser && <Button
          variant="outlined"
          color="error"
          sx={{ minWidth: 150 }}
          onClick={() => setDeleteDialog(true)}
        >
          {t("user-admin-details-page.delete")}
        </Button>}
        <Button
          variant="contained"
          color="primary"
          sx={{ minWidth: 150 }}
          onClick={() => setSaveDialog(true)}
          disabled={!isChanged || Object.values(validations).some(v => !v)}
        >
          {newUser ? t("user-admin-details-page.create-user") : t("user-admin-details-page.save-changes")}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ minWidth: 150 }}
          onClick={() => {
            if (isChanged) setCancelDialog(true)
            else navigate("..")
          }}
        >
          {t("user-admin-details-page.cancel")}
        </Button>
      </Stack>
      <DragonConfirmationDialogWithPromise
        open={saveDialog}
        title={t("save-changes_confirmation")}
        onClose={() => {
          setSaveDialog(false)
          setSaveError("")
        }}
        onConfirm={async () => {
          await handleSaveChangesAsync()
          navigate("..")
        }}
        isLoading={putLoading}
        errorText={saveError}
      />
      <DragonConfirmationDialog
        open={cancelDialog}
        title={t("discard-changes_confirmation")}
        onClose={() => setCancelDialog(false)}
        onConfirm={() => {
          setDraft(initialState)
          navigate("..")
        }}
      />
      <DragonConfirmationDialogWithPromise
        open={deleteDialog}
        title={t(
          "delete-user_confirmation",
          { user: user.data.name }
        )}
        onClose={() => {
          setDeleteDialog(false)
          setDeleteError("")
        }}
        onConfirm={async () => {
          await handleDeleteUser()
          if (userData?.profile.sub === user.data.id)
            signOutRedirect()
          else
            navigate("..")
        }}
        isLoading={deleteLoading}
        errorText={deleteError}
      />
      <ChangePasswordModal
        open={changePasswordDialog}
        onClose={() => setChangePasswordDialog(false)}
        user={user}
      />
    </>
  )
}

export const UserDetailsContainer: FC = () => {
  const { t } = useTranslation()
  const { userId } = useParams<"userId">()
  const [error, setError] = useState("")

  const {
    isLoading,
    isError,
    isSuccess,
    data
  } = useQuery<IUserDetailsWithEtag, AxiosError, IUserDetailsWithEtag, [string, string]>(
    ["userAdmin", userId ?? "default"],
    getUserDetails,
    {
      onError: (error) => {
        if (error.response?.status === 404)
          return setError(t("user-details.user-does-not-exist"))
        return setError(t("something-went-wrong"))
      }
    }
  )

  return (
    <DragonPageWrapper>
      {(() => {
                if (isLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{error}</h2>

                if (isSuccess)
                    return (
                        <ErrorBoundary>
                            <UserAdminDetails user={data} newUser={!userId} />
                        </ErrorBoundary>
                    )
            })()}
    </DragonPageWrapper>
  )
}

export default UserDetailsContainer