import { Box, Grid, Stack, Typography } from "@mui/material"
import { getVehicleColor, getVehicleIcon } from "Components/VehicleColors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//import { useTranslation } from "react-i18next"
export interface ICountingVehicleData{
    [vClass:string]: number
}

const VEHICLE_CLASSES=["Pedestrian","Bike","Motorbike","Car","Van","Truck","Bus"]

export default function CountingVehicleDetail({ data }: { data?: ICountingVehicleData }){
    //const {t} = useTranslation()
    return <Box height={"100%"} width={"50%"} minWidth={200} sx={{overflow:"auto"}}>
        {/* <Typography variant="h6" color="white" textAlign="center">
        {t("data-analytics-page.gate-vehicle-detail")}
        </Typography> */}
        <Grid container spacing={2} maxHeight={"100%"} alignItems={"center"}>
        {data && VEHICLE_CLASSES.map(k =>
                    <Grid item md={4}><Box width={"100%"} minHeight={"59px"} boxSizing={"border-box"} p={2} key={k} sx={{
                        backgroundColor: getVehicleColor(k)
                    }}>
                        <Stack height={"100%"} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Box fontSize={"2em"}><FontAwesomeIcon icon={getVehicleIcon(k)} inverse fixedWidth size="2xl"/></Box>
                            <Box sx={{color:"white", textAlign:"end"}}>
                                <Typography sx={{fontWeight: 700, fontSize:"1.5em"}}>{Object.keys(data).includes(k) ? data[k]: 0}</Typography>
                                {/* <Typography>{t(`vehicle-classes.${k}`)}</Typography> */}
                            </Box>
                            
                        </Stack>
                    </Box></Grid>
                )}
    </Grid></Box>
}