import { getFilterSelectionFromLocalStorage, saveFilterSelectionToLocalStorage } from "utils/filterSelection"
import parseSavedTimeFrame from "utils/parseSavedTimeFrame"
import { validateSelectedCameraHierarchy } from "utils/validateSelectedCameraHierarchy"
import { IGateFilterSelection, ISavedGateFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import { defaultSelection } from "../GateTab/defaultSelection"

const LOCAL_STORAGE_KEY = `analytics-filter-gate-${process.env.REACT_APP_LOCAL_STORAGE_KEY_SUFFIX}`

export const saveSelectionToLocalStorage = (
    filter: IGateFilterSelection
) => saveFilterSelectionToLocalStorage(LOCAL_STORAGE_KEY, filter)

interface ILoadInitalSelection {
    vehicleClassIds: string[]
    defaultVehicleClassIds: string[],
    defaultCameraIds: string[],
    valid: {
        cameraGroupIds: string[]
        cameraIds: string[]
        zoneIds: string[]
    }
}

export function loadInitalSelection({
    vehicleClassIds,
    defaultVehicleClassIds,
    defaultCameraIds,
    valid
}: ILoadInitalSelection): IGateFilterSelection {
    const savedFilterSelection = getFilterSelectionFromLocalStorage<ISavedGateFilterSelection>(LOCAL_STORAGE_KEY)

    if (savedFilterSelection === null)
        return {
            ...defaultSelection,
            cameraIds: defaultCameraIds,
            vehicleClassIds: defaultVehicleClassIds
        }

    return {
        ...savedFilterSelection,
        timeFrame: parseSavedTimeFrame(savedFilterSelection.timeFrame),
        vehicleClassIds: savedFilterSelection.vehicleClassIds.filter(id => vehicleClassIds.includes(id)),
        ...validateSelectedCameraHierarchy({
            valid,
            selected: savedFilterSelection
        })
    }
}
