import { IconDefinition, IconName, IconPrefix } from "@fortawesome/pro-regular-svg-icons"
import {
    faHome as dashboardIcon,
    faHistory as eventJournalIcon,
    faSensorAlert as eventManagerIcon,
    faAnalytics as dataAnalyticsIcon,
    faMonitorHeartRate as deviceStatusIcon,
    faTh as cameraOverviewIcon,
    faConstruction as scenariosIcon,
    faPersonDigging as scenarioManagementIcon,
    faUserShield as userAdminIcon,
    faUser as userManagementIcon,
    faUsers as teamManagementIcon,
    faVideo as camerasIcon,
    faVideoPlus as cameraGroupsIcon,
    faServer as serversIcon,
    faMapMarkerAlt as mapOverviewIcon,
    faFileSearch as auditingIcon,
    faEnvelopesBulk as smtpIcon,
    faArrowRightArrowLeft as uspIcon,
    faNetworkWired as genetecIcon,
    faNetworkWired as milestoneIcon,
    faNetworkWired as waveIcon,
    faNetworkWired as nxmetaIcon,
    faChartNetwork as opcIcon,
    faScrewdriverWrench as rulesIcon,
    //faHospitals as failoverIcon,
    faCircleQuestion as noIcon,
    faGears as systemSettingsIcon,
    faNetworkWired as outputComponentsIcon
} from "@fortawesome/pro-regular-svg-icons"

import {
    faHome as dashboardSolidIcon,
    faHistory as eventJournalSolidIcon,
    faSensorAlert as eventManagerSolidIcon,
    faAnalytics as dataAnalyticsSolidIcon,
    faMonitorHeartRate as deviceStatusSolidIcon,
    faTh as cameraOverviewSolidIcon,
    faConstruction as scenariosSolidIcon,
    faPersonDigging as scenarioManagementSolidIcon,
    faUserShield as userAdminSolidIcon,
    faUser as userManagementSolidIcon,
    faUsers as teamManagementSolidIcon,
    faVideo as camerasSolidIcon,
    faVideoPlus as cameraGroupsSolidIcon,
    faServer as serversSolidIcon,
    faMapMarkerAlt as mapOverviewSolidIcon,
    faFileSearch as auditingSolidIcon,
    faEnvelopesBulk as smtpSolidIcon,
    faArrowRightArrowLeft as uspSolidIcon,
    faNetworkWired as genetecSolidIcon,
    faNetworkWired as milestoneSolidIcon,
    faNetworkWired as waveSolidIcon,
    faNetworkWired as nxmetaSolidIcon,
    faChartNetwork as opcSolidIcon,
    faScrewdriverWrench as rulesSolidIcon,
    //faHospitals as failoverSolidIcon,
    faCircleQuestion as noSolidIcon,
    faGears as systemSettingsSolidIcon,
    faNetworkWired as outputComponentsSolidIcon
} from "@fortawesome/pro-solid-svg-icons"

const faFailoverServer: IconDefinition = {
    prefix: "fac" as IconPrefix,
    iconName: "failover-server" as IconName,
    icon: [
      640,
      512,
      [],
      "e001",
      "M0 96C0 60.7 28.7 32 64 32c128 0 256 0 384 0c35.3 0 64 28.7 64 64c0 21.3 0 42.7 0 64c0 11.9-3.3 23.1-9 32.7c-9.3-1.2-18.9 0-27.8 3.5c-23.2 9.3-46.3 18.5-69.5 27.8c-113.9 0-227.8 0-341.7 0c-35.3 0-64-28.7-64-64c0-21.3 0-42.7 0-64zM0 352c0-35.3 28.7-64 64-64c85.5 0 171.1 0 256.6 0c-.4 2.7-.6 5.4-.6 8.2c0 11.9 .8 25.4 2.9 39.8c-86.3 0-172.6 0-258.9 0c-8.8 0-16 7.2-16 16c0 21.3 0 42.7 0 64c0 8.8 7.2 16 16 16c96.7 0 193.3 0 290 0c9 16.6 20.3 32.9 34.1 48C280.1 480 172 480 64 480c-35.3 0-64-28.7-64-64c0-21.3 0-42.7 0-64zM48 96c0 21.3 0 42.7 0 64c0 8.8 7.2 16 16 16c128 0 256 0 384 0c8.8 0 16-7.2 16-16c0-21.3 0-42.7 0-64c0-8.8-7.2-16-16-16c-128 0-256 0-384 0c-8.8 0-16 7.2-16 16zm280 32c0-13.3 10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24s-24-10.7-24-24zm0 256c0-3.9 .9-7.5 2.5-10.8c2.9 10.7 6.5 21.5 11 32.4c-8-3.9-13.5-12.1-13.5-21.6zm24-88c0-9.8 6-18.6 15.1-22.3c40-16 80-32 120-48c5.7-2.3 12.1-2.3 17.8 0c40 16 80 32 120 48C634 277.4 640 286.2 640 296c0 63.3-25.9 168.8-134.7 214.2c-5.9 2.5-12.6 2.5-18.5 0C377.9 464.8 352 359.3 352 296zm40-168c0-13.3 10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24s-24-10.7-24-24zM496 273.8l0 187.9c68.2-33 91.5-99 95.4-149.7c-31.8-12.7-63.6-25.5-95.4-38.2z"
    ]
  }
const sidebarIconMap = {
    "dashboard": {
        icon: dashboardIcon,
        solidIcon: dashboardSolidIcon
    } as ICustomIcon,
    "map": {
        icon: mapOverviewIcon,
        solidIcon: mapOverviewSolidIcon
    }as ICustomIcon,
    "event-journal":  {
        icon: eventJournalIcon,
        solidIcon: eventJournalSolidIcon
    } as ICustomIcon,
    "event-manager": {
        icon: eventManagerIcon,
        solidIcon: eventManagerSolidIcon
    }as ICustomIcon,
    "data-analytics": {
        icon: dataAnalyticsIcon,
        solidIcon: dataAnalyticsSolidIcon
    }as ICustomIcon,
    "device-status":{
        icon: deviceStatusIcon,
        solidIcon: deviceStatusSolidIcon
    }as ICustomIcon,
    "camera-overview":{
        icon: cameraOverviewIcon,
        solidIcon: cameraOverviewSolidIcon
    }as ICustomIcon,
    "scenarios":{
        icon: scenariosIcon,
        solidIcon: scenariosSolidIcon
    }as ICustomIcon,
    "scenario-management":{
        icon: scenarioManagementIcon,
        solidIcon: scenarioManagementSolidIcon
    }as ICustomIcon,
    "event-rules":{
        icon: rulesIcon,
        solidIcon: rulesSolidIcon
    }as ICustomIcon,
    "user-management":{
        icon: userManagementIcon,
        solidIcon: userManagementSolidIcon
    }as ICustomIcon,
    "team-management":{
        icon: teamManagementIcon,
        solidIcon: teamManagementSolidIcon
    }as ICustomIcon,
    "cameras":{
        icon: camerasIcon,
        solidIcon: camerasSolidIcon
    }as ICustomIcon,
    "camera-groups":{
        icon: cameraGroupsIcon,
        solidIcon: cameraGroupsSolidIcon
    }as ICustomIcon,
    "servers":{
        icon: serversIcon,
        solidIcon: serversSolidIcon
    }as ICustomIcon,
    "failover":{
        icon: faFailoverServer,
        solidIcon: faFailoverServer
    }as ICustomIcon,
    "smtp":{
        icon: smtpIcon,
        solidIcon: smtpSolidIcon
    }as ICustomIcon,
    "milestone":{
        icon: milestoneIcon,
        solidIcon: milestoneSolidIcon
    }as ICustomIcon,
    "genetec":{
        icon: genetecIcon,
        solidIcon: genetecSolidIcon
    }as ICustomIcon,
    "wave":{
        icon: waveIcon,
        solidIcon: waveSolidIcon
    }as ICustomIcon,
    "nxmeta":{
        icon: nxmetaIcon,
        solidIcon: nxmetaSolidIcon
    }as ICustomIcon,
    "usp":{
        icon: uspIcon,
        solidIcon: uspSolidIcon
    }as ICustomIcon,
    "opc":{
        icon: opcIcon,
        solidIcon: opcSolidIcon
    }as ICustomIcon,
    "auditing":{
        icon: auditingIcon,
        solidIcon: auditingSolidIcon
    }as ICustomIcon,
    "user-administration":{
        icon: userAdminIcon,
        solidIcon: userAdminSolidIcon
    }as ICustomIcon,
    "system-settings":{
        icon: systemSettingsIcon,
        solidIcon: systemSettingsSolidIcon
    }as ICustomIcon,
    "output-components":{
        icon: outputComponentsIcon,
        solidIcon: outputComponentsSolidIcon
    }as ICustomIcon
} as const

export interface ICustomIcon{
    icon: IconDefinition,
    solidIcon: IconDefinition
}
export function getSidebarIcon(linkTypeId: string): ICustomIcon {
    if (!(linkTypeId in sidebarIconMap))
        return {
            icon: noIcon,
            solidIcon: noSolidIcon
        }

    return sidebarIconMap[linkTypeId as keyof typeof sidebarIconMap]
}