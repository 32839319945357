import { blue, green, red, yellow } from "App/colors"
import { Theme } from "Contexts/ThemeContext"
import { LayerProps } from "react-map-gl/maplibre"

const TRAFFIC_EVENT_COLOR = red[500]
const SYSTEM_EVENT_COLOR = yellow[500]
const PEOPLE_EVENT_COLOR = blue[500]
const NO_EVENTS_COLOR = green[500]

export const clusterLayer: LayerProps = {
    id: "clusters",
    type: "circle",
    source: "events",
    filter: ["has", "point_count"],
    paint: {
        "circle-color": [
            "case",
            ["any", ["get", "has-traffic-events"]], TRAFFIC_EVENT_COLOR!,
            ["any", ["get", "has-system-events"]], SYSTEM_EVENT_COLOR!,
            ["any", ["get", "has-people-events"]], PEOPLE_EVENT_COLOR!,
            NO_EVENTS_COLOR!
        ],
        "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40]
    }
}

export const clusterCountLayer: LayerProps = {
    id: "cluster-count",
    type: "symbol",
    source: "events",
    filter: ["has", "point_count"],
    layout: {
        "text-field": "{point_count_abbreviated}",
        "text-size": 18,
        "text-font": ["Noto Sans Regular"],
        "text-allow-overlap": true
    },
    paint: {
        "text-color": "#FFF"
    }
}

export const unclusteredCamerasPointLayer: LayerProps = {
    id: "unclustered-camera-point",
    type: "circle",
    source: "events",
    filter: ["!", ["has", "point_count"]],
    paint: {
        "circle-color": [
            "case",
            ["boolean", ["get", "has-traffic-events"], true], TRAFFIC_EVENT_COLOR!,
            ["boolean", ["get", "has-system-events"], true], SYSTEM_EVENT_COLOR!,
            ["boolean", ["get", "has-people-events"], true], PEOPLE_EVENT_COLOR!,
            NO_EVENTS_COLOR!
        ],
        "circle-radius": 9,
        "circle-stroke-width": [
            "case",
            ["boolean", ["get", "selected"], true], 2,
            0
        ]
    }
}

export const createUnclusteredCamerasNameLayer = (theme: Theme): LayerProps => ({
    id: "unclustered-camera-name",
    type: "symbol",
    source: "events",
    filter: ["!", ["has", "point_count"]],
    layout: {
        "text-field": "{name}",
        "text-size": 15,
        "text-font": ["Noto Sans Regular"],
        "text-offset": [0, -1.3]
    },
    paint: {
        "text-halo-width": 2,
        "text-color":
            theme === Theme.dark
                ? "#FFF"
                : "#000",
        "text-halo-color":
            theme === Theme.dark
                ? "#000"
                : "#FFF"
    }
})