import { faChevronDown, faVideo, faVideoSlash } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { styled } from "@mui/material/styles"
import { Box, Card, CardHeader, Collapse, IconButton, IconButtonProps, Stack, Typography, useTheme } from "@mui/material"
import axios from "axios"
import { useQuery, useQueryClient } from "react-query"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import DragonTitle from "Components/DragonTitle"
import { IPosition } from "Types/IPosition"
import { yellow } from "App/colors"
import { assertUnreachable } from "utils/assertUnrechable"

interface ICamera {
    id: string
    name: string
    position: IPosition | null
    deviceStateId: "Unknown" | "Ok" | "NotOk"
    eventAssets: {
        assetTypeId: "Undefined" | "Snapshot" | "Video"
        uri: string
    }[]
}

interface IGroup {
    id: string
    name: string
    cameras: ICamera[]
}

async function getDeviceStatus() {
    const response = await axios.get<IGroup[]>("/api/event/v1/camera-groups/device-status")
    return response.data
}

export default function CameraStatus() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const { data: groups, isLoading, isError, isSuccess } = useQuery("device-status", getDeviceStatus)

    useEffect(() => {
        const interval = setInterval(() => {
            queryClient.refetchQueries("device-status")
        }, 5 * 60000)
        return () => clearInterval(interval)
    }, [queryClient])

    return (
        <Box
        display={"flex"}
        flexDirection="column"
        overflow={"hidden"}
        height={1}
        width={1}
        flex={{xs: 1, md: 2}}
                >
            <DragonTitle
                title={t("device-status-page.camera-status")}
            /> 

            <Stack spacing={3} flex={1} overflow={"auto"} >
                {(() => {
                    if (isLoading)
                        return <DelayedCircularProgress delay={250} />

                    if (isError)
                        return <Typography>{t("device-status-page.failed-to-load")}</Typography>

                    if (isSuccess && groups.length === 0)
                        return <Typography>{t("device-status-page.no-camera-groups")}</Typography>

                    if (isSuccess && groups.length > 0)
                        return [...groups]
                            .sort((a, b) => {
                                return b.cameras.filter(c => c.deviceStateId !== "Ok").length - a.cameras.filter(c => c.deviceStateId !== "Ok").length
                            })
                            .map((group) => <DeviceGroupCard key={group.id} group={group} />)
                })()}
            </Stack>
        </Box>
    )
}


interface ExpandMoreProps extends IconButtonProps {
    expand: boolean
}
const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand: _, ...other } = props
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
    })
}))



function DeviceGroupCard({ group }: { group: IGroup }) {
    const [expanded, setExpanded] = useState(false)
    const { palette } = useTheme()
    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const faultyCameras = group.cameras.filter(c => c.deviceStateId !== "Ok")
    const workingCameras = group.cameras.filter(c => c.deviceStateId === "Ok")

    return (
        <Card elevation={0} sx={{ flexShrink:0, bgcolor: palette.background.default }} >
            <CardHeader
                title={<Typography fontSize="1.3em">{group.name}</Typography>}
                subheader={`${group.cameras.filter(c => c.deviceStateId === "Ok").length} / ${group.cameras.length} OK`}
                action={
                    <ExpandMore expand={expanded} onClick={handleExpandClick}>
                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: ".75em" }} />
                    </ExpandMore>
                }
            />

            {!!faultyCameras.length && <Box
                sx={{
                    display: "grid",
                    gap: 2,
                    gridTemplateColumns: "repeat(auto-fill, minmax(187px, 1fr))",
                    p: 2
                }}
            >
                {faultyCameras.map((camera) => <DeviceCard camera={camera} key={camera.id} />)}
            </Box>}

            <Collapse in={expanded}>
                {!!workingCameras.length && <Box
                    sx={{
                        display: "grid",
                        gap: 2,
                        gridTemplateColumns: "repeat(auto-fill, minmax(187px, 1fr))",
                        p: 2
                    }}
                >
                    {workingCameras.map((camera) => <DeviceCard camera={camera} key={camera.id} />)}
                </Box>}
            </Collapse>
        </Card>
    )
}



function DeviceCard({ camera }: { camera: ICamera }) {
    const { palette } = useTheme()

    return (
        <Card elevation={0} >
            <CardHeader
                avatar={(() => {
                    switch (camera.deviceStateId) {
                        case "Ok":
                            return <FontAwesomeIcon icon={faVideo} style={{ fontSize: "2em", color: palette.success.main }} />
                        case "NotOk":
                            return <FontAwesomeIcon icon={faVideoSlash} style={{ fontSize: "2em", color: yellow[500] }} />
                        case "Unknown":
                            return <FontAwesomeIcon icon={faVideo} style={{ fontSize: "2em" }} />
                        default:
                            assertUnreachable(camera.deviceStateId)
                    }
                })()}
                title={camera.name}
                sx={{ p: "8px 15px", height: "53px" }}
            />
        </Card>
    )
}