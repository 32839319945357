import axios from "axios"
import { ITraceFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import {
    ITrafficDataVehicleTrace} from "../ITrafficData"
import getMostSpecificIdsFromFilter from "Pages/EventJournal/utils/getMostSpecificIdsFromFilter"
import convertTimeFrameToTimespanFilter from "../utils/convertTimeFrameToTimespanFilter"

interface ITrafficDataRequestBase {
    cameraGroupIds?: string[]
    cameraIds?: string[]
    zoneIds?: string[]
    vehicleClassIds?: string[]
    traceTypeIds?:string[]
    timeZone: string
    timespanGroupBy: string
}

interface ITrafficDataRequestCustom extends ITrafficDataRequestBase {
    timespanFilterTypeId: "Custom"
    timespanFilterTypeCustomFromGe: Date
    timespanFilterTypeCustomToLe: Date
}

interface ITrafficDataRequestRelative extends ITrafficDataRequestBase {
    timespanFilterTypeId: "Relative"
    timespanFilterTypeRelativeFromGe: string
    timespanFilterTypeRelativeToLe: string
}

type ITrafficDataRequest = ITrafficDataRequestCustom | ITrafficDataRequestRelative

const filterFromSelection = (selection: ITraceFilterSelection): ITrafficDataRequest => ({
    ...getMostSpecificIdsFromFilter(selection),
    vehicleClassIds: selection.vehicleClassIds,
    traceTypeIds: selection.traceTypeIds,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timespanGroupBy: selection.resolution,
    ...convertTimeFrameToTimespanFilter(selection.timeFrame)
})


export async function getVehicleTrace(selection: ITraceFilterSelection): Promise<ITrafficDataVehicleTrace[]> {
    const filter = filterFromSelection(selection)
    if((filter.cameraIds && filter.cameraIds.length ) || (filter.zoneIds && filter.zoneIds.length) ){
    const response = await axios.put<ITrafficDataVehicleTrace[]>("/api/event/v1/data/vehicle-trace", filter)
    return response
        .data
        .map(({ trace, ...o }) => ({ ...o, trace }))
    } else return []
}
