import { ICameraGroupSelectDto } from "."

export enum EventRuleType{
    Block = "Block",
    Allow = "Allow",
    Trigger = "Trigger"
}
export interface IEventRulesSelectDto {
    id: string
    name: string
}

export interface IEventRulesListDto {
    id: string
    name: string
    cameraGroups: ICameraGroupSelectDto[]
}

export interface IEventRuleReadDto {
    id: string
    name: string
    description: string | null

    triggerEvent:string
    triggerCamera: number | null
    triggerZone: number | null
    subEvent: string
    subCamera: number | null
    rangeFrom: number
    rangeTo: number
    subZone: number | null
    ttl: number | null
    type: EventRuleType
    
    createAt: string | null
    createName: string | null
    updateAt: string | null
    updatename: string | null
    cameraGroups: ICameraGroupSelectDto[]
}

export interface IEventRuleCreateUpdateDto {
    name: string
    description: string | null

    triggerEvent:string
    triggerCamera: number | null
    triggerZone: number | null
    subEvent: string
    subCamera: number | null
    rangeFrom: number
    rangeTo: number
    subZone: number | null
    ttl: number | null
    type: EventRuleType
    cameraGroupIds: string[] | null
}
