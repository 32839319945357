import { faFileChartLine } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Typography } from "@mui/material"
import axios from "axios"
import Filter from "Components/Filter/Filter"
import MultipleSelector from "Components/Filter/Filters/MultipleSelector/MultipleSelector"
import { ITimeFrame } from "Components/Filter/Filters/TimeFrameSelector/ITimeFrame"
import TimeFrameSelector from "Components/Filter/Filters/TimeFrameSelector/TimeFrameSelector"
import useOnMount from "hooks/useOnMount"
import { t } from "i18next"
import { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { formatDate, formatTime } from "utils/timedisplay"
import { getLogEntryDiplayString } from "./AuditingTable"
import { defaultSelection, validLogTypes } from "./defaultSelection"
import { getAuditLogExportData, IAuditLogListItem, IAuditLogType } from "./getAuditLog"
import { loadInitalSelection, saveSelectionToLocalStorage } from "./persistance"

export interface IAuditingFilterSelection {
    userIds: string[]
    timeframe: ITimeFrame
    logTypes: IAuditLogType[]
}


async function getUsers(): Promise<{ id: string, name: string }[]> {
    const response = await axios.get<{ id: string, name: string }[]>("/api/event/v1/users/select")
    return response.data
}
interface IAuditingWrapper {
    onSubmit: (selection: IAuditingFilterSelection) => void
}

export default function AuditingFilterWrapper({
    onSubmit
}: IAuditingWrapper) {
    const usersQuery = useQuery("users", getUsers)

    if (usersQuery.isLoading)
        return <DelayedCircularProgress delay={500} />

    if (usersQuery.isError || !usersQuery.isSuccess)
        return <Typography>{t("failed-to-load-filter")}</Typography>

    return <AuditingFilter
        onSubmit={onSubmit}
        users={usersQuery.data}
    />
}

interface IAuditingFilter {
    onSubmit: (selection: IAuditingFilterSelection) => void
    users: { id: string, name: string }[]
}

function AuditingFilter({
    onSubmit,
    users
}: IAuditingFilter) {
    const userIds = useMemo(() => users.map(({ id }) => id), [users])
    const [selection, setSelection] = useState<IAuditingFilterSelection>(() => loadInitalSelection({ userIds }))
    const [startDownload, setStartDownload] = useState(false)

    useOnMount(() => onSubmit(selection))

    useEffect(() => {
        saveSelectionToLocalStorage(selection)
    }, [selection])

    const csvExportQuery = useQuery(
        ["audit-log-export", selection],
        () => getAuditLogExportData(selection),
        {
            enabled: startDownload
        }
    )

    useEffect(() => {
        if (!csvExportQuery.isSuccess)
            return

        if (!startDownload)
            return

        const csvRows = convertDataToCsv(csvExportQuery.data)

        const blob = new Blob([csvRows], { type: "text/csv" })

        const dateOfExport = new Date()

        const url = window.URL.createObjectURL(blob)

        const link = document.createElement("a")
        link.href = url
        link.download = `${t("auditing-page.title")}-${formatDate(dateOfExport)}-${formatTime(dateOfExport)}.csv`
        link.click()

        setStartDownload(false)

        window.URL.revokeObjectURL(url)
    }, [csvExportQuery.data, csvExportQuery.isSuccess, startDownload])

    return <Filter
        onReset={() => setSelection(defaultSelection)}
        onSubmit={() => onSubmit(selection)}
        hasActiveFilters={hasActiveFilters(selection)}
        customActions={
            <Button
                variant="outlined"
                color="secondary"
                onClick={() => setStartDownload(true)}
                startIcon={<FontAwesomeIcon icon={faFileChartLine} />}
                endIcon={csvExportQuery.isLoading && <DelayedCircularProgress delay={1000} size={18} />}
                disabled={csvExportQuery.isLoading}
            >
                {t("event-filter.download-csv")}
            </Button>
        }
    >
        <MultipleSelector
            values={userIds}
            selectedValues={selection.userIds}
            label={t("users")}
            valueFormatter={id => users.find(user => user.id === id)?.name ?? ""}
            onChange={userIds => setSelection({ ...selection, userIds })}
        />
        <MultipleSelector
            values={validLogTypes}
            selectedValues={selection.logTypes}
            label={t("auditing-page.log-action-type")}
            valueFormatter={id => t(`auditing-page.${id}.title`)}
            onChange={actionTypes => setSelection({ ...selection, logTypes: actionTypes as IAuditLogType[] })}
        />
        <TimeFrameSelector
            timeFrame={selection.timeframe}
            onUpdateTimeFrame={timeframe => setSelection({ ...selection, timeframe })}
            includeCustom
        />
    </Filter>
}

function hasActiveFilters(selection: IAuditingFilterSelection) {
    return !defaultSelection.logTypes.every(type => selection.logTypes.includes(type))
        || defaultSelection.timeframe.type !== selection.timeframe.type
        || selection.userIds.length > 0
}

function convertDataToCsv(data: IAuditLogListItem[]): string {
    const headerRow = [
        t("auditing-page.date-col"),
        t("auditing-page.user-name-col"),
        t("auditing-page.action-col")
    ].join(",")

    const dataRows = data.map(row => ([
        `${formatDate(row.createdAt)} ${formatTime(row.createdAt)}`,
        row.createdName,
        t(`auditing-page.${getLogEntryDiplayString(row)}`)
    ].join(",")))

    const csvRows = [
        headerRow,
        ...dataRows
    ].join("\n")

    return csvRows
}