import { FormControl, ListItemText, MenuItem, Select, SelectChangeEvent, Checkbox } from "@mui/material"
import { useTranslation } from "react-i18next"

import FilterControlLabel from "Components/Filter/FilterControlLabel"
import ICameraGroupHierarchy from "../CameraSelector/ICameraGroupHierarchy"
import { useMemo } from "react"

interface IZoneSelector {
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
    cameraGroups: string[]
    cameras: string[]
    selectedValues: string[]
    onChange: (values: string[]) => void
}

export default function ZoneSelector({ selectedValues, onChange, cameraGroupsHierarchy, cameraGroups, cameras }: IZoneSelector) {
    const { t } = useTranslation()

    const zoneIdsGroupedByZoneName = useMemo(
        () => cameraGroupsHierarchy
            .filter(group => !cameraGroups.length || cameraGroups.includes(group.id))
            .flatMap(group => group.cameras)
            .filter(camera => !cameras.length || cameras.includes(camera.id))
            .flatMap(camera => camera.zones)
            .reduce<{ [name: string]: string[] }>((acc, zone) => {
                acc[zone.name] = [zone.id, ...(acc[zone.name] ?? [])]

                return acc
            }, {}),
        [cameraGroupsHierarchy, cameraGroups, cameras]
    )
    function handleChange(event: SelectChangeEvent<string[]>) {
        const value = event.target.value

        if (typeof value === "string")
            return

        const zoneIds = value.flatMap(name => zoneIdsGroupedByZoneName[name])

        onChange(Array.from(new Set(zoneIds)))
    }

    const selectedZones = selectedValues.length === 0
        ? []
        : Object
            .entries(zoneIdsGroupedByZoneName)
            .reduce<string[]>((acc, [zoneName, zoneIds]) => {
                if (zoneIds.every(id => selectedValues.includes(id)))
                    acc.push(zoneName)

                return acc
            }, [])

    return <FormControl sx={{ width: "100%" }}>
        <FilterControlLabel text={t("event-filter.zones")} />
        <Select
            multiple
            value={selectedZones}
            onChange={handleChange}
            variant="filled"
            disableUnderline
            MenuProps={{
                sx: {
                    ".MuiMenu-list": {
                        padding: 0
                    }
                }
            }}
            renderValue={() => selectedZones.join(", ")}
        >
            {Object
                .keys(zoneIdsGroupedByZoneName)
                .map(name =>
                    <MenuItem
                        key={name}
                        value={name}
                        sx={{ pl: 1, pr: 1, pb: 0, pt: 0 }}
                    >
                        <Checkbox checked={selectedZones.includes(name)} />
                        <ListItemText
                            primary={name}
                            primaryTypographyProps={{ sx: { marginTop: "3px" } }}
                        />
                    </MenuItem>
                )}
        </Select>
    </FormControl>
}