import {DatePicker} from "@mui/x-date-pickers"
import {LocalizationProvider} from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { useState } from "react"
import { Box, Button, Card, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { formatDate, formatTime, SecondsDisplay } from "utils/timedisplay"
import moment, { Moment } from "moment"

interface ICustomTimeFrameSelector {
    day: Date | undefined
    onUpdateTimeFrame: (from: Date, to: Date) => void
}

// Returns current time rounded down to the minute
function getNow() {
    return moment().startOf("minute")
}


const MIN_DATE = moment(new Date("2021-01-01 00:00:00"))

function defaultFrom() {
    return getNow().subtract(2, "day").startOf("day")
}

function defaultTo() {
    return getNow().subtract(2, "day").endOf("day")
}

export default function CustomSingleDayTimeFrameSelector({
    day: initalFrom,
    onUpdateTimeFrame
}: ICustomTimeFrameSelector) {
    const { t } = useTranslation()
    const [from, setFrom] = useState<Moment | undefined>(initalFrom ? moment(initalFrom).startOf("day") : defaultFrom())
    const [to, setTo] = useState<Moment | undefined>(initalFrom ? moment(initalFrom).endOf("day") : defaultTo())

    const rightArrow = "→"

    function resetTimeFrame() {
        setFrom(defaultFrom())
        setTo(defaultTo())
    }

    const setDate = (value:moment.Moment | undefined)=>{
        if(value){
            setFrom(value.clone().startOf("day"))
            setTo(value.clone().endOf("day"))
        }
    }

    return <LocalizationProvider dateAdapter={AdapterMoment}>
        <Card
            sx={{
                p: 2
            }}
        >
            <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                    <Box sx={{ position: "relative" }}>
                        <Typography>{t("event-filter.select-day")}</Typography>
                        <CustomDateSelector
                            value={from}
                            onChange={newValue => setDate(newValue)}
                            minDateTime={MIN_DATE}
                        />
                    </Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                    {(from?.toDate && to?.toDate()) &&
                        <Typography>
                            {formatDate(from.toDate())} {formatTime(from.toDate(), SecondsDisplay.HIDE_SECONDS)} {rightArrow} {formatDate(to.toDate())} {formatTime(to.toDate(), SecondsDisplay.HIDE_SECONDS)}
                        </Typography>
                    }
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => resetTimeFrame()}
                    >{t("event-filter.time-frame.clear")}</Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={!from || !to}
                        onClick={() => {
                            if (!from || !to)
                                throw new Error("From and to have to be set")

                            onUpdateTimeFrame(from.toDate(), to.toDate())
                        }}
                    >{t("event-filter.time-frame.done")}</Button>
                </Stack>
            </Stack>
        </Card>
    </LocalizationProvider>
}

interface ICustomDateSelector {
    value: moment.Moment | undefined,//ParseableDate<moment.Moment | null>
    onChange: (date: moment.Moment | undefined) => void
    minDateTime?: moment.Moment 
}

function CustomDateSelector({
    value,
    onChange,
    minDateTime
}: ICustomDateSelector) {
    return <DatePicker
        value={value}
        onChange={(newValue: moment.Moment | null |undefined) => onChange(newValue ?? undefined)}
        minDate={minDateTime}
        maxDate={moment().clone().subtract(2,"days")}
        orientation="portrait"
        slots={{
            actionBar:()=>null,
            toolbar:()=>null
        }}
        disableFuture={true}
        openTo="day"
        views={["day"]}
        format={"DD/MM/YYYY"}
    />
}