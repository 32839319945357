import { Box, Typography } from "@mui/material"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import ChartCard from "./ChartCard"
import { IDragonChartCardActions } from "./DragonChartCardActions"

interface IDragonChartCard {
    data: unknown[] | undefined
    status: "idle" | "loading" | "error" | "success"
    title: string
    height?: number | "auto"
    children: React.ReactNode
    actions?: React.ReactNode
    cardActionsRef?: React.Dispatch<React.SetStateAction<IDragonChartCardActions|null>>
}

export default function DragonChartCard({
    data,
    status,
    title,
    height = 315,
    children,
    actions,
    cardActionsRef
}: IDragonChartCard) {
    const { t } = useTranslation()
    useEffect(()=>{
        if(!!cardActionsRef && !(status === "success" && data?.length) ){
            cardActionsRef(null)
        }
    })
    return <ChartCard title={title} actions={actions}>
        <Box p={1} height={height} boxSizing={"border-box"} overflow={"unset"}>
            {(() => {
                if (status === "loading")
                    return <Loading />

                if (status === "error" || !data)
                    return <Error />

                if (data.length === 0)
                    return <Typography>{t("no-data-for-given-filter")}</Typography>

                return children
            })()}
        </Box>
    </ChartCard>
}

function Loading() {
    return <Box sx={{ display: "flex", justifyContent: "center" }}>
        <DelayedCircularProgress delay={250} />
    </Box>
}

function Error() {
    const { t } = useTranslation()
    return <Typography variant="h5" color="text.secondary" textAlign="center" lineHeight={3}>
        {t("data-analytics-page.failed-to-load-data")}
    </Typography>
}