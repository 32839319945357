
import DragonChartCard from "../DragonChartCard"
import { ITrafficData } from "../ITrafficData"
import CountingCameraPieChart, { IVehicleCountCamera } from "./CountingCameraPieChart"

import Grid from "@mui/material/Grid"

interface ICountingCameraList {
    data: ITrafficData[]
}

export default function CountingCameraList({
    data
}: ICountingCameraList) {
    const formattedData = transformData(data)
    
    if(formattedData.length === 0) return null

    return <Grid container spacing={2}>
        {formattedData.map(cam=>(
            <Grid item xs={12} sm={6} md={3} key={cam.name}>
                <DragonChartCard
                    title={cam.name}
                    data={cam.zones}
                    status={"success"}
                >
                    <CountingCameraPieChart camera={cam} />
                </DragonChartCard>
            </Grid>
        ))}

    </Grid>
}

function transformData(
    data: ITrafficData[]
) {
    return data.reduce<IVehicleCountCamera[]>((acc, entry) => {
        const camName = entry.camera.name
        const zoneName = entry.zone?.name 
        const vClass = entry.vehicleClassId!
        if(vClass === "Partial" || vClass === "Total") return acc
        let cam = acc.find(x=>x.name === camName)
        if (!cam){
            cam = {
                name: camName,
                zones: []
            }
            acc.push(cam)
        } 
        let zone = cam!.zones.find(z => z.name === zoneName)
        if(!zone){
            zone = {
                name: zoneName,
                vehicles: []
            }
            cam!.zones.push(zone)
        }
        const vehicle = zone.vehicles.find(v=> v.name === vClass)
        if(!vehicle){
            zone.vehicles.push({
                name: vClass,
                count: entry.value
            })
        }else{
            vehicle.count += entry.value
        }
        return acc
    }, [])
}