import { ReactComponent as faImageSlash } from "icons/faImageSlash-regular.svg"
import { Box, Button, Divider, Paper, Stack, SvgIcon, Typography, useTheme } from "@mui/material"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { InfiniteData, useMutation, useQueryClient } from "react-query"
import { formatDate, formatTime } from "utils/timedisplay"
import { IBookmark } from "./BookmarksTab"

interface IBookmarkItem {
    snapshotUrl?: string
    cameraName: string
    eventTypeId: string
    startedAt: Date
    ttl: Date
    bookmarkId: string
}

export default function BookmarkItem({ snapshotUrl, cameraName, eventTypeId, startedAt,ttl, bookmarkId }: IBookmarkItem) {
    const { t } = useTranslation()
    const { palette } = useTheme()

    const queryClient = useQueryClient()

    const { mutate: removeBookmark, isLoading } = useMutation(() => {
        return axios.delete(`/api/event/v1/event-bookmarks/${bookmarkId}`)
    }, {
        onSuccess: () => {
            queryClient.setQueryData<InfiniteData<IBookmark[]>>("bookmarks", (previousBookmarks) => {
                if (!previousBookmarks) throw new Error("Couldn't find previous bookmarks")
                return {
                    ...previousBookmarks,
                    pages: previousBookmarks.pages.map(page => page.filter(bookmark => bookmark.id !== bookmarkId))
                }
            })
        }
    })

    return (
        <Paper
            elevation={0}
            sx={{
                backgroundColor: theme => theme.palette.background.default,
                borderRadius: "4px 4px 0 0"
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    aspectRatio: "16 / 9",
                    backgroundImage: snapshotUrl && `url(${snapshotUrl})`,
                    backgroundPosition: "center center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "4px 4px 0 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {!snapshotUrl && <SvgIcon component={faImageSlash} viewBox="0 0 640 512" style={{ fontSize: 48, color: palette.text.secondary }} />}
            </Box>
            <Stack
                direction="column"
                divider={<Divider orientation="horizontal" flexItem />}
                spacing={1}
                p={2}
                sx={{ flexGrow: 1 }}
            >
                <Typography>{cameraName}</Typography>
                <Typography>
                    {t(`event-types.${eventTypeId}`)}
                </Typography>
                <Typography>{formatDate(startedAt)} {formatTime(startedAt)}</Typography>
                <Typography>{t("available-until")}: {formatDate(ttl)} {formatTime(ttl)}</Typography>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ width: "fit-content" }}
                    onClick={(e) => {
                        e.preventDefault()
                        removeBookmark()
                    }}
                    disabled={isLoading}
                >
                    {t("bookmarks.remove")}
                </Button>
            </Stack>
        </Paper>
    )
}