import { useTheme } from "@mui/material"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Checkbox, Dialog, Grid, IconButton, Typography } from "@mui/material"
import axios from "axios"
import { getWidgetWidthByType } from "Components/WidgetCreator/WidgetCreator"
import { IDataSources, IWidgetData, IWidgetTypes } from "Components/Widgets/types"
import { WidgetBase } from "Pages/Dashboard/WidgetBase"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { IResolution } from "Types/IResolution"

interface ICameraWidgetDto {
    id: string
    code: string
    name: string
    sourceId: IDataSources
    chartTypeId: IWidgetTypes
    timespanFilterTypeRelativeFromGe: string
    timespanFilterTypeRelativeToLe: string
    timespanGroupBy: IResolution
}

async function getCameraWidgets(): Promise<ICameraWidgetDto[]> {
    const response = await axios.get("/api/admin/v1/widget-templates/select")
    return response.data
}

interface IWidgetsWrapper {
    cameraId: string
    selectedWidgetIds: string[]
    onChangeSelectedWidgetIds: (updated: string[]) => void
}

export default function WidgetsWrapper({
    cameraId,
    selectedWidgetIds,
    onChangeSelectedWidgetIds
}: IWidgetsWrapper) {
    const { t } = useTranslation()
    const availableWidgets = useQuery("camera-details-widgets", getCameraWidgets)

    if (availableWidgets.isLoading)
        return <DelayedCircularProgress delay={500} />

    if (availableWidgets.isError || !availableWidgets.isSuccess)
        return <Typography>{t("something-went-wrong")}</Typography>

    return <WidgetsSelector
        widgets={availableWidgets.data}
        selectedWidgetIds={selectedWidgetIds}
        onChangeSelectedWidgetIds={onChangeSelectedWidgetIds}
        cameraId={cameraId}
    />
}

interface IWidgetsSelector {
    cameraId: string
    widgets: ICameraWidgetDto[]
    selectedWidgetIds: string[]
    onChangeSelectedWidgetIds: (updated: string[]) => void
}

const MAX_SELECTABLE_WIDGET_AMOUNT = 3

function WidgetsSelector({
    widgets,
    selectedWidgetIds,
    onChangeSelectedWidgetIds,
    cameraId
}: IWidgetsSelector) {
    const { t } = useTranslation()

    const [showWidgetAmountWarning, setShowWidgetAmountWarning] = useState(false)

    const closeDialog = () => setShowWidgetAmountWarning(false)

    return <>
        <Grid
            container
            spacing={2}
            p={2}
            sx={{
                overflow: "auto",
                marginTop: 0,
                width: "auto",
                marginLeft: -2
            }}
        >
            <Grid item xs={12}>
                <Typography variant="h6" component="h2">
                    {t("camera-details-page.camera-widget-introduction")}
                </Typography>
            </Grid>
            {widgets.map(widget => {
                const widgetWidth = getWidgetWidthByType(widget.chartTypeId,widget.sourceId)

                return <Grid
                    item
                    xl={widgetWidth}
                    lg={widgetWidth + 1}
                    md={widgetWidth + 2}
                    xs={12}
                    key={widget.id}
                >
                    <CameraDetailsWidget
                        widget={widget}
                        cameraId={cameraId}
                        isSelected={selectedWidgetIds.includes(widget.id)}
                        onSelectionChanged={checked => {
                            if (checked) {
                                if (selectedWidgetIds.length === MAX_SELECTABLE_WIDGET_AMOUNT) {
                                    setShowWidgetAmountWarning(true)
                                } else {
                                    onChangeSelectedWidgetIds([...selectedWidgetIds, widget.id])
                                }
                            }
                            else
                                onChangeSelectedWidgetIds(selectedWidgetIds.filter(id => id !== widget.id))
                        }}
                    />
                </Grid>
            })}
        </Grid>
        <Dialog
            open={showWidgetAmountWarning}
            onClose={closeDialog}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box display="flex" justifyContent="end" p={2}>
                <IconButton size="small" onClick={closeDialog}>
                    <FontAwesomeIcon icon={faTimes} />
                </IconButton>
            </Box>
            <Box p={4}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t("camera-details-page.max-amount-of-widgets-selected", { count: selectedWidgetIds.length })}
                </Typography>
            </Box>
        </Dialog>
    </>
}

interface ICameraDetailsWidget {
    widget: ICameraWidgetDto
    cameraId: string
    isSelected: boolean
    onSelectionChanged: (checked: boolean) => void
}

function CameraDetailsWidget({
    widget,
    cameraId,
    isSelected,
    onSelectionChanged
}: ICameraDetailsWidget) {
    const theme = useTheme()

    const widgetDataQuery = useQuery(["widgetData", widget.id, cameraId], () => getWidgetData(widget.id, cameraId))

    return <WidgetBase
        widget={widget}
        widgetDataQuery={widgetDataQuery}
        actions={
            <Checkbox
                checked={isSelected}
                onChange={event => onSelectionChanged(event.target.checked)}
            />
        }
        {...{ sx: { width: "100%", outline: `1px solid ${theme.palette.divider}` } }}
    />
}

async function getWidgetData(widgetId: string, cameraId: string): Promise<IWidgetData> {
    const response = await axios.get<IWidgetData>(`/api/event/v1/widget-templates/${widgetId}/cameras/${cameraId}/data`)
    return response.data
}