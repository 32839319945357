import {
    Paper,
    CardHeader,
    Typography,
    Divider
} from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartLine } from "@fortawesome/pro-regular-svg-icons"

interface Props {
    title: string
    children: React.ReactNode
    actions?: React.ReactNode
}

export default function ChartCard({ title, children,actions }: Props) {
    return (
        <Paper variant="outlined" sx={{ bgcolor: "unset" }}>
            <CardHeader
                avatar={<FontAwesomeIcon icon={faChartLine} />}
                title={<Typography fontSize="16px">{title}</Typography>}
                action={actions}
            />
            <Divider />
            {children}
        </Paper>
    )
}