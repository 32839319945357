import { useTheme } from "@mui/material"
import { getVehicleColor } from "Components/VehicleColors"
import { useTranslation } from "react-i18next"
import {
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    BarChart,
    Bar,
    LabelList
} from "recharts"

export interface IBarSeries {
    name: string,
    stackId: string,
    [serie: string]: number | string | null
}

export interface IDetailedBarChart {
    data: (IBarSeries | undefined)[]
    legendFormatter: (series: string) => string
}

export default function DetailedBarChart({
    data,
    legendFormatter
}: IDetailedBarChart){
    const { t } = useTranslation()
    const theme = useTheme()

    const digitsOfHighestValue = data.reduce<number>((prev, current) => {
        const numbers = Object.values(current ?? {}).filter(Number)
        const highest = Math.max(...(numbers.map(el => typeof el === "number" ? el.toString().length : 0)))
        return highest > prev ? highest : prev
    }, 0)
    return (
        <ResponsiveContainer debounce={300}>
            <BarChart
                data={data}
                margin={{
                    left: digitsOfHighestValue * 3
                }}
            >
                <XAxis
                    dataKey="name"

                />
                <YAxis />
                <Tooltip
                    contentStyle={{
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: theme.shape.borderRadius,
                        boxShadow: theme.shadows[1],
                        border: "none",
                        maxHeight: "300px",
                        width: "100%",
                        overflowX: "hidden",
                        overflowY: "auto",
                        pointerEvents: "none"
                    }}
                    itemStyle={{
                        lineHeight: .75
                    }}
                    isAnimationActive={false}
                    allowEscapeViewBox={{ x: true }}
                />
                <Legend
                    layout="vertical"
                    align="right"
                    wrapperStyle={{
                        overflow: "auto",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        paddingLeft: theme.spacing(2)
                    }}
                    formatter={(serie: string) => {
                        return (
                            <span
                                key={serie}
                                style={{
                                    color: theme.palette.text.primary,
                                    opacity:1
                                }}
                            >
                                {legendFormatter(serie)}
                            </span>
                        )
                    }}
                />
                <CartesianGrid strokeDasharray="3 3" />
                <Bar name={t("vehicle-classes.Pedestrian")} dataKey="Pedestrian" stackId="stacked" fill={getVehicleColor("Pedestrian")} >
                    <LabelList dataKey={"Pedestrian"} position={"center"} fill="black"/>
                </Bar>
                <Bar name={t("vehicle-classes.Bike")} dataKey="Bike" stackId="stacked" fill={getVehicleColor("Bike")} >
                    <LabelList dataKey={"Bike"} position={"center"} fill="white"/>
                </Bar>
                <Bar name={t("vehicle-classes.Motorbike")} dataKey="Motorbike" stackId="stacked" fill={getVehicleColor("Motorbike")} >
                    <LabelList dataKey={"Motorbike"} position={"center"} fill="white"/>
                </Bar>
                <Bar name={t("vehicle-classes.Car")} dataKey="Car" stackId="stacked" fill={getVehicleColor("Car")} >
                    <LabelList dataKey={"Car"} position={"inside"} fill="white"/>
                </Bar>
                <Bar name={t("vehicle-classes.Van")} dataKey="Van" stackId="stacked" fill={getVehicleColor("Van")} >
                    <LabelList dataKey={"Van"} position={"inside"} fill="white"/>
                </Bar>
                <Bar name={t("vehicle-classes.Truck")} dataKey="Truck" stackId="stacked" fill={getVehicleColor("Truck")} >
                    <LabelList dataKey={"Truck"} position={"inside"} fill="white"/>
                </Bar>

                <Bar name={t("vehicle-classes.Bus")} dataKey="Bus" stackId="stacked" fill={getVehicleColor("Bus")} >
                    <LabelList dataKey={"Bus"} position={"center"} fill="white"/>
                </Bar>
                <Bar name={t("vehicle-classes.Total")} dataKey="Total" fill={getVehicleColor("Total")} >
                    <LabelList dataKey={"Total"} position={"center"} fill="white"/>
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}