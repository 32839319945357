import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, FormControl, FormControlLabel, Grid, MenuItem, Paper, Select, SelectChangeEvent, Stack, Switch, TextField, Typography } from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import DragonPageWrapper from "Components/DragonPageWrapper"
import DragonTitle from "Components/DragonTitle"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { faEdit } from "@fortawesome/pro-regular-svg-icons"
import ErrorBoundary from "utils/ErrorBoundary"
import { DragonConfirmationDialogWithPromise } from "Components/DragonDialog"

enum IUSPMode {
    Json = "JSON",
    JsonImage = "JSON-IMG",
    Custom = "Custom"
}
interface IUSPSettings{
    isEnabled:boolean,
    ip: string,
    port: number,
    keepConnection: boolean,
    retryTimeout: number,
    eventFormat: IUSPMode,
    sendTransitAsEvent: boolean,
    sendCumulatedStats: boolean,
    sendSystemEvents:boolean,
    sendConfigEvents:boolean,
    customMessageSyntax?:string
}

const getUspSettings = async (): Promise<IUSPSettings> => {
    const response = await axios.get<IUSPSettings>("/api/v1/usp/config")
    return response.data
}
const putUspSettings = async (payload: IUSPSettings): Promise<AxiosResponse> => {
    return await axios.put("/api/v1/usp/config", payload)
}
export default function USPConfiguration() {
    const { t } = useTranslation()
    const [error, setError] = useState("")
    const [saveDialog, setSaveDialog] = useState<boolean>(false)
    const queryClient = useQueryClient()

    const {
        isLoading,
        isError,
        isSuccess,
        isFetching,
        data
    } = useQuery(
        "usp-settings",
        getUspSettings,
        { 
            onError: (error:AxiosError) => {
                if (error.response?.status === 404)
                    return setError(t("usp-settings.error-retrieve"))
                return setError(t("something-went-wrong"))
            },
            retry(failureCount, error) {
                if(failureCount<=10 && error.response?.status === 502) return true
                return false
            }
        }
    )

    const initialState= useMemo<IUSPSettings>(() => ({
        isEnabled: data?.isEnabled ?? false,
        ip: data?.ip ?? "0.0.0.0",
        port: data?.port ?? 0,
        keepConnection: data?.keepConnection ?? true,
        retryTimeout: data?.retryTimeout ?? 60,
        eventFormat: data?.eventFormat ?? IUSPMode.Json,
        sendTransitAsEvent: data?.sendTransitAsEvent ?? false,
        sendCumulatedStats: data?.sendCumulatedStats ?? false,
        sendSystemEvents:data?.sendSystemEvents ?? false,
        sendConfigEvents:data?.sendConfigEvents ?? false,
        customMessageSyntax: data?.customMessageSyntax
    }),[data])


    const [draft, setDraft] = useState<IUSPSettings>(initialState)
    
    useEffect(()=>{
        setDraft(initialState)
    },[initialState])

    const isChanged = useMemo<boolean>(
        () => (
            Object.entries(draft).some(
                ([key, value]) => initialState[key as keyof IUSPSettings] !== value
            )
        ),
        [draft, initialState]
    )

    const validation = useMemo<Partial<Record<keyof IUSPSettings, boolean>>>(
        () => (
            {
                isEnabled: true,
                ip: draft.ip ? true : false,
                port: !isNaN(draft.port) && typeof draft.port === "number",
                keepConnection: true,
                retryTimeout: !isNaN(draft.retryTimeout) && typeof draft.port === "number",
                eventFormat: true,
                sendTransitAsEvent: true,
                sendCumulatedStats: true,
                sendSystemEvents: true,
                sendConfigEvents: true,
                customMessageSyntax: true
            }
        ),
        [draft]
    )

    const { mutateAsync: handleSaveChangesAsync, isLoading: isLoadingSave } = useMutation(() => putUspSettings(draft),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("usp-settings")
            }
        }
    )    
    
    const handleChangeValue = useCallback<
    (key: string) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
        >((key) => (event) => {
            const updateDraft = (updated :{ [key: string]: unknown }) => setDraft({ ...draft, ...updated })
            updateDraft(({ [key]: event.target.value }))
        }, [draft])
    const handleChangeNumber = useCallback<
        (key: string) => (event: ChangeEvent<HTMLInputElement>) => void
            >((key) => (event) => {
                const num = parseInt(event.target.value)
                const updateDraft = (updated :{ [key: string]: number }) => setDraft({ ...draft, ...updated })
                updateDraft(({ [key]: num }))
            }, [draft])
    const handleChangeBoolean = useCallback<
    (key: string) => (event: ChangeEvent<HTMLInputElement>,checked:boolean) => void
        >((key) => (event,checked) => {
            const updateDraft = (updated :{ [key: string]: boolean }) => setDraft({ ...draft, ...updated })
            updateDraft(({ [key]: checked }))
        }, [draft])
    const handleChangeMode = useCallback<
        () => (event: SelectChangeEvent<IUSPMode>) => void
            >(() => (event) => {
                const value = event.target.value as IUSPMode
                setDraft({ ...draft, ...{eventFormat: value} })
            }, [draft])
    return (
        <DragonPageWrapper>
            <DragonTitle title={t("usp-settings.title")} />
            {(() => {
                if (isLoading || isFetching || isLoadingSave)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{error}</h2>

                if (isSuccess)
                    return (
                        <ErrorBoundary>
                            <Grid container component={Paper} overflow="auto" flex={1}>
                                <Grid item xs={12} md={4} p={2}>
                            <Stack spacing={2}>
                                <FormControlLabel 
                                    control={
                                        <Switch
                                        checked={draft.isEnabled}
                                        onChange={handleChangeBoolean("isEnabled")}
                                        
                                        />
                                    }
                                    label={t("usp-settings.is-enabled")}
                                />
                                

                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("usp-settings.ip")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="url"
                                            InputProps={{
                                                disableUnderline: initialState.ip === draft.ip,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                            }}
                                            value={draft.ip ?? ""}
                                            onChange={handleChangeValue("ip")}
                                            error={!validation.ip}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("usp-settings.port")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="number"
                                            InputProps={{
                                                disableUnderline: initialState.port === draft.port,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                inputMode: "numeric",
                                                type: "number"
                                            }}
                                            value={draft.port ?? 487}
                                            onChange={handleChangeNumber("port")}
                                            error={!validation.port}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControlLabel 
                                    control={
                                        <Switch
                                        checked={draft.keepConnection}
                                        onChange={handleChangeBoolean("keepConnection")}
                                        
                                        />
                                    }
                                    label={t("usp-settings.keep-connection")}
                                />
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("usp-settings.retry-timeout")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="number"
                                            InputProps={{
                                                disableUnderline: initialState.retryTimeout === draft.retryTimeout,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                inputMode: "numeric",
                                                type: "number"
                                            }}
                                            value={draft.retryTimeout}
                                            onChange={handleChangeNumber("retryTimeout")}
                                            error={!validation.retryTimeout}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("usp-settings.event-format.title")}</Typography>
                                        <Select
                                            variant="filled"
                                            value={draft.eventFormat}
                                            onChange={handleChangeMode()}
                                            disableUnderline={initialState.eventFormat === draft.eventFormat}
                                        >
                                            <MenuItem value={IUSPMode.Json}>{t("usp-settings.event-format.json")}</MenuItem>
                                            <MenuItem value={IUSPMode.JsonImage}>{t("usp-settings.event-format.json-img")}</MenuItem>
                                            {/* <MenuItem value={IUSPMode.Custom}>{t("usp-settings.event-format.custom")}</MenuItem> */}
                                        </Select>
                                    </Stack>
                                </FormControl>
                                <FormControlLabel 
                                    control={
                                        <Switch
                                        checked={draft.sendTransitAsEvent}
                                        onChange={handleChangeBoolean("sendTransitAsEvent")}
                                        
                                        />
                                    }
                                    label={t("usp-settings.traffic-events")}
                                />
                                <FormControlLabel 
                                    control={
                                        <Switch
                                        checked={draft.sendSystemEvents}
                                        onChange={handleChangeBoolean("sendSystemEvents")}
                                        
                                        />
                                    }
                                    label={t("usp-settings.system-events")}
                                />
                                
                                <FormControlLabel 
                                    control={
                                        <Switch
                                        checked={draft.sendConfigEvents}
                                        onChange={handleChangeBoolean("sendConfigEvents")}
                                        
                                        />
                                    }
                                    label={t("usp-settings.config-events")}
                                />
                            </Stack>
                            
                            </Grid>
                            </Grid>
                            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ minWidth: 150 }}
                                    onClick={() => setSaveDialog(true)}
                                    disabled={!isChanged || Object.values(validation).some(v => !v)}
                                >
                                    {t("save-changes")}
                                </Button>
                            </Stack>
                           <DragonConfirmationDialogWithPromise
                                open={saveDialog}
                                variant="save"
                                title={t("usp-settings.save-changes_confirmation")}
                                onClose={() => setSaveDialog(false)}
                                onConfirm={async () => {
                                    await handleSaveChangesAsync()
                                }}
                                isLoading={isLoading}
                                errorText={t("usp-settings.save-changes_failed")}
                            />
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>
        
    )
}