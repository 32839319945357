import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, FormControl, FormControlLabel, Grid, Paper, Stack, Switch, TextField, Typography } from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import DragonPageWrapper from "Components/DragonPageWrapper"
import DragonTitle from "Components/DragonTitle"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { faEdit } from "@fortawesome/pro-regular-svg-icons"
import ErrorBoundary from "utils/ErrorBoundary"
import { DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import NXMetaCamerasConfig from "./NXMetaCameraConfig"

export interface WaveCamera{
    dragonID: string,
    waveID: string,
    name: string,
    ip: string,
    url: string
}

export interface IWaveSettings{
    isEnabled:boolean,
    host: string,
    port: number,
    username:string,
    password:string,
    waveVersion:number,
    sendBookmark:boolean,
    sendEvents:boolean,
    lastSync?: Date,
    cameras: WaveCamera[]
}

const getNXMetaSettings = async (): Promise<IWaveSettings> => {
    const response = await axios.get<IWaveSettings>("/api/v1/nxmeta/config")
    return response.data
}
const putNXMetaSettings = async (payload: IWaveSettings): Promise<AxiosResponse> => {
    return await axios.put("/api/v1/nxmeta/config", payload)
}

export default function NXMetaConfiguration() {
    const { t } = useTranslation()
    const [error, setError] = useState("")
    const [saveDialog, setSaveDialog] = useState<boolean>(false)
    const queryClient = useQueryClient()

    const {
        isLoading,
        isError,
        isSuccess,
        isFetching,
        data
    } = useQuery(
        "nxmeta-settings",
        getNXMetaSettings,
        { 
            onError: (error:AxiosError) => {
                if (error.response?.status === 404)
                    return setError(t("nxmeta-settings.error-retrieve"))
                return setError(t("something-went-wrong"))
            },
            retry(failureCount, error) {
                if(failureCount<=10 && error.response?.status === 502) return true
                return false
            }
        }
    )

    const initialState= useMemo<IWaveSettings>(() => ({
        isEnabled: data?.isEnabled ?? false,
        host: data?.host ?? "",
        port: data?.port ?? 7001,
        username: data?.username ?? "",
        password: data?.password ?? "",
        waveVersion: 5,
        sendBookmark: data?.sendBookmark ?? false,
        sendEvents: data?.sendEvents ?? false,
        cameras: data?.cameras ?? []
    }),[data])


    const [draft, setDraft] = useState<IWaveSettings>(initialState)
    
    useEffect(()=>{
        setDraft(initialState)
    },[initialState])

    const isChanged = useMemo<boolean>(
        () => (
            Object.entries(draft).some(
                ([key, value]) => initialState[key as keyof IWaveSettings] !== value
            )
        ),
        [draft, initialState]
    )

    const validation = useMemo<Partial<Record<keyof IWaveSettings, boolean>>>(
        () => (
            {
                isEnabled: true,
                host: draft.host.length >0 && draft.host.length <300,
                port: !isNaN(draft.port) && typeof draft.port === "number",
                waveVersion: !isNaN(draft.waveVersion) && typeof draft.waveVersion === "number",
                cameras: !!draft.cameras && draft.cameras.length > 0,
                username: draft.waveVersion>4 ? draft.username.length >0 && draft.username.length <300 : true,
                password: draft.waveVersion>4 ? draft.password.length >0 && draft.password.length <300 : true
            }
        ),
        [draft]
    )

    const { mutateAsync: handleSaveChangesAsync, isLoading: isLoadingSave } = useMutation(() => putNXMetaSettings(draft),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("nxmeta-settings")
            }
        }
    )    
    const handleChangeValue = useCallback<
    (key: string) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
        >((key) => (event) => {
            const updateDraft = (updated :{ [key: string]: unknown }) => setDraft({ ...draft, ...updated })
            updateDraft(({ [key]: event.target.value }))
        }, [draft])
    const handleChangeNumber = useCallback<
        (key: string) => (event: ChangeEvent<HTMLInputElement>) => void
            >((key) => (event) => {
                const num = parseInt(event.target.value)
                const updateDraft = (updated :{ [key: string]: number }) => setDraft({ ...draft, ...updated })
                updateDraft(({ [key]: num }))
            }, [draft])
    const handleChangeBoolean = useCallback<
    (key: string) => (event: ChangeEvent<HTMLInputElement>,checked:boolean) => void
        >((key) => (event,checked) => {
            const updateDraft = (updated :{ [key: string]: boolean }) => setDraft({ ...draft, ...updated })
            updateDraft(({ [key]: checked }))
        }, [draft])
    const handleCameraSync = (cameras:WaveCamera[]) => {
            setDraft({ ...draft, ...{cameras: cameras} })
        }
    return (
        <DragonPageWrapper>
            <DragonTitle title={t("nxmeta-settings.title")} />
            {(() => {
                if (isLoading || isFetching || isLoadingSave)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{error}</h2>

                if (isSuccess)
                    return (
                        <ErrorBoundary>
                            <Grid container component={Paper} overflow="auto" flex={1}>
                                <Grid item xs={12} md={4} p={2}>
                                <Stack spacing={2}>
                                    <FormControl>
                                        <FormControlLabel 
                                            control={
                                                <Switch
                                                checked={draft.isEnabled}
                                                onChange={handleChangeBoolean("isEnabled")}
                                                
                                                />
                                            }
                                            label={t("nxmeta-settings.is-enabled")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Stack spacing={1}>
                                            <Typography variant="h6" fontWeight="bold">{t("nxmeta-settings.host")}</Typography>
                                            <TextField
                                                variant="filled"
                                                type="url"
                                                InputProps={{
                                                    disableUnderline: initialState.host === draft.host,
                                                    endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                    inputMode: "url",
                                                    type: "url"
                                                }}
                                                value={draft.host ?? ""}
                                                onChange={handleChangeValue("host")}
                                                error={!validation.host}
                                                fullWidth
                                            />
                                        </Stack>
                                    </FormControl>
                                    <FormControl>
                                        <Stack spacing={1}>
                                            <Typography variant="h6" fontWeight="bold">{t("nxmeta-settings.port")}</Typography>
                                            <TextField
                                                variant="filled"
                                                type="number"
                                                InputProps={{
                                                    disableUnderline: initialState.port === draft.port,
                                                    endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                    inputMode: "numeric",
                                                    type: "number"
                                                }}
                                                value={draft.port ?? 1883}
                                                onChange={handleChangeNumber("port")}
                                                error={!validation.port}
                                                fullWidth
                                            />
                                        </Stack>
                                    </FormControl>
                                    <FormControl>
                                            <Stack spacing={1}>
                                                <Typography variant="h6" fontWeight="bold">{t("nxmeta-settings.username")}</Typography>
                                                <TextField
                                                    variant="filled"
                                                    type="text"
                                                    InputProps={{
                                                        disableUnderline: initialState.username === draft.username,
                                                        endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                        inputMode: "text",
                                                        type: "text"
                                                    }}
                                                    value={draft.username ?? ""}
                                                    onChange={handleChangeValue("username")}
                                                    
                                                    fullWidth
                                                />
                                            </Stack>
                                        </FormControl>
                                        <FormControl>
                                            <Stack spacing={1}>
                                                <Typography variant="h6" fontWeight="bold">{t("nxmeta-settings.password")}</Typography>
                                                <TextField
                                                    variant="filled"
                                                    type="password"
                                                    InputProps={{
                                                        disableUnderline: initialState.password === draft.password,
                                                        endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                        inputMode: "text",
                                                        type: "password"
                                                    }}
                                                    value={draft.password ?? ""}
                                                    onChange={handleChangeValue("password")}
                                                    
                                                    fullWidth
                                                />
                                            </Stack>
                                        </FormControl>
                                    <FormControl>
                                        <FormControlLabel 
                                            control={
                                                <Switch
                                                checked={draft.sendBookmark}
                                                onChange={handleChangeBoolean("sendBookmark")}
                                                
                                                />
                                            }
                                            label={t("nxmeta-settings.send-bookmark")}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormControlLabel 
                                            control={
                                                <Switch
                                                checked={draft.sendEvents}
                                                onChange={handleChangeBoolean("sendEvents")}
                                                
                                                />
                                            }
                                            label={t("nxmeta-settings.send-events")}
                                        />
                                    </FormControl>
                                </Stack>
                                </Grid>
                                <Grid item xs={12} md={8} p={2}>
                                    <Stack spacing={2}>
                                        <Typography variant="h6" fontWeight="bold">{t("nxmeta-settings.cameras.title")}</Typography>
                                        <NXMetaCamerasConfig
                                            waveConfig={draft}
                                            onCameraSync={handleCameraSync}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ minWidth: 150 }}
                                    onClick={() => setSaveDialog(true)}
                                    disabled={!isChanged || Object.values(validation).some(v => !v)}
                                >
                                    {t("save-changes")}
                                </Button>
                            </Stack>
                           <DragonConfirmationDialogWithPromise
                                open={saveDialog}
                                variant="save"
                                title={t("nxmeta-settings.save-changes_confirmation")}
                                onClose={() => setSaveDialog(false)}
                                onConfirm={async () => {
                                    await handleSaveChangesAsync()
                                }}
                                isLoading={isLoading}
                                errorText={t("nxmeta-settings.save-changes_failed")}
                            />
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>
        
    )
}