import { useUserSettings } from "App/UserSettingsProvider"
import { useAuth } from "oidc-react"

export type IRole = "UserAdministrator" | "Administrator" | "Operator" | "Scenarios"
export type IDragonFeatures = "DashboardEnabled" | "MapEnabled"| "EventJournalEnabled" | "DataAnalyticsEnabled" | "RealtimeEventsEnabled" | "DeviceStatusEnabled" | "CameraOverviewEnabled" | "ScenarioEnabled" | "RulesEnabled" | "ReportEnabled" | "EmailEnabled" | "USPEnabled" | "OPCEnabled" | "FailoverEnabled" | "GenetecEnabled" | "WaveEnabled" | "MilestoneEnabled" | "NXMetaEnabled" | "TraceEnabled"
interface IProfile {
    name: string
    email?: string
    phoneNumber?: string
    roles: IRole[]
    permissions: {
        canMarkEventAsTrueOrFalse: boolean
        canSeeCommentsOnEvent: boolean
        canExportEvents: boolean
        canSeeEventExtraData: boolean
    }
    features: IDragonFeatures[]
}

export function useUserProfile(): IProfile {
    const { userData } = useAuth()
    const {userSettings} = useUserSettings()

    if (!userData?.profile)
        throw new Error("Can't use useUserInfo hook on non authenticated session!")

    const profile = userData.profile

    if (profile.name === undefined)
        throw new Error("Users must have a name!")

    if (profile.roles === undefined || !(profile.roles as string).length)
        throw new Error("Users must have a role!")

    return {
        name: profile.name,
        email: profile.email,
        phoneNumber: profile.phone_number,
        roles: ((profile.roles! as string).split(", ") as IRole[]),
        permissions: {
            canExportEvents: userSettings?.team?.permissionIds.includes("EventExport") ?? false,
            canMarkEventAsTrueOrFalse: userSettings?.team?.permissionIds.includes("EventValid") ?? false,
            canSeeCommentsOnEvent: userSettings?.team?.permissionIds.includes("EventComment") ?? false,
            canSeeEventExtraData: userSettings?.team?.permissionIds.includes("EventComment") ?? false
        },
        features: (process.env.REACT_APP_DRAGON_FEATURES?.split(",").map(feat => feat.trim()) as IDragonFeatures[]) ?? []
    }
}