import { Box, Paper, Typography } from "@mui/material"
import axios from "axios"
import { getWidgetWidthByType } from "Components/WidgetCreator/WidgetCreator"
import { IWidget, IWidgetData } from "Components/Widgets/types"
import { WidgetBase } from "Pages/Dashboard/WidgetBase"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { IMapCamera } from "./MapOverview"


export default function CameraDetails({
    widgetTemplates,
    id
}: IMapCamera) {
    const { t } = useTranslation()
    return <Box
        display="flex"
        flexWrap="wrap"
        gap={1}
        p={2}
        onDrag={e => {
            e.stopPropagation()
            e.preventDefault()
        }}
    >
        {widgetTemplates.length === 0
            ? <Box component={Paper} p={2} maxWidth={"100%"} sx={{position:"relative"}}>
                <Typography>{t("map-overview-page.no-widgets-selected-for-camera")}</Typography>
            </Box>
            : widgetTemplates
                .map(widget =><Box maxWidth={"100%"} sx={{position:"relative"}}>
                    <Widget
                        key={widget.id}
                        widget={widget}
                        cameraId={id}
                    /></Box>
                )
        }
    </Box>
}

export type ICameraDetailsWidget = IWidget & {
    timespanFilterTypeRelativeFromGe: string
    timespanFilterTypeRelativeToLe: string
    timespanGroupBy: string
}

function Widget({
    widget,
    cameraId
}: {
    widget: ICameraDetailsWidget,
    cameraId: string
}) {
    const widgetDataQuery = useQuery(
        ["widgetData", widget.id, cameraId],
        () => getWidgetData(widget.id, cameraId)
    )
    return <WidgetBase
        widget={widget}
        widgetDataQuery={widgetDataQuery}
        {...{
            sx: {
                width: `${getWidgetWidthByType(widget.chartTypeId,widget.sourceId) * 300}px`,
                maxWidth: "100%"
            }
        }}
    />
}

async function getWidgetData(widgetId: string, cameraId: string): Promise<IWidgetData> {
    const response = await axios.get<IWidgetData>(`/api/event/v1/widget-templates/${widgetId}/cameras/${cameraId}/data`)
    return response.data
}