import { memo, useEffect, useMemo, useState } from "react"
import {
    Stack,
    Button,
    Step,
    StepLabel,
    Stepper,
    Typography,
    Box
} from "@mui/material"
import SourceAndType from "./SourceAndType"
import Filtering from "./Filtering"
import NameAndPreview from "./NameAndPreview"
import IWidgetSelection from "./types/IWidgetSelection"
import IWidgetDetails from "./types/IWidgetDetails"
import React from "react"
import { defaultSelection } from "./utils/defaultSelection"
import axios, { AxiosError } from "axios"
import { useMutation, useQueries, useQueryClient } from "react-query"
import IEventTypeGroup from "Components/Filter/Filters/TrafficEventTypesSelector/IEventTypeGroup"
import ICameraGroupHierarchy from "Components/Filter/Filters/CameraSelector/ICameraGroupHierarchy"
import IVehicleClass from "Components/Filter/Filters/VehicleClassesSelector/IVehicleClass"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { useTranslation } from "react-i18next"
import { filterFromSelection } from "./utils/filterFromSelection"
import { selectionFromFilter } from "./utils/selectionFromFilter"
import { GridWidget } from "Pages/Dashboard/Dashboard"
import { useEnqueueSnackbar } from "utils/useEnqueueSnackbar"
import { dataSources } from "./utils/dataSources"
import DragonDialog from "Components/DragonDialog"
import { assertUnreachable } from "utils/assertUnrechable"
import { IDataSources, IWidget, IWidgetTypes } from "Components/Widgets/types"
import { IEntityWithEtag } from "utils/queryHelpers"

export enum Steps {
    SOURCE_AND_TYPE,
    FILTERING,
    NAME_AND_PREVIEW
}

const renderStep = (
    step: Steps,
    selection: IWidgetSelection,
    setSelection: React.Dispatch<React.SetStateAction<IWidgetSelection>>,
    eventTypes: IEventTypeGroup[],
    vehicleClasses: IVehicleClass[],
    cameraGroups: ICameraGroupHierarchy[]
) => {
    switch (step) {
        case Steps.SOURCE_AND_TYPE:
            return <SourceAndType
                selection={selection}
                setSelection={setSelection}
            />
        case Steps.FILTERING:
            return <Filtering
                selection={selection}
                setSelection={setSelection}
                eventTypes={eventTypes}
                vehicleClasses={vehicleClasses}
                cameraGroupsHierarchy={cameraGroups}
            />
        case Steps.NAME_AND_PREVIEW:
            return <NameAndPreview
                selection={selection}
                setSelection={setSelection}
            />
        default:
            assertUnreachable(step)
    }
}


interface IWidgetCreatorWrapper {
    open: boolean
    handleClose: (wdg:{ i: string; w: number; h: number; dataWidget: IEntityWithEtag<IWidget>; x: number; y: number; } | null) => void
    widgetId?: string
}

function WidgetCreatorWrapper({ open, handleClose, widgetId }: IWidgetCreatorWrapper) {
    const { t } = useTranslation()

    const result = useQueries([
        { queryKey: "traffic-event-types", queryFn: getEventType },
        { queryKey: "vehicle-classes", queryFn: getVehicleClasses },
        { queryKey: "camera-groups", queryFn: getCameraHeirarchy },
        { queryKey: ["widget-details", widgetId], queryFn: () => getWidgetDetails(widgetId as string), enabled: !!widgetId }
    ])

    const isLoading = result.some(query => query.isLoading)
    const isError = result.some(query => query.isError)

    const [{ data: trafficEventTypes }, { data: vehicleClasses }, { data: cameraGroups }, { data: widgetDetails }] = result
    const isSuccess = !!(trafficEventTypes && vehicleClasses && cameraGroups && !!widgetDetails === !!widgetId)

    return (
        <DragonDialog open={open} onClose={()=>handleClose(null)} maxWidth="xl">
            {(() => {
                if (isLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <Typography>{t("event-filter.failed-to-load")}</Typography>

                if (isSuccess)
                    return (
                        <WidgetCreator
                            handleClose={handleClose}
                            eventTypes={trafficEventTypes}
                            vehicleClasses={vehicleClasses}
                            cameraGroups={cameraGroups}
                            widgetDetails={widgetDetails}
                        />
                    )
            })()}
        </DragonDialog>
    )
}

export default memo(WidgetCreatorWrapper)

export function getWidgetWidthByType(widgetType: IWidgetTypes, dataSources: IDataSources) {
    switch (widgetType) {
        case "Donut":
            if(dataSources === "PeopleOccupancy") return 3
            return 2
        case "Chord":
            return 2
        case "Line":
        case "Table":
        case "Bar":
        case "Heatmap":
            return 6
        default:
            assertUnreachable(widgetType)
    }
}

interface IWidgetCreator {
    handleClose: (wdg:{ i: string; w: number; h: number; dataWidget: IEntityWithEtag<IWidget>; x: number; y: number; } | null) => void
    eventTypes: IEventTypeGroup[]
    vehicleClasses: IVehicleClass[]
    cameraGroups: ICameraGroupHierarchy[]
    widgetDetails?: { selection: IWidgetSelection, id: string, eTag: string }
}

function WidgetCreator({ handleClose, eventTypes, vehicleClasses, cameraGroups, widgetDetails }: IWidgetCreator) {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const enqueueSnackbar = useEnqueueSnackbar()
    
    const steps = [t("widget-creator.step-1"), t("widget-creator.step-2"), t("widget-creator.step-3")]

    const defaultSelectedEventTypeIds = useMemo(() => getDefaultSelectedEventTypeIds(eventTypes), [eventTypes])
    const defaultSelectedVehicleClassIds = useMemo(() => {
        return vehicleClasses.map(vclass => vclass.id)
    }, [vehicleClasses])

    const [selection, setSelection] = useState<IWidgetSelection>(widgetDetails?.selection ?? {
        ...defaultSelection,
        eventTypeIds: defaultSelectedEventTypeIds,
        vehicleClassIds: defaultSelectedVehicleClassIds
    })

    useEffect(()=>{
        if(selection && selection.dataSource === "PeopleCount"){
            selection.peopleClassIds = ["Person"]
        }
        if(selection && selection.dataSource === "BikeCount"){
            selection.peopleClassIds = ["Bike"]
        } else{
            selection.peopleClassIds = []
        }
    },[widgetDetails,selection])

    const [activeStep, setActiveStep] = useState(Steps.SOURCE_AND_TYPE)

    const { mutate: postWidget } = useMutation((selection: IWidgetSelection) => {
        return axios.post("/api/event/v1/data-widgets", filterFromSelection(selection))
    }, {
        onSuccess: ({ data, headers }, selection) => {
            const filter = filterFromSelection(selection)
            const wdg = {
                i: data.id,
                dataWidget: {
                    entity: {
                        id: data.id,
                        name: filter.name,
                        sourceId: filter.sourceId,
                        chartTypeId: filter.chartTypeId,
                        timespanFilterTypeRelativeFromGe: filter.timespanFilterTypeRelativeFromGe,
                        timespanFilterTypeRelativeToLe: filter.timespanFilterTypeRelativeToLe,
                        timespanGroupBy: filter.timespanGroupBy,
                        refreshIntervalSeconds: filter.refreshIntervalSeconds
                    },
                    eTag: headers.etag
                },
                x: 0,
                y: 0,
                w: getWidgetWidthByType(filter.chartTypeId,filter.sourceId),
                h: 1
            }
            queryClient.setQueryData<GridWidget[]>("layout", (previousLayout) => {
                if (!previousLayout) throw new Error("Couldn't find previous dashboard layout")
                return [
                    ...previousLayout,
                    wdg
                ]
            })
            handleClose(wdg)
            setActiveStep(Steps.SOURCE_AND_TYPE)
        },
        onError: (error: AxiosError<{errors:{validationRule:string}}>) => {
            if (error.response?.data?.errors?.validationRule.includes("DataWidgetNameDuplicate"))
                enqueueSnackbar(t("widget-creator.name-already-taken"), { variant: "error" })
            else
                enqueueSnackbar(t("widget-creator.failed-create-widget"), { variant: "error" })
        }
    })

    const { mutate: updateWidget } = useMutation(({ selection, id, eTag }: { selection: IWidgetSelection, id: string, eTag: string }) => {
        return axios.put(`/api/event/v1/data-widgets/${id}`, filterFromSelection(selection), { headers: { "If-Match": eTag } })
    }, {
        onSuccess: ({ headers }, { selection, id }) => {
            const filter = filterFromSelection(selection)
            queryClient.setQueryData<GridWidget[]>("layout", (previousLayout) => {
                if (!previousLayout) throw new Error("Couldn't find previous dashboard layout")
                const newLayout = [...previousLayout]
                const layoutItemIndex = newLayout.findIndex(x => x.i === id)
                newLayout[layoutItemIndex] = {
                    i: id,
                    dataWidget: {
                        entity: {
                            id: id,
                            name: filter.name,
                            sourceId: filter.sourceId,
                            chartTypeId: filter.chartTypeId,
                            timespanFilterTypeRelativeFromGe: filter.timespanFilterTypeRelativeFromGe,
                            timespanFilterTypeRelativeToLe: filter.timespanFilterTypeRelativeToLe,
                            timespanGroupBy: filter.timespanGroupBy,
                            refreshIntervalSeconds: filter.refreshIntervalSeconds
                        },
                        eTag: headers.etag
                    },
                    x: newLayout[layoutItemIndex].x,
                    y: newLayout[layoutItemIndex].y,
                    w: getWidgetWidthByType(filter.chartTypeId,filter.sourceId),
                    h: 1
                }
                return newLayout
            })
            queryClient.invalidateQueries(["widgetData", id])
            queryClient.invalidateQueries(["widget-details", id])
            queryClient.invalidateQueries(["widgetConfigWithEtag", id])
            handleClose(null)
            setActiveStep(Steps.SOURCE_AND_TYPE)
        }
    })

    const skipFilteringStep = useMemo(() => !!dataSources.find(source => source.id === selection.dataSource)?.skipFilteringStep, [selection.dataSource])

    const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + (skipFilteringStep ? 2 : 1))
    const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - (skipFilteringStep ? 2 : 1))
    const handleFinish = (selection: IWidgetSelection, id?: string, eTag?: string) => {
        id && eTag ? updateWidget({ selection, id, eTag }) : postWidget(selection)
    }

    const canClickNext = (() => {
        switch (activeStep) {
            case Steps.SOURCE_AND_TYPE:
                return !!(selection.dataSource && selection.widgetType)
            case Steps.FILTERING:
                return true
            case Steps.NAME_AND_PREVIEW:
                return selection.name.length > 0
            default:
                assertUnreachable(activeStep)
        }
    })()

    return (
        <Box>
            <Stepper activeStep={activeStep} sx={{ mb: 3 }} alternativeLabel>
                {steps.filter((_, i) => skipFilteringStep ? i !== Steps.FILTERING : true).map((label) => (
                    <Step key={label}>
                        <StepLabel componentsProps={{ label: { style: { marginTop: ".5em" } } }}>
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>

            {renderStep(activeStep, selection, setSelection, eventTypes, vehicleClasses, cameraGroups)}

            <Stack direction="row" justifyContent="space-between" mt={3}>
                <Button disabled={activeStep === Steps.SOURCE_AND_TYPE} onClick={handleBack} variant="outlined">
                    {t("back")}
                </Button>
                <Button
                    onClick={activeStep === Steps.NAME_AND_PREVIEW
                        ? () => handleFinish(selection, widgetDetails?.id, widgetDetails?.eTag)
                        : handleNext
                    }
                    variant="contained"
                    disabled={!canClickNext}
                >
                    {activeStep === Steps.NAME_AND_PREVIEW ?
                        widgetDetails ? t("widget-creator.update-widget") : t("widget-creator.create-widget")
                        : t("next")}
                </Button>
            </Stack>
        </Box>
    )
}


async function getEventType() {
    const response = await axios.get<IEventTypeGroup[]>("/api/event/v1/event-type-groups/select")
    return response.data
}

async function getVehicleClasses() {
    const response = await axios.get<IVehicleClass[]>("/api/event/v1/vehicle-classes")
    return response.data
}

async function getCameraHeirarchy() {
    const response = await axios.get<ICameraGroupHierarchy[]>("/api/event/v1/camera-groups/select")
    return response.data
}

async function getWidgetDetails(id: string) {
    const response = await axios.get<IWidgetDetails>(`/api/event/v1/data-widgets/${id}`)
    return {
        selection: selectionFromFilter(response.data),
        id: response.data.id,
        eTag: response.headers.etag
    }
}

function getDefaultSelectedEventTypeIds(eventTypeGroups: IEventTypeGroup[]) {
    const defaultTypeIds = eventTypeGroups
        .flatMap(type => type.eventTypeGroupItems)
        .filter(type => type.isDefault)
        .map(type => type.eventTypeId)

    return defaultTypeIds
}