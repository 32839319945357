import { getFilterSelectionFromLocalStorage, saveFilterSelectionToLocalStorage } from "utils/filterSelection"
import parseSavedTimeFrame from "utils/parseSavedTimeFrame"
import { validateSelectedCameraHierarchy } from "utils/validateSelectedCameraHierarchy"
import { ISavedPeopleOccupancySelection, IPeopleOccupancyFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import { defaultSelection } from "./defaultSelection"

const LOCAL_STORAGE_KEY = `analytics-filter-people-occupancy-${process.env.REACT_APP_LOCAL_STORAGE_KEY_SUFFIX}`

export const saveSelectionToLocalStorage = (
    filter: IPeopleOccupancyFilterSelection
) => saveFilterSelectionToLocalStorage(LOCAL_STORAGE_KEY, filter)

interface ILoadInitalSelection {
    valid: {
        cameraGroupIds: string[]
        cameraIds: string[]
        zoneIds: string[]
    }
}

export function loadInitalSelection({
    valid
}: ILoadInitalSelection): IPeopleOccupancyFilterSelection {
    const savedFilterSelection = getFilterSelectionFromLocalStorage<ISavedPeopleOccupancySelection>(LOCAL_STORAGE_KEY)

    if (savedFilterSelection === null)
        return {
            ...defaultSelection
        }

    return {
        ...savedFilterSelection,
        timeFrame: parseSavedTimeFrame(savedFilterSelection.timeFrame),
        ...validateSelectedCameraHierarchy({
            valid,
            selected: savedFilterSelection
        })
    }
}
