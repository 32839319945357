import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Box } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faTrashCanXmark } from "@fortawesome/pro-regular-svg-icons"

import { DragonTableWithSearchField, HeaderCell, BodyRow } from "Components/DragonTable"
//import ServerContextMenu from "Pages/Servers/ServerContextMenu"
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import CreateFailoverServerDialog, { IFailoverClusterDetails } from "./CreateFailoverServerDialog"
import DragonTitle from "Components/DragonTitle"
import { DragonConfirmationDialog } from "Components/DragonDialog"

export interface IFailoverServerTable{
    data: IFailoverClusterDetails[]
    onChange:(serverList:IFailoverClusterDetails[])=>void
}

export const FailoverServersTable: FC<IFailoverServerTable> = ({data,onChange}) => {
    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useState<string>("")
    const [createServerDialog, setCreateServerDialog] = useState<boolean>(false)
    const [selectedServer, setSelectedServer] = useState<number | undefined>()


    const handleAddServer = useCallback((server:IFailoverClusterDetails)=>{
        onChange([...data,server])
        setCreateServerDialog(false)
    },[data,onChange])

    const handleRemoveServer = useCallback(()=>{
        if(selectedServer !== undefined && selectedServer>=0){
            const temp = [...data]
            temp.splice(selectedServer,1)
            onChange([...temp])
        }
        setSelectedServer(undefined)

    },[data,onChange,selectedServer])
    const tableColumns = useMemo<HeaderCell[]>(() => [
        { value: t("failover-settings.servers.group-id") },
        { value: t("failover-settings.servers.server-ip") },
        { value: t("failover-settings.servers.port") },
        { value: t("failover-settings.servers.is-failover") },
        { value: null }
    ], [t])

    const tableData = useMemo<BodyRow[]>(() => {
        return data.filter(z => searchValue? z.serverIP.includes(searchValue) : true).map(({ groupID, serverIP, port,isFailover },index) => (
            {
                row: [
                    { value: groupID },
                    {value:serverIP},
                    {value:port},
                    {value:(isFailover && <FontAwesomeIcon icon={faCheckCircle} color="primary"/>)},
                    {
                        value: (
                            <Button color="error" onClick={() => {
                                setSelectedServer(index)
                            }}>
                                <FontAwesomeIcon icon={faTrashCanXmark} />
                            </Button>
                        ),
                        padding: "checkbox"
                    }
                ],
                hover: true
            }
        ))

    }, [data,searchValue])

    return (
        <>
                <Box sx={{ display: "flex", overflow: "hidden", flexDirection:"column" }}>
                    <DragonTitle title={t("servers")} />
                    <DragonTableWithSearchField
                        columns={tableColumns}
                        data={tableData}
                        hasNextPage={false}
                        fetchNextPage={()=>null}
                        isFetching={false}
                        noDataText={t("no-server-found")}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        searchPlaceholder={t("search-for-server")}
                        toolbarAction={
                            <Button
                                variant="outlined"
                                startIcon={<FontAwesomeIcon icon={faPlus} name={faPlus.iconName} />}
                                onClick={() => setCreateServerDialog(true)}
                            >
                                {t("create-server")}
                            </Button>
                        }
                    />
                </Box>
            <CreateFailoverServerDialog

                open={createServerDialog}
                onSave={handleAddServer}
                onClose={() => setCreateServerDialog(false)}
            />
            <DragonConfirmationDialog 
                open={selectedServer !== undefined}
                variant="delete"
                title={t("failover-settings.servers.remove-server-propt-confirmation")}
                onConfirm={handleRemoveServer}
                onClose={()=>setSelectedServer(undefined)}
            />
        </>
    )
}

export default FailoverServersTable
