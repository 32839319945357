import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useTranslation } from "react-i18next"

import FilterControlLabel from "Components/Filter/FilterControlLabel"
import ICameraGroupHierarchy from "../CameraSelector/ICameraGroupHierarchy"
import { useEffect, useMemo, useState } from "react"

interface ISingleCameraSelector {
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
    selectedValues: string[]
    onChange: (values: string[]) => void
}

export default function SingleCameraSelector({ cameraGroupsHierarchy,selectedValues, onChange }: ISingleCameraSelector) {
    const { t } = useTranslation()

    const cameras = useMemo(
        () => cameraGroupsHierarchy
            .flatMap(group => group.cameras),
        [cameraGroupsHierarchy]
    )

    function handleChange(event: SelectChangeEvent<string>) {
        const camId = JSON.parse(event.target.value)
        onChange([...camId])
    }
    const [selectedCamera, setSelectedCamera] = useState("")

    // If the selected resolution is no longer selectable
    // Select the first available resolution
    useEffect(() => {
        setSelectedCamera(JSON.stringify(selectedValues))
    }, [selectedValues])

    // MUI complains if we try to set a value in the select that don't exist
    // So we render null for this run, the next render we will have a valid
    // resolution
    // if (!cameras.flatMap(c => c.id).includes(selectedCamera))
    //     return null

    return <FormControl sx={{ width: "100%" }}>
        <FilterControlLabel text={t("event-filter.camera")} />
        <Select
            value={selectedCamera}
            onChange={handleChange}
            variant="filled"
            disableUnderline
            MenuProps={{
                sx: {
                    ".MuiMenu-list": {
                        padding: 0
                    }
                }
            }}
            //renderValue={()=>cameras.find(c=>c.id === selectedCamera)?.name || ""}
        >
            <MenuItem key={"no-camera"} value={"[]"}>{t("no-camera-selected")}</MenuItem>
            {cameras.map(c =>
                    <MenuItem
                        key={c.name}
                        value={`["${c.id}"]`}
                    >
                        {c.name}
                    </MenuItem>
                )}
        </Select>
    </FormControl>
}