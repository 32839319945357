import { Box, Divider, Toolbar, Typography, Link } from "@mui/material"

import dragonLogo from "logos/logo.png"
import invertedDragonLogo from "logos/logo_inverted.png"
import { useThemeContext } from "Contexts/ThemeContext"
import { Link as RouterLink } from "react-router-dom"
import AvatarWithQuickSettings from "./QuickSettings"
import DownloadCenter from "./DownloadCenter"
import NotificationCenter from "./NotificationCenter"
import { useUserProfile } from "hooks/useUserProfile"

interface IHeader {
    systemName?: string
}

export default function Header({ systemName }: IHeader) {
    const { mode } = useThemeContext()
    const { roles, permissions } = useUserProfile()

    const hasOperatorRole = roles.includes("Operator")

    return <Box
        component="header"
        sx={{
            gridArea: "header",
            display: "flex",
            alignItems: "center",
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
            backgroundColor: theme => theme.palette.background.frame
        }}
    >
        <Toolbar
            disableGutters
            sx={{
                flexGrow: 1,
                paddingRight: "24px",
                paddingLeft: "24px"
            }}
        >
            <Link component={RouterLink} to="/">
                <img
                    src={mode === "light"
                        ? dragonLogo
                        : invertedDragonLogo
                    }
                    style={{ display: "block" }}
                    alt="Dragon logo"
                    width={120}
                />
            </Link>
            <HorizontalSpacer />
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                    src={mode === "light"
                        ? `${process.env.PUBLIC_URL}/img/system_image.png`
                        : `${process.env.PUBLIC_URL}/img/system_image_inverted.png`
                    }
                    style={{ display: "block" }}
                    alt=""
                    height={42}
                />

                {systemName && <>
                    <Typography
                        variant="h5"
                        sx={{ paddingLeft: "12px", paddingRight: "12px" }}
                    >
                        {systemName}
                    </Typography>
                    <VerticalDivider />
                </>}
                {hasOperatorRole &&
                    <>
                        {permissions.canExportEvents && <DownloadCenter />}
                        {permissions.canSeeCommentsOnEvent && <NotificationCenter />}
                        {(permissions.canExportEvents || permissions.canSeeCommentsOnEvent) &&
                            <VerticalDivider />
                        }
                    </>
                }
                <AvatarWithQuickSettings />
            </Box>
        </Toolbar>
    </Box>
}

function VerticalDivider() {
    return <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ marginLeft: 1, marginRight: 1 }}
    />
}

function HorizontalSpacer() {
    return <Box sx={{ flexGrow: 1 }} />
}