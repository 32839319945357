import { MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useTranslation } from "react-i18next"

interface ILanguageSelector {
    value?: string
    onChange: (event: SelectChangeEvent<string>) => void
}

export default function LanguageSelector({ value, onChange }: ILanguageSelector) {
    const { t, i18n } = useTranslation()

    return <Select
        value={value ?? i18n.language}
        onChange={onChange}
        data-testid="language-selector"
        variant="filled"
        disableUnderline
    >
        {i18n.languages.map(language =>
            <MenuItem
                key={language}
                value={language}
            >
                {t("meta-data.flag", { lng: language })} {t("meta-data.name", { lng: language })}
            </MenuItem>
        )}
    </Select>
}