/**
 * validateSelectedCameraHierarchy
 * @param valid valid ids for cameraGroup, camera, and zone
 * @param selected selected ids for cameraGrup, camera, and zone
 * @returns the valid ids per type of the selected ids
 */
export function validateSelectedCameraHierarchy({
    valid,
    selected
}: {
    valid: {
        cameraGroupIds: string[],
        cameraIds: string[],
        zoneIds: string[]
    },
    selected: {
        cameraGroupIds: string[],
        cameraIds: string[],
        zoneIds: string[]
    }
}): {
    cameraGroupIds: string[]
    cameraIds: string[]
    zoneIds: string[]
} {
    return {
        cameraGroupIds: selected.cameraGroupIds.filter(id => valid.cameraGroupIds.includes(id)),
        cameraIds: selected.cameraIds.filter(id => valid.cameraIds.includes(id)),
        zoneIds: selected.zoneIds.filter(id => valid.zoneIds.includes(id))
    }
}