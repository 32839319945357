import { FC, useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { useQuery } from "react-query"
import { Button, Stack } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft } from "@fortawesome/pro-regular-svg-icons"

import DragonListWithSearchField from "Components/DragonListWithSearchField"
import { ICameraGroupSelectDto } from "Types/admin"

type ICameraGroupSelect = ICameraGroupSelectDto
type ICameraGroupList = ICameraGroupSelect[]


const getCameraGroups = async (): Promise<ICameraGroupList> => {
    const response = await axios.get<ICameraGroupList>("/api/admin/v1/camera-groups/select")
    return response.data
}

interface ICameraGroupAssignmentForm {
    cameraGroups: ICameraGroupSelect[]
    onChange: (cameraGroupIds: string[]) => void
}

export const CameraGroupAssignmentForm: FC<ICameraGroupAssignmentForm> = ({ cameraGroups, onChange }) => {
    const { t } = useTranslation()
    const allCameraGroupssListRef = useRef<HTMLUListElement>(null)
    const [selected, setSelected] = useState<{
    target: "system" | "group", items: ICameraGroupSelect[]
  }>({ target: "system", items: [] })
    const [assignedCameraGroups, setAssignedCameraGroups] = useState<ICameraGroupSelect[]>(cameraGroups)
    const [searchAllCameraGroups, setSearchAllCameraGroups] = useState<string>("")
    const [searchAssignedCameraGroups, setSearchAssignedCameraGroups] = useState<string>("")

    const { data, isFetching } = useQuery("camera-groups-select", getCameraGroups)

    const filteredAllCameraGroups = useMemo<ICameraGroupSelect[]>(() => {
        return data ?
            data
                .filter(({ id }) => !assignedCameraGroups.some((cg) => cg.id === id))
                .filter(({ name }) => name.toLowerCase().includes(searchAllCameraGroups.toLowerCase()))
            : []
    }, [assignedCameraGroups, data, searchAllCameraGroups])

    const filteredAssignedCameraGroups = useMemo<ICameraGroupSelect[]>(() => {
        if (searchAssignedCameraGroups.length === 0) return assignedCameraGroups
        return assignedCameraGroups.filter((cg) => (
            cg.name.toLowerCase().includes(searchAssignedCameraGroups.toLowerCase())
        ))
    }, [assignedCameraGroups, searchAssignedCameraGroups])

    const isCameraGroupSelected = useCallback<(cg: ICameraGroupSelect) => boolean>((cg) =>
        selected.items.some(({ id }) => cg.id === id), [selected.items]
    )

    const handleSelectCameraGroup = useCallback<(target: "system" | "group") => (cg: ICameraGroupSelect) => void>(
        (target) => (cg) => {
            setSelected(prevState => {
                if (prevState.target === target) {
                    if (prevState.items.some(({ id }) => cg.id === id)) {
                        return { target, items: [...prevState.items.filter(({ id }) => cg.id !== id)] }
                    }
                    return { target, items: [...prevState.items, cg] }
                }
                return { target, items: [cg] }
            })
        }, []
    )

    const handleAssignCameraGroups = useCallback(() => {
        const result = [...assignedCameraGroups, ...selected.items]
        onChange(result.map(({ id }) => id))
        setAssignedCameraGroups(result)
        setSelected(prevState => ({ ...prevState, target: "group" }))
    }, [assignedCameraGroups, onChange, selected.items])

    const handleAssignAllCameraGroups = useCallback(() => {
        const result = [...assignedCameraGroups, ...filteredAllCameraGroups]
        onChange(result.map(({ id }) => id))
        setAssignedCameraGroups(result)
        setSelected({ target: "group", items: filteredAllCameraGroups })
    }, [assignedCameraGroups, filteredAllCameraGroups, onChange])

    const handleUnassignCameraGroups = useCallback(() => {
        const result = assignedCameraGroups.filter(({ id }) => !selected.items.some((cg) => cg.id === id))
        onChange(result.map(({ id }) => id))
        setAssignedCameraGroups(result)
        setSelected(prevState => ({ ...prevState, target: "system" }))
    }, [assignedCameraGroups, onChange, selected.items])

    const handleUnassignAllCameraGroups = useCallback(() => {
        onChange([])
        setAssignedCameraGroups([])
        setSelected({ target: "system", items: filteredAssignedCameraGroups })
    }, [filteredAssignedCameraGroups, onChange])

    return (
        <Stack flex={1} spacing={2} direction="row" overflow="hidden">
            <DragonListWithSearchField
                title={t("event-rule-details.available-camera-groups", { count: filteredAllCameraGroups.length })}
                ref={allCameraGroupssListRef}
                list={filteredAllCameraGroups}
                searchPhrase={searchAllCameraGroups}
                isSelected={isCameraGroupSelected}
                onSelect={handleSelectCameraGroup("system")}
                onSearch={(v) => setSearchAllCameraGroups(v)}
                loading={isFetching}
            />
            <Stack spacing={2} justifyContent="center">
                <Button
                    variant="contained"
                    onClick={handleAssignAllCameraGroups}
                    disabled={!filteredAllCameraGroups.length}
                >
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                </Button>
                <Button
                    variant="contained"
                    onClick={handleAssignCameraGroups}
                    disabled={!selected.items.length || selected.target !== "system"}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </Button>
                <Button
                    variant="contained"
                    onClick={handleUnassignCameraGroups}
                    disabled={!selected.items.length || selected.target !== "group"}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </Button>
                <Button
                    variant="contained"
                    onClick={handleUnassignAllCameraGroups}
                    disabled={!filteredAssignedCameraGroups.length}
                >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </Button>
            </Stack>
            <DragonListWithSearchField
                title={t("event-rule-details.included-camera-groups", { count: filteredAssignedCameraGroups.length })}
                list={filteredAssignedCameraGroups}
                searchPhrase={searchAssignedCameraGroups}
                isSelected={isCameraGroupSelected}
                onSelect={handleSelectCameraGroup("group")}
                onSearch={(v) => setSearchAssignedCameraGroups(v)}
            />
        </Stack>
    )
}

export default CameraGroupAssignmentForm
