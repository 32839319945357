import { useTheme } from "@mui/material"
import { colorsList } from "App/colors"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from "recharts"
import { IEventCount } from "./types"

const DISABLED_OPACITY = 0.3

export default function EventPieChartWidget({ data: propData }: { data: IEventCount[] }) {
    const theme = useTheme()
    const { t } = useTranslation()

    const groupedByEventType = propData.reduce<{ [eventTypeId: string]: number }>((acc, event) => {
        acc[event.eventTypeId] = (acc[event.eventTypeId] ?? 0) + event.count

        return acc
    }, {})

    const data = Object
        .entries(groupedByEventType)
        .map(([type, count]) => ({ name: type, value: count }))
        .sort((a, b) => b.value - a.value)

    const [hiddenDataPoints, setHiddenDataPoints] = useState(
        data.reduce<{ [dataKey: string]: boolean }>((acc, entry) => {
            acc[entry.name] = false
            return acc
        }, {})
    )

    const [hoveredEventType, setHoveredEventType] = useState<string | null>(null)

    const outerRadius = 85
    const innerRadius = outerRadius * .55

    return (
        <ResponsiveContainer minHeight={303}>
            <PieChart>
                {data.length === 0 &&
                    <Pie
                        data={[{ value: 1 }]}
                        dataKey="value"
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        fill="rgba(128, 128, 128, 0.5)"
                        stroke="none"
                        legendType="none"
                    />
                }
                <Pie
                    data={data.map(({ name, value }) => ({
                        name,
                        value: hiddenDataPoints[name] ? 0 : value
                    }))}
                    dataKey="value"
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    stroke="none"
                    onMouseEnter={data => setHoveredEventType(data.name)}
                    onMouseLeave={() => setHoveredEventType(null)}
                >
                    {data.map((entry, index) =>
                        <Cell
                            key={index}
                            fill={colorsList[index % colorsList.length]}
                            opacity={(hoveredEventType && entry.name !== hoveredEventType)
                                ? DISABLED_OPACITY
                                : 1.0
                            }
                        />
                    )}
                </Pie>
                <Legend
                    wrapperStyle={{
                        height: "5em",
                        overflow: "auto"
                    }}
                    formatter={(value: string, entry) => {

                        return <span
                            key={entry.value}
                            style={{
                                color: theme.palette.text.primary,
                                opacity: (hoveredEventType !== value && hiddenDataPoints[value]) ? DISABLED_OPACITY : 1,
                                textDecoration: hiddenDataPoints[value] ? "line-through" : "none"
                            }}
                        >
                            {t(`event-types.${entry.value}`)} {groupedByEventType[entry.value]}
                        </span>
                    }}
                    onMouseEnter={data => setHoveredEventType(data.id ?? null)}
                    onMouseLeave={() => setHoveredEventType(null)}
                    onClick={data => setHiddenDataPoints({
                        ...hiddenDataPoints,
                        [data.value]: !hiddenDataPoints[data.value]
                    })}
                />
                {data.length > 0 && <Tooltip
                    formatter={(value: number, name: string) =>
                        ([value, t(`event-types.${name}`) as string])
                    }
                />}
            </PieChart>
        </ResponsiveContainer>
    )
}