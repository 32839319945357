import { ITrafficData } from "../ITrafficData"
import DetailedBarChart, { IBarSeries } from "../DetailedBarChart"

interface ICountingBarChart {
    data: ITrafficData[]
}

export default function CountingBarChart({
    data
}: ICountingBarChart) {
    const formattedData = transformData(
        data
    )

    return <DetailedBarChart
        data={formattedData}
        legendFormatter={serie => formatSerieName(serie)}
    />
}

function formatSerieName(serie: string) {
    return serie
}

interface ISerie {
    camera: {
        id: string
        name: string
    }
    zone: {
        id: string
        name: string
    }
    vehicleClassId: string | null
    count: number
}

function transformData(
    data: ITrafficData[]
): IBarSeries[] {
    const allSeries = data.reduce<ISerie[]>((acc, entry) => {
        if (!acc.some(x =>
            x.camera.id === entry.camera.id
            && x.zone.id === entry.zone.id
            && x.vehicleClassId === entry.vehicleClassId
        ))
            acc.push({
                camera: {
                    id: entry.camera.id,
                    name: entry.camera.name
                },
                zone: entry.zone,
                vehicleClassId: entry.vehicleClassId,
                count: entry.value
            })
        else{
            acc.filter(x =>
                x.camera.id === entry.camera.id
                && x.zone.id === entry.zone.id
                && x.vehicleClassId === entry.vehicleClassId).forEach(x=>x.count+=entry.value)
        }

        return acc
    }, [])

    return allSeries.reduce<IBarSeries[]>((acc,entry)=>{
        if(!acc.some( x => x.stackId === `${entry.camera.name}_${entry.zone.name}`)){
            acc.push({
                name: `${entry.camera.name} > ${entry.zone.name}`,
                stackId: `${entry.camera.name}_${entry.zone.name}`,
                [entry.vehicleClassId!]: entry.count
            })
        }
        else{
           const serie =  acc.find(x=>x.stackId === `${entry.camera.name}_${entry.zone.name}`)!
            if(serie[entry.vehicleClassId!]){
                (serie[entry.vehicleClassId!] as number) += entry.count
            } else{
                serie[entry.vehicleClassId!] = entry.count
            }
        }
        return acc
    },[]).sort((a,b)=> a.name.localeCompare(b.name))
}