import { FC, useCallback, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios, { AxiosResponse } from "axios"
import { useMutation, useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"
import { Typography, Button, Stack, FormControl, TextField, DialogContentText } from "@mui/material"

import DragonDialog from "Components/DragonDialog"
import { ICameraGroupCreateUpdateDto, ICameraGroupReadDto, IPostResponseDto } from "Types/admin"
import { DelayedLinearProgress } from "utils/DelayedProgress"

type ICameraGroupDetails = ICameraGroupReadDto
type ICameraGroupCreatePayload = ICameraGroupCreateUpdateDto
type IPostResponse = IPostResponseDto

const createCameraGroup = async (payload: ICameraGroupCreatePayload): Promise<AxiosResponse<IPostResponse>> => (
    await axios.post<IPostResponse, AxiosResponse<IPostResponse>, ICameraGroupCreatePayload>(
        "/api/admin/v1/camera-groups", payload
    )
)

interface ICloneCameraGroup {
    open: boolean
    cameraGroup: ICameraGroupDetails
    onClose: () => void
    onSuccess: () => void
}

export const CloneCameraGroup: FC<ICloneCameraGroup> = ({ open, cameraGroup, onClose, onSuccess }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const nameInputRef = useRef<HTMLInputElement>(null)
    const initialName = useMemo<string>(
        () => t("clone-camera-group-form.camera-group-clone", { camera_group: cameraGroup.name }),
        [cameraGroup.name, t])
    const [name, setName] = useState<string>(initialName)
    const [errorMessage, setErrorMessage] = useState<string>()

    const { mutateAsync: onConfirm, isLoading } = useMutation(
        () => createCameraGroup({
            name,
            description: cameraGroup.description,
            cameraIds: cameraGroup.cameras.map(({ id }) => id),
            deviceStatusUse: cameraGroup.deviceStatusUse
        }),
        {
            onSuccess: () => {
                onSuccess()
                queryClient.invalidateQueries("camera-groups")
            },
            onError: () => {
                setErrorMessage(t("clone-camera-group-form.clone-camera-group_failed"))
            }
        }
    )

    const handleClose = useCallback<() => void>(
        () => {
            setName(initialName)
            setErrorMessage(undefined)
            onClose()
        },
        [initialName, onClose]
    )

    const handleChangeName = useCallback<(value: string) => void>((value) => {
        setErrorMessage(undefined)
        setName(value)
    }, [])

    const handleConfirm = useCallback<() => void>(() => {
        setErrorMessage(undefined)
        if (name.length === 0) {
            setErrorMessage(t("clone-camera-group-form.name-is-required"))
            nameInputRef.current?.click()
        } else {
            onConfirm().then(({ data }) => {
                handleClose()
                navigate(`../camera-group/${data.id}`)
            })
        }
    }, [handleClose, name.length, navigate, onConfirm, t])


    return (
        <DragonDialog
            open={open}
            onClose={onClose}
            title={t("clone-camera-group-form.clone-camera-group")}
            actions={
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        onClick={handleConfirm}
                        disabled={isLoading}
                    >
                        {t("clone")}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="large"
                        onClick={handleClose}
                        disabled={isLoading}
                    >
                        {t("cancel")}
                    </Button>
                </>
            }
        >
            <Stack spacing={2}>
                <DialogContentText>
                    {t("clone-camera-group-form.clone-camera-group_confirmation", { camera_group: cameraGroup.name })}
                </DialogContentText>
                <FormControl>
                    <Typography variant="h6" fontWeight="bold">{t("clone-camera-group-form.name")}</Typography>
                    <TextField
                        variant="filled"
                        InputProps={{
                            required: true,
                            ref: nameInputRef,
                            autoComplete: "off"
                        }}
                        value={name}
                        onChange={(event) => handleChangeName(event.target.value)}
                        error={name.length === 0}
                        fullWidth
                    />
                </FormControl>
                {isLoading && <DelayedLinearProgress delay={250} />}
                <Typography color="error">{errorMessage}</Typography>
            </Stack>
        </DragonDialog >
    )
}

export default CloneCameraGroup