import { ReactComponent as faImageSlash } from "icons/faImageSlash-regular.svg"
import { Paper, Box, Stack, Divider, Typography, useTheme, SvgIcon } from "@mui/material"
import { useTranslation } from "react-i18next"
import { formatDate, formatTime } from "utils/timedisplay"

interface IEventListItem {
    snapshotUrl?: string
    cameraName: string
    eventTypeId: string
    startedAt: Date
}

export default function EventListItem({
    snapshotUrl,
    cameraName,
    eventTypeId,
    startedAt
}: IEventListItem) {
    const { t } = useTranslation()
    const { palette } = useTheme()

    return <Paper
        elevation={0}
        sx={{
            backgroundColor: theme => theme.palette.background.default,
            borderRadius: "4px 4px 0 0"
        }}
    >
        <Box
            sx={{
                width: "100%",
                aspectRatio: "16 / 9",
                backgroundImage: snapshotUrl && `url(${snapshotUrl})`,
                backgroundPosition: "center center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                borderRadius: "4px 4px 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            {!snapshotUrl && <SvgIcon component={faImageSlash} viewBox="0 0 640 512" style={{ fontSize: 48, color: palette.text.secondary }} />}
        </Box>
        <Stack
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            spacing={1}
            p={2}
            sx={{ flexGrow: 1 }}
        >
            <Typography>{cameraName}</Typography>
            <Typography>
                {t(`event-types.${eventTypeId}`)}
            </Typography>
            <Typography>{formatDate(startedAt)} {formatTime(startedAt)}</Typography>
        </Stack>
    </Paper>
}