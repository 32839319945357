/**
 * assertUnrechable
 * @description Used to enforce that all variants have been handled in a `switch` or `if/else`
 * @link https://basarat.gitbook.io/typescript/type-system/discriminated-unions#exhaustive-checks (2022-04-05)
 * @example
 *
 * type Test = "Foo" | "Bar";
 *
 * function displayString(s: Test) {
 *   switch (s) {
 *     case "Foo":
 *       return "foo";
 *     case "Bar":
 *       return "bar";
 *     // If a new case is added at compile time you will get a compile error
 *     // If a new value appears at runtime you will get a runtime error
 *     default:
 *       return assertUnrechable(s);
 *  }
 * }
 */

export function assertUnreachable(x: never): never {
    throw new Error(`Unexpected value: ${x}. Should have been never.`)
}