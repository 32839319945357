import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@mui/material"

import { DragonDialogBase, IDragonDialogBase } from "."

export interface IDragonConfirmationDialog extends Omit<IDragonDialogBase, "children"> {
    onConfirm: () => void
}

export const DragonConfirmationDialog: FC<IDragonConfirmationDialog> = ({ open, title, onConfirm, onClose, variant }) => {
    const { t } = useTranslation()

    return (
        <DragonDialogBase
            open={open}
            onClose={onClose}
            variant={variant}
            title={title}
            actions={
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        onClick={() => {
                            onConfirm()
                            onClose()
                        }}
                    >
                        {t("yes-confirm")}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="large"
                        onClick={onClose}
                    >
                        {t("no-cancel")}
                    </Button>
                </>
            }
        >
        </DragonDialogBase>
    )
}

export default DragonConfirmationDialog