import { faCheckCircle, faExclamationCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Badge, CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { useGlobalEvents } from "Contexts/GlobalEventsContext"
import { useUserProfile } from "hooks/useUserProfile"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface IExportProgress {
    id: string
    name: string
    progressPercent: number
    exportStateId: "Undefined" | "Waiting" | "Processing" | "Succeeded" | "Failed"
}

export default function DownloadCenter() {
    const { t } = useTranslation()
    const { palette } = useTheme()
    const { permissions } = useUserProfile()

    const anchorRef = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)

    const [exports, setExports] = useState<IExportProgress[]>([])

    const { subscribeToEvents } = useGlobalEvents()

    const onSignalREvent = (newExportEvent: IExportProgress) => {
        setExports(prevState => {
            const newState = [...prevState]
            const indexInExports = newState.findIndex(exportItem => exportItem.id === newExportEvent.id)

            if (indexInExports === -1) {
                setOpen(true)
                return [newExportEvent, ...newState]
            }
            newState[indexInExports] = newExportEvent
            return newState
        })
    }

    useEffect(() => {
        subscribeToEvents("EventExportProgress", onSignalREvent)
    }, [subscribeToEvents])

    const combinedPercent = useMemo(() => exports.reduce((acc, current) => acc + current.progressPercent, 0) / exports.length, [exports])

    return (!permissions.canExportEvents || exports.length === 0)
        ? null
        : <>
            <IconButton onClick={() => setOpen(true)} ref={anchorRef}>
                <Badge badgeContent={exports.length}>
                    {combinedPercent === 100
                        ? <FontAwesomeIcon color={palette.success.main} icon={faCheckCircle} style={{ fontSize: "1em" }} />
                        : <CircularProgress
                            variant="determinate"
                            value={combinedPercent}
                            size="1em"
                            thickness={7.5}
                            sx={{
                                ".MuiCircularProgress-circleDeterminate": {
                                    transitionDuration: "0ms"
                                }
                            }}
                        />
                    }
                </Badge>
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorRef.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <Box sx={{ width: 300, px: 2, py: 1 }}>
                    <List disablePadding dense>
                        {exports.map((exportItem) => (
                            <ListItem key={exportItem.id} disableGutters>
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    {exportItem.exportStateId === "Succeeded"
                                        ? <FontAwesomeIcon color={palette.success.main} icon={faCheckCircle} />
                                        : exportItem.exportStateId === "Failed"
                                            ? <FontAwesomeIcon color={palette.error.main} icon={faExclamationCircle} />
                                            : <CircularProgress
                                                variant="determinate"
                                                value={exportItem.progressPercent}
                                                size="1.75em"
                                                thickness={7.5}
                                                sx={{
                                                    ".MuiCircularProgress-circleDeterminate": {
                                                        transitionDuration: "0ms"
                                                    }
                                                }}
                                            />
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={exportItem.name}
                                    secondary={`${Math.round(exportItem.progressPercent)}%`}
                                    sx={{ textOverflow: "ellipsis", pl: 2 }}
                                    primaryTypographyProps={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                />
                            </ListItem>
                        ))}
                        <ListItem
                            disablePadding
                            disableGutters
                        >
                            <ListItemButton
                                component={Link}
                                to="/account/exports"
                            >
                                <ListItemText
                                    primary={t("see-all-exports")}
                                    primaryTypographyProps={{ sx: { textAlign: "center" } }}
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Popover>
        </>
}