import { FC, useMemo, useState } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { Paper, Button, Badge } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"

import { DragonTableWithSearchField, HeaderCell, BodyRow } from "Components/DragonTable"
import CameraGroupDetails from "Pages/CameraGroupDetails/CameraGroupDetails"
import { ICameraGroupListDto } from "Types/admin"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"

type ICameraGroupListItem = ICameraGroupListDto

const getCameraGroups = async ({ pageParam: offset = 0, queryKey }: QueryFunctionContext): Promise<ICameraGroupListItem[]> => {
    const [, searchName] = queryKey
    const response = await axios.get<ICameraGroupListItem[]>("/api/admin/v1/camera-groups", {
        params: { limit: 25, offset, name: searchName || undefined }
    })
    return response.data
}

export const CameraGroups: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState<string>("")

    const showDetails = useLocation().pathname.startsWith("/camera-groups/")

    const {
        data, hasNextPage = false, fetchNextPage, isFetching
    } = useInfiniteQuery(["camera-groups", searchValue], getCameraGroups, {
        getNextPageParam: (lastPage, allPages) => (
            lastPage.length === 0 ? undefined : allPages.reduce((amount, page) => amount + page.length, 0)
        ),
        keepPreviousData: true
    })

    const tableColumns = useMemo<HeaderCell[]>(() => [
        { value: t("name") },
        { value: t("cameras") },
        { value: t("teams") },
        { value: t("included-in-device-status"), align: "center" }
    ], [t])

    const tableData = useMemo<BodyRow[]>(() => {
        const list = data?.pages.flat() ?? []
        return list.map(({ id, name, cameras, teams, deviceStatusUse }) => (
            {
                row: [
                    { value: name },
                    { value: `${cameras.slice(0, 2).map(({ name }) => name).join(", ")}${cameras.length > 2 ? t("and-more-with-amount", { amount: cameras.length - 2 }) : ""}` },
                    { value: `${teams.slice(0, 2).map(({ name }) => name).join(", ")}${teams.length > 2 ? t("and-more-with-amount", { amount: teams.length - 2 }) : ""}` },
                    {
                        value: deviceStatusUse && <Badge
                            color="success"
                            variant="dot"
                            sx={{ ".MuiBadge-badge": { height: 14, width: 14, borderRadius: 2 } }}
                        />,
                        align: "center"
                    }
                ],
                onClick: () => navigate(`camera-group/${id}`),
                sx: { cursor: "pointer" },
                hover: true
            }
        ))
    }, [data?.pages, navigate, t])

    return (
        <>
            <DragonPageWrapper hidden={showDetails}>
                <DragonTitle title={t("camera-groups")} />
                <Paper sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
                    <DragonTableWithSearchField
                        columns={tableColumns}
                        data={tableData}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetching={isFetching}
                        noDataText={t("no-camera-group-found")}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        searchPlaceholder={t("search-for-camera-group")}
                        toolbarAction={
                            <Button
                                variant="outlined"
                                startIcon={<FontAwesomeIcon icon={faPlus} name={faPlus.iconName} />}
                                onClick={() => navigate("new")}
                            >
                                {t("create-camera-group")}
                            </Button>
                        }
                    />
                </Paper>
            </DragonPageWrapper>
            <Routes>
                <Route path="camera-group/:cameraGroupId" element={<CameraGroupDetails />} />
                <Route path="new" element={<CameraGroupDetails />} />
            </Routes>
        </>
    )
}

export default CameraGroups
