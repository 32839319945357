import React from "react"
import { styled, Tab, TabProps, Tabs } from "@mui/material"
import { Link } from "react-router-dom"

// TODO: component should not be a prop here, 
// it's only needed for typescript to accept that a Tab takes a component

const DragonTab = styled((props: TabProps & { to?: string, component?: React.ElementType }) => {
    if (props.to) {
        return <Tab
            disableRipple
            iconPosition="start"
            component={Link}
            {...props}
        />
    }

    return <Tab
        disableRipple
        iconPosition="start"
        {...props}
    />
}
)(
    ({ theme }) => ({
        padding: "0 10px",
        margin: 0,
        minHeight: "36px",
        fontSize: "16px",
        fontStyle: "normal",
        textTransform: "none",
        borderRadius: "4px",
        "&.Mui-selected": {
            background: theme.palette.background.default,
            borderBottom: "none",
            "&:before": {
                content: "none"
            },
            color: theme.palette.text.primary
        },
        "&:not(:first-of-type)": {
            marginLeft: "30px"
        },
        "svg": {
            marginRight: "10px"
        },
        "&.Mui-disabled": {
            color: theme.palette.action.disabled
        }
    })
)

const DragonTabs = styled(Tabs)(
    () => ({
        ".MuiTabs-indicator": {
            display: "none"
        },
        padding: "13px",
        minHeight: "62px",
        flexGrow: 1
    })
)

export { DragonTab, DragonTabs }