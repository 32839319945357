import { Typography } from "@mui/material"
import axios from "axios"
import Filter from "Components/Filter/Filter"
import CameraSelector from "Components/Filter/Filters/CameraSelector/CameraSelector"
import ICameraGroupHierarchy from "Components/Filter/Filters/CameraSelector/ICameraGroupHierarchy"
import EventStateSelector from "Components/Filter/Filters/EventStateSelector/EventStateSelector"
import ResolutionSelector, { getDefaultResolutionBasedOnTimeFrame } from "Components/Filter/Filters/ResolutionSelector/ResolutionSelector"
import TimeFrameSelector from "Components/Filter/Filters/TimeFrameSelector/TimeFrameSelector"
import EventTypesSelector from "Components/Filter/Filters/TrafficEventTypesSelector/EventTypesSelector"
import IEventTypeGroup from "Components/Filter/Filters/TrafficEventTypesSelector/IEventTypeGroup"
import ZoneSelector from "Components/Filter/Filters/ZoneSelector/ZoneSelector"
import useOnMount from "hooks/useOnMount"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueries } from "react-query"
import { IResolution } from "Types/IResolution"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { groupByGroupCameraZoneId } from "utils/groupByGroupCameraZoneId"
import { IEventFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import { defaultSelection } from "./defaultSelection"
import { loadInitalSelection, saveSelectionToLocalStorage } from "./persistance"

async function getEventType() {
    const response = await axios.get<IEventTypeGroup[]>("/api/event/v1/event-type-groups/select")
    return response.data
}

async function getCameraHeirarchy() {
    const response = await axios.get<ICameraGroupHierarchy[]>("/api/event/v1/camera-groups/select")
    return response.data
}

export default function EventFilterWrapper({ onSubmit }: { onSubmit: (selection: IEventFilterSelection) => void }) {
    const { t } = useTranslation()

    const result = useQueries([
        { queryKey: "event-types", queryFn: getEventType },
        { queryKey: "camera-groups", queryFn: getCameraHeirarchy }
    ])

    const isLoading = result.some(query => query.isLoading)
    const isError = result.some(query => query.isError)

    const [{ data: eventTypes }, { data: cameraGroups }] = result
    const isSuccess = !!(eventTypes && cameraGroups)

    if (isLoading)
        return <DelayedCircularProgress delay={250} />

    if (isError || !isSuccess)
        return <Typography>{t("event-filter.failed-to-load")}</Typography>

    return <EventFilter
        eventTypes={eventTypes}
        cameraGroupsHierarchy={cameraGroups}
        onSubmit={onSubmit}
    />
}

interface IEventFilter {
    eventTypes: IEventTypeGroup[]
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
    onSubmit: (selection: IEventFilterSelection) => void
}

function EventFilter({
    eventTypes,
    cameraGroupsHierarchy,
    onSubmit
}: IEventFilter) {
    const eventTypeIds = useMemo(
        () => eventTypes
            .flatMap(group => group.eventTypeGroupItems.map(entry => entry.eventTypeId)),
        [eventTypes]
    )

    const defaultEventTypeIds = useMemo(
        () => eventTypes
            .flatMap(group => group.eventTypeGroupItems)
            .filter(eventType => eventType.isDefault)
            .map(eventType => eventType.eventTypeId),
        [eventTypes]
    )

    const [selection, setSelection] = useState<IEventFilterSelection>(() =>
        loadInitalSelection({
            eventTypeIds,
            defaultEventTypeIds,
            valid: groupByGroupCameraZoneId(cameraGroupsHierarchy)
        })
    )

    useEffect(() => {
        saveSelectionToLocalStorage(selection)
    }, [selection])

    useOnMount(() => onSubmit(selection))

    const defaultResolution = useMemo(
        () =>  defaultSelection.resolution ?? getDefaultResolutionBasedOnTimeFrame(selection.timeFrame),
        [selection.timeFrame]
    )
    const filterOpened = useMemo(
        ()=> selection.cameraGroupIds.length === 0 && selection.cameraIds.length === 0,
        [selection.cameraGroupIds,selection.cameraIds]
    )

    return <Filter
        onSubmit={() => onSubmit(selection)}
        onReset={() => setSelection({
            ...defaultSelection,
            eventTypeIds: defaultEventTypeIds,
            resolution: defaultResolution
        })}
        hasActiveFilters={hasAnyActiveFilters(selection, defaultEventTypeIds, defaultResolution)}
        variant="outlined"
        isOpened={filterOpened}
    >
        <CameraSelector
            selectedIds={selection.cameraIds}
            setSelectedIds={selectedCameras => {
                setSelection({ ...selection, cameraIds: selectedCameras, zoneIds:[] })
            }}
            cameraGroupsHierarchy={cameraGroupsHierarchy}
        />
        <ZoneSelector
            cameraGroupsHierarchy={cameraGroupsHierarchy}
            cameraGroups={selection.cameraGroupIds}
            cameras={selection.cameraIds}
            selectedValues={selection.zoneIds}
            onChange={(selectedZones) => {
                setSelection({ ...selection, zoneIds: selectedZones })
            }}
        />
        <TimeFrameSelector
            timeFrame={selection.timeFrame}
            onUpdateTimeFrame={newTimeFrame =>
                setSelection({ ...selection, timeFrame: newTimeFrame })
            }
        />
        <ResolutionSelector
            resolution={selection.resolution}
            onUpdateResolution={newResolution =>
                setSelection({ ...selection, resolution: newResolution })
            }
            timeFrame={selection.timeFrame}
        />
        <EventTypesSelector
            values={eventTypes}
            selectedValues={selection.eventTypeIds}
            onChange={ids =>
                setSelection({ ...selection, eventTypeIds: ids })
            }
        />
        <EventStateSelector
            states={selection}
            onChange={changed =>
                setSelection({ ...selection, ...changed })
            }
        />
    </Filter>
}

function hasAnyActiveFilters(
    selection: IEventFilterSelection,
    defaultTypeIds: string[],
    defaultResolution: IResolution
) {
    return selection.cameraGroupIds.length > 0
        || selection.cameraIds.length > 0
        || selection.zoneIds.length > 0
        || defaultSelection.timeFrame.type !== selection.timeFrame.type
        || defaultSelection.includeFiltered !== selection.includeFiltered
        || defaultSelection.includeSimulated !== selection.includeSimulated
        || defaultSelection.includeMarkedAsFalse !== selection.includeMarkedAsFalse
        || defaultSelection.includeMarkedAsTrue !== selection.includeMarkedAsTrue
        || defaultSelection.includeUnmarked !== selection.includeUnmarked
        || defaultResolution !== selection.resolution
        || !defaultTypeIds.every(id => selection.eventTypeIds.includes(id))
        || !selection.eventTypeIds.every(id => defaultTypeIds.includes(id))
}