import { Box, FormControlLabel, Checkbox, FormGroup } from "@mui/material"
import FilterControlLabel from "Components/Filter/FilterControlLabel"
import { useTranslation } from "react-i18next"

interface IEventStateSelector {
    states: {
        includeSimulated: boolean
        includeFiltered: boolean
        includeMarkedAsTrue: boolean
        includeMarkedAsFalse: boolean
        includeUnmarked: boolean
    }
    onChange: (changed: { [key: string]: boolean }) => void
}

export default function EventStateSelector({
    states: {
        includeSimulated,
        includeFiltered,
        includeMarkedAsTrue,
        includeMarkedAsFalse,
        includeUnmarked
    },
    onChange
}: IEventStateSelector) {
    const { t } = useTranslation()

    return <Box>
        <FilterControlLabel text={t("event-filter.event-states")} />
        <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={includeUnmarked}
                        onChange={() => onChange({ includeUnmarked: !includeUnmarked })}
                    />
                }
                label={t("event-filter.unmarked") as string}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={includeMarkedAsTrue}
                        onChange={() => onChange({ includeMarkedAsTrue: !includeMarkedAsTrue })}
                    />
                }
                label={t("event-filter.marked-as-true") as string}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={includeMarkedAsFalse}
                        onChange={() => onChange({ includeMarkedAsFalse: !includeMarkedAsFalse })}
                    />
                }
                label={t("event-filter.marked-as-false") as string}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={includeFiltered}
                        onChange={() => onChange({ includeFiltered: !includeFiltered })}
                    />
                }
                label={t("event-filter.filtered") as string}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={includeSimulated}
                        onChange={() => onChange({ includeSimulated: !includeSimulated })}
                    />
                }
                label={t("event-filter.simulated") as string}
            />
        </FormGroup>
    </Box>
}