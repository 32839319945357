import {useRef, useLayoutEffect, useState} from "react"

const useImage = (url:string | undefined): [undefined | HTMLImageElement, "loaded" | "loading" | "failed"] => {
  // lets use refs for image and status
  // so we can update them during render
  // to have instant update in status/image when new data comes in
  const statusRef = useRef<"loaded" | "loading" | "failed">("loading")
  const imageRef = useRef<HTMLImageElement>()

  // we are not going to use token
  // but we need to just to trigger state update
  const [_, setStateToken] = useState(0)

  // keep track of old props to trigger changes
  const oldUrl = useRef<string>()

  if (oldUrl.current !== url) {
    statusRef.current = "loading"
    imageRef.current = undefined
    oldUrl.current = url
  }

  useLayoutEffect(
    ()=>{
      if (!url) return
      const img = document.createElement("img")

      const onload= () => {
        statusRef.current = "loaded"
        imageRef.current = img
        setStateToken(Math.random())
      }

      const onerror= ()=> {
        statusRef.current = "failed"
        imageRef.current = undefined
        setStateToken(Math.random())
      }

      img.addEventListener("load", onload)
      img.addEventListener("error", onerror)
      img.src = url

      return () => {
        img.removeEventListener("load", onload)
        img.removeEventListener("error", onerror)
      }
    },
    [url]
  )

  // return array because it is better to use in case of several useImage hooks
  // const [background, backgroundStatus] = useImage(url1)
  // const [patter] = useImage(url2)
  return [imageRef.current, statusRef.current]
}

export default useImage