import { ComponentProps, FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Button, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack } from "@mui/material"
import { faVideo } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { DragonDialogBase, IDragonDialogBase } from "."
import { IPostResponseDto, IServerSyncCameraDto } from "Types/admin"
import axios, { AxiosResponse } from "axios"
import { useMutation, useQueryClient } from "react-query"
import { DelayedLinearProgress } from "utils/DelayedProgress"

type IServerSyncCameraList = IServerSyncCameraDto[]
type IServerSyncPayload = { id: string }
type IPostResponse = IPostResponseDto

const syncServer = async (id: string): Promise<AxiosResponse<IPostResponse>> => {
    return await axios.post<IPostResponse, AxiosResponse<IPostResponse>, IServerSyncPayload>(
        `/api/admin/v1/servers/${id}/sync`)
}
interface IDragonServerTestResultDialog extends Pick<IDragonDialogBase, "open" | "onClose"> {
    data: IServerSyncCameraList | undefined
    id?: string
}
export const DragonServerTestResultDialog: FC<IDragonServerTestResultDialog> = ({ open, onClose, data, id }) => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const { mutateAsync: onSyncServer, isLoading, isError, isSuccess } = useMutation(() => syncServer(id ?? ""),
        { onSuccess: () => { queryClient.invalidateQueries(["servers-sync", id]) } }
    )

    const syncButtonColor = useMemo<ComponentProps<typeof Button>["color"]>(() => {
        if (isError) return "error"
        if (isSuccess) return "success"
        return "primary"
    }, [isError, isSuccess])

    return (
        <DragonDialogBase
            open={open && !!data}
            onClose={onClose}
            title={t("test-result")}
            actions={
                <Stack direction="row" spacing={2} width={1}>
                    {id && (
                        <Button
                            variant="contained"
                            color={syncButtonColor}
                            fullWidth
                            onClick={() => { onSyncServer() }}
                            disabled={isLoading}
                        >
                            {t("sync")}
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={onClose}
                    >
                        {t("close")}
                    </Button>
                </Stack>
            }
        >
            <Stack spacing={2}>
                {data?.map(({ externalId, name, serverSyncStateId: cameraState, zones }) => (
                    <Stack component={Paper} key={externalId} p={2}>
                        <ListItem sx={{
                            flexShrink:0
                        }}>
                            <ListItemAvatar>
                                <FontAwesomeIcon icon={faVideo} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={name}
                                secondary={t(`server-sync-state-id.${cameraState}`)}
                            />
                        </ListItem>
                        {zones && <List>
                            {zones.map(({ externalId, name, serverSyncStateId: zoneState }) => (
                                <ListItem key={externalId}>
                                    <ListItemText
                                        primary={name}
                                        secondary={t(`server-sync-state-id.${zoneState}`)}
                                    />
                                </ListItem>
                            ))}
                        </List>}
                    </Stack>
                ))}
                {isLoading && <DelayedLinearProgress delay={250} />}
            </Stack>
        </DragonDialogBase>
    )
}

export default DragonServerTestResultDialog
