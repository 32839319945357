import { CSSProperties, FC, useCallback, useEffect, useRef, useState } from "react"

export interface IHeatmapTraceDatum{
    trace: {
        x: number,
        y: number
    }[]
}
// Runtime type checking for React props
export interface IHeatlines{
    width: number,
    height: number,
    data: IHeatmapTraceDatum[],
    maxOccurances: number,
    stroke: number,
    alpha: number,
    gradientColor?: IHeatmapGradient[],
    style?:CSSProperties
}

export interface IHeatmapGradient {
    stopPoint: number,
    color: string
}

const MIN_OPACITY = 0.05
const DEFAULT_GRADIENT:IHeatmapGradient[] = [
    {
        stopPoint: 0.4,
        color: "blue"
    },
    {
        stopPoint: 0.6,
        color: "cyan"
    },
    {
        stopPoint: 0.7,
        color: "lime"
    },
    {
        stopPoint: 0.8,
        color: "yellow"
    },
    {
        stopPoint: 1.0,
        color: "red"
    }
]
export const Heatlines: FC<IHeatlines> = ({ width,height,data,maxOccurances,stroke,alpha,gradientColor,style}) => {

    // Component-level properties (these are not part of the state)
    const canvas = useRef<HTMLCanvasElement>(null) // main canvas ref
    const [defaultStroke,setDefaultStroke ] = useState(stroke) // some default values
    const [defaultAlpha,setDefaultAlpha] = useState(alpha)  // some default values
    const [defaultGradient,setDefaultGradient] = useState(gradientColor ?? DEFAULT_GRADIENT)
    const [gradient,setGradient] = useState<Uint8ClampedArray | null>(null) // gradient canvas

    useEffect(()=>{
        setDefaultAlpha(alpha)
        setDefaultStroke(stroke)
        setDefaultGradient(gradientColor ?? DEFAULT_GRADIENT)
    },[stroke,alpha,gradientColor])

      
    // Create a 256x1 gradient that we'll use to turn a grayscale heatmap into a colored one
    const createGradient = useCallback(()=>{
        const gradientCanvas = document.createElement("canvas")
        /* eslint-disable prefer-const */
        const ctx = gradientCanvas.getContext("2d",{willReadFrequently:true})!
        const gradient = ctx.createLinearGradient(0, 0, 0, 256)
        /* eslint-enable prefer-const */
        gradientCanvas.width = 1
        gradientCanvas.height = 256

        defaultGradient.forEach((val)=>{
            gradient.addColorStop(+val.stopPoint,val.color)
        }) 
        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, 1, 256)

        setGradient(ctx.getImageData(0, 0, 1, 256).data)
    },[defaultGradient])

    const colorize = useCallback((pixels:Uint8ClampedArray) => {
        if(!gradient) return
        for (let i = 0, len = pixels.length, j; i < len; i += 4) {
          j = pixels[i + 3] * 4 // get gradient color from opacity value
    
          if (j) {
            pixels[i] = gradient[j]
            pixels[i + 1] = gradient[j + 1]
            pixels[i + 2] = gradient[j + 2]
          }
        }
    },[gradient])

    useEffect(()=> {
        const opacity = MIN_OPACITY
        const ctx = canvas?.current?.getContext("2d",{willReadFrequently:true})
        if(!ctx) return

        if (gradient === null) {
            createGradient()
            return
        }
        ctx.clearRect(0, 0, width, height)
        ctx.globalAlpha = Math.min(Math.max(data.length / maxOccurances, opacity), 1)
        ctx.lineWidth=defaultStroke
        ctx.strokeStyle= "#ffffff"
        // draw a grayscale heatmap by putting a blurred circle at each data point
        data.forEach(datum => {
            const trace = datum.trace?[...datum.trace]:[]
            if(trace.length>1){
                ctx.beginPath()
                ctx.moveTo(trace[0].x*width,trace[0].y*height)
                for(let i=1;i<trace.length;i++){
                    ctx.lineTo(trace[i].x*width,trace[i].y*height)
                }
                //ctx.closePath()
                ctx.stroke()
            }
            
        })
        // colorize the heatmap, using opacity value of each pixel to get the right color from our gradient
        const colored = ctx.getImageData(0, 0, width, height)
        colorize(colored.data)
        ctx.putImageData(colored, 0, 0)
    },[width,height,data,defaultAlpha,defaultStroke,gradient,colorize,createGradient,maxOccurances])

    return (
      <div style={style}>
        <canvas ref={canvas} width={width} height={height} />
      </div>
    )
}

export default Heatlines