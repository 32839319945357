import React, { ComponentProps, ReactNode, forwardRef, ForwardedRef } from "react"
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Toolbar,
    Divider
} from "@mui/material"

export type HeaderCell = { value: (string | null) } & ComponentProps<typeof TableCell>
export type BodyCell = { value: string | number | ReactNode | null; } & ComponentProps<typeof TableCell>
export type BodyRow = { row: BodyCell[], key?: number | string } & ComponentProps<typeof TableRow>

export interface IDragonTableBase {
    columns?: HeaderCell[];
    data: BodyRow[]
    toolbar?: React.ReactNode
    onScroll?: React.UIEventHandler<HTMLTableElement>
    footer?: React.ReactNode
    size?: "medium" | "small"
    stickyHeader?:boolean
}

export default forwardRef<HTMLDivElement, IDragonTableBase>(function DragonTableBase({ columns, data, toolbar, footer, onScroll, size,stickyHeader }: IDragonTableBase, ref: ForwardedRef<HTMLDivElement>) {
    return (
        <Box sx={{ display: "block",height: 1, overflow: "hidden", width: 1 }}>
            {toolbar &&
                <>
                    <Toolbar
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            justifyContent: "space-between",
                            py: { xs: 2, sm: 0 }
                        }}
                    >
                        {toolbar}
                    </Toolbar>
                    <Divider />
                </>
            }
            <TableContainer onScroll={onScroll} ref={ref} sx={{height:"inherit"}}>
                <Table stickyHeader={stickyHeader} size={size}>
                    {columns && <TableHead>
                        <TableRow>
                            {columns.map(({ value, ...tableCellProps }, key) => (
                                <TableCell {...tableCellProps} key={key}>{value}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>}
                    <TableBody>
                        {data.map(({ row, key, ...tableRowProps }, rowIndex) => (
                            <TableRow {...tableRowProps} key={key ?? rowIndex} >
                                {row.map(({ value, ...tableCellProps }, cellIndex) => (
                                    <TableCell {...tableCellProps} key={cellIndex}>
                                        {value}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {footer}
            </TableContainer>
        </Box>
    )
})
