import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import {
    Paper,
    Stack,
    Box,
    Divider
} from "@mui/material"

import VehicleCounting from "./CountingTab/TrafficCounting"
import TrafficFlow from "./TrafficTab/TrafficCounting"
import { faStopwatch, faCars, faChartBar, faPeopleGroup, faRoute, faChartScatterBubble,faSmog} from "@fortawesome/pro-regular-svg-icons"
import { faStopwatch as faStopwatchSolid,faCars as faCarsSolid, faChartBar as faChartBarSolid, faRoute as faRouteSolid, faPeopleGroup as faPeopleGroupSolid,faChartScatterBubble as faChartScatterBubbleSolid, faSmog as faSmogSolid } from "@fortawesome/pro-solid-svg-icons"

import { DragonTabs, DragonTab } from "Components/DragonTabs"
import EventCounting from "./EventTab/EventCounting"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import Icon from "Components/Icon"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"
import GateCounting from "./GateTab/GateCounting"
import PeopleOccupancy from "./PeopleOccupancyTab/PeopleOccupancy"
import VehicleTracing from "./TraceTab/VehicleTracing"
import { useUserProfile } from "hooks/useUserProfile"
import PollutionMap from "./PollutionTab/PollutionCounting"

export enum Tab {
    VEHICLE_COUNTING,
    TRAFFIC_FLOW,
    VEHICLE_TRACING,
    PEOPLE_COUNTING,
    BIKE_COUNTING,
    EVENT_COUNT,
    GATE_COUNTING,
    PEOPLE_OCCUPANCY,
    POLLUTION_MAP
}

export default function TrafficData() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const {features} = useUserProfile()

    let selectedTab: Tab | undefined = undefined

    if (location.pathname.endsWith("/data-analytics"))
        selectedTab = Tab.VEHICLE_COUNTING
        if (location.pathname.endsWith("/traffic-flow"))
        selectedTab = Tab.TRAFFIC_FLOW
    else if (location.pathname.endsWith("/vehicle-trace"))
        selectedTab = Tab.VEHICLE_TRACING
    // else if (location.pathname.endsWith("/people"))
    //     selectedTab = Tab.PEOPLE_COUNTING
    // else if (location.pathname.endsWith("/bike"))
    //     selectedTab = Tab.BIKE_COUNTING
    else if (location.pathname.endsWith("/events"))
        selectedTab = Tab.EVENT_COUNT
    else if(location.pathname.endsWith("/gate"))
        selectedTab = Tab.GATE_COUNTING
    else if(location.pathname.endsWith("/people-occupancy"))
        selectedTab = Tab.PEOPLE_OCCUPANCY
        else if(location.pathname.endsWith("/pollution"))
        selectedTab = Tab.POLLUTION_MAP

    useEffect(() => {
        if (!selectedTab)
            navigate(".", { replace: true })
    }, [selectedTab, navigate])

    if (selectedTab === undefined)
        return null

    return (
        <DragonPageWrapper>
            <DragonTitle title={t("data-analytics")} />
            <Paper sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "auto"
            }}>
                <Box display="flex">
                    <DragonTabs
                        value={selectedTab}
                    >
                        <DragonTab
                            label={t("data-analytics-page.counting")}
                            value={Tab.VEHICLE_COUNTING}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.VEHICLE_COUNTING}
                                    icon={faStopwatch}
                                    selectedIcon={faStopwatchSolid}
                                />
                            }
                            to="."
                        />
                        <DragonTab
                            label={t("data-analytics-page.traffic")}
                            value={Tab.TRAFFIC_FLOW}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.TRAFFIC_FLOW}
                                    icon={faCars}
                                    selectedIcon={faCarsSolid}
                                />
                            }
                            to="traffic-flow"
                        />
                        {/* <DragonTab
                            label={t("data-analytics-page.people")}
                            value={Tab.PEOPLE_COUNTING}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.PEOPLE_COUNTING}
                                    icon={faUserFriends}
                                    selectedIcon={faUserFriendsSolid}
                                />
                            }
                            to="people"
                        />
                        <DragonTab
                            label={t("data-analytics-page.bike")}
                            value={Tab.BIKE_COUNTING}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.BIKE_COUNTING}
                                    icon={faBiking}
                                    selectedIcon={faBikingSolid}
                                />
                            }
                            to="bike"
                        /> */}
                        <DragonTab
                            label={t("data-analytics-page.people-occupancy")}
                            value={Tab.PEOPLE_OCCUPANCY}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.PEOPLE_OCCUPANCY}
                                    icon={faPeopleGroup}
                                    selectedIcon={faPeopleGroupSolid}
                                />
                            }
                            to="people-occupancy"
                        />
                        <DragonTab
                            label={t("data-analytics-page.gates")}
                            value={Tab.GATE_COUNTING}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.GATE_COUNTING}
                                    icon={faRoute}
                                    selectedIcon={faRouteSolid}
                                />
                            }
                            to="gate"
                        />
                        {features.includes("TraceEnabled") && <DragonTab
                            label={t("data-analytics-page.tracing")}
                            value={Tab.VEHICLE_TRACING}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.VEHICLE_TRACING}
                                    icon={faChartScatterBubble}
                                    selectedIcon={faChartScatterBubbleSolid}
                                />
                            }
                            to="vehicle-trace"
                        />}
                        <DragonTab
                            label={t("data-analytics-page.pollution")}
                            value={Tab.POLLUTION_MAP}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.POLLUTION_MAP}
                                    icon={faSmog}
                                    selectedIcon={faSmogSolid}
                                />
                            }
                            to="pollution"
                        />
                        <DragonTab
                            label={t("data-analytics-page.events")}
                            value={Tab.EVENT_COUNT}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.EVENT_COUNT}
                                    icon={faChartBar}
                                    selectedIcon={faChartBarSolid}
                                />
                            }
                            to="events"
                        />
                    </DragonTabs>
                </Box>
                <Divider />
                <Stack spacing={2} sx={{ overflow: "hidden" }}>
                    <Routes>
                        <Route index element={<VehicleCounting />} />
                        {features.includes("TraceEnabled") &&<Route path="vehicle-trace" element={<VehicleTracing />} />}
                        {/* <Route path="people" element={<PeopleCounting />} />
                        <Route path="bike" element={<BikeCounting />} /> */}
                        <Route path="traffic-flow" element={<TrafficFlow />} />
                        <Route path="gate" element={<GateCounting />} />
                        <Route path="people-occupancy" element={<PeopleOccupancy />} />
                        <Route path="pollution" element={<PollutionMap />} />
                        <Route path="events" element={<EventCounting />} />
                    </Routes>
                </Stack>
            </Paper>
        </DragonPageWrapper>
    )
}
