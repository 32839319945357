import dragonSplash from "logos/splash_dragon.png"
import { Box, Typography } from "@mui/material"
import DragonPageWrapper from "Components/DragonPageWrapper"

export default function DragonVersion() {
    const systemVersion = process.env.REACT_APP_DRAGON_SYSTEM_VERSION

    return (
        <DragonPageWrapper>
            <Box sx={{ display: "flex", flex:"1", justifyContent:"center",alignItems:"center", overflow: "hidden"}}>
                <Box sx={{position:"relative", width:600, height:425, flexShrink:0}}>
                    <img style={{position:"absolute", width:"100%", top:0, left:0}}src={dragonSplash} alt="Sprinx Dragon"></img>
                    <Typography variant="h3" position="absolute" top={220} right={28} zIndex={10} color="white">{`v${systemVersion}`}</Typography>
                </Box>
            </Box>
        </DragonPageWrapper>
    )
}