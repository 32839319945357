import { FormControl, FilledInput } from "@mui/material"
import FilterControlLabel from "Components/Filter/FilterControlLabel"

import { useTranslation } from "react-i18next"

interface IName {
    selectedValue: string
    onChange: (newValue: string) => void
}

export default function NameInput({ selectedValue, onChange }: IName) {
    const { t } = useTranslation()

    return (
        <FormControl fullWidth>
            <FilterControlLabel text={t("widget-creator.name")} />
            <FilledInput
                value={selectedValue}
                onChange={(e) => onChange(e.target.value)}
                disableUnderline
            />
        </FormControl>
    )
}