import { Stack, Typography } from "@mui/material"

interface IDragonTitle {
    title: string
    action?: React.ReactNode
}

export default function DragonTitle({ title, action }: IDragonTitle) {
    return (
        <Stack direction="row" mt="8px" mb="20px" alignItems={"center"}>
            <Typography variant="h1" fontWeight="bold" fontSize={27} flexGrow={1}>
                {title}
            </Typography>
            {action}
        </Stack>
    )
}