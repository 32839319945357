import { Box, Card, CardContent, CardHeader, IconButton, Stack, SvgIcon, Typography, useTheme } from "@mui/material"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"

import { DelayedCircularProgress } from "utils/DelayedProgress"
import IEvent from "./IEvent"
import axios from "axios"
import getStartAndEndDateFromTimeFrame from "../../Components/Filter/utils/getTimeFrameFromFilter"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import getFilteredStateIds from "./utils/getFilteredStateIds"
import IEventJournalFilterSelection from "./types/IEventJournalFilterSelection"
import { ReactComponent as faImageSlash } from "icons/faImageSlash-regular.svg"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { colorsList } from "App/colors"

type ISelection = Pick<IEventJournalFilterSelection, "timeFrame" | "eventTypeIds" | "includeMarkedAsFalse" | "includeMarkedAsTrue" | "includeFiltered" | "includeSimulated" | "includeUnmarked">

interface IMapViewStatistics {
    cameraId: string
    selection: ISelection
    onClose: () => void
}

export default function MapViewStatistics({
    cameraId,
    selection,
    onClose
}: IMapViewStatistics) {
    const { t } = useTranslation()
    const theme = useTheme()

    // const { containerRef } = useControl({
    //     captureDrag: true,
    //     captureClick: true,
    //     captureDoubleClick: true,
    //     capturePointerMove: true,
    //     captureScroll: true
    // })

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isError
    } = useInfiniteQuery(
        ["camera-statistics", { cameraId, selection }],
        getFilteredEvents,
        {
            getNextPageParam: (lastPage, _) => {
                if (lastPage.length === 0)
                    return undefined

                return lastPage[lastPage.length - 1].startedAt
            },
            onSuccess: () => {
                if (hasNextPage)
                    fetchNextPage()
            },
            onError: () => {
                // Override on error so snackbar doesn't show
            }
        }
    )

    const events = data?.pages.flat() ?? []

    const newestSnapshot = getNewestSnapshot(events)

    return <Card
        sx={{
            maxWidth: 650,
            height: 250,
            position: "absolute",
            bottom: 10,
            left: 10,
            right: 10,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            cursor: "default"
        }}
        //ref={containerRef}
    >
        <CardHeader
            action={
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={faTimes} style={{ fontSize: "1em" }} />
                </IconButton>
            }
            title={isFetching
                ? t("loading-info")
                : isError
                    ? t("something-went-wrong")
                    : events[0].camera.name
            }
            sx={{
                borderBottom: theme => `1px solid ${theme.palette.divider}`
            }}
        />
        <CardContent
            sx={{
                overflow: "hidden",
                p: theme.spacing(2),
                ":last-child": {
                    p: theme.spacing(2)
                },
                flexGrow: 1,
                display: "flex"
            }}
        >
            {isFetching
                ? <DelayedCircularProgress delay={250} />
                : isError
                    ? <Typography>{t("something-went-wrong")}</Typography>
                    : <Stack direction="row" spacing={2} sx={{ overflow: "hidden", flexGrow: 1 }}>
                        <Box
                            component="picture"
                            sx={{
                                aspectRatio: "16 / 9",
                                width: {
                                    lg: 200,
                                    xs: 160
                                },
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexShrink: 0,
                                flexGrow: 0
                            }}
                        >
                            {newestSnapshot
                                ? <Box
                                    component="img"
                                    sx={{
                                        borderRadius: theme => theme.shape.borderRadius
                                    }}
                                    src={newestSnapshot}
                                    alt={t("event-journal.latest-snapshot")}
                                    style={{
                                        width: "100%"
                                    }}
                                />
                                : <SvgIcon component={faImageSlash} viewBox="0 0 640 512" style={{ fontSize: 48, color: theme.palette.text.secondary }} />
                            }
                        </Box>
                        <EventTypePieChart events={events} />
                    </Stack>
            }
        </CardContent>
    </Card>
}

const DISABLED_OPACITY = 0.3

function EventTypePieChart({
    events
}: {
    events: IEvent[]
}) {
    const theme = useTheme()
    const { t } = useTranslation()

    const groupedByEventType = events.reduce<{ [eventTypeId: string]: number }>((acc, event) => {
        acc[event.eventTypeId] = (acc[event.eventTypeId] ?? 0) + 1

        return acc
    }, {})

    const data = Object
        .entries(groupedByEventType)
        .map(([type, count]) => ({ name: type, value: count }))
        .sort((a, b) => b.value - a.value)

    const [hiddenDataPoints, setHiddenDataPoints] = useState(
        data.reduce<{ [dataKey: string]: boolean }>((acc, entry) => {
            acc[entry.name] = false
            return acc
        }, {})
    )

    const [hoveredEventType, setHoveredEventType] = useState<string | null>(null)

    const outerRadius = 65
    const innerRadius = outerRadius / 2

    return <Box style={{ position: "relative", flexGrow: 1 }}>
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie
                    data={data.map(({ name, value }) => ({
                        name,
                        value: hiddenDataPoints[name] ? 0 : value
                    }))}
                    dataKey="value"
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    stroke="none"
                    onMouseEnter={data => setHoveredEventType(data.name)}
                    onMouseLeave={() => setHoveredEventType(null)}
                >
                    {data
                        .map((entry, index) =>
                            <Cell
                                key={index}
                                fill={colorsList[index % colorsList.length]}
                                opacity={(hoveredEventType && entry.name !== hoveredEventType)
                                    ? DISABLED_OPACITY
                                    : 1.0
                                }
                            />
                        )}
                </Pie>
                <Legend
                    layout="vertical"
                    align="right"
                    wrapperStyle={{
                        overflow: "auto",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: outerRadius * 2,
                        paddingLeft: theme.spacing(2)
                    }}
                    formatter={(value: string, entry) => {
                        const percentageOfTotal = Math.round(groupedByEventType[entry.value] / events.length * 100)

                        return <span
                            key={entry.value}
                            style={{
                                color: theme.palette.text.primary,
                                opacity: (hoveredEventType !== value && hiddenDataPoints[value]) ? DISABLED_OPACITY : 1,
                                textDecoration: hiddenDataPoints[value] ? "line-through" : "none"
                            }}
                        >
                            {t(`event-types.${entry.value}`)} {percentageOfTotal}%
                        </span>
                    }}
                    onMouseEnter={data => setHoveredEventType(data.id ?? null)}
                    onMouseLeave={() => setHoveredEventType(null)}
                    onClick={data => setHiddenDataPoints({
                        ...hiddenDataPoints,
                        [data.value]: !hiddenDataPoints[data.value]
                    })}
                    style={{
                        overflow: "auto"
                    }}
                />
                <Tooltip
                    formatter={(value: number, name: string) => 
                        ([value, t(`event-types.${name}`) as string])
                    }
                />
            </PieChart>
        </ResponsiveContainer>
    </Box>
}

async function getFilteredEvents(context: QueryFunctionContext<[string, { cameraId: string, selection: ISelection }]>) {
    const [_, { cameraId, selection }] = context.queryKey
    const lastStartedAt = context.pageParam
    const limit = 1000

    const [startedAt, endedAt] = getStartAndEndDateFromTimeFrame(selection.timeFrame)

    const response = await axios.put<IEvent[]>(
        "/api/event/v1/events/event-journal",
        {
            ...getFilteredStateIds(selection),
            "cameraIds": [cameraId],
            "limit": limit,
            "startedAtGe": startedAt,
            "endedAtLe": endedAt,
            "lastStartedAt": lastStartedAt,
            "eventTypeIds": selection.eventTypeIds,
            "orderBy": "StartedAtAscending"
        }
    )

    return response.data.map<IEvent>(event => ({
        ...event,
        startedAt: new Date(event.startedAt),
        endedAt: event.endedAt !== null
            ? new Date(event.endedAt)
            : null
    }))
}

function getNewestSnapshot(events: IEvent[]) {
    const newestEvent = events.reduce<IEvent | null>((acc, event) => {
        if (!event.eventAssets.some(asset => asset.assetTypeId === "Snapshot"))
            return acc

        if (acc === null)
            return event

        if ((event.endedAt ?? event.startedAt) > (acc.endedAt ?? acc.startedAt))
            return event

        return acc
    }, null)

    if (!newestEvent)
        return null

    const snapshot = newestEvent
        .eventAssets
        .find(asset => asset.assetTypeId === "Snapshot")
        ?.uri ?? null

    return snapshot
}