export const dataSources = [
    {
        id: "EventCount",
        widgetTypes: ["Donut", "Line", "Bar"]
    },
    {
        id: "VehicleCount",
        widgetTypes: ["Donut", "Line", "Bar"]
    },
    {
        id: "VehicleSpeed",
        widgetTypes: ["Line"]
    },
    // {
    //     id: "VehicleDistance",
    //     widgetTypes: ["Line"]
    // },
    {
        id: "TrafficInfoDensity",
        widgetTypes: ["Line"]
    },
    {
        id: "TrafficInfoSpeed",
        widgetTypes: ["Line"]
    },
    {
        id: "GateCount",
        widgetTypes: ["Heatmap"]
    },
    // {
    //     id: "PeopleCount",
    //     widgetTypes: ["Donut", "Line"]
    // },
    // {
    //     id: "BikeCount",
    //     widgetTypes: ["Donut", "Line"]
    // },
    {
        id: "PeopleOccupancy",
        widgetTypes: ["Donut","Line"]
    },
    // {
    //     id: "EventJournal",
    //     widgetTypes: ["Table"]
    // },
    {
        id: "EventComment",
        widgetTypes: ["Table"],
        skipFilteringStep: true
    }
]