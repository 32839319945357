import axios from "axios"
import { ITrafficFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import {
    ITrafficData,
    ITrafficDataTrafficDensity,
    ITrafficDataTrafficSpeed,
    ITrafficDataVehicleSpeed
} from "../ITrafficData"
import getMostSpecificIdsFromFilter from "Pages/EventJournal/utils/getMostSpecificIdsFromFilter"
import convertTimeFrameToTimespanFilter from "../utils/convertTimeFrameToTimespanFilter"

interface ITrafficDataRequestBase {
    cameraGroupIds?: string[]
    cameraIds?: string[]
    zoneIds?: string[]
    vehicleClassIds?: string[]
    timeZone: string
    timespanGroupBy: string
}

interface ITrafficDataRequestCustom extends ITrafficDataRequestBase {
    timespanFilterTypeId: "Custom"
    timespanFilterTypeCustomFromGe: Date
    timespanFilterTypeCustomToLe: Date
}

interface ITrafficDataRequestRelative extends ITrafficDataRequestBase {
    timespanFilterTypeId: "Relative"
    timespanFilterTypeRelativeFromGe: string
    timespanFilterTypeRelativeToLe: string
}

type ITrafficDataRequest = ITrafficDataRequestCustom | ITrafficDataRequestRelative

const filterFromSelection = (selection: ITrafficFilterSelection): ITrafficDataRequest => ({
    ...getMostSpecificIdsFromFilter(selection),
    vehicleClassIds: selection.vehicleClassIds,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timespanGroupBy: selection.resolution,
    ...convertTimeFrameToTimespanFilter(selection.timeFrame)
})

export async function getTrafficDensity(selection: ITrafficFilterSelection): Promise<ITrafficData[]> {
    const filter = filterFromSelection(selection)
    if(Object.keys(filter).some(k=> k === "cameraIds" || k === "camerGroupIds" || k === "zoneIds")){
    const response = await axios.put<ITrafficDataTrafficDensity[]>("/api/event/v1/data/traffic-info-density", filter)
    return response
        .data
        .map(({ density, ...o }) => ({ ...o, value: density, vehicleClassId: null }))
    } else return []
}

export async function getTrafficSpeed(selection: ITrafficFilterSelection): Promise<ITrafficData[]> {
    const filter = filterFromSelection(selection)
    if(Object.keys(filter).some(k=> k === "cameraIds" || k === "camerGroupIds" || k === "zoneIds")){
    const response = await axios.put<ITrafficDataTrafficSpeed[]>("/api/event/v1/data/traffic-info-speed", filter)
    return response
        .data
        .map(({ speed, ...o }) => ({ ...o, value: speed, vehicleClassId: null }))
    } else return []
}

export async function getVehicleSpeed(selection: ITrafficFilterSelection): Promise<ITrafficData[]> {
    const filter = filterFromSelection(selection)
    if(Object.keys(filter).some(k=> k === "cameraIds" || k === "camerGroupIds" || k === "zoneIds")){
    const response = await axios.put<ITrafficDataVehicleSpeed[]>("/api/event/v1/data/vehicle-speed", filter)
    return response
        .data
        .map(({ speed, ...o }) => ({ ...o, value: speed }))
    }else return []
}