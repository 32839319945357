import { FormControl, FilledInput, InputBaseComponentProps, List, ListItemButton, ListItemText, ListItem } from "@mui/material"
import FilterControlLabel from "Components/Filter/FilterControlLabel"
import { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import IWidgetSelection from "../types/IWidgetSelection"
import { dataSources } from "../utils/dataSources"

interface IDataSourceSelector {
    selectedValue: IWidgetSelection["dataSource"]
    widgetType: IWidgetSelection["widgetType"]
    onChange: (newValue: IWidgetSelection["dataSource"]) => void
}

export default function DataSourceSelector({ selectedValue, widgetType, onChange }: IDataSourceSelector) {
    const { t } = useTranslation()

    function handleChange(value: IWidgetSelection["dataSource"]) {
        if (value === selectedValue) onChange(null)
        else onChange(value)
    }

    return (
        <FormControl fullWidth sx={{ height: "100%" }}>
            <FilterControlLabel text={t("widget-creator.data-source")} />
            <FilledInput
                inputProps={{ selectedValue, widgetType, handleChange }}
                inputComponent={DataSourcesList}
                disableUnderline
                sx={{ flexGrow: 1, alignItems: "start" }}
            />
        </FormControl>
    )
}

const DataSourcesList = forwardRef(({ selectedValue, widgetType, handleChange }: IDataSourceSelector & InputBaseComponentProps, _ref) => {
    const { t } = useTranslation()

    return (
        <List sx={{ width: "100%" }}>
            {dataSources.map((dataSource) => (
                <ListItem
                    key={dataSource.id}
                    sx={{ backgroundColor: dataSource.id === selectedValue ? "primary.main" : undefined }}
                    disablePadding
                >
                    <ListItemButton
                        selected={dataSource.id === selectedValue}
                        onClick={() => handleChange(dataSource.id)}
                        disabled={!([...dataSource.widgetTypes, null].includes(widgetType))}
                        dense
                    >
                        <ListItemText primary={t(`widget-creator.data-sources.${dataSource.id}`)} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    )
})