import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, FormControl, FormControlLabel, Grid, Paper, Stack, Switch, TextField, Typography } from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import DragonPageWrapper from "Components/DragonPageWrapper"
import DragonTitle from "Components/DragonTitle"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { faEdit } from "@fortawesome/pro-regular-svg-icons"
import ErrorBoundary from "utils/ErrorBoundary"
import { DragonConfirmationDialogWithPromise } from "Components/DragonDialog"


interface IMilestoneSettings{
    isEnabled:boolean,
    url: string,
    port: number,
    cameraMapping: {
        [id:string]:string
    }
}

const getMilestoneSettings = async (): Promise<IMilestoneSettings> => {
    const response = await axios.get<IMilestoneSettings>("/api/v1/milestone/config")
    return response.data
}
const putMilestoneSettings = async (payload: IMilestoneSettings): Promise<AxiosResponse> => {
    return await axios.put("/api/v1/milestone/config", payload)
}
export default function MilestoneConfiguration() {
    const { t } = useTranslation()
    const [error, setError] = useState("")
    const [saveDialog, setSaveDialog] = useState<boolean>(false)
    const queryClient = useQueryClient()

    const {
        isLoading,
        isError,
        isSuccess,
        isFetching,
        data
    } = useQuery(
        "milestone-settings",
        getMilestoneSettings,
        { 
            onError: (error:AxiosError) => {
                if (error.response?.status === 404)
                    return setError(t("milestone-settings.error-retrieve"))
                return setError(t("something-went-wrong"))
            },
            retry(failureCount, error) {
                if(failureCount<=10 && error.response?.status === 502) return true
                return false
            }
        }
    )

    const initialState= useMemo<IMilestoneSettings>(() => ({
        isEnabled: data?.isEnabled ?? false,
        url: data?.url ?? "",
        port: data?.port ?? 1883,
        cameraMapping: data?.cameraMapping ?? {}

    }),[data])


    const [draft, setDraft] = useState<IMilestoneSettings>(initialState)
    
    useEffect(()=>{
        setDraft(initialState)
    },[initialState])

    const isChanged = useMemo<boolean>(
        () => (
            Object.entries(draft).some(
                ([key, value]) => initialState[key as keyof IMilestoneSettings] !== value
            )
        ),
        [draft, initialState]
    )

    const validation = useMemo<Partial<Record<keyof IMilestoneSettings, boolean>>>(
        () => (
            {
                isEnabled: true,
                url: draft.url.length >0 && draft.url.length <300,
                port: !isNaN(draft.port) && typeof draft.port === "number",
                cameraMapping: draft.cameraMapping !== null &&  draft.cameraMapping !== undefined
            }
        ),
        [draft]
    )

    const { mutateAsync: handleSaveChangesAsync, isLoading: isLoadingSave } = useMutation(() => putMilestoneSettings(draft),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("milestone-settings")
            }
        }
    )    
    const handleChangeValue = useCallback<
    (key: string) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
        >((key) => (event) => {
            const updateDraft = (updated :{ [key: string]: unknown }) => setDraft({ ...draft, ...updated })
            updateDraft(({ [key]: event.target.value }))
        }, [draft])
    const handleChangeNumber = useCallback<
        (key: string) => (event: ChangeEvent<HTMLInputElement>) => void
            >((key) => (event) => {
                const num = parseInt(event.target.value)
                const updateDraft = (updated :{ [key: string]: number }) => setDraft({ ...draft, ...updated })
                updateDraft(({ [key]: num }))
            }, [draft])
    const handleChangeBoolean = useCallback<
    (key: string) => (event: ChangeEvent<HTMLInputElement>,checked:boolean) => void
        >((key) => (event,checked) => {
            const updateDraft = (updated :{ [key: string]: boolean }) => setDraft({ ...draft, ...updated })
            updateDraft(({ [key]: checked }))
        }, [draft])
    return (
        <DragonPageWrapper>
            <DragonTitle title={t("milestone-settings.title")} />
            {(() => {
                if (isLoading || isFetching || isLoadingSave)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{error}</h2>

                if (isSuccess)
                    return (
                        <ErrorBoundary>
                            <Grid container component={Paper} overflow="auto" flex={1}>
                                <Grid item xs={12} md={4} p={2}>
                            <Stack spacing={2}>
                                <FormControl>
                                    <FormControlLabel 
                                        control={
                                            <Switch
                                            checked={draft.isEnabled}
                                            onChange={handleChangeBoolean("isEnabled")}
                                            
                                            />
                                        }
                                        label={t("milestone-settings.is-enabled")}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("milestone-settings.host")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="url"
                                            InputProps={{
                                                disableUnderline: initialState.url === draft.url,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                inputMode: "url",
                                                type: "url"
                                            }}
                                            value={draft.url ?? ""}
                                            onChange={handleChangeValue("url")}
                                            error={!validation.url}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("milestone-settings.port")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="number"
                                            InputProps={{
                                                disableUnderline: initialState.port === draft.port,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                inputMode: "numeric",
                                                type: "number"
                                            }}
                                            value={draft.port ?? 1883}
                                            onChange={handleChangeNumber("port")}
                                            error={!validation.port}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                            </Stack>
                            
                            </Grid>
                            </Grid>
                            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ minWidth: 150 }}
                                    onClick={() => setSaveDialog(true)}
                                    disabled={!isChanged || Object.values(validation).some(v => !v)}
                                >
                                    {t("save-changes")}
                                </Button>
                            </Stack>
                           <DragonConfirmationDialogWithPromise
                                open={saveDialog}
                                variant="save"
                                title={t("milestone-settings.save-changes_confirmation")}
                                onClose={() => setSaveDialog(false)}
                                onConfirm={async () => {
                                    await handleSaveChangesAsync()
                                }}
                                isLoading={isLoading}
                                errorText={t("milestone-settings.save-changes_failed")}
                            />
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>
        
    )
}