import { Stack, Paper } from "@mui/material"
import DragonDialog from "Components/DragonDialog"
import DragonPageWrapper from "Components/DragonPageWrapper"
import DragonTitle from "Components/DragonTitle"
import EventDetailsWrapper from "Pages/EventDetails/EventDetails"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import AuditingFilter, { IAuditingFilterSelection } from "./AuditingFilter"
import AuditingTable from "./AuditingTable"
import EventExportDetails from "./EventExportDetails"

export default function Auditing() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const [selection, setSelection] = useState<IAuditingFilterSelection | null>(null)

    const updateSelection = useCallback((selection: IAuditingFilterSelection) => setSelection(selection), [])

    const hideAuditLog = pathname.includes("/event")

    return <>
        <DragonPageWrapper hidden={hideAuditLog}>
            <DragonTitle title={t("auditing-page.title")} />
            <Stack spacing={1} overflow="hidden">
                <AuditingFilter
                    onSubmit={updateSelection}
                />
                {selection !== null &&
                    <Paper sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                        <AuditingTable
                            selection={selection}
                        />
                    </Paper>
                }
            </Stack>
        </DragonPageWrapper>
        <Routes>
            <Route path="event/:eventId" element={<EventDetailsWrapper />} />
            <Route
                path="export/:exportId"
                element={
                    <DragonDialog
                        open
                        onClose={() => navigate(".")}
                        title={t("event-export-details.title")}
                    >
                        <EventExportDetails />
                    </DragonDialog>
                }
            />
        </Routes>
    </>
}
