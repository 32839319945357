import { Theme } from "Contexts/ThemeContext"
import { teal } from "App/colors"
import { LayerProps } from "react-map-gl/maplibre"
export const circlePollutionLayer: LayerProps = {
    id: "pollution-circle",
    type: "circle",
    source: "pollution",
    filter: ["has", "pollution-level"],
    paint: {
        "circle-color": teal[500],
        "circle-blur":1,
        "circle-opacity":0.8,
        "circle-radius": ["interpolate",
            ["linear"], ["zoom"],
            5,1,
            12, ["*",["get","pollution-level"],0.04],
            15, ["*",["get","pollution-level"],0.1],
            20, ["*",["get","pollution-level"],0.3],
            23, ["*",["get","pollution-level"],0.35]
        ],
        "circle-pitch-alignment":"map"
    }
}

export const createUnclusteredCamerasNameLayer = (theme: Theme): LayerProps => ({
    id: "unclustered-camera-name",
    type: "symbol",
    source: "events",
    filter: ["!", ["has", "point_count"]],
    layout: {
        "text-field": "{name}",
        "text-size": 15,
        "text-font": ["Noto Sans Regular"],
        "text-offset": [0, -1.3]
    },
    paint: {
        "text-halo-width": 2,
        "text-color":
            theme === Theme.dark
                ? "#FFF"
                : "#000",
        "text-halo-color":
            theme === Theme.dark
                ? "#000"
                : "#FFF"
    }
})