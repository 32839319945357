import { Box, Typography } from "@mui/material"
import { Feature, GeoJsonProperties, Geometry } from "geojson"
import { Layer, MapLayerMouseEvent, MapRef, Source,GeoJSONSource } from "react-map-gl/maplibre"

import { DelayedCircularProgress } from "utils/DelayedProgress"
import IEventJournalFilterSelection from "./types/IEventJournalFilterSelection"
import Map, { ZOOM_LEVEL } from "Components/Map/Map"
import MapViewStatistics from "./MapViewStatistics"
import axios from "axios"
import calculateBoundingBox from "Components/Map/utils/calculateBoundingBox"
import getMostSpecificIdsFromFilter from "./utils/getMostSpecificIdsFromFilter"
import getStartAndEndDateFromTimeFrame from "../../Components/Filter/utils/getTimeFrameFromFilter"
import { useQuery } from "react-query"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import getFilteredStateIds from "./utils/getFilteredStateIds"
import { useThemeContext } from "Contexts/ThemeContext"
import { IPosition } from "Types/IPosition"
import { createUnclusteredCamerasNameLayer, clusterLayer, unclusteredCamerasPointLayer, clusterCountLayer } from "Components/Map/layers/clusterLayers"

interface IMapViewWrapper {
    filter: IEventJournalFilterSelection
}

export default function MapViewWrapper({ filter }: IMapViewWrapper) {
    const { t } = useTranslation()

    const { data, isLoading, isSuccess, isError } = useQuery(
        [
            "event-journal-map-view",
            filter
        ],
        () => getMapData(filter)
    )

    if (isLoading)
        return <DelayedCircularProgress delay={250} />

    if (isError || !isSuccess)
        return <Typography p={2}>{t("failed-to-load-map")}</Typography>

    if (data.length === 0)
        return <Typography p={2}>{t("you-dont-have-access-to-any-cameras")}</Typography>

    const positions = data
        .map(({ position }) => position)
        .filter((position): position is IPosition => !!position)

    // TODO: Expose padding prop from map
    const boundingBox = calculateBoundingBox(positions)

    const centralPoint = {
        latitude: (boundingBox.max.latitude + boundingBox.min.latitude) / 2,
        longitude: (boundingBox.max.longitude + boundingBox.min.longitude) / 2
    }

    return <MapView
        centralPoint={centralPoint}
        data={data}
        selection={filter}
    />
}

type ISelection = Pick<IEventJournalFilterSelection, "timeFrame" | "eventTypeIds" | "includeMarkedAsFalse" | "includeMarkedAsTrue" | "includeFiltered" | "includeSimulated" | "includeUnmarked">

interface IMapView {
    centralPoint: IPosition
    data: IMapData[]
    selection: ISelection
}

function MapView({ centralPoint, data, selection }: IMapView) {
    const { mode } = useThemeContext()

    const [viewport] = useState<{ position: IPosition, zoom: number }>({
        position: centralPoint,
        zoom: 13
    })

    const [selectedCameraId, setSelectedCameraId] = useState<string | null>(null)

    function onClick(event: MapLayerMouseEvent, map: MapRef) {
        if (!event.features || event.features.length === 0)
            return

        const feature = event.features[0]
        const layerId = feature.layer.id

        switch (layerId) {
            case "unclustered-camera-point":
            case "unclustered-camera-name":
                const cameraId = feature.properties.id
                const hasAnyEvents = (
                    feature.properties["has-traffic-events"] ||
                    feature.properties["has-system-events"] ||
                    feature.properties["has-people-events"]
                )

                if (hasAnyEvents){
                    setSelectedCameraId(cameraId)
                    const point = feature.geometry as GeoJSON.Point
                    map.flyTo({
                        center:{
                            lat:point.coordinates[1],
                            lon:point.coordinates[0]
                        },
                        animate:true
                    })
                }
                    
                break
            case "clusters":
                const clusterId = feature.properties.cluster_id

                const mapboxSource = map.getSource("events")
                if(mapboxSource){
                    if (mapboxSource.type !== "geojson")
                        break
                    const geoSource= mapboxSource as unknown as GeoJSONSource
                    geoSource.getClusterExpansionZoom(clusterId).then((zoom)=>{
                        const point = feature.geometry as GeoJSON.Point
                            map.flyTo({
                                center:{
                                    lat:point.coordinates[1],
                                    lon:point.coordinates[0]
                                },
                                animate:true,
                                zoom:zoom ?? 15
                            })
                        })                
                }
                break
        }
    }

    const unclusteredCamerasNameLayer = createUnclusteredCamerasNameLayer(mode)

    return <Box sx={{ height: "100vh", position: "relative" }}>
        <Map
            position={viewport.position}
            zoom={viewport.zoom}
            allowInteraction
            interactiveLayerIds={[
                clusterLayer.id!,
                unclusteredCamerasPointLayer.id!,
                unclusteredCamerasNameLayer.id!
            ]}
            onClick={onClick}
            mapCenter={{ ...centralPoint, zoom: ZOOM_LEVEL }}
        >
            <Source
                id="events"
                type="geojson"
                data={{
                    type: "FeatureCollection",
                    features: data
                        .map((entry): Feature<Geometry, GeoJsonProperties> => ({
                            type: "Feature",
                            properties: {
                                id: entry.id,
                                name: entry.name,
                                "has-system-events": entry.eventTypeGroups.some(group => group.code === "SystemEvents"),
                                "has-traffic-events": entry.eventTypeGroups.some(group => group.code === "TrafficEvents"),
                                "has-people-events": entry.eventTypeGroups.some(group => group.code === "PeopleEvents"),
                                "selected": selectedCameraId === entry.id
                            },
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    entry.position.longitude,
                                    entry.position.latitude
                                ]
                            }
                        }))
                }}
                cluster
                clusterMaxZoom={16}
                clusterRadius={22}
                clusterProperties={{
                    "has-system-events": ["any", ["get", "has-system-events"]],
                    "has-traffic-events": ["any", ["get", "has-traffic-events"]],
                    "has-people-events": ["any", ["get", "has-people-events"]]
                }}
            >
                <Layer {...clusterLayer} />
                <Layer {...clusterCountLayer} />
                <Layer {...unclusteredCamerasPointLayer} />
                <Layer {...unclusteredCamerasNameLayer} />
            </Source>
            {selectedCameraId &&
                <MapViewStatistics
                    cameraId={selectedCameraId}
                    selection={selection}
                    onClose={() => setSelectedCameraId(null)}
                />
            }
        </Map>
    </Box>
}

interface IMapDataDto {
    id: string
    name: string
    position: IPosition | null
    cameraGroups: {
        id: string
        name: string
    }[]
    eventTypeGroups: {
        id: string
        name: string
        code: string
    }[]
}

interface IMapData extends Omit<IMapDataDto, "position"> {
    position: IPosition
}

async function getMapData(filter: IEventJournalFilterSelection): Promise<IMapData[]> {
    const [startedAt, endedAt] = getStartAndEndDateFromTimeFrame(filter.timeFrame)

    const response = await axios.put<IMapDataDto[]>(
        "/api/event/v1/cameras/event-journal",
        {
            ...getMostSpecificIdsFromFilter(filter),
            ...getFilteredStateIds(filter),
            eventTypeIds: filter.eventTypeIds,
            eventStartedAtGe: startedAt,
            eventEndedAtLe: endedAt
        }
    )

    return response
        .data
        .filter(entry =>
            entry.position !== null
        ) as IMapData[]
}