import React from "react"
import { useTranslation } from "react-i18next"
import { Typography } from "@mui/material"
import DragonPageWrapper from "Components/DragonPageWrapper"

export const AccessDenied = () => {
    const { t } = useTranslation()

    return (
        <DragonPageWrapper>
            <Typography color="error" variant="h3">{t("access-denied")}</Typography>
        </DragonPageWrapper>
    )
}

export default AccessDenied