
import { ISavedTimeFrame } from "Components/Filter/Filters/TimeFrameSelector/ITimeFrame"
import { getFilterSelectionFromLocalStorage, saveFilterSelectionToLocalStorage } from "utils/filterSelection"
import parseSavedTimeFrame from "utils/parseSavedTimeFrame"
import { IAuditingFilterSelection } from "./AuditingFilter"
import { defaultSelection } from "./defaultSelection"

const LOCAL_STORAGE_KEY = `auditing-filter-${process.env.REACT_APP_LOCAL_STORAGE_KEY_SUFFIX}`

export const saveSelectionToLocalStorage = (
    filter: IAuditingFilterSelection
) => saveFilterSelectionToLocalStorage(LOCAL_STORAGE_KEY, filter)

interface ILoadInitalSelection {
    userIds: string[]
}


interface ISavedAuditingFilterSelection extends Omit<IAuditingFilterSelection, "timeframe"> {
    timeframe: ISavedTimeFrame
}

export function loadInitalSelection({
    userIds
}: ILoadInitalSelection): IAuditingFilterSelection {
    const savedFilterSelection = getFilterSelectionFromLocalStorage<ISavedAuditingFilterSelection>(LOCAL_STORAGE_KEY)

    if (savedFilterSelection === null)
        return defaultSelection

    return {
        ...savedFilterSelection,
        timeframe: parseSavedTimeFrame(savedFilterSelection.timeframe),
        userIds: savedFilterSelection.userIds.filter(savedUserId => userIds.includes(savedUserId))
    }
}