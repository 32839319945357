import { FC, useMemo, useState } from "react"
import {Route, Routes, useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { Button, Paper } from "@mui/material"

import { DragonTableWithSearchField, HeaderCell, BodyRow } from "Components/DragonTable"
import UserDetails from "Pages/UserAdministration/UserDetails"
import DragonTitle from "Components/DragonTitle"

import DragonPageWrapper from "Components/DragonPageWrapper"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"

interface IUserAdminListItem {
  id: string
  username: string
  name: string
  enabled: string
}

const getUsers = async ({ pageParam: offset = 0, queryKey }: QueryFunctionContext): Promise<IUserAdminListItem[]> => {
  const [, searchName] = queryKey
  const response = await axios.get<IUserAdminListItem[]>("/api/v1/user-administration/users", {
    params: { limit: 25, offset, name: searchName || undefined }
  })
  return response.data
}

export const UserAdministration: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState<string>("")

  const showDetails = useLocation().pathname.startsWith("/user-administration/") 

  const {
    data, hasNextPage = false, fetchNextPage, isFetching
  } = useInfiniteQuery(["usersAdmin", searchValue], getUsers, {
    getNextPageParam: (lastPage, allPages) => (
      lastPage.length === 0 ? undefined : allPages.reduce((amount, page) => amount + page.length, 0)
    )
  })

  const tableColumns = useMemo<HeaderCell[]>(() => [
    { value: t("users-admin-page.username") },
    { value: t("users-admin-page.name") },
    { value: t("users-admin-page.enabled") }
  ], [t])

  const tableData = useMemo<BodyRow[]>(() => {
    const list = data?.pages.flat() ?? []
    return list.map(({ id, name, username, enabled }) => (
      {
        row: [
          { value: name },
          { value: username },
          { value: enabled && <FontAwesomeIcon icon={faCheckCircle} name={faCheckCircle.iconName} color="primary" /> }
        ],
        onClick: () => navigate(`user/${id}`),
        sx: { cursor: "pointer" },
        hover: true
      }
    ))

  }, [data?.pages, navigate])

  return (
    <>
      <DragonPageWrapper hidden={showDetails}>
        <DragonTitle title={t("users-admin-page.title")} />
        <Paper sx={{ display: "flex", overflow: "hidden" }}>
          <DragonTableWithSearchField
            columns={tableColumns}
            data={tableData}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetching={isFetching}
            noDataText={t("users-admin-page.no-user-found")}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchPlaceholder={t("users-admin-page.search-for-user")}
            toolbarAction={
                <Button
                  variant="outlined"
                  startIcon={<FontAwesomeIcon icon={faPlus} name={faPlus.iconName} />}
                  onClick={() => navigate("new")}
                >
                  {t("users-admin-page.create-user")}
                </Button>
            }
          />
        </Paper>
      </DragonPageWrapper>
      <Routes>
        <Route path="user/:userId" element={<UserDetails />} />
        <Route path="new" element={<UserDetails />} />
      </Routes>
    </>
  )
}

export default UserAdministration
