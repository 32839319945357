import axios from "axios"
import { ITimeFrame } from "Components/Filter/Filters/TimeFrameSelector/ITimeFrame"
import moment from "moment"
import { QueryFunctionContext } from "react-query"
import { IAuditingFilterSelection } from "./AuditingFilter"
import qs from "query-string"
import { assertUnreachable } from "utils/assertUnrechable"
import { IAcknowledgeStateId } from "Types/event/IAcknowledgeStateId"
import { IValidStateId } from "Types/event/IValidStateId"

export type IAuditLogType = "Undefined" | "Identity" | "EventAcknowledge" | "EventValid" | "EventComment" | "EventExport" | "Server"
type IAuditLogAction = "Undefined" | "Create" | "Read" | "Update" | "Delete" | "SignIn" | "SignOut" | "Sync"

interface IAuditLogListDto {
    createdAt: string
    createdName: string
    auditLogTypeId: IAuditLogType
    auditLogActionId: IAuditLogAction
    eventId: string | null
    eventExportId: string | null
    acknowledgeStateId: IAcknowledgeStateId | null
    validStateId: IValidStateId | null
}

export interface IAuditLogListItem extends Omit<IAuditLogListDto, "createdAt"> {
    createdAt: Date
}

async function getAuditLog(filter: IAuditingFilterSelection, limit: number, lastCreatedAt: Date | undefined): Promise<IAuditLogListItem[]> {
    const { from, to } = getDateFromTimeFrame(filter.timeframe)

    const response = await axios.get<IAuditLogListDto[]>("/api/admin/v1/audit-logs", {
        params: {
            limit: limit,
            lastCreatedAt: lastCreatedAt?.toISOString(),
            userId: filter.userIds,
            createdAtFrom: from.toISOString(),
            createdAtTo: to.toISOString(),
            auditLogTypeId: filter.logTypes.length === 0
                ? undefined
                : filter.logTypes
        },
        // axios default param serializer handles multiple of the same parameter as
        // id[]=1&id[]=2&id[]=3
        // The backend wants multiple of the same param to formatted as
        // id=1&id=2&id=3
        paramsSerializer: params => qs.stringify(params, { arrayFormat: "none" })
    })

    return response
        .data
        .map(log => ({
            ...log,
            createdAt: new Date(log.createdAt)
        }))
}

export async function getInfiniteAuditLog({ queryKey, pageParam }: QueryFunctionContext<[string, IAuditingFilterSelection], Date>): Promise<IAuditLogListItem[]> {
    const [_key, filterSelection] = queryKey

    return getAuditLog(
        filterSelection, 
        pageParam ? 20 : 40,
        pageParam
    )
}

export async function getAuditLogExportData(filterSelection: IAuditingFilterSelection): Promise<IAuditLogListItem[]> {
    return getAuditLog(
        filterSelection,
        1_000_000_000,
        undefined
    )
}

function getDateFromTimeFrame(timeframe: ITimeFrame): { from: Date, to: Date } {
    switch (timeframe.type) {
        case "today":
            return {
                from: moment().startOf("day").toDate(),
                to: moment().endOf("day").toDate()
            }
        case "yesterday":
            return {
                from: moment().subtract(1, "day").startOf("day").toDate(),
                to: moment().subtract(1, "day").endOf("day").toDate()
            }
        case "this-week":
            return {
                from: moment().startOf("week").toDate(),
                to: moment().endOf("week").toDate()
            }
        case "custom":
            return {
                from: timeframe.from,
                to: timeframe.to
            }
        default:
            assertUnreachable(timeframe)
    }
}