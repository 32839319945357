import { useState, useEffect } from "react"

/**
 * Calls a function once on mount
 * @param fn a function that will be called once the component is mounted
 */
export default function useOnMount(fn: () => void) {
    const [initalLoad, setInitalLoad] = useState(true)

    useEffect(() => {
        if (!initalLoad)
            return

        setInitalLoad(false)

        fn()
    }, [initalLoad, fn])
}