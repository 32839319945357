import { faChevronDown, faRotateExclamation, faVideo,faPersonDigging} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { styled } from "@mui/material/styles"
import { Box, Card, CardHeader, Collapse, IconButton, IconButtonProps, Stack, Switch, Tooltip, Typography, useTheme } from "@mui/material"
import axios, { AxiosResponse } from "axios"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"
import ICameraGroupHierarchy from "Components/Filter/Filters/CameraSelector/ICameraGroupHierarchy"
import { yellow, teal } from "App/colors"

interface IScenario {
    id: string
    name: string
    description?: string
    isActive :boolean
    statusError: boolean
    levels:{
        cameraId:string
        zoneId?:string
        isActive:boolean
    }[]
}

interface IGroup {
    id: string
    name: string
    scenarios: IScenario[]
}

async function getScenarioGroup() {
    const response = await axios.get<IGroup[]>("/api/scenario/v1/scenario/groups")
    return response.data
}

async function getCameraGroups() {
    const response = await axios.get<ICameraGroupHierarchy[]>("/api/scenario/v1/team/camera-groups")
    return response.data
}

interface IScenarioActivationRequest{
    id:string
    active:boolean
}

const changeScenarioStatus = async (payload:IScenarioActivationRequest): Promise<AxiosResponse> => {
    return await axios.put("/api/scenario/v1/scenario/", payload )
}

const scenarioResync = async (payload:IScenarioActivationRequest): Promise<AxiosResponse> => {
    return await axios.put("/api/scenario/v1/scenario/resync", payload )
}

export default function ScenarioStatus() {
    const { t } = useTranslation()


    const { data: groups, isLoading, isError, isSuccess } = useQuery("user-scenario-status", getScenarioGroup)

    const { data: cameraGroupsHierarchy, isLoading:isLoadingCameras, isError:isErrorCameras} = useQuery("user-camera-groups", getCameraGroups)

    const cameras = useMemo(() => (
        cameraGroupsHierarchy?.flatMap(group => group.cameras).reduce<{[id: string]: string }>((acc, cam) => {
            acc[cam.id] = cam.name
            return acc
        }, {}) ?? {}
    ), [cameraGroupsHierarchy])

    return (
        <DragonPageWrapper scrollable>
            <DragonTitle
                title={t("scenarios")}
            />

            <Stack spacing={3}>
                {(() => {
                    if (isLoading || isLoadingCameras)
                        return <DelayedCircularProgress delay={250} />

                    if (isError || isErrorCameras)
                        return <Typography>{t("scenario-page.failed-to-load")}</Typography>
                    // if (activationError)
                    //     return <Typography>{t("scenario-page.failed-to-activate-scenario")}</Typography>
                    if (isSuccess && groups.length === 0)
                        return <Typography>{t("scenario-page.no-scenario-groups")}</Typography>

                    if (isSuccess && groups.length > 0)
                        return [...groups]
                            .sort((a, b) => {
                                return b.scenarios.filter(c => c.isActive).length - a.scenarios.filter(c => c.isActive).length
                            })
                            .map((group) => <ScenarioGroupCard key={group.id} 
                            group={group}  cameras={cameras}/>)
                })()}
            </Stack>
        </DragonPageWrapper>
    )
}


interface ExpandMoreProps extends IconButtonProps {
    expand: boolean
}
const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand: _, ...other } = props
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
    })
}))



function ScenarioGroupCard({ group,cameras}: { 
    group: IGroup,
    cameras: {[id: string]: string }
}) {
    //const { t } = useTranslation()
    const [expanded, setExpanded] = useState(false)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const inactiveScenarios = group.scenarios.filter(c => !c.isActive && !c.statusError)
    const activeScenarios = group.scenarios.filter(c => c.isActive || c.statusError)

    return (
        <Card>
            <CardHeader
                title={<Typography fontSize="1.3em">{group.name}</Typography>}
                subheader={`${activeScenarios.length} / ${group.scenarios.length}`}
                action={
                    <ExpandMore expand={expanded} onClick={handleExpandClick}>
                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: ".75em" }} />
                    </ExpandMore>
                }
            />

            {!!activeScenarios.length && <Box
                sx={{
                    display: "grid",
                    gap: 2,
                    gridTemplateColumns: "repeat(auto-fill)",
                    p: 2
                }}
            >
                {activeScenarios.map((scenario) => <ScenarioCard scenario={scenario} key={scenario.id} cameras={cameras}/>)}
            </Box>}

            <Collapse in={expanded}>
                {!!inactiveScenarios.length && <Box
                    sx={{
                        display: "grid",
                        gap: 2,
                        gridTemplateColumns: "repeat(auto-fill)",
                        p: 2
                    }}
                >
                    {inactiveScenarios.map((scenario) => <ScenarioCard scenario={scenario} key={scenario.id} cameras={cameras} />)}
                </Box>}
            </Collapse>
        </Card>
    )
}

function ScenarioCard({ scenario, cameras}: { 
    scenario: IScenario
    cameras: {[id: string]: string }
}) {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const [expanded, setExpanded] = useState(false)

    const { mutateAsync: handleScenarioActivation, isLoading: activationLoading } = useMutation(
        changeScenarioStatus,
        { onSuccess: () => { queryClient.invalidateQueries("user-scenario-status") } }
    )

    const { mutateAsync: handleScenarioResync, isLoading: resyncLoading} = useMutation(
        scenarioResync,
        { onSuccess: () => { queryClient.invalidateQueries("user-scenario-status") } }
    )

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const levels = useMemo(() => (
        scenario.levels.reduce<{[id: string]: boolean }>((acc, lvl) => {
            const camName = cameras[lvl.cameraId] ?? "-"
            acc[camName] = (acc[camName] ?? false) || (lvl.isActive !== scenario.isActive)
            return acc
        }, {}) ?? {}
    ), [scenario,cameras])

    return <Box key={scenario.id} sx={{
        padding:2,
        border:1,
        borderColor:"#CCC"
    }}>
        <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            flexGrow={1}
        >
            <Stack>
                <Typography fontSize="1.3em">{scenario.name}</Typography>
                <Typography color={"GrayText"}>{scenario.description}</Typography>
            </Stack>
            <Stack spacing={2} direction={"row"} justifyContent={"right"}>
                 {(() => {
                    if(activationLoading || resyncLoading) return <DelayedCircularProgress delay={250} />
                    else return (<>
                    { scenario.statusError &&
                        <Tooltip title={t("scenario-page.sync-error")} arrow placement="bottom">
                            <IconButton 
                                color="error"
                                size="large"     
                                onClick={event => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    handleScenarioResync({id:scenario.id,active:scenario.isActive})
                                }}
                            >
                                <FontAwesomeIcon icon={faRotateExclamation} style={{ fontSize: ".75em" }} />
                            </IconButton>
                        </Tooltip>}
                <Switch
                    checked={scenario.isActive}
                    onChange={event => {
                        event.preventDefault()
                        event.stopPropagation()
                        handleScenarioActivation({id:scenario.id,active:event.target.checked})
                    }}
                    /></>)
                })()}
                <ExpandMore expand={expanded} onClick={handleExpandClick}>
                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: ".75em" }} />
                </ExpandMore>
                
            </Stack>
        </Stack>
        <Collapse in={expanded}>
        {levels && Object.keys(levels) && <Box
                    sx={{
                        display: "grid",
                        gap: 2,
                        gridTemplateColumns: "repeat(auto-fill, minmax(187px, 1fr))",
                        paddingY:2
                    }}
                >
                    {Object.keys(levels).map((key) => <DeviceCard name={key} error={levels[key]} key={key} />)}
                </Box>}
        </Collapse>
    </Box>
}

function DeviceCard({ name,error }: { name: string, error: boolean }) {
    const {palette} = useTheme()
    return (
        <Box sx={{ bgcolor: palette.background.default, p: "8px 15px", height: "53px" }}
            display={"flex"} 
            flexDirection={"row"} gap={2} 
            alignItems={"center"} justifyContent={"space-between"}>

            <FontAwesomeIcon icon={faVideo} style={{ fontSize: "2em"}} />
            <Typography fontSize="1.1em">{name}</Typography>
            {error &&<FontAwesomeIcon icon={faPersonDigging} style={{ fontSize: "2em", color: yellow[500] }} />}
            {!error &&<FontAwesomeIcon icon={faPersonDigging} style={{ fontSize: "2em", color: teal[500] }} />}

        </Box>
    )
}