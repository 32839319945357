import { ITraceFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import { HeatType } from "./VehicleTracingCanvas"

export const defaultSelection: ITraceFilterSelection = {
    cameraGroupIds: [],
    cameraIds: [],
    zoneIds: [],
    vehicleClassIds: [],
    resolution: "1h",
    heatmapType: HeatType.Lines,
    traceTypeIds:["VehicleCount"],
    timeFrame: { type: "today" }
}