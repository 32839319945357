import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Button } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"

export const GoBackButton: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <Button
            onClick={() => navigate("..")}
            sx={{ width: "fit-content", mb: 2 }}
            startIcon={<FontAwesomeIcon icon={faArrowLeft} name={faArrowLeft.iconName} />}
        >
            {t("back")}
        </Button>
    )
}

export default GoBackButton