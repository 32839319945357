export function getFilterSelectionFromLocalStorage<T>(key: string): T | null {
    const filterString = localStorage.getItem(key)

    if (!filterString)
        return null

    return JSON.parse(filterString)
}

export function saveFilterSelectionToLocalStorage<T>(key: string, filterSelection: T) {
    localStorage.setItem(key, JSON.stringify(filterSelection))
}