import { Divider, IconButton, Paper, Stack} from "@mui/material"
import { useTranslation } from "react-i18next"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"
import CameraStatus from "./CameraStatus"
import ServerStatus from "./ServerStatus"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRedo } from "@fortawesome/pro-regular-svg-icons"
import { useQueryClient } from "react-query"


export default function DeviceStatus() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const refetchAll = ()=>{
        queryClient.refetchQueries("device-status")
        queryClient.refetchQueries("server-status")
    }

    return (
        <DragonPageWrapper>
            <DragonTitle
                title={t("device-status")}
                action={<IconButton onClick={refetchAll}>
                    <FontAwesomeIcon icon={faRedo} />
                </IconButton>}
            />
            <Paper sx={{
                display: "flex",
                overflow: "hidden",
                height: 1,
                width: 1
            }} >
                <Stack direction={{ xs: "column", md: "row" }} spacing={3} p={3} width={1} height={1} divider={<Divider orientation="vertical" flexItem />}>
                    <CameraStatus />
                    <ServerStatus />
                </Stack>

            </Paper>

        </DragonPageWrapper>
    )
}



