import IWidgetSelection from "./types/IWidgetSelection"
import NameInput from "./filters/NameInput"

interface INameAndPreview {
    selection: IWidgetSelection
    setSelection: React.Dispatch<React.SetStateAction<IWidgetSelection>>,
}

export default function NameAndPreview({ selection, setSelection }: INameAndPreview) {
    return (
        <NameInput
            selectedValue={selection.name}
            onChange={newName =>
                setSelection({ ...selection, name: newName })
            }
        />
    )
}