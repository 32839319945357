import { getFilterSelectionFromLocalStorage, saveFilterSelectionToLocalStorage } from "utils/filterSelection"
import parseSavedTimeFrame from "utils/parseSavedTimeFrame"
import { validateSelectedCameraHierarchy } from "utils/validateSelectedCameraHierarchy"
import { ISavedTraceFilterSelection, ITraceFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import { defaultSelection } from "./defaultSelection"

const LOCAL_STORAGE_KEY = `analytics-filter-trace-${process.env.REACT_APP_LOCAL_STORAGE_KEY_SUFFIX}`

export const saveSelectionToLocalStorage = (
    filter: ITraceFilterSelection
) => saveFilterSelectionToLocalStorage(LOCAL_STORAGE_KEY, filter)

interface ILoadInitalSelection {
    vehicleClassIds: string[]
    defaultVehicleClassIds: string[]
    valid: {
        cameraGroupIds: string[]
        cameraIds: string[]
        zoneIds: string[]
    }
}

export function loadInitalSelection({
    vehicleClassIds,
    defaultVehicleClassIds,
    valid
}: ILoadInitalSelection): ITraceFilterSelection {
    const savedFilterSelection = getFilterSelectionFromLocalStorage<ISavedTraceFilterSelection>(LOCAL_STORAGE_KEY)

    if (savedFilterSelection === null)
        return {
            ...defaultSelection,
            vehicleClassIds: defaultVehicleClassIds
        }

    return {
        ...savedFilterSelection,
        timeFrame: parseSavedTimeFrame(savedFilterSelection.timeFrame),
        vehicleClassIds: savedFilterSelection.vehicleClassIds.filter(id => vehicleClassIds.includes(id)),
        ...validateSelectedCameraHierarchy({
            valid,
            selected: savedFilterSelection
        })
    }
}
