import {DateTimePicker} from "@mui/x-date-pickers"
import {LocalizationProvider} from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { useState } from "react"
import { Box, Button, Card, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { formatDate, formatTime, SecondsDisplay } from "utils/timedisplay"
import moment, { Moment } from "moment"

interface ICustomTimeFrameSelector {
    from: Date | undefined
    to: Date | undefined
    onUpdateTimeFrame: (from: Date, to: Date) => void
}

// Returns current time rounded down to the minute
function getNow() {
    return moment().startOf("minute")
}


const MIN_DATE = moment(new Date("2021-01-01 00:00:00"))

function defaultFrom() {
    return getNow().subtract(5, "day")
}

function defaultTo() {
    return getNow()
}

export default function CustomTimeFrameSelector({
    from: initalFrom,
    to: initalTo,
    onUpdateTimeFrame
}: ICustomTimeFrameSelector) {
    const { t } = useTranslation()
    const [from, setFrom] = useState<Moment | undefined>(initalFrom ? moment(initalFrom) : defaultFrom())
    const [to, setTo] = useState<Moment | undefined>(initalTo ? moment(initalTo) : defaultTo())

    const rightArrow = "→"

    function resetTimeFrame() {
        setFrom(defaultFrom())
        setTo(defaultTo())
    }

    return <LocalizationProvider dateAdapter={AdapterMoment}>
        <Card
            sx={{
                p: 2
            }}
        >
            <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                    <Box sx={{ position: "relative" }}>
                        <Typography>{t("event-filter.time-from")}</Typography>
                        <CustomDateTimeSelector
                            value={from}
                            onChange={newValue => setFrom(newValue)}
                            minDateTime={MIN_DATE}
                            maxDateTime={to}
                        />
                    </Box>
                    <Box sx={{ position: "relative" }}>
                        <Typography>
                            {t("event-filter.time-to")}
                        </Typography>
                        <CustomDateTimeSelector
                            value={to}
                            onChange={newValue => setTo(newValue)}
                            minDateTime={from}
                            maxDateTime={getNow()}
                        />
                    </Box>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                    {(from?.toDate && to?.toDate()) &&
                        <Typography>
                            {formatDate(from.toDate())} {formatTime(from.toDate(), SecondsDisplay.HIDE_SECONDS)} {rightArrow} {formatDate(to.toDate())} {formatTime(to.toDate(), SecondsDisplay.HIDE_SECONDS)}
                        </Typography>
                    }
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => resetTimeFrame()}
                    >{t("event-filter.time-frame.clear")}</Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={!from || !to}
                        onClick={() => {
                            if (!from || !to)
                                throw new Error("From and to have to be set")

                            onUpdateTimeFrame(from.toDate(), to.toDate())
                        }}
                    >{t("event-filter.time-frame.done")}</Button>
                </Stack>
            </Stack>
        </Card>
    </LocalizationProvider>
}

interface ICustomDateTimeSelector {
    value: moment.Moment | undefined,//ParseableDate<moment.Moment | null>
    onChange: (date: moment.Moment | undefined) => void
    minDateTime?: moment.Moment 
    maxDateTime?: moment.Moment
}

function CustomDateTimeSelector({
    value,
    onChange,
    minDateTime,
    maxDateTime
}: ICustomDateTimeSelector) {
    return <DateTimePicker
        value={value}
        onChange={(newValue: moment.Moment | null |undefined) => onChange(newValue ?? undefined)}
        minDateTime={minDateTime}
        maxDateTime={maxDateTime}

        ampm={false}
        ampmInClock={false}
        orientation="portrait"
        slots={{
            actionBar:()=>null,
            toolbar:()=>null
        }}
        disableFuture={true}
        openTo="day"
        views={["day","month","year","hours","minutes"]}
        format={"DD/MM/YYYY HH:mm"}
    />
}