import { faMoon, faSun } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Switch, Typography, FormControlLabel } from "@mui/material"
import { Box } from "@mui/system"
import { useUserSettings } from "App/UserSettingsProvider"
import LanguageSelector from "Components/LanguageSelector"
import { Theme, useThemeContext } from "Contexts/ThemeContext"
import { useUserProfile } from "hooks/useUserProfile"
import { useAuth } from "oidc-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import getMaxTwoInitialsFromName from "utils/getMaxTwoInitalsFromName"

function CurrentUserAvatar() {
    const profile = useUserProfile()
    const initials = getMaxTwoInitialsFromName(profile.name)

    return <Avatar>
        <span style={{ marginTop: 3 }}>{initials}</span>
    </Avatar>
}

export default function AvatarWithQuickSettings() {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const isOpen = !!anchorEl

    return <>
        <IconButton
            onClick={event => setAnchorEl(event.currentTarget)}
        >
            <CurrentUserAvatar />
        </IconButton>
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            data-testid="quick-settings"
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
        >
            <QuickSettingsContent />
        </Popover>
    </>
}

export function QuickSettingsContent() {
    const auth = useAuth()
    const profile = useUserProfile()
    const { t } = useTranslation()
    const { mode } = useThemeContext()
    const {setTheme,setLanguage} = useUserSettings()

    return <Box sx={{ width: 300, padding: "5px" }}>
        <List disablePadding>
            <ListItemButton
                component={Link}
                to="/account"
            >
                <ListItemIcon>
                    <CurrentUserAvatar />
                </ListItemIcon>
                <Typography>
                    {profile.name}
                </Typography>
            </ListItemButton>
            <ListItem>
                <ListItemText>
                    {mode === Theme.dark && t("theme.dark")}
                    {mode === Theme.light && t("theme.light")}
                </ListItemText>
                <FormControlLabel
                    control={
                        <Switch
                            checked={mode === Theme.dark}
                            onChange={event => {
                                const checked = event.target.checked

                                if (checked)
                                    setTheme(Theme.dark)
                                else
                                    setTheme(Theme.light)
                            }}
                            inputProps={{ role: "switch" }}
                        />
                    }
                    labelPlacement="start"
                    label={
                        <ListItemIcon sx={{ marginTop: 1 }}>
                            {mode === Theme.dark && <FontAwesomeIcon icon={faMoon} />}
                            {mode === Theme.light && <FontAwesomeIcon icon={faSun} />}
                        </ListItemIcon>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemText primary={t("language")} />
                <LanguageSelector onChange={(event) => setLanguage(event.target.value)} />
            </ListItem>
            <ListItemButton
                onClick={() =>auth.signOutRedirect()}
            >
                <ListItemText primary={t("sign-out")} />
            </ListItemButton>
        </List>
    </Box>
}