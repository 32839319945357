import { Box, Select, MenuItem, SelectChangeEvent } from "@mui/material"
import FilterControlLabel from "Components/Filter/FilterControlLabel"
import { HeatType } from "Pages/DataAnalytics/TraceTab/VehicleTracingCanvas"
import { useTranslation } from "react-i18next"

interface ITraceTypeSelector {
    selectedValue: HeatType
    onChange: (value:HeatType) => void
}

export default function TraceTypeSelector({
    selectedValue,
    onChange
}: ITraceTypeSelector) {
    const { t } = useTranslation()

    function handleChange(event: SelectChangeEvent<HeatType>) {
        const heatType = event.target.value as HeatType
        onChange(heatType)
    }
    return <Box>
        <FilterControlLabel text={t("trace-type.type-selection")} />
        <Select
            value={selectedValue}
            onChange={handleChange}
            variant="filled"
            disableUnderline
            MenuProps={{
                sx: {
                    ".MuiMenu-list": {
                        padding: 0
                    }
                }
            }}
        >
        <MenuItem key={"heat-type-line"} value={HeatType.Lines}>{t("trace-type.type-line")}</MenuItem>
        <MenuItem key={"heat-type-dot"} value={HeatType.Dot}>{t("trace-type.type-dot")}</MenuItem>
        </Select>
    </Box>
}