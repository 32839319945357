import { useTheme } from "@mui/material"
import { colorsList } from "App/colors"
import FileSaver from "file-saver"
import moment from "moment"
import { useCallback, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    CartesianGrid,
    ReferenceArea,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts"
import {unparse} from "papaparse"
import { useCurrentPng } from "recharts-to-png"
import { IResolution } from "Types/IResolution"
import { formatDate, formatTime, SecondsDisplay } from "utils/timedisplay"
import { IDragonChartCardActions } from "./DragonChartCardActions"

const DISABLED_OPACITY = 0.1
const HOVER_DEBOUNCE_MS = 100

export interface ISeries {
    [serie: string]: number | string | null
}
export interface IChartData extends ISeries {
    timestamp: number
}
export interface IChartZoomArea {
    leftLimit: string
    rightLimit:string
}
export interface IDetailedLineChart {
    data: (IChartData | undefined)[]
    resolution: IResolution
    syncID?:string
    onZoomChange: (zoom: IChartZoomArea)=>void
    onZoomReset: ()=>void
    zoom:IChartZoomArea
    tooltipFormatter: (value: number, series: string) => ([value: number, label: string])
    legendFormatter: (series: string) => string
    colorSelector?:(serie:string)=>string
    csvFormatter?:(name:string)=>Record<string, unknown>[]
    cardActionsRef?: React.Dispatch<React.SetStateAction<IDragonChartCardActions | null>>
}
function timeFormatter(timestamp: Date, resolution: IResolution, language: string) {
    switch (resolution) {
        case "y":
            return timestamp.getFullYear().toString()
        case "M":
            return timestamp.toLocaleString(language, { month: "short", year: "numeric" })
        case "w":
        case "d":
            return formatDate(timestamp)
        default:
            return `${formatDate(new Date(timestamp))} ${formatTime(new Date(timestamp), SecondsDisplay.HIDE_SECONDS)}`
    }
}

export default function DetailedLineChart({
    data,
    resolution,
    onZoomChange,
    onZoomReset,
    zoom,
    legendFormatter,
    tooltipFormatter,
    syncID = undefined,
    colorSelector = undefined,
    csvFormatter=undefined,
    cardActionsRef = undefined
}: IDetailedLineChart){
    const { i18n } = useTranslation()
    const theme = useTheme()
    const [hiddenDataPoints, setHiddenDataPoints] = useState(
        Object.keys(data[0] ?? {}).reduce<{ [serie: string]: boolean }>((acc, entry) => {
            acc[entry] = false
            return acc
        }, {})
    )

    const [hover, setHover] = useState<string | null>(null)
    const [leftZoomArea,setLeftZoomArea] = useState<string | null>(zoom.leftLimit)
    const [rightZoomArea,setRightZoomArea] = useState<string | null>(zoom.rightLimit)

    let timeout: NodeJS.Timeout | null = null

    const handleLegendMouseEnter = (e: { dataKey: string }) => {
        if (!hiddenDataPoints[e.dataKey])
            timeout = setTimeout(
                () => setHover(e.dataKey),
                HOVER_DEBOUNCE_MS
            )
    }
    const handleLegendMouseLeave = () => {
        if (timeout)
            clearInterval(timeout)
        setHover(null)
    }

    const handleZoomRequest = ()=>{
        if(leftZoomArea && rightZoomArea){

            const zoom = ([leftZoomArea,rightZoomArea]).sort()
            onZoomChange({leftLimit:zoom[0],rightLimit:zoom[1]})
        }
        setLeftZoomArea(null)
        setRightZoomArea(null)
    }

    const handleMouseAreaLeave = ()=>{
        if(leftZoomArea && !rightZoomArea){
            setLeftZoomArea(null)
            setRightZoomArea(null)
        }
    }
    //PNG
    const [getPng, pngProp] = useCurrentPng()
    const handlePNGDownload = useCallback(async (name:string) => {
        const png = await getPng()
        if (png) {
            FileSaver.saveAs(png, `${name}-${Date.now()}.png`)
        }
    }, [getPng])
    
    const handleCSVDownload = useCallback(async (name:string)=>{
        if(!!csvFormatter){
            const blob = new Blob([unparse(csvFormatter(name),{quotes:true})], {type : "text/csv"})
            FileSaver.saveAs(blob, `${name}-${Date.now()}.csv`)
        }

    },[csvFormatter])

    const actionsRef = useRef<IDragonChartCardActions>({
        ResetZoom:onZoomReset,
        DownloadPNG:handlePNGDownload,
        DownloadCSV: !!csvFormatter ? handleCSVDownload : undefined
    })

    const setCardAction = useCallback(() => {
        if(cardActionsRef && actionsRef?.current){
            cardActionsRef(actionsRef?.current)
        }
    },[cardActionsRef,actionsRef])

    const selectColor = (index:number,serie:string):string=>{
        if(colorSelector !== undefined){
            return colorSelector(serie)
        }else{
            return colorsList[index % colorsList.length]
        }
    }

    const digitsOfHighestValue = data.reduce<number>((prev, current) => {
        const numbers = Object.values(current ?? {}).filter(Number)
        const highest = Math.max(...(numbers.map(el => typeof el === "number" ? el.toString().length : 0)))
        return highest > prev ? highest : prev
    }, 0)
    return (
        <ResponsiveContainer debounce={300} ref={setCardAction}>
            <LineChart
                ref={pngProp.ref}
                data={data}
                syncId={syncID}
                margin={{
                    left: digitsOfHighestValue * 3
                }}
                onMouseDown={(nextState, event: MouseEvent)=>{
                    if(event.button){
                        event.preventDefault()
                        onZoomReset()
                    }else{
                        if(nextState?.activeLabel) setLeftZoomArea(nextState.activeLabel)
                    }
                }}
                onMouseMove={(nextState)=>{ 
                    if(leftZoomArea && nextState?.activeLabel) setRightZoomArea(nextState.activeLabel)
                }}
                onMouseUp={handleZoomRequest}
                onMouseLeave={handleMouseAreaLeave}
            >
                <XAxis
                    dataKey="timestamp"
                    type="number"
                    allowDataOverflow={true}
                    domain={[zoom.leftLimit,zoom.rightLimit]}
                    tickFormatter={(timestamp) => timeFormatter(new Date(timestamp), resolution, i18n.language)}
                    interval="preserveStartEnd"
                />
                <YAxis />
                <Tooltip
                    formatter={tooltipFormatter}
                    contentStyle={{
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: theme.shape.borderRadius,
                        boxShadow: theme.shadows[1],
                        border: "none",
                        maxHeight: "300px",
                        width: "100%",
                        overflowX: "hidden",
                        overflowY: "auto",
                        pointerEvents: "none"
                    }}
                    itemStyle={{
                        lineHeight: .75
                    }}
                    labelFormatter={(timestamp:number) => timeFormatter(moment(timestamp).toDate(), resolution, i18n.language)}
                    isAnimationActive={false}
                    allowEscapeViewBox={{ x: true }}
                />
                <Legend
                    layout="vertical"
                    align="right"
                    wrapperStyle={{
                        overflow: "auto",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        paddingLeft: theme.spacing(2)
                    }}
                    formatter={(serie: string) => {
                        return (
                            <span
                                key={serie}
                                style={{
                                    color: theme.palette.text.primary,
                                    opacity: hiddenDataPoints[serie] ? DISABLED_OPACITY : 1,
                                    textDecoration: hiddenDataPoints[serie] ? "line-through" : "none"
                                }}
                            >
                                {legendFormatter(serie)}
                            </span>
                        )
                    }}
                    onClick={data => setHiddenDataPoints({
                        ...hiddenDataPoints,
                        [data.value]: !hiddenDataPoints[data.value]
                    })}
                    onMouseOver={handleLegendMouseEnter}
                    onMouseOut={handleLegendMouseLeave}
                />
                <CartesianGrid strokeDasharray="3 3" />
                {data.length && Object.keys(data[0] ?? {})
                    .filter(e => e !== "timestamp")
                    .sort((a, b) => a.localeCompare(b))
                    .map((entry, index) => (
                        <Line
                            connectNulls={false}
                            dot={hover === entry}
                            key={entry}
                            dataKey={entry}
                            stroke={selectColor(index,entry)}
                            strokeWidth={hover !== entry || !hover ? 2 : 5}
                            hide={hiddenDataPoints[entry]}
                            isAnimationActive={false}
                            opacity={hover === entry || !hover ? 1 : DISABLED_OPACITY}
                        />
                    ))
                }
                {leftZoomArea && rightZoomArea ? (
                    <ReferenceArea x1={leftZoomArea} x2={rightZoomArea} strokeOpacity={0.5} />
                ) : null}
            </LineChart>
        </ResponsiveContainer>
    )
}