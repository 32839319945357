import axios from "axios"
import { ITrafficFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import {
    ITrafficDataVehicleCount,
    ITrafficData
} from "../ITrafficData"
import getMostSpecificIdsFromFilter from "Pages/EventJournal/utils/getMostSpecificIdsFromFilter"
import convertTimeFrameToTimespanFilter from "../utils/convertTimeFrameToTimespanFilter"

interface ITrafficDataRequestBase {
    cameraGroupIds?: string[]
    cameraIds?: string[]
    zoneIds?: string[]
    vehicleClassIds?: string[]
    timeZone: string
    timespanGroupBy: string
}

interface ITrafficDataRequestCustom extends ITrafficDataRequestBase {
    timespanFilterTypeId: "Custom"
    timespanFilterTypeCustomFromGe: Date
    timespanFilterTypeCustomToLe: Date
}

interface ITrafficDataRequestRelative extends ITrafficDataRequestBase {
    timespanFilterTypeId: "Relative"
    timespanFilterTypeRelativeFromGe: string
    timespanFilterTypeRelativeToLe: string
}

type ITrafficDataRequest = ITrafficDataRequestCustom | ITrafficDataRequestRelative

const filterFromSelection = (selection: ITrafficFilterSelection): ITrafficDataRequest => ({
    ...getMostSpecificIdsFromFilter(selection),
    vehicleClassIds: selection.vehicleClassIds,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timespanGroupBy: selection.resolution,
    ...convertTimeFrameToTimespanFilter(selection.timeFrame)
})


export async function getVehicleCount(selection: ITrafficFilterSelection): Promise<ITrafficData[]> {
    const filter = filterFromSelection(selection)

    const response = await axios.put<ITrafficDataVehicleCount[]>("/api/event/v1/data/vehicle-count", filter)
    return response
        .data
        .map(({ count, ...o }) => ({ ...o, value: count }))
}
