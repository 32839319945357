import axios from "axios"
import { useMutation, useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"

import {
    Box,
    Divider,
    Button,
    Chip,
    Stack,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material"

import { DragonToggleButton, DragonToggleButtonGroup } from "Components/DragonToggleGroup"

import { IEvent } from "./EventTypes"
import { IDataWithEtag, useQueryHelpers } from "utils/queryHelpers"
import { formatDate, formatTime } from "utils/timedisplay"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookmark, faCalendarAlt, faClock, faDownload, faThumbsDown, faThumbsUp } from "@fortawesome/pro-regular-svg-icons"
import { faBookmark as faBookmarkSolid, faThumbsUp as faThumbsUpSolid, faThumbsDown as faThumbsDownSolid } from "@fortawesome/pro-solid-svg-icons"
import Icon from "Components/Icon"
import EventTypeChip from "Components/EventTypeChip"
import { useUserProfile } from "hooks/useUserProfile"
import { assertUnreachable } from "utils/assertUnrechable"
import EventClassAndColor from "Components/EventClassAndColor"

export default function EventTab({ eventData }: { eventData: IEvent }) {
    const { t } = useTranslation()
    const { updateEtag } = useQueryHelpers()

    const { permissions } = useUserProfile()

    const eventCanBeMarked = !eventData.isSimulated && !eventData.isFiltered

    const snapshotUri = eventData.eventAssets.find(asset => asset.assetTypeId === "Snapshot")?.uri
    const videoUri = eventData.eventAssets.find(asset => asset.assetTypeId === "Video")?.uri

    const queryClient = useQueryClient()
    const { mutate: setValidState, isLoading: settingValidState } = useMutation((newValidState: "Valid" | "NotValid" | "Undefined") => {
        const data = queryClient.getQueryData<IDataWithEtag<IEvent>>(["event", eventData.id])

        if (!data?.eTag)
            throw new Error("Data was missing etag")

        return axios.put(`/api/event/v1/events/${eventData.id}/valid`, { validStateId: newValidState }, { headers: { "If-Match": data.eTag } })
    }, {
        onSuccess: ({ headers }, newValidState) => {
            updateEtag(headers.etag, "event", eventData.id)
            queryClient.setQueryData<IDataWithEtag<IEvent> | undefined>(
                ["event", eventData.id],
                (data: IDataWithEtag<IEvent> | undefined) => {
                    const markedAs = (() => {
                        switch (newValidState) {
                            case "Valid":
                                return "true"
                            case "NotValid":
                                return "false"
                            case "Undefined":
                                return null
                            default:
                                assertUnreachable(newValidState)
                        }
                    })()

                    if (data)
                        data.data.markedAs = markedAs

                    return data
                }
            )
        }
    })

    const { mutate: bookmarkEvent, isLoading: isLoadingBookmarkPost } = useMutation(async (eventId: string): Promise<string> => {
        const response = await axios.post("/api/event/v1/event-bookmarks", { eventId })

        return response.data?.id
    }, {
        onSuccess: (bookmarkId: string) => {
            queryClient.setQueryData<IDataWithEtag<IEvent>>(["event", eventData.id], (previousEventData) => {
                if (!previousEventData) throw new Error("Couldn't find previous event data")
                return {
                    ...previousEventData,
                    data: {
                        ...previousEventData.data,
                        bookmarked: true,
                        eventBookmarkId: bookmarkId
                    }
                }
            })
        }
    })

    const { mutate: removeBookmark, isLoading: isLoadingBookmarkDelete } = useMutation(async (bookmarkId: string) => {
        axios.delete(`/api/event/v1/event-bookmarks/${bookmarkId}`)
    }, {
        onSuccess: () => {
            queryClient.setQueryData<IDataWithEtag<IEvent>>(["event", eventData.id], (previousEventData) => {
                if (!previousEventData) throw new Error("Couldn't find previous event data")
                return {
                    ...previousEventData,
                    data: {
                        ...previousEventData.data,
                        bookmarked: false,
                        eventBookmarkId: null
                    }
                }
            })
        }
    })

    return (
        <Box sx={{ overflow: "auto", p: "21px 23px" }}>
            <Stack direction="row" gap="30px" justifyContent="center" sx={{ bgcolor: (videoUri && snapshotUri) ? "unset" : "black" }}>
                {videoUri && <video controls controlsList="nodownload" src={videoUri} style={{ aspectRatio: "16 / 9", width: "50%" }} />}
                {snapshotUri && <img src={snapshotUri} style={{ aspectRatio: "16 / 9", width: "50%", pointerEvents: "none" }} alt="snapshot" />}
            </Stack>
            <Stack direction={{md: "column", lg:"row"}} gap="30px" justifyContent={"space-between"} maxWidth={1}>
                <Stack direction="row" gap="48px">
                    <List>
                        <ListItem disablePadding>
                            <ListItemText primary={t("event-details.start")} sx={{ textTransform: "uppercase", fontWeight: "bold" }} disableTypography />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </ListItemIcon>
                            <ListItemText primary={formatDate(new Date(eventData.startedAt))} />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                                <FontAwesomeIcon icon={faClock} />
                            </ListItemIcon>
                            <ListItemText primary={formatTime(new Date(eventData.startedAt))} />
                        </ListItem>
                    </List>

                    <List>
                        <ListItem disablePadding>
                            <ListItemText primary={t("event-details.stop")} sx={{ textTransform: "uppercase", fontWeight: "bold" }} disableTypography />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </ListItemIcon>
                            <ListItemText primary={eventData.endedAt ? formatDate(new Date(eventData.endedAt)) : "-"} />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                                <FontAwesomeIcon icon={faClock} />
                            </ListItemIcon>
                            <ListItemText primary={eventData.endedAt ? formatTime(new Date(eventData.endedAt)) : "-"} />
                        </ListItem>
                    </List>
                    <List>
                        <ListItem disablePadding>
                            <ListItemText primary={t("event-details.event")} sx={{ textTransform: "uppercase", fontWeight: "bold" }} disableTypography />
                        </ListItem>
                        <ListItem disablePadding>
                            <EventTypeChip height="40px" eventTypeGroup={eventData.eventTypeGroup} eventTypeId={eventData.eventTypeId} />
                        </ListItem>
                    </List>
                    {(eventData.objectClassId || eventData.color) && <List>
                        <ListItem disablePadding>
                            <ListItemText primary={t("event-details.class-color")} sx={{ textTransform: "uppercase", fontWeight: "bold" }} disableTypography />
                        </ListItem>
                        <ListItem disablePadding>
                            <EventClassAndColor objectClassId={eventData.objectClassId} color={eventData.color} />
                        </ListItem>
                    </List>}
                    <List>
                        <ListItem disablePadding>
                            <ListItemText primary={t("event-details.available-until")} sx={{ textTransform: "uppercase", fontWeight: "bold" }} disableTypography />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </ListItemIcon>
                            <ListItemText primary={formatDate(new Date(eventData.timeToLiveAt))} />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ minWidth: "unset", mr: 1 }}>
                                <FontAwesomeIcon icon={faClock} />
                            </ListItemIcon>
                            <ListItemText primary={formatTime(new Date(eventData.timeToLiveAt))} />
                        </ListItem>
                    </List>
                </Stack>

                <Stack direction="row" gap="10px" height="48px" my="17px">
                    <Button variant="outlined" disabled sx={{ p: "10px 13px", minWidth: "unset" }}>
                        <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <DragonToggleButtonGroup>
                        <DragonToggleButton
                            value="bookmarked"
                            onClick={() => (eventData.bookmarked && eventData.eventBookmarkId)
                                ? removeBookmark(eventData.eventBookmarkId)
                                : bookmarkEvent(eventData.id)
                            }
                            sx={{ p: "10px 13px", minWidth: "unset" }}
                            selected={eventData.bookmarked}
                            disabled={isLoadingBookmarkPost || isLoadingBookmarkDelete}
                        >
                            <Icon
                                isSelected={eventData.bookmarked}
                                icon={faBookmark}
                                selectedIcon={faBookmarkSolid}
                            />
                        </DragonToggleButton>
                    </DragonToggleButtonGroup>

                    {permissions.canMarkEventAsTrueOrFalse && eventCanBeMarked &&
                        <DragonToggleButtonGroup
                            value={eventData.markedAs}
                            exclusive
                            onChange={(_, newValidState: IEvent["markedAs"]) => {
                                const value = (() => {
                                    switch (newValidState) {
                                        case "true":
                                            return "Valid"
                                        case "false":
                                            return "NotValid"
                                        case null:
                                            return "Undefined"
                                        default:
                                            assertUnreachable(newValidState)
                                    }
                                })()

                                setValidState(value)
                            }}
                        >
                            <DragonToggleButton value="true" disabled={settingValidState}>
                                <Icon
                                    isSelected={eventData.markedAs === "true"}
                                    icon={faThumbsUp}
                                    selectedIcon={faThumbsUpSolid}
                                    style={{ margin: "0 10px 0 5px" }}
                                />
                                {t("event-details.true")}
                            </DragonToggleButton>
                            <DragonToggleButton value="false" disabled={settingValidState}>
                                <Icon
                                    isSelected={eventData.markedAs === "false"}
                                    icon={faThumbsDown}
                                    selectedIcon={faThumbsDownSolid}
                                    style={{ margin: "0 10px 0 5px" }}
                                />
                                {t("event-details.false")}
                            </DragonToggleButton>
                        </DragonToggleButtonGroup>
                    }

                    {!permissions.canMarkEventAsTrueOrFalse && eventCanBeMarked &&
                        <Button variant="outlined" disabled>
                            {(() => {
                                switch (eventData.markedAs) {
                                    case "true":
                                        return <>
                                            <FontAwesomeIcon icon={faThumbsUpSolid} style={{ margin: "0 10px 0 5px" }} />
                                            {t("event-filter.marked-as-true")}
                                        </>
                                    case "false":
                                        return <>
                                            <FontAwesomeIcon icon={faThumbsDownSolid} style={{ margin: "0 10px 0 5px" }} />
                                            {t("event-filter.marked-as-false")}
                                        </>
                                    case null:
                                        return t("event-filter.unmarked")
                                    default:
                                        assertUnreachable(eventData.markedAs)
                                }
                            })()}
                        </Button>
                    }

                    {!eventCanBeMarked &&
                        <Button variant="outlined" disabled>
                            {eventData.isSimulated && <Typography>{t("event-details.simulated")}</Typography>}
                            {eventData.isFiltered && <Typography>{t("event-details.filtered")}</Typography>}
                        </Button>
                    }

                </Stack>

            </Stack>
            <Divider flexItem />
            <Typography fontWeight="bold" sx={{ textTransform: "uppercase", my: "10px" }}>{t("event-details.camera-groups")}</Typography>
            {eventData.cameraGroups &&
                <Stack
                    direction="row"
                    spacing={1}
                >
                    {eventData
                        .cameraGroups
                        .map(cameraGroup =>
                            <Chip
                                key={cameraGroup.id}
                                label={cameraGroup.name}
                                color="secondary"
                            />
                        )}
                </Stack>
            }
        </Box>
    )
}