import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material"
import { DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import axios, { AxiosResponse } from "axios"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { QueryFunctionContext, useInfiniteQuery, useMutation, useQueryClient } from "react-query"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { formatDate, formatTime } from "utils/timedisplay"

export interface IExportItem {
    id: string
    exportAsId: "EventExportFormatIdHtml" | "EventExportFormatIdCsv"
    exportStateId: "Undefined" | "Waiting" | "Processing" | "Succeeded" | "Failed"
    createdAt: string
    uris: string[]
    timeToLiveAt?: string
    etag: string
}

async function getExports({ pageParam: lastCreatedAt }: QueryFunctionContext) {
    const limit = 48
    const response = await axios.get<IExportItem[]>("/api/event/v1/event-exports", {
        params: {
            limit,
            lastCreatedAt
        }
    })
    return response.data
}
const deleteExport = async ({id, etag}:IExportItem): Promise<AxiosResponse> => {
    return await axios.delete(`/api/event/v1/event-exports/${id}`, { headers: { "If-Match": `"${etag}"` } })
}

export default function ExportsTab() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
    const [selectedExport, setSelectedExport] = useState<IExportItem|null>(null)
    const {
        data: exportItems,
        isLoading,
        isError,
        isFetching,
        isFetchingNextPage,
        isSuccess,
        fetchNextPage,
        hasNextPage
    } = useInfiniteQuery("exports", getExports,
        {
            getNextPageParam: (lastPage, _) => {
                if (lastPage.length === 0)
                    return undefined

                return lastPage[lastPage.length - 1].createdAt
            }
        }
    )
    const { mutateAsync: handleDeleteAsync, isLoading: isDeleteLoading } = useMutation((data:IExportItem) => deleteExport(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("exports")
            }
        }
    )

    const allExportItems = useMemo(() => exportItems?.pages.flat() ?? [], [exportItems])

    function handleScroll({ currentTarget }: React.UIEvent<HTMLDivElement>) {
        if (isFetchingNextPage)
            return

        const bottom = currentTarget.scrollHeight
        const scrollY = currentTarget.scrollTop + currentTarget.clientHeight

        const isScrollEnd = bottom - scrollY <= (currentTarget.clientHeight * 2)

        if (isScrollEnd && hasNextPage && !isFetching)
            fetchNextPage()
    }

    const listRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!listRef?.current)
            return

        const list = listRef.current
        const myObserver = new ResizeObserver(entries => entries.forEach(({ target }) => {
            if (
                hasNextPage
                && !isFetching
                && target.clientHeight >= target.scrollHeight
            )
                fetchNextPage()
        }))
        myObserver.observe(list)
        return () => {
            myObserver.unobserve(list)
        }
    }, [fetchNextPage, hasNextPage, isFetching])

    function download(urls: string[]) {
        urls.forEach(url => {
            const a = document.createElement("a")
            a.setAttribute("href", url)
            a.setAttribute("download", "")
            a.setAttribute("target", "_blank")
            a.click()
        })
    }

    return (
        <>
        <Box sx={{ overflow: "auto" }} onScroll={handleScroll} ref={listRef}>
            <Box
                sx={{
                    display: "grid",
                    gap: 2,
                    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                    p: 2
                }}
            >
                {(() => {
                    if (isLoading || isDeleteLoading)
                        return <DelayedCircularProgress delay={250} />

                    if (isError)
                        return <Typography>{t("exports.failed-to-load")}</Typography>

                    if (isSuccess && allExportItems.length === 0)
                        return <Typography>{t("exports.no-exports")}</Typography>

                    if (isSuccess && allExportItems.length > 0)
                        return allExportItems.map((exportItem) => (
                            <Paper
                                key={exportItem.id}
                                elevation={0}
                                sx={{
                                    backgroundColor: theme => theme.palette.background.default,
                                    borderRadius: "4px 4px 0 0"
                                }}
                            >
                                <Stack
                                    direction="column"
                                    divider={<Divider orientation="horizontal" flexItem />}
                                    spacing={1}
                                    p={2}
                                    sx={{ flexGrow: 1 }}
                                >
                                    <Typography>
                                        {formatDate(new Date(exportItem.createdAt))} {formatTime(new Date(exportItem.createdAt))}
                                    </Typography>
                                    {!!exportItem.timeToLiveAt &&
                                        <Typography>
                                            {t("available-until")}: {formatDate(new Date(exportItem.timeToLiveAt))} {formatTime(new Date(exportItem.timeToLiveAt))}
                                        </Typography>
                                    }
                                    <Typography>
                                        {t(`exports.states.${exportItem.exportStateId}`)}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        disabled={exportItem.exportStateId !== "Succeeded"}
                                        onClick={() => download(exportItem.uris)}
                                    >
                                        {t("exports.download")}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        disabled={exportItem.exportStateId !== "Succeeded"}
                                        onClick={() => {
                                            setSelectedExport(exportItem)
                                            setDeleteDialog(true)
                                        }}
                                    >
                                        {t("exports.delete")}
                                    </Button>
                                </Stack>
                            </Paper>
                        ))
                })()}
                {isFetchingNextPage &&
                    <Box sx={{ display: "flex", justifyContent: "center", gridColumn: "1 / -1" }}>
                        <DelayedCircularProgress delay={250} />
                    </Box>
                }
            </Box>
        </Box>
        <DragonConfirmationDialogWithPromise
                open={deleteDialog && !!selectedExport}
                variant="warning"
                title={t("exports.delete_confirmation")}
                onClose={() => {
                    setDeleteDialog(false)
                    setSelectedExport(null)
                }}
                onConfirm={async () => {
                    await handleDeleteAsync(selectedExport!)
                }}
                isLoading={isDeleteLoading}
                errorText={t("exports.delete_failed")}
            />
        </>
    )
}