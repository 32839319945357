import { Paper, Stack } from "@mui/material"
import { Feature, GeoJsonProperties, Geometry } from "geojson"
import { Layer, MapRef, Source,MapLayerMouseEvent} from "react-map-gl/maplibre"
import { viewport as zoomCalc} from "@mapbox/geo-viewport"
import Map from "Components/Map/Map"
import calculateBoundingBox, { BoundingBox } from "Components/Map/utils/calculateBoundingBox"
import { useCallback, useEffect, useRef, useState } from "react"
import { useThemeContext } from "Contexts/ThemeContext"
import { IPosition } from "Types/IPosition"
import { ITrafficData } from "../ITrafficData"
import { circlePollutionLayer, createUnclusteredCamerasNameLayer } from "Components/Map/layers/pollutionLayers"
import { ITimeFrame } from "Components/Filter/Filters/TimeFrameSelector/ITimeFrame"
import TimeSelector from "./TimeSelector"


export default function PollutionMapWrapper({data,timeframe}:{data:ITrafficData[],timeframe: ITimeFrame}) {
    //const { t } = useTranslation()
    const [filteredData,setFilteredData] = useState(data)

    const positions = Object
    .values(data)
    .map(({ camera }) => camera.position)
    .filter((position): position is IPosition => !!position)

        // TODO: Expose padding prop from map
    const boundingBox = calculateBoundingBox(positions)

    const handleTimeChange = useCallback((start:Date,end:Date)=>{
        setFilteredData(data?.filter(t => new Date(`${t.timespanGroup}Z`) >= start && new Date(`${t.timespanGroup}Z`)<= end )?? [])
    },[data])

    return <MapView
        boundingBox={boundingBox}
        data={trafficDataToMapData(filteredData)}
        timeframe={timeframe}
        onTimeChange={handleTimeChange}
    />
}

interface IMapView {
    boundingBox: BoundingBox
    data: IMapCameras
    timeframe: ITimeFrame
    onTimeChange: (start:Date,end:Date)=>void
}

function MapView({ boundingBox, data: initalData,timeframe,onTimeChange }: IMapView) {
    const { mode } = useThemeContext()

    const centralPoint = {
        latitude: (boundingBox.max.latitude + boundingBox.min.latitude) / 2,
        longitude: (boundingBox.max.longitude + boundingBox.min.longitude) / 2
    }
    
    const [viewport, setViewport] = useState<{ position: IPosition, zoom: number }>({
        position: centralPoint,
        zoom: 9
    })

    const [data, setData] = useState<IMapCameras>(initalData)

    useEffect(() => {
        setData(initalData)
    }, [initalData])

    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const width = ref.current ? ref.current.offsetWidth : 0
        const height= ref.current ? ref.current.offsetHeight : 0
        const geo = zoomCalc([boundingBox.min.latitude,boundingBox.min.longitude,boundingBox.max.latitude,boundingBox.max.longitude],[width,height])
        setViewport({
            position:{
                latitude: geo.center[0],
                longitude: geo.center[1]
            },
            zoom:geo.zoom
        })
    }, [boundingBox.min.latitude,boundingBox.min.longitude,boundingBox.max.latitude,boundingBox.max.longitude])

    function onClick(event: MapLayerMouseEvent, map: MapRef) {
        if (!event.features || event.features.length === 0) {
            return
        }

        const feature = event.features[0]
        const layerId = feature.layer.id

        switch (layerId) {
            case "pollution-circle":
                const mapboxSource = map.getSource("pollution")

                if (mapboxSource?.type !== "geojson")
                    break
                const point= feature.geometry as unknown as GeoJSON.Point
                map.flyTo({
                    center:{
                        lat:point.coordinates[1],
                        lon:point.coordinates[0]
                    },
                    animate:true,
                    zoom: 18
                })
                break
        }
    }
    const unclusteredCamerasNameLayer = createUnclusteredCamerasNameLayer(mode)

    return<Stack direction={"column"} gap={1} height={"100%"}>
            <TimeSelector timeFrame={timeframe} onChange={onTimeChange} />
            <Paper sx={{ height: "100%", position: "relative" }} ref={ref}>
        <Map
            position={viewport.position}
            zoom={viewport.zoom}
            defaultZoomLevel={viewport.zoom}
            allowInteraction
            onClick={onClick}
            interactiveLayerIds={[unclusteredCamerasNameLayer.id!, circlePollutionLayer.id!]}
            mapCenter={{ ...centralPoint, zoom: viewport.zoom }}
        >
            <Source
                id="pollution"
                type="geojson"
                data={{
                    type: "FeatureCollection",
                    features: Object
                        .values(data)
                        .map((entry): Feature<Geometry, GeoJsonProperties> => ({
                            type: "Feature",
                            properties: {
                                id: entry.id,
                                name: entry.name,
                                "pollution-level": entry.pollution
                            },
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    entry.position.longitude,
                                    entry.position.latitude
                                ]
                            }
                        }))
                    }}
                >
                <Layer {...circlePollutionLayer} />
                <Layer {...unclusteredCamerasNameLayer} />
            </Source>
        </Map>
        </Paper>
    </Stack> 
}

interface IMapCameraDto {
    id: string
    name: string
    position: {
        latitude: number
        longitude: number
    }
    cameraGroups:
    {
        id: string
        name: string
    }[]
}

export interface IMapCamera extends IMapCameraDto {
    pollution: number
}

interface IMapCameras {
    [cameraId: string]: IMapCamera
}
function trafficDataToMapData(data: ITrafficData[]):IMapCameras{
    return data.reduce((acc,curr)=>{
        const camID = curr.camera?.id
        const pollution = vehicleToPollution(curr.vehicleClassId!,curr.value)
        if(!camID) return acc
        if(Object.keys(acc).some(k=>k ===camID)){
            acc[camID].pollution += pollution
        }else{
            acc[camID] = {
                id: camID,
                name: curr.camera.name,
                cameraGroups: [...curr.cameraGroups],
                position: {...curr.camera.position!},
                pollution: pollution
            } 
        }
        return acc
    },{} as IMapCameras)
}

function vehicleToPollution(vehicle: string, count: number ): number {
    switch(vehicle){
        case "Car": return count * 1
        case "Van": return count * 1.5
        case "Truck": return count * 4
        case "Bus": return count * 3
        case "Motorbike": return count * 0.5
        default: return 0
    }
}