import { faCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getVehicleColor, getVehicleIcon } from "./VehicleColors" 
import { Box, Stack } from "@mui/material"
import { CSSProperties } from "react"

type Color = CSSProperties["color"]

const eventColorMap = {
    "White": "#ffffff",
    "Black": "#000000",
    "Red": "#ff0000",
    "Blue": "#0000ff",
    "Gray": "#c2c2c2",
    "Yellow": "#00ffff"
} as const


const getEventColors = (color: string | null): { eventColor: Color } => {
    if(color && color in eventColorMap){
        return {
            eventColor: eventColorMap[color as keyof typeof eventColorMap]
        }
    }
    return {
        eventColor: "transparent"
    }
}

interface IEventClassAndColor {
    objectClassId: string | null
    color: string | null
}

export default function EventClassAndColor({
    objectClassId,
    color
}: IEventClassAndColor) {


    const vehicleIcon = getVehicleIcon(objectClassId ?? "")
    const vehicleColor = getVehicleColor(objectClassId ?? "")
    const { eventColor } = getEventColors(color)
    return <Stack direction={"row"} gap={"5px"} alignItems={"center"} sx={{
        borderStyle:"dashed",
        borderWidth:"2px",
        borderColor: vehicleColor,
        borderRadius:"4px",
        padding:"2px",
        backgroundColor: `${vehicleColor}7e`
    }}>
        {objectClassId && <Box width={32} height={32} sx={{ display: "inline-flex", alignItems:"center",justifyContent:"center" ,backgroundColor: vehicleColor, borderRadius:"4px"}}>
                <FontAwesomeIcon icon={vehicleIcon} style={{ color: "white", fontSize:"24px" }} />
            </Box>}
        {color && 
            <Box p="2px">
                <FontAwesomeIcon icon={faCircle} style={{ color: eventColor, fontSize:"24px"}} />
            </Box>
        }
    </Stack>
}