import IEventJournalFilterSelection from "../types/IEventJournalFilterSelection"

export const defaultSelection: IEventJournalFilterSelection = {
    cameraGroupIds: [],
    cameraIds: [],
    zoneIds: [],
    eventTypeIds: [],
    timeFrame: { type: "today" },
    includeSimulated: false,
    includeFiltered: false,
    includeMarkedAsFalse: true,
    includeMarkedAsTrue: true,
    includeUnmarked: true
}