import { ReactComponent as faImageSlash } from "icons/faImageSlash-regular.svg"
import {
    Card,
    keyframes,
    SvgIcon,
    useTheme
} from "@mui/material"
import Map from "Components/Map/Map"
import { Marker } from "react-map-gl"
import { red } from "App/colors"
import { IPosition } from "Types/IPosition"

interface IEventMgrMap {
    position: IPosition
    snapshotUri?: string,
    eventId: string
}

const slideUp = keyframes({
    from: {
        opacity: 0,
        transform: "translateY(17%)"
    },
    to: {
        opacity: 1,
        transform: "translateY(0)"
    }
})

const TRAFFIC_EVENT_COLOR = red[500]

export default function EventMgrMap({ position, snapshotUri, eventId }: IEventMgrMap) {
    const { palette } = useTheme()

    const markerIconSize = 24

    return <Map
        position={position}
        defaultZoomLevel={16}
    >
        <Marker
            longitude={position.longitude}
            latitude={position.latitude}
        >
            <div style={{
                width: markerIconSize,
                height: markerIconSize,
                backgroundColor: TRAFFIC_EVENT_COLOR,
                borderRadius: "50%"
            }} />
        </Marker>
        <Card
            key={eventId}
            sx={{
                height: "207px",
                aspectRatio: "16 / 9",
                position: "absolute",
                m: "25px 28px",
                animation: `${slideUp} 300ms ease-out`,
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
            }}
        >
            {snapshotUri
                ? <img src={snapshotUri} alt="event-snapshot" height="100%" />
                : <SvgIcon component={faImageSlash} viewBox="0 0 640 512" style={{ fontSize: 48, color: palette.text.secondary }} />
            }
        </Card>
    </Map>
}
