import { Typography } from "@mui/material"
import GateCountWidget from "Components/Widgets/GateCountWidget"
import EventBarChartWidget from "Components/Widgets/EventBarChartWidget"
import EventPieChartWidget from "Components/Widgets/EventPieChartWidget"
import MentionsWidget from "Components/Widgets/MentionsWidget"
import PeopleCountPieChartWidget from "Components/Widgets/PeopleCountPieChartWidget"
import { IWidgetWithData } from "Components/Widgets/types"
import VehicleCountPieChartWidget from "Components/Widgets/VehicleCountPieChartWidget"
import { IChartZoomArea } from "Pages/DataAnalytics/DetailedLineChart"
import EventLineChart from "Pages/DataAnalytics/EventTab/EventLineChart"
import PeopleOccupancyLineChart from "Pages/DataAnalytics/PeopleOccupancyTab/PeopleOccupancyLineChart"
import PeopleLineChart from "Pages/DataAnalytics/PeopleTab/PeopleLineChart"
import TrafficLineChart from "Pages/DataAnalytics/TrafficTab/TrafficLineChart"
import CountingLineChart from "Pages/DataAnalytics/CountingTab/CountingLineChart"
import { convertTimespanFilterToTimeFrame } from "Pages/DataAnalytics/utils/convertTimeFrameToTimespanFilter"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { assertUnreachable } from "utils/assertUnrechable"
import { convertDatePartsToDateString, generateTimestampsForTimeframeAndResolution } from "utils/generateTimestampsForTimeframeAndResolution"
import CountingBarChart from "Pages/DataAnalytics/CountingTab/CountingBarChart"
import PeopleOccupancyPieChartWidget from "Components/Widgets/PeopleOccupancyPieWidget"

interface IWidgetSwitch {
    widget: IWidgetWithData
}

function NoData() {
    const { t } = useTranslation()
    return <Typography variant="h5" color="text.secondary" textAlign="center" lineHeight={3}>
        {t("widget.no-data")}
    </Typography>
}

export default function WidgetSwitch({ widget }: IWidgetSwitch) {
    const { t } = useTranslation()
    const timestamps = widget.timespanGroupBy ? generateTimestampsForTimeframeAndResolution(
        convertTimespanFilterToTimeFrame({ timespanFilterTypeId: "Relative", ...widget }),
        widget.timespanGroupBy
    ): []
    const [zoom,setZoom] = useState<IChartZoomArea>({leftLimit:"",rightLimit:""})
    const resetZoom= () =>{
        if(timestamps){
            setZoom({leftLimit:convertDatePartsToDateString(timestamps[0]),rightLimit:convertDatePartsToDateString(timestamps[timestamps.length-1])})
        }
    }
    switch (widget.sourceId) {
        case "EventCount":
            if (!widget.eventCounts || !widget.eventCounts.length)
                return <NoData />

            switch (widget.chartTypeId) {
                case "Donut":
                    return <EventPieChartWidget data={widget.eventCounts} />
                case "Bar":
                    return <EventBarChartWidget data={widget.eventCounts} />
                case "Line":
                    return <EventLineChart
                        data={widget.eventCounts}
                        zoom={zoom}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        resolution={widget.timespanGroupBy}
                        timestamps={timestamps}
                        insertZeroIfNoDataAtTimeStamp
                    />
                default:
                    assertUnreachable(widget)
            }
            break
        case "VehicleCount":
            if (!widget.vehicleCounts || !widget.vehicleCounts.length)
                return <NoData />

            switch (widget.chartTypeId) {
                case "Donut":
                    return <VehicleCountPieChartWidget data={widget.vehicleCounts} />
                case "Line":
                    return <CountingLineChart
                        data={widget.vehicleCounts.map(({ count, ...o }) => ({ ...o, value: count }))}
                        zoom={zoom}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        resolution={widget.timespanGroupBy}
                        timestamps={timestamps}
                        insertZeroIfNoDataAtTimeStamp
                    />
                case "Bar":
                    return <CountingBarChart data={widget.vehicleCounts.map(({ count, ...o }) => ({ ...o, value: count }))} />
                default:
                    assertUnreachable(widget)
            }
            break
        case "VehicleSpeed":
            if (!widget.vehicleSpeeds || !widget.vehicleSpeeds.length)
                return <NoData />

            switch (widget.chartTypeId) {
                case "Line":
                    return <CountingLineChart
                        data={widget.vehicleSpeeds.map(({ speed, ...o }) => ({ ...o, value: speed }))}
                        zoom={zoom}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        resolution={widget.timespanGroupBy}
                        timestamps={timestamps}
                    />
                default:
                    assertUnreachable(widget)
            }
            break
        case "VehicleDistance":
            if (!widget.vehicleDistances || !widget.vehicleDistances.length)
                return <NoData />

            switch (widget.chartTypeId) {
                case "Line":
                    return <TrafficLineChart
                        data={widget.vehicleDistances.map(({ distance, ...o }) => ({ ...o, value: distance }))}
                        zoom={zoom}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        resolution={widget.timespanGroupBy}
                        timestamps={timestamps}
                    />
                default:
                    assertUnreachable(widget)

            }
            break
        case "TrafficInfoDensity":
            if (!widget.trafficInfoDensities || !widget.trafficInfoDensities.length)
                return <NoData />

            switch (widget.chartTypeId) {
                case "Line":
                    return <TrafficLineChart
                        data={widget.trafficInfoDensities.map(({ density, ...o }) => ({ ...o, value: density, vehicleClassId: null }))}
                        zoom={zoom}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        insertZeroIfNoDataAtTimeStamp
                        resolution={widget.timespanGroupBy}
                        timestamps={timestamps}
                    />
                default:
                    assertUnreachable(widget)
            }
            break
        case "TrafficInfoSpeed":
            if (!widget.trafficInfoSpeeds || !widget.trafficInfoSpeeds.length)
                return <NoData />

            switch (widget.chartTypeId) {
                case "Line":
                    return <TrafficLineChart
                        data={widget.trafficInfoSpeeds.map(({ speed, ...o }) => ({ ...o, value: speed, vehicleClassId: null }))}
                        zoom={zoom}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        resolution={widget.timespanGroupBy}
                        timestamps={timestamps}
                    />
                default:
                    assertUnreachable(widget)
            }
            break
        case "PeopleCount":
            if (!widget.peopleCounts || !widget.peopleCounts.length)
                return <NoData />

            switch (widget.chartTypeId) {
                case "Donut":
                    return <PeopleCountPieChartWidget data={widget.peopleCounts} />
                case "Line":
                    return <PeopleLineChart
                        data={widget.peopleCounts}
                        zoom={zoom}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        resolution={widget.timespanGroupBy}
                        timestamps={timestamps}
                        insertZeroIfNoDataAtTimeStamp
                    />
                default:
                    assertUnreachable(widget)
            }
            break
        case "BikeCount":
            if (!widget.bikeCounts || !widget.bikeCounts.length)
                return <NoData />

            switch (widget.chartTypeId) {
                case "Donut":
                    return <PeopleCountPieChartWidget data={widget.bikeCounts} />
                case "Line":
                    return <PeopleLineChart
                    data={widget.bikeCounts}
                    zoom={zoom}
                    onZoomChange={setZoom}
                    onZoomReset={resetZoom}
                    resolution={widget.timespanGroupBy}
                    timestamps={timestamps}
                    insertZeroIfNoDataAtTimeStamp
                />
                default:
                    assertUnreachable(widget)
            }
            break
        case "EventComment":
            if (!widget.eventComments || !widget.eventComments.length)
                return <NoData />

            switch (widget.chartTypeId) {
                case "Table":
                    return <MentionsWidget data={widget.eventComments} />
                default:
                    assertUnreachable(widget)
            }
            break
        case "GateCount":
            if (!widget.gateCounts || !widget.gateCounts.length)
                return <NoData />
            switch (widget.chartTypeId) {
                case "Heatmap":
                    return <GateCountWidget data={widget.gateCounts}/>
                default:
                    assertUnreachable(widget)
            }
            break
        case "PeopleOccupancy":
            if (!widget.peopleOccupancies || !widget.peopleOccupancies.length)
                return <NoData />
            switch (widget.chartTypeId) {
                case "Line":
                    return <PeopleOccupancyLineChart 
                                data={widget.peopleOccupancies}
                                zoom={zoom}
                                onZoomChange={setZoom}
                                onZoomReset={resetZoom}
                                resolution={widget.timespanGroupBy}
                                timestamps={timestamps}
                                insertZeroIfNoDataAtTimeStamp />
                case "Donut":
                    return <PeopleOccupancyPieChartWidget 
                                data={widget.peopleOccupancies}
                            />
                default:
                    assertUnreachable(widget)
            }
            break
        case "CameraGateCount":
        case "EventJournal":
            return <Typography>{t("widget.widget-not-working")}</Typography>
        default:
            assertUnreachable(widget)
    }
}