import axios from "axios"
import { WebStorageStateStore } from "oidc-client-ts"
import { AuthContext, AuthProvider, AuthProviderProps, UserManager } from "oidc-react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import CenteredDelayedSpinner from "./CenteredDelayedSpinner"

const oidcConfig: AuthProviderProps = {
    // autoSignIn in redirects to login screen directly if true
    autoSignIn: true,
    onSignIn: () => {
        // This removes all query parameters
        // that's provided when we return from the login screen
        window.history.replaceState(null, "", "/")
    }
}

interface IAuthSettings {
    identityClientId: string
    identityAuthorizeUrl: string
    identityRedirectUrl: string
}

async function getAuthSettings(): Promise<IAuthSettings> {
    const response = await axios.get<IAuthSettings>("/api/v1/identity/settings")
    return response.data
}

export default function AuthWrapper({ children }: { children: React.ReactNode }) {
    const { t } = useTranslation()
    const userStore = new WebStorageStateStore({prefix:"auth", store: window.localStorage})
    const { isLoading, isError, data: authSettings } = useQuery("auth-settings", getAuthSettings, { staleTime: Infinity })
    if (isLoading)
        return <CenteredDelayedSpinner />

    if (isError || !authSettings) {
        userStore.getAllKeys().then(keys =>{
            keys.map(async (k)=> await userStore.remove(k))
        })
        return <div>{t("something-went-wrong")}</div>
    }
        



    // Use local storage instead of session storage to enable cross tab logins
    const userManager = new UserManager({
        userStore: userStore,
        authority: authSettings.identityAuthorizeUrl,
        client_id: authSettings.identityClientId,
        redirect_uri: authSettings.identityRedirectUrl,
        post_logout_redirect_uri: authSettings.identityRedirectUrl,
        response_type: "code",
        scope: "openid user_api user_admin_api offline_access profile email phone",
        loadUserInfo: true,
        automaticSilentRenew: true
    })

    // userManager.signinCallback().catch((e: Error)=>{
    //     if(e.message === "No matching state found in storage"){
    //         userManager.clearStaleState()
    //         window.history.replaceState(null, "", "/")
    //     }
        
    // })
    return <AuthProvider
        userManager={userManager}

        {...oidcConfig}
    >
        <AuthContext.Consumer>
            {auth => {
                // We're not logged in!
                if (!auth || auth.isLoading || !auth.userData || auth.userData.expired)
                    return <CenteredDelayedSpinner />

                // Clear incomplete login requests from local storage
                auth.userManager.clearStaleState()

                // Add auth header to all subsequent requests
                axios.defaults.headers.common["Authorization"] = `Bearer ${auth.userData.access_token}`

                return children
            }}
        </AuthContext.Consumer>
    </AuthProvider>
}
