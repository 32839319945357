import { useQueries } from "react-query"

import {
    Box,
    Stack
} from "@mui/material"
import { ITrafficFilterSelection } from "../types/IDataAnalyticsFilterSelection"

import { getTrafficDensity, getTrafficSpeed, getVehicleSpeed} from "./getTrafficData"
import DragonChartCard from "../DragonChartCard"
import { useTranslation } from "react-i18next"
import { useMemo, useState } from "react"
import { defaultSelection } from "./defaultSelection"
import TrafficLineChart from "./TrafficLineChart"
import TrafficFilter from "./Filter"
import { convertDatePartsToDateString, generateTimestampsForTimeframeAndResolution } from "utils/generateTimestampsForTimeframeAndResolution"
import { IChartZoomArea } from "../DetailedLineChart"
import DragonChartCardActions, { IDragonChartCardActions } from "../DragonChartCardActions"

const SYNC_ID = "Traffic_Data_Sync"
export default function TrafficCountingWrapper() {
    const [selection, setSelection] = useState<ITrafficFilterSelection>(defaultSelection)
    function onSubmit(selection: ITrafficFilterSelection) {
        setSelection(selection)
    }

    return <>
        <Box mx={3} mt={3}>
            <TrafficFilter onSubmit={onSubmit} />
        </Box>
        <TrafficCounting filter={selection} />
    </>
}


export function TrafficCounting({ filter }: { filter: ITrafficFilterSelection }) {
    const { t } = useTranslation()

    const [
        { data: trafficDensityData, status: trafficDensityStatus },
        { data: trafficSpeedData, status: trafficSpeedStatus },
        { data: vehicleSpeedData, status: vehicleSpeedStatus }
    ] = useQueries([
        { queryKey: ["trafficDensity", filter], queryFn: () => getTrafficDensity(filter) },
        { queryKey: ["trafficSpeed", filter], queryFn: () => getTrafficSpeed(filter) },
        { queryKey: ["vehicleSpeed", filter], queryFn: () => getVehicleSpeed(filter) }
    ])

    const timestamps = useMemo(
        () => generateTimestampsForTimeframeAndResolution(filter.timeFrame, filter.resolution),
        [filter.timeFrame, filter.resolution]
    )
    const [zoom,setZoom] = useState<IChartZoomArea>({leftLimit:convertDatePartsToDateString(timestamps[0]),rightLimit:convertDatePartsToDateString(timestamps[timestamps.length-1])})
    const resetZoom= () =>{
        if(timestamps){
            setZoom({leftLimit:convertDatePartsToDateString(timestamps[0]),rightLimit:convertDatePartsToDateString(timestamps[timestamps.length-1])})
        }
    }
    const [avgTrafficDensityRef,setAvgTrafficDensityRef] = useState<IDragonChartCardActions|null>(null)
    const [avgTrafficSpeedRef,setAvgTrafficSpeedRef] = useState<IDragonChartCardActions|null>(null)
    const [avgSpeedRef,setAvgSpeedRef] = useState<IDragonChartCardActions|null>(null)
    return (
        <Box sx={{ overflow: "auto", p: 3 }}>
            <Stack gap="20px">
            <DragonChartCard
                    title={`${t("data-analytics-page.average-speed")} (km/h)`}
                    data={vehicleSpeedData}
                    status={vehicleSpeedStatus}
                    actions={<DragonChartCardActions reference={avgSpeedRef} name={t("data-analytics-page.average-speed")} />}
                >
                    <TrafficLineChart
                        data={vehicleSpeedData ?? []}
                        zoom={zoom}
                        syncID={SYNC_ID}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        cardActionsRef={setAvgSpeedRef}
                        resolution={filter.resolution}
                        timestamps={timestamps}
                    />
                </DragonChartCard>
                <DragonChartCard
                    title={t("data-analytics-page.traffic-density")}
                    data={trafficDensityData}
                    status={trafficDensityStatus}
                    actions={<DragonChartCardActions reference={avgTrafficDensityRef} name={t("data-analytics-page.traffic-density")}/>}
                >
                    <TrafficLineChart
                        data={trafficDensityData ?? []}
                        zoom={zoom}
                        syncID={SYNC_ID}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        cardActionsRef={setAvgTrafficDensityRef}
                        insertZeroIfNoDataAtTimeStamp
                        resolution={filter.resolution}
                        timestamps={timestamps}
                    />
                </DragonChartCard>
                <DragonChartCard
                    title={`${t("data-analytics-page.traffic-speed")} (km/h)`}
                    data={trafficSpeedData}
                    status={trafficSpeedStatus}
                    actions={<DragonChartCardActions reference={avgTrafficSpeedRef} name={t("data-analytics-page.traffic-speed")}/>}
                >
                    <TrafficLineChart
                        data={trafficSpeedData ?? []}
                        zoom={zoom}
                        syncID={SYNC_ID}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        cardActionsRef={setAvgTrafficSpeedRef}
                        resolution={filter.resolution}
                        timestamps={timestamps}
                    />
                </DragonChartCard>
            </Stack>
        </Box>
    )
}