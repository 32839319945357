import { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { QueryFunctionContext, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"
import { Box, Stack, Button, Paper, TextField, Typography, Divider, Grid } from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileAlt, faUsers, faPlus, faMinus } from "@fortawesome/pro-regular-svg-icons"
import {
    faFileAlt as faFileAltSolid,
    faUsers as faUsersSolid
} from "@fortawesome/pro-solid-svg-icons"

import ErrorBoundary from "utils/ErrorBoundary"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { IDataWithEtag } from "utils/queryHelpers"
import GoBackButton from "Components/DragonGoBackButton"
import { DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import { DragonTab, DragonTabs } from "Components/DragonTabs"
import { DragonTableWithSearchField, HeaderCell, BodyRow } from "Components/DragonTable"
import { IUserReadDto, ITeamListDto, IUserUpdateDto } from "Types/admin"
import Icon from "Components/Icon"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"

type IUserDetails = IUserReadDto
type ITeamListItem = ITeamListDto
type IUserUpdate = IUserUpdateDto
type IUserDetailsWithEtag = IDataWithEtag<IUserDetails>

enum Tab {
    DESCRIPTIONS = "descriptions",
    TEAM = "team"
}

const getUserDetails = async ({ queryKey }: QueryFunctionContext<[string, string]>): Promise<IUserDetailsWithEtag> => {
    const [, id] = queryKey
    const response = await axios.get<IUserDetails>(`/api/admin/v1/users/${id}`)
    return {
        data: response.data,
        eTag: response.headers.etag
    }
}

const getTeams = async ({ pageParam: offset = 0, queryKey }: QueryFunctionContext): Promise<ITeamListItem[]> => {
    const [, searchName] = queryKey
    const response = await axios.get<ITeamListItem[]>("/api/admin/v1/teams", {
        params: { limit: 25, offset, name: searchName || undefined }
    })
    return response.data
}

const putUserDetails = async (id: string, eTag: string, payload: IUserUpdate): Promise<AxiosResponse> => {
    return await axios.put(`/api/admin/v1/users/${id}`, payload, { headers: { "If-Match": eTag } })
}

interface Props {
    user: IUserDetailsWithEtag
}

export const UserDetails: FC<Props> = ({ user }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const queryClient = useQueryClient()
    const initialState = useMemo<IUserUpdate>(() => ({ teamId: user.data.teamId }), [user.data.teamId])
    const [draft, setDraft] = useState<IUserUpdate>(initialState)
    const [activeTab, setActiveTab] = useState<Tab>(Tab.DESCRIPTIONS)
    const [searchTeam, setSearchTeam] = useState<string>("")

    const isChanged = useMemo<boolean>(
        () => (
            Object.entries(draft).some(
                ([key, value]) => initialState[key as keyof IUserUpdate] !== value
            )
        ),
        [draft, initialState]
    )

    const { mutateAsync: handleSaveChangesAsync, isLoading } = useMutation(() => putUserDetails(user.data.id, user.eTag, draft),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("users")
                queryClient.invalidateQueries(["user", user.data.id])

                // If the user has both operator and administrator roles
                // and changes team on himself, he would need to reload the page
                // before being able to use the updated team permissions
                queryClient.invalidateQueries("user-settings")
            }
        }
    )

    const {
        data: teams, hasNextPage: hasNextTeamsPage = false, fetchNextPage: fetchNextTeamsPage, isFetching: isFetchingTeams
    } = useInfiniteQuery(["teams", searchTeam], getTeams, {
        getNextPageParam: (lastPage, allPages) => (
            lastPage.length === 0 ? undefined : allPages.reduce((amount, page) => amount + page.length, 0)
        ),
        keepPreviousData: true
    })

    const tableColumns = useMemo<HeaderCell[]>(() => [
        { value: t("name") },
        { value: t("camera-groups") },
        { value: t("members") },
        { value: "" }
    ], [t])

    const tableData = useMemo<BodyRow[]>(() => {
        const list = teams?.pages.flat() ?? []
        return list.map(({ id, name, users, cameraGroups }) => (
            {
                key: id,
                row: [
                    { value: name },
                    { value: `${cameraGroups.slice(0, 2).map(({ name }) => name).join(", ")}${cameraGroups.length > 2 ? t("and-more-with-amount", { amount: cameraGroups.length - 2 }) : ""}` },
                    { value: `${users.slice(0, 2).map(({ name }) => name).join(", ")}${users.length > 2 ? t("and-more-with-amount", { amount: users.length - 2 }) : ""}` },
                    {
                        value: (
                            <AddRemoveButton
                                value={user.data.teamId === id}
                                onClick={() => {
                                    setDraft((prevState) => ({ ...prevState, teamId: prevState.teamId !== id ? id : null }))
                                }}
                            />
                        ),
                        align: "right"
                    }
                ],
                hover: true
            }
        ))
    }, [teams?.pages, t, user.data.teamId])

    useEffect(() => {
        const hash = location.hash.replace("#", "")
        let selectedTab: Tab | undefined
        switch (hash) {
            case "":
                selectedTab = Tab.DESCRIPTIONS
                break
            case Tab.TEAM:
                selectedTab = Tab.TEAM
                break
            default:
                selectedTab = undefined
        }
        if (selectedTab) {
            setActiveTab(selectedTab)
        } else {
            navigate(".")
        }
    }, [location.hash, navigate])

    return (
        <>
            <GoBackButton />
            <DragonTitle title={user.data.name} />
            <Paper sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <Stack direction="row">
                    <DragonTabs value={activeTab}>
                        <DragonTab
                            label={t("user-details-page.descriptions")}
                            value={Tab.DESCRIPTIONS}
                            icon={
                                <Icon
                                    isSelected={activeTab === Tab.DESCRIPTIONS}
                                    icon={faFileAlt}
                                    selectedIcon={faFileAltSolid}
                                />
                            }
                            to="."
                        />
                        <DragonTab
                            label={t("user-details-page.team")}
                            value={Tab.TEAM}
                            icon={
                                <Icon
                                    isSelected={activeTab === Tab.TEAM}
                                    icon={faUsers}
                                    selectedIcon={faUsersSolid}
                                />
                            }
                            to="#team"
                        />
                    </DragonTabs>
                </Stack>
                <Divider flexItem />
                {/* DESCRIPTION */}
                {activeTab === Tab.DESCRIPTIONS && (
                    <Box padding={2} overflow="auto">
                        <Grid container spacing={2} overflow="auto">
                            <Grid item xs={12}>
                                <Typography variant="h6" fontWeight="bold">{t("user-details-page.name")}</Typography>
                                <TextField
                                    variant="filled"
                                    InputProps={{ disableUnderline: true, sx: { pointerEvents: "none" } }}
                                    value={user.data.name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6" fontWeight="bold">{t("user-details-page.email")}</Typography>
                                <TextField
                                    variant="filled"
                                    InputProps={{ disableUnderline: true, sx: { pointerEvents: "none" } }}
                                    value={user.data.email ?? ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6" fontWeight="bold">{t("user-details-page.mobile-phone")}</Typography>
                                <TextField
                                    variant="filled"
                                    InputProps={{ disableUnderline: true, sx: { pointerEvents: "none" } }}
                                    value={user.data.mobilePhone ?? ""}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {/* TEAM */}
                {activeTab === Tab.TEAM && (
                    <Stack spacing={2} pt={2} overflow="hidden">
                        <Typography px={2} variant="h6" fontWeight="bold">{t("user-details-page.team-overview")}</Typography>
                        <Typography px={2}>{t("user-details-page.update-team_description")}</Typography>
                        <DragonTableWithSearchField
                            columns={tableColumns}
                            data={tableData}
                            hasNextPage={hasNextTeamsPage}
                            fetchNextPage={fetchNextTeamsPage}
                            isFetching={isFetchingTeams}
                            noDataText={t("no-team-found")}
                            searchValue={searchTeam}
                            setSearchValue={setSearchTeam}
                            searchPlaceholder={t("search-for-team")}
                        />
                    </Stack>
                )}
            </Paper>
            <DragonConfirmationDialogWithPromise
                open={isChanged}
                title={t("user-details-page.update-team_confirmation", { user: user.data.name })}
                onClose={() => setDraft(initialState)}
                onConfirm={async () => {
                    await handleSaveChangesAsync()
                    navigate("..")
                }}
                isLoading={isLoading}
                errorText={t("user-details-page.update-team_failed")}
            />
        </>
    )
}

export const UserDetailsContainer: FC = () => {
    const { t } = useTranslation()
    const { userId } = useParams<"userId">()
    const [error, setError] = useState("")

    if (!userId)
        throw new Error("User id is not valid")

    const {
        isLoading: isUserDetailsLoading,
        isError: isUserDetailsError,
        isSuccess,
        data: userDetails
    } = useQuery<IUserDetailsWithEtag, AxiosError, IUserDetailsWithEtag, [string, string]>(
        ["user", userId],
        getUserDetails,
        {
            onError: (error) => {
                if (error.response?.status === 404)
                    return setError(t("user-details.user-does-not-exist"))
                return setError(t("something-went-wrong"))
            }
        }
    )

    const isLoading = useMemo<boolean>(
        () => isUserDetailsLoading,
        [isUserDetailsLoading]
    )

    const isError = useMemo<boolean>(
        () => isUserDetailsError,
        [isUserDetailsError]
    )

    return (
        <DragonPageWrapper>
            {(() => {
                if (isLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{error}</h2>

                if (isSuccess)
                    return (
                        <ErrorBoundary>
                            <UserDetails user={userDetails} />
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>
    )
}

export default UserDetailsContainer

const AddRemoveButton: FC<{ value: boolean, onClick: () => void }> = ({ value, onClick }) => {
    return (
        <Button variant="contained" color={value ? "error" : "primary"} onClick={onClick}>
            <FontAwesomeIcon icon={value ? faMinus : faPlus} name={(value ? faMinus : faPlus).iconName} />
        </Button>
    )
}