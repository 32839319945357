import { Box, Stack } from "@mui/material"
import { Theme, useThemeContext } from "Contexts/ThemeContext"
import React, { useEffect, useState, useRef } from "react"
import ReactMapGL, { MapLayerMouseEvent, MapRef} from "react-map-gl/maplibre"
import { Fullscreen, Recenter, ZoomIn, ZoomOut } from "./Controls"
import { IPosition } from "Types/IPosition"
import { assertUnreachable } from "utils/assertUnrechable"

interface IViewport {
    latitude: number
    longitude: number
    zoom: number
    bearing: number
    pitch: number
    transitionDuration: number
}

const mapStyles = {
    light: "/dragonmaps/styles/day-style/style.json",
    night: "/dragonmaps/styles/night-style/style.json"
}

const fullscreenStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9001
}

interface IMap {
    defaultZoomLevel?: number
    allowInteraction?: boolean
    position: IPosition
    zoom?: number
    children?: React.ReactNode
    sx?: React.CSSProperties
    interactiveLayerIds?: string[]
    onClick?: (evt: MapLayerMouseEvent, map: MapRef) => void
    mapCenter?: { latitude: number, longitude: number, zoom: number }
}

const TRANSITION_DURATION = 250
export const ZOOM_LEVEL = Number(process.env.REACT_APP_DRAGON_MAP_DEFAULT_ZOOM) ?? 13
export const MIN_ZOOM_LEVEL = Number(process.env.REACT_APP_DRAGON_MAP_MIN_ZOOM) ?? 6
export const MAX_ZOOM_LEVEL = Number(process.env.REACT_APP_DRAGON_MAP_MAX_ZOOM) ?? 20

export default function Map({
    defaultZoomLevel = ZOOM_LEVEL,
    allowInteraction,
    position,
    zoom,
    sx,
    children,
    interactiveLayerIds,
    onClick,
    mapCenter
}: IMap) {
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
    const [mouseCursor,setMouseCursor] = useState<string|undefined>()
    const [viewport, setViewport] = useState<IViewport>({
        latitude: position.latitude,
        longitude: position.longitude,
        zoom: defaultZoomLevel,
        bearing: 0,
        pitch: 0,
        transitionDuration: TRANSITION_DURATION
    })

    useEffect(() => {
        setViewport(viewport => ({
            ...viewport,
            latitude: position.latitude,
            longitude: position.longitude,
            zoom: zoom || viewport.zoom,
            transitionDuration: TRANSITION_DURATION
        }))
    }, [position, zoom])

    const { mode } = useThemeContext()
    const style = (() => {
        switch (mode) {
            case Theme.light:
                return mapStyles.light
            case Theme.dark:
                return mapStyles.night
            default:
                assertUnreachable(mode)
        }
    })()

    const mapRef = useRef<MapRef>(null)

    return <Box overflow={"hidden"} height={"100%"}><ReactMapGL
        ref={mapRef}
        {...viewport}
        baseApiUrl = "/dragonmaps"
        mapStyle={style}
        attributionControl={false}
        renderWorldCopies={false}
        minZoom={MIN_ZOOM_LEVEL}
        maxZoom={MAX_ZOOM_LEVEL}
        interactiveLayerIds={allowInteraction? interactiveLayerIds : undefined}
        onClick={mapEvent => {
            if (!onClick)
                return

            if (!mapRef.current)
                throw new Error("mapRef.current shouldn't be undefined here")

            onClick(mapEvent, mapRef.current)
        }}
        onMove={evt => setViewport(prev => ({...prev, ...evt.viewState}))}
        style={
            isFullscreen
                ? { ...sx, ...fullscreenStyle }
                : { ...sx }
        }
        cursor={mouseCursor}
        onMouseEnter={()=>setMouseCursor("pointer")}
        onMouseLeave={()=>setMouseCursor(undefined)}
    >
        {children}
        {allowInteraction &&<Stack
            direction="column"
            spacing={2}
            sx={{
                position: "absolute",
                top: 10,
                right: 10
            }}
        >
            <Fullscreen
                toggleFullscreen={() => setIsFullscreen(!isFullscreen)}
                isFullscreen={isFullscreen}
            />
            <ZoomIn
                zoomIn={() =>{ mapRef.current!.flyTo({animate:true,zoom:viewport.zoom+1})}}
            />
            <ZoomOut
                zoomOut={() =>{ mapRef.current!.flyTo({animate:true,zoom:viewport.zoom-1})}}
            />
            {mapCenter &&
                <Recenter
                    recenter={() => { mapRef.current!.flyTo({
                        animate:true,
                        center:{
                            lat:mapCenter.latitude,
                            lon:mapCenter.longitude
                        },
                        bearing:0,
                        pitch:0,
                        zoom:mapCenter.zoom
                        })}
                    }
                />
            }
        </Stack>}
    </ReactMapGL></Box>
}