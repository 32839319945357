import { useTranslation } from "react-i18next"
import {
    Select,
    MenuItem,
    SelectChangeEvent
} from "@mui/material"
import FilterControlLabel from "../../FilterControlLabel"
import { ITimeFrame } from "../TimeFrameSelector/ITimeFrame"
import { useEffect, useMemo } from "react"
import { assertUnreachable } from "utils/assertUnrechable"
import { IResolution } from "Types/IResolution"

interface IResolutionSelector {
    resolution: IResolution
    onUpdateResolution: (resolution: IResolution) => void
    timeFrame: ITimeFrame
}

export default function ResolutionSelector({ resolution, onUpdateResolution, timeFrame }: IResolutionSelector) {
    const { t } = useTranslation()

    const handleChange = (event: SelectChangeEvent<IResolution>) => {
        onUpdateResolution(event.target.value as IResolution)
    }

    const availableResolutions = useMemo(() => getResolutionOptions(timeFrame), [timeFrame])

    // If the selected resolution is no longer selectable
    // Select the first available resolution
    useEffect(() => {
        if (!availableResolutions.includes(resolution)) {
            onUpdateResolution(availableResolutions[0] as IResolution)
        }
    }, [availableResolutions, onUpdateResolution, resolution])

    // MUI complains if we try to set a value in the select that don't exist
    // So we render null for this run, the next render we will have a valid
    // resolution
    if (!availableResolutions.includes(resolution))
        return null

    return (
        <>
            <FilterControlLabel text={t("data-analytics-page.resolution")} />
            <Select
                value={resolution}
                onChange={handleChange}
                variant="filled"
                fullWidth
                disableUnderline
                required
            >
                {availableResolutions.map((option, i) => {
                    const { unit, value } = getResolutionTranslationKeyAndValue(option)

                    return <MenuItem key={i} value={option}>
                        {t(`${unit}WithCount`, { count: value })}
                    </MenuItem>
                })}
            </Select>
        </>
    )
}

const DAY_IN_SECONDS = 3600 * 24

const ONE_MINUTE: IResolution = "1m"
const FIFTEEN_MINUTES: IResolution = "15m"
const HALF_AN_HOUR: IResolution = "30m"
const ONE_HOUR: IResolution = "1h"
const ONE_DAY: IResolution = "d"
const ONE_MONTH: IResolution = "M"
const ONE_YEAR: IResolution = "y"

function getResolutionOptions(timeFrame: ITimeFrame): IResolution[] {
    switch (timeFrame.type) {
        case "today":
        case "yesterday":
            return [ONE_MINUTE, FIFTEEN_MINUTES, ONE_HOUR, ONE_DAY]
        case "this-week":
            return [HALF_AN_HOUR, ONE_HOUR, ONE_DAY]
        case "custom":
            const span = Math.ceil((timeFrame.to.valueOf() - timeFrame.from.valueOf())/1000)
            const days = Math.ceil(span / DAY_IN_SECONDS)
            if (days <= 1)
                return [ONE_MINUTE, FIFTEEN_MINUTES, ONE_HOUR, ONE_DAY]

            if (days <= 2)
                return [FIFTEEN_MINUTES, ONE_HOUR, ONE_DAY]

            if (days <= 7)
                return [FIFTEEN_MINUTES, ONE_HOUR, ONE_DAY]

            if (days <= 31)
                return [ONE_HOUR, ONE_DAY]

            if (days <= 31 * 3)
                return [ONE_DAY, ONE_MONTH]

            if (days <= 365)
                return [ONE_DAY, ONE_MONTH]

            return [ONE_MONTH, ONE_YEAR]
        default:
            assertUnreachable(timeFrame)
    }
}

export function getDefaultResolutionBasedOnTimeFrame(timeFrame: ITimeFrame): IResolution | null {
    const options = getResolutionOptions(timeFrame)

    if (options.length > 0)
        return options[0]

    return null
}

// This maps to the (unit)WithCount_(one/other) in the translation file
type IUnit = "minute" | "hour" | "day" | "week" | "month" | "year"

function getResolutionTranslationKeyAndValue(resolution: IResolution): { unit: IUnit, value: number } {
    switch (resolution) {
        case "m":
        case "1m":
            return { unit: "minute", value: 1 }
        case "2m":
            return { unit: "minute", value: 2 }
        case "3m":
            return { unit: "minute", value: 3 }
        case "4m":
            return { unit: "minute", value: 4 }
        case "5m":
            return { unit: "minute", value: 5 }
        case "6m":
            return { unit: "minute", value: 6 }
        case "10m":
            return { unit: "minute", value: 10 }
        case "15m":
            return { unit: "minute", value: 15 }
        case "20m":
            return { unit: "minute", value: 20 }
        case "30m":
            return { unit: "minute", value: 30 }
        case "h":
        case "1h":
            return { unit: "hour", value: 1 }
        case "2h":
            return { unit: "hour", value: 2 }
        case "3h":
            return { unit: "hour", value: 3 }
        case "4h":
            return { unit: "hour", value: 4 }
        case "6h":
            return { unit: "hour", value: 6 }
        case "12h":
            return { unit: "hour", value: 12 }
        case "d":
            return { unit: "day", value: 1 }
        case "w":
            return { unit: "week", value: 1 }
        case "M":
            return { unit: "month", value: 1 }
        case "y":
            return { unit: "year", value: 1 }
        default:
            assertUnreachable(resolution)
    }
}