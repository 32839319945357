import { FC, useMemo, useState } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { Paper } from "@mui/material"

import { DragonTableWithSearchField, HeaderCell, BodyRow } from "Components/DragonTable"
import { IUserListDto } from "Types/admin"
import UserDetails from "Pages/UserDetails/UserDetails"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"

type IUserListItem = IUserListDto

const getUsers = async ({ pageParam: offset = 0, queryKey }: QueryFunctionContext): Promise<IUserListItem[]> => {
    const [, searchName] = queryKey
    const response = await axios.get<IUserListItem[]>("/api/admin/v1/users", {
        params: { limit: 25, offset, name: searchName || undefined }
    })
    return response.data
}

export const UserManagement: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState<string>("")

    const showDetails = useLocation().pathname.startsWith("/user-management/user")

    const {
        data, hasNextPage = false, fetchNextPage, isFetching
    } = useInfiniteQuery(["users", searchValue], getUsers, {
        getNextPageParam: (lastPage, allPages) => (
            lastPage.length === 0 ? undefined : allPages.reduce((amount, page) => amount + page.length, 0)
        ),
        keepPreviousData: true
    })

    const tableColumns = useMemo<HeaderCell[]>(() => [
        { value: t("name") },
        { value: t("email") },
        { value: t("mobile") },
        { value: t("team") }
    ], [t])

    const tableData = useMemo<BodyRow[]>(() => {
        const list = data?.pages.flat() ?? []
        return list.map(({ id, name, email, mobilePhone, team }) => (
            {
                row: [
                    { value: name },
                    { value: email },
                    { value: mobilePhone },
                    { value: team?.name }
                ],
                onClick: () => navigate(`user/${id}`),
                sx: { cursor: "pointer" },
                hover: true
            }
        ))

    }, [data?.pages, navigate])

    return (
        <>
            <DragonPageWrapper hidden={showDetails}>
                <DragonTitle title={t("user-management")} />
                <Paper sx={{ display: "flex", overflow: "hidden" }}>
                    <DragonTableWithSearchField
                        columns={tableColumns}
                        data={tableData}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetching={isFetching}
                        noDataText={t("no-user-found")}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        searchPlaceholder={t("search-for-user")}
                    />
                </Paper>
            </DragonPageWrapper>
            <Routes>
                <Route path="user/:userId" element={<UserDetails />} />
            </Routes>
        </>
    )
}

export default UserManagement
