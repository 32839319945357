export type BoundingBox = {
    min: {
        latitude: number
        longitude: number
    }
    max: {
        latitude: number
        longitude: number
    }
}

/**
 * Calculate bounding box
 * @param positions a list of positions to calculate the bounding box for
 * @returns {BoundingBox} gives the top left and bottom right coordinates of the bounding box
 */

export default function calculateBoundingBox(positions: { latitude: number, longitude: number }[]): BoundingBox {
    return positions.reduce<BoundingBox>(
        (acc, position) => {
            if (position.latitude < acc.min.latitude)
                acc.min.latitude = position.latitude

            if (position.longitude < acc.min.longitude)
                acc.min.longitude = position.longitude

            if (position.latitude > acc.max.latitude)
                acc.max.latitude = position.latitude

            if (position.longitude > acc.max.longitude)
                acc.max.longitude = position.longitude

            return acc
        },
        {
            min: {
                latitude: Infinity,
                longitude: Infinity
            },
            max: {
                latitude: -Infinity,
                longitude: -Infinity
            }
        }
    )
}