import { useEffect, useState } from "react"
import { colorsList } from "App/colors"
import { ResponsiveSunburst, SunburstCustomLayerProps } from "@nivo/sunburst"
import { getVehicleColor } from "Components/VehicleColors"
import { useTheme } from "@nivo/core"
import { TFunction, useTranslation } from "react-i18next"


export interface IVehicleCountData{
    name: string
    count: number
}

export interface IVehicleCountZoneData {
    name: string
    vehicles:IVehicleCountData[]
}
export interface IVehicleCountCamera{
    name: string
    zones: IVehicleCountZoneData[]
}
interface IVehicleCountCameraPieChart {
    camera:IVehicleCountCamera
}

interface VehicleDatum {
    id:string
    name: string
    color:string
    count: number
}

interface ZoneDatum{
    id:string
    name: string
    color:string
    children: VehicleDatum[]
}
interface CameraData{
    name:string
    color:string
    children:ZoneDatum[]
}


const CenteredMetric = ({ nodes, centerX, centerY }: SunburstCustomLayerProps<CameraData>) => {
    const theme = useTheme()
    const total = nodes.filter(n=> n.depth === 1).reduce((total, datum) => total + datum.value, 0)
    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fill: theme.textColor,
                fontSize: "42px",
                fontWeight: 600
            }}
        >
            {Number.parseFloat(`${total}`).toLocaleString()}
        </text>
    )
}

// const CustomArcLabel = ({depth,formattedValue}:ComputedDatum<CameraData>) =>{
//     return (
//         <text
//             x={centerX}
//             y={centerY}
//             textAnchor="middle"
//             dominantBaseline="central"
//             style={{
//                 fill: theme.textColor,
//                 fontSize: "42px",
//                 fontWeight: 600
//             }}
//         >
//             {Number.parseFloat(`${formattedValue}`)}
//         </text>
//     )
// }

export default function CountingCameraPieChart({
    camera
}: IVehicleCountCameraPieChart) {
    const theme = useTheme()
    const {t} = useTranslation()
    const [formattedData,setFormattedData] = useState<CameraData>({
        name:camera.name,
        color:"#000000",
        children:[]
    })
    useEffect(()=>{
        setFormattedData(formatData(camera,t))
    },[camera,t])

    return<ResponsiveSunburst
                key={`sunburst-count-${camera.name}`}
                data={formattedData}
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                id="id"
                value="count"
                animate
                inheritColorFromParent={false}
                borderWidth={3}
                borderColor={{theme:"background"}}
                childColor={(_,child)=>{
                    return child.data.color
                }}
                cornerRadius={4}
                enableArcLabels={true}
                arcLabelsSkipAngle={15}
                colors={(element)=>{
                    return element.data.color
                }}

                valueFormat={(value)=>`${value.toLocaleString()}`}
                layers={["arcs","arcLabels", CenteredMetric]}
                theme={theme}
            />
}


const formatData = (cameraCount:IVehicleCountCamera,t:TFunction<"translation", undefined>):CameraData => {
    const cameraData:CameraData = {
        name: cameraCount.name,
        color:"#c2c2c2",
        children:cameraCount.zones.map((z,index)=>({
            id:z.name,
            name:z.name,
            color:colorsList[index % colorsList.length],
            children: z.vehicles.map((v)=>{
                const tr = t(`vehicle-classes.${v.name}`)
                return {
                    id: `${z.name} > ${tr}`,
                    name: tr,
                    color: getVehicleColor(v.name),
                    count: v.count
                }
            })
        }))
    }
    return cameraData
}