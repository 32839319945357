import { IResolution } from "Types/IResolution"
import { assertUnreachable } from "./assertUnrechable"

const ONE_MINUTE_IN_SECONDS = 60
const ONE_HOUR_IN_SECONDS = 60 * ONE_MINUTE_IN_SECONDS

export function convertResolutionToSeconds(resolution: IResolution): number {
    switch (resolution) {
        case "m":
        case "1m":
            return ONE_MINUTE_IN_SECONDS
        case "2m":
            return 2 * ONE_MINUTE_IN_SECONDS
        case "3m":
            return 3 * ONE_MINUTE_IN_SECONDS
        case "4m":
            return 4 * ONE_MINUTE_IN_SECONDS
        case "5m":
            return 5 * ONE_MINUTE_IN_SECONDS
        case "6m":
            return 6 * ONE_MINUTE_IN_SECONDS
        case "10m":
            return 10 * ONE_MINUTE_IN_SECONDS
        case "15m":
            return 15 * ONE_MINUTE_IN_SECONDS
        case "20m":
            return 20 * ONE_MINUTE_IN_SECONDS
        case "30m":
            return 30 * ONE_MINUTE_IN_SECONDS
        case "h":
        case "1h":
            return ONE_HOUR_IN_SECONDS
        case "2h":
            return 2 * ONE_HOUR_IN_SECONDS
        case "3h":
            return 3 * ONE_HOUR_IN_SECONDS
        case "4h":
            return 4 * ONE_HOUR_IN_SECONDS
        case "6h":
            return 6 * ONE_HOUR_IN_SECONDS
        case "12h":
            return 12 * ONE_HOUR_IN_SECONDS
        case "d":
            return 24 * ONE_HOUR_IN_SECONDS
        case "w":
            return 24 * 7 * ONE_HOUR_IN_SECONDS
        case "M":
            return 30 * 24 * ONE_HOUR_IN_SECONDS
        case "y":
            return 365 * 24 * ONE_HOUR_IN_SECONDS
        default:
            assertUnreachable(resolution)
    }
}