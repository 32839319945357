import { ForwardedRef, forwardRef, useEffect, useState } from "react"
import axios from "axios"
import { useMutation, useQuery, useQueryClient, UseQueryResult } from "react-query"
import {
    Paper,
    CardHeader,
    Typography,
    Divider,
    Box,
    IconButton,
    Menu,
    MenuItem,
    SxProps
} from "@mui/material"
import { faChartBar, faChartLine, faChartNetwork, faChartPie, faEllipsisH, faThList,faGameBoardSimple, IconDefinition } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { IWidget, IWidgetData, IWidgetWithData, IWidgetTypes } from "Components/Widgets/types"
import WidgetSwitch from "./WidgetSwitch"
import WidgetCreator from "Components/WidgetCreator/WidgetCreator"

async function getWidgetData(id: string) {
    const response = await axios.get<IWidgetData>(`/api/event/v1/data-widgets/${id}/data`)
    return response.data
}

export function Loading() {
    return <Box sx={{ display: "flex", justifyContent: "center" }}>
        <DelayedCircularProgress delay={250} />
    </Box>
}

export function Error() {
    const { t } = useTranslation()
    return <Typography variant="h5" color="text.secondary" textAlign="center" lineHeight={3}>
        {t("data-analytics-page.failed-to-load-data")}
    </Typography>
}

export function getWidgetTimeFrame(from: string, to: string) {
    if (from === "now/d" && (to === "now/d" || to === "now"))
        return "today"
    if (from === "now-1d/d" && to === "now-1d/d")
        return "yesterday"
    if (from === "now/w" && to === "now/w")
        return "this-week"

    return "custom-time-frame"
}

export const WidgetBase = forwardRef(({
    widget,
    actions,
    cardHeaderClassName,
    cardHeaderStyle,
    widgetDataQuery,
    ...props
}: {
    widget: IWidget
    actions?: React.ReactNode
    cardHeaderClassName?: string
    widgetDataQuery: UseQueryResult<IWidgetData>
    cardHeaderStyle?: SxProps

}, ref: ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation()

    const { data: widgetData, isLoading, isLoadingError, isSuccess, refetch } = widgetDataQuery

    useEffect(() => {
        if (!widget.refreshIntervalSeconds) return
        const interval = setInterval(() => {
            refetch()
        }, widget.refreshIntervalSeconds * 1000)
        return () => clearInterval(interval)
    }, [refetch, widget])

    const icon: Record<IWidgetTypes, IconDefinition> = {
        "Line": faChartLine,
        "Bar": faChartBar,
        "Donut": faChartPie,
        "Table": faThList,
        "Chord": faChartNetwork,
        "Heatmap": faGameBoardSimple
    }

    return <Paper {...props} ref={ref}>
        <CardHeader
            avatar={<FontAwesomeIcon icon={icon[widget.chartTypeId]} />}
            title={<Typography fontSize="16px" sx={{...cardHeaderStyle}}>{widget.name}</Typography>}
            className={cardHeaderClassName}
            action={actions}
            sx={{ height: "62px" }}
            subheader={t(`event-filter.${getWidgetTimeFrame(widget.timespanFilterTypeRelativeFromGe, widget.timespanFilterTypeRelativeToLe)}`)}
        />
        <Divider />
        {(() => {
            if (isLoading)
                return <Loading />

            if (isLoadingError)
                return <Error />

            if (isSuccess)
                return <Box height="305px" p={1} overflow="hidden">
                    <WidgetSwitch widget={{ ...widget, ...widgetData } as IWidgetWithData} />
                </Box>
        })()}
    </Paper>
})

export const DashboardWidget = forwardRef(({
    widget,
    eTag,
    ...props
}: {
    widget: IWidget
    eTag: string
}, ref: ForwardedRef<HTMLDivElement>) => {
    const queryClient = useQueryClient()

    const { mutate: deleteWidget } = useMutation(({ id, eTag }: { id: string, eTag: string }) => {
        return axios.delete(`/api/event/v1/data-widgets/${id}`, { headers: { "If-Match": eTag } })
    }, {
        onSuccess: () => queryClient.invalidateQueries("layout")
    })

    const widgetDataQuery = useQuery(["widgetData", widget.id], () => getWidgetData(widget.id))

    const [showWidgetCreator, setShowWidgetCreator] = useState(false)

    const onSettingsClicked = () => {
        setShowWidgetCreator(true)
    }

    const onRemove = (id: string, eTag: string) => {
        deleteWidget({ id, eTag })
    }

    return <>
        <WidgetBase
            widget={widget}
            widgetDataQuery={widgetDataQuery}
            actions={<OptionsMenu onSettingsClicked={onSettingsClicked} onRemove={() => onRemove(widget.id, eTag)} />}
            ref={ref}
            cardHeaderClassName="widget-draggable-handle"
            cardHeaderStyle={{ cursor: "grab", "&:active": { cursor: "grabbing" } }}
            {...props}
        />
        <WidgetCreator open={showWidgetCreator} handleClose={() => setShowWidgetCreator(false)} widgetId={widget.id} />
    </>
})

interface IOptionsMenu {
    onRemove: () => void
    onSettingsClicked: () => void
}

function OptionsMenu({ onRemove, onSettingsClicked }: IOptionsMenu) {
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    return (
        <>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <FontAwesomeIcon icon={faEllipsisH} style={{ fontSize: ".85em" }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom"
                }}
            >
                <MenuItem onClick={onSettingsClicked}>{t("widget.edit")}</MenuItem>
                <MenuItem onClick={onRemove}>{t("widget.remove")}</MenuItem>
            </Menu>
        </>
    )
}