import { Box, Stack } from "@mui/material"
import GateCountingVehicleDetail, { ICountingVehicleData } from "Pages/DataAnalytics/CountingVehicleDetail"
import GateHeatmapChart from "Pages/DataAnalytics/GateTab/GateHeatmapChart"
import { IGateData } from "Pages/DataAnalytics/GateTab/getGateData"
import { useEffect, useState } from "react"

export default function GateCountWidget({ data }: { data: IGateData[] }) {
    const [selectedDetail, setSelectedDetail] = useState<ICountingVehicleData>()

    useEffect(()=>{
        setSelectedDetail(undefined)
    },[data])


    const onCellSelected = (data:ICountingVehicleData) =>{
        setSelectedDetail(data)
    }
    return( <Stack direction={"row"}
    gap="10px" height={303}>


    <Box  width="100%">
        <GateHeatmapChart
            data={data ?? []}
            onChange={onCellSelected}
        ></GateHeatmapChart>
    </Box>
    <GateCountingVehicleDetail
        data={selectedDetail}
    ></GateCountingVehicleDetail>
    </Stack>  )
}