import { IEventFilterSelection } from "../types/IDataAnalyticsFilterSelection"

export const defaultSelection: IEventFilterSelection = {
    cameraGroupIds: [],
    cameraIds: [],
    zoneIds: [],
    resolution: "1h",
    timeFrame: { type: "today" },
    eventTypeIds: [],
    includeFiltered: false,
    includeSimulated: false,
    includeMarkedAsTrue: true,
    includeMarkedAsFalse: true,
    includeUnmarked: true
}