import { Box, Paper, Typography } from "@mui/material"
import { Feature, GeoJsonProperties, Geometry } from "geojson"
import { Layer, Source,MapLayerMouseEvent, MapRef } from "react-map-gl/maplibre"
import { viewport as zoomCalc} from "@mapbox/geo-viewport"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import Map from "Components/Map/Map"
import axios from "axios"
import calculateBoundingBox, { BoundingBox } from "Components/Map/utils/calculateBoundingBox"
import { useQuery } from "react-query"
import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from "oidc-react"
import { useEnqueueSnackbar } from "utils/useEnqueueSnackbar"
import { HubConnectionBuilder } from "@microsoft/signalr"
import CameraDetails, { ICameraDetailsWidget } from "./CameraDetails"
import { useThemeContext } from "Contexts/ThemeContext"
import DragonTitle from "Components/DragonTitle"
import { IPosition } from "Types/IPosition"
import DragonPageWrapper from "Components/DragonPageWrapper"
import { createUnclusteredCamerasNameLayer, clusterLayer, unclusteredCamerasPointLayer, clusterCountLayer } from "Components/Map/layers/clusterLayers"
import { GeoJSONSource } from "react-map-gl"


export default function MapOverview() {
    const { t } = useTranslation()

    const { data, isLoading, isError, isSuccess } = useQuery(
        "map-overview-cameras",
        () => getMapData()
    )


    return <DragonPageWrapper>
        <DragonTitle title={t("map-overview-page.title")} />
        {(() => {
            if (isLoading)
                return <DelayedCircularProgress delay={250} />

            if (isError || !isSuccess)
                return <Typography>{t("failed-to-load-map")}</Typography>

            if (Object.keys(data).length === 0)
                return <Typography>{t("you-dont-have-access-to-any-cameras")}</Typography>

            const positions = Object
                .values(data)
                .map(({ position }) => position)
                .filter((position): position is IPosition => !!position)

            // TODO: Expose padding prop from map
            const boundingBox = calculateBoundingBox(positions)

            return <MapView
                boundingBox={boundingBox}
                data={data}
            />
        })()}
    </DragonPageWrapper>
}

interface IMapView {
    boundingBox: BoundingBox
    data: IMapCameras
}

function MapView({ boundingBox, data: initalData }: IMapView) {
    const auth = useAuth()
    const enqueueSnackbar = useEnqueueSnackbar()
    const { mode } = useThemeContext()
    const { t } = useTranslation()

    const centralPoint = {
        latitude: (boundingBox.max.latitude + boundingBox.min.latitude) / 2,
        longitude: (boundingBox.max.longitude + boundingBox.min.longitude) / 2
    }
    
    const [viewport, setViewport] = useState<{ position: IPosition, zoom: number }>({
        position: centralPoint,
        zoom: 9
    })

    const [data, setData] = useState<IMapCameras>(initalData)

    useEffect(() => {
        setData(initalData)
    }, [initalData])

    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const width = ref.current ? ref.current.offsetWidth : 0
        const height= ref.current ? ref.current.offsetHeight : 0
        const geo = zoomCalc([boundingBox.min.latitude,boundingBox.min.longitude,boundingBox.max.latitude,boundingBox.max.longitude],[width,height])
        setViewport({
            position:{
                latitude: geo.center[0],
                longitude: geo.center[1]
            },
            zoom:geo.zoom
        })
    }, [boundingBox.min.latitude,boundingBox.min.longitude,boundingBox.max.latitude,boundingBox.max.longitude])

    const [selectedCameraId, setSelectedCameraId] = useState<string | null>(null)

    const onSignalREvent = useCallback((eventDto: ISignalRMapCameraEventDto): void => {
        setData((prevState): IMapCameras => {
            if (!(eventDto.cameraId in prevState)) {
                return prevState
            }

            const cameraId = eventDto.cameraId

            const cameraData = prevState[cameraId]

            // Remove event that has ended from active events
            if (typeof eventDto.endedAt === "string")
                return {
                    ...prevState,
                    [cameraId]: {
                        ...prevState[cameraId],
                        events: cameraData.events.filter(event => event.id !== eventDto.id)
                    }
                }

            const event = mapEventDto(eventDto)

            // Add the new event to the active events list
            return {
                ...prevState,
                [cameraId]: {
                    ...prevState[cameraId],
                    events: [...cameraData.events, event]
                }
            }
        })
    }, [])

    useEffect(() => {
        const hubConnection = new HubConnectionBuilder()
            .withUrl("/hubs/event/v1/events/active-events", {
                accessTokenFactory: () => {
                    if (!auth?.userData?.access_token)
                        throw new Error("User has no access token!")

                    return auth.userData.access_token
                }
            })
            .withAutomaticReconnect()
            .build()

        hubConnection
            .start()
            .then(() => hubConnection.on("ActiveEvent", onSignalREvent))
            .catch(() => enqueueSnackbar(t("map-overview-page.live-connection-failed"), { variant: "error" }))

        return () => {
            hubConnection.stop()
        }
    }, [auth, enqueueSnackbar, t, onSignalREvent])

    function onClick(event:MapLayerMouseEvent, map: MapRef) {
        if (!event.features || event.features.length === 0) {
            setSelectedCameraId(null)
            return
        }
        const feature = event.features[0]
        const layerId = feature.layer.id

        switch (layerId) {
            case "unclustered-camera-point":
            case "unclustered-camera-name":
                const cameraId = feature.properties?.id
                setSelectedCameraId(cameraId)
                const point = feature.geometry as GeoJSON.Point
                            map.flyTo({
                                center:{
                                    lat:point.coordinates[1],
                                    lon:point.coordinates[0]
                                },
                                animate:true
                            })
                break
            case "clusters":
                const clusterId = feature.properties?.cluster_id

                const mapboxSource = map.getSource("events")
                if(mapboxSource){
                    if (mapboxSource.type !== "geojson")
                        break
                    const geoSource= mapboxSource as unknown as GeoJSONSource
                    geoSource.getClusterExpansionZoom(clusterId, (err: Error, zoom: number) => {
                            if (err)
                                throw err
                            const point = feature.geometry as GeoJSON.Point
                            map.flyTo({
                                center:{
                                    lat:point.coordinates[1],
                                    lon:point.coordinates[0]
                                },
                                animate:true,
                                zoom:zoom
                            })
                        })
                
                }
                break
        }
    }

    const selectedCamera = selectedCameraId
        ? data[selectedCameraId]
        : null

    const unclusteredCamerasNameLayer = createUnclusteredCamerasNameLayer(mode)

    return <Paper sx={{ height: "100vh", overflow: "hidden"}}  ref={ref}>
        <Map
            position={viewport.position}
            zoom={viewport.zoom}
            defaultZoomLevel={viewport.zoom}
            allowInteraction
            interactiveLayerIds={[clusterLayer.id!, unclusteredCamerasNameLayer.id!, unclusteredCamerasPointLayer.id!]}
            onClick={onClick}
            mapCenter={{ ...centralPoint, zoom: viewport.zoom }}
        >
            <Source
                id="events"
                type="geojson"
                data={{
                    type: "FeatureCollection",
                    features: Object
                        .values(data)
                        .map((entry): Feature<Geometry, GeoJsonProperties> => ({
                            type: "Feature",
                            properties: {
                                id: entry.id,
                                name: entry.name,
                                "has-system-events": entry.events.some(event => event.eventTypeGroup.code === "SystemEvents"),
                                "has-traffic-events": entry.events.some(event => event.eventTypeGroup.code === "TrafficEvents"),
                                "has-people-events": entry.events.some(event => event.eventTypeGroup.code === "PeopleEvents"),
                                "selected": selectedCameraId === entry.id
                            },
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    entry.position.longitude,
                                    entry.position.latitude
                                ]
                            }
                        }))
                }}
                cluster
                clusterMaxZoom={16}
                clusterRadius={22}
                clusterProperties={{
                    "has-system-events": ["any", ["get", "has-system-events"]],
                    "has-traffic-events": ["any", ["get", "has-traffic-events"]],
                    "has-people-events": ["any", ["get", "has-people-events"]]
                }}
            >
                <Layer {...clusterLayer}/>
                <Layer {...clusterCountLayer} />
                <Layer {...unclusteredCamerasPointLayer} />
                <Layer {...unclusteredCamerasNameLayer} />
            </Source>
            {selectedCamera &&
                <Box
                    sx={{
                        direction: "rtl",
                        overflow: "auto",
                        width: "calc(100% - 72px)",
                        height: "100%"
                    }}
                >
                    <Box sx={{ direction: "ltr" }}>
                        <CameraDetails {...selectedCamera} />
                    </Box>
                </Box>
            }
        </Map>
    </Paper>
}

function mapEventDto(dto: IMapCameraEventDto): IMapCameraEvent {
    return {
        ...dto,
        startedAt: new Date(dto.startedAt),
        endedAt: dto.endedAt !== null
            ? new Date(dto.endedAt)
            : null
    }
}
interface IMapCameraDto {
    id: string
    name: string
    position: {
        latitude: number
        longitude: number
    }
    cameraGroups:
    {
        id: string
        name: string
    }[]
    widgetTemplates: ICameraDetailsWidget[]
    events: IMapCameraEventDto[]
}

interface IMapCameraEventDto {
    id: string,
    zone: {
        id: string
        name: string
    } | null
    eventTypeId: string
    eventTypeGroup: {
        id: string
        name: string
        code: string
    }
    startedAt: string
    endedAt: string | null
}

interface ISignalRMapCameraEventDto extends IMapCameraEventDto {
    cameraId: string
}

export interface IMapCameraEvent extends Omit<IMapCameraEventDto, "startedAt" | "endedAt"> {
    startedAt: Date
    endedAt: Date | null
}

export interface IMapCamera extends Omit<IMapCameraDto, "events"> {
    events: IMapCameraEvent[]
}

interface IMapCameras {
    [cameraId: string]: IMapCamera
}

async function getMapData(): Promise<IMapCameras> {

    const response = await axios.get<IMapCameraDto[]>(
        "/api/event/v1/cameras/active-events"
    )

    return response
        .data
        .filter(entry =>
            entry.position !== null
        )
        .reduce<IMapCameras>((acc, camera) => {
            if (camera.position === null)
                throw new Error("Camera is missing position!")

            acc[camera.id] = {
                ...camera,
                events: camera
                    .events
                    .map(mapEventDto)
            }

            return acc
        }, {})
}