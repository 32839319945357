import { faCars, faCarSide, faCloud, faCube, faExclamationTriangle, faEyeSlash, faHammer, faMinusCircle, faMotorcycle, faPeopleArrows, faQuestionSquare, faRandom, faShuttleVan, faSkullCrossbones, faTachometerAltFastest, faTachometerAltSlowest, faTachometerSlowest, faTruckMoving, faUserFriends, faVideoSlash, faWalking, faAlien8bit, faScribble } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip } from "@mui/material"
import { blue, red, yellow } from "App/colors"
import { CSSProperties } from "react"
import { useTranslation } from "react-i18next"

const eventTypeIconMap = {
    "CameraFault": faVideoSlash,
    "Car": faCarSide,
    "CargoLoss": faCube,
    "Congested": faCars,
    "Crowd": faUserFriends,
    "ImageDegradation": faEyeSlash,
    "LaneChange": faRandom,
    "LossOfVisibility": faCloud,
    "Motorbike": faMotorcycle,
    "Overspeed": faTachometerAltFastest,
    "Pedestrian": faWalking,
    "PlateHazmat": faSkullCrossbones,
    "PlateMissing": faQuestionSquare,
    "Proximity": faPeopleArrows,
    "Queue": faCars,
    "Slowdown": faCars,
    "SpeedDrop": faTachometerSlowest,
    "Stop": faExclamationTriangle,
    "Tampering": faHammer,
    "Truck": faTruckMoving,
    "Underspeed": faTachometerAltSlowest,
    "Van": faShuttleVan,
    "VideoLoss": faVideoSlash,
    "WrongWayDriver": faMinusCircle,
    "Unknownobject": faAlien8bit,
    "Anomaly": faScribble
} as const

export function getEventTypeIcon(eventTypeId: string) {
    if (!(eventTypeId in eventTypeIconMap))
        throw new Error(`Unknown event type id ${eventTypeId}`)

    return eventTypeIconMap[eventTypeId as keyof typeof eventTypeIconMap]
}

const TRAFFIC_EVENT_COLOR = red[500]
const SYSTEM_EVENT_COLOR = yellow[500]
const PEOPLE_EVENT_COLOR = blue[500]

type Color = CSSProperties["color"]

const getChipColors = (eventTypeGroupCode: string): { backgroundColor: Color, color: Color } => {
    switch (eventTypeGroupCode) {
        case "SystemEvents":
            return { backgroundColor: SYSTEM_EVENT_COLOR, color: "black" }
        case "TrafficEvents":
            return { backgroundColor: TRAFFIC_EVENT_COLOR, color: "white" }
        case "PeopleEvents":
            return { backgroundColor: PEOPLE_EVENT_COLOR, color: "white" }
        default:
            throw new Error(`Unknown event type group code: ${eventTypeGroupCode}`)
    }
}

interface IEventTypeChip {
    eventTypeGroup: {
        name: string
        code: string
    }
    eventTypeId: string
    height?: string
}

export default function EventTypeChip({
    eventTypeGroup,
    eventTypeId,
    height="32px"
}: IEventTypeChip) {
    const { t } = useTranslation()

    if (!(eventTypeId in eventTypeIconMap))
        throw new Error(`Missing icon for event type with id ${eventTypeId}`)

    const icon = getEventTypeIcon(eventTypeId)
    const { backgroundColor, color } = getChipColors(eventTypeGroup.code)

    return <Chip
        label={t(`event-types.${eventTypeId}`)}
        icon={<FontAwesomeIcon icon={icon} style={{ color: color }} />}
        sx={{
            height:height,
            backgroundColor: backgroundColor,
            color: color,
            ".MuiChip-label": {
                marginTop: "2px"
            }
        }}
    />
}