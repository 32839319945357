import { IDataSources, IWidgetTypes } from "Components/Widgets/types"
import getFilteredStateIds from "Pages/EventJournal/utils/getFilteredStateIds"
import { IFilteredStateId } from "Types/event/IFilteredStateId"
import { ISimulatedStateId } from "Types/event/ISimulatedStateId"
import { IValidStateId } from "Types/event/IValidStateId"
import { IResolution } from "Types/IResolution"
import { assertUnreachable } from "utils/assertUnrechable"
import IWidgetSelection from "../types/IWidgetSelection"

interface DataWidgetCreateUpdateDto {
    name: string
    sourceId: IDataSources
    chartTypeId: IWidgetTypes
    eventTypeIds?: string[]
    validStateIds: IValidStateId[] | null
    simulatedStateIds: ISimulatedStateId[] | null
    filteredStateIds: IFilteredStateId[] | null
    vehicleClassIds?: string[]
    peopleClassIds?: string[]
    peopleCountDirectionIds?: ("PeopleCountDirectionUnknown" | "PeopleCountDirectionIn" | "PeopleCountDirectionOut")[] | null
    timespanFilterTypeRelativeFromGe: string
    timespanFilterTypeRelativeToLe: string
    timespanGroupBy?: IResolution
    timeZone: string
    limit?: number
    refreshIntervalSeconds?: number
    cameraGroupIds?: string[]
    cameraIds?: string[]
    zoneIds?: string[]
}

export function filterFromSelection(selection: IWidgetSelection) {

    const getPeopleCountDirectionIds = ({ includeComing, includeGoing }: { includeComing: boolean, includeGoing: boolean }) => {
        const peopleCountDirectionIds = []
        if (includeComing)
            peopleCountDirectionIds.push("PeopleCountDirectionIn")
        if (includeGoing)
            peopleCountDirectionIds.push("PeopleCountDirectionOut")

        return { "peopleCountDirectionIds": peopleCountDirectionIds.length > 0 ? peopleCountDirectionIds : null }
    }

    const [from, to] = ((): [string, string] => {
        switch (selection.timeFrame.type) {
            case "today":
                return ["now/d", "now/d"]
            case "yesterday":
                return ["now-1d/d", "now-1d/d"]
            case "this-week":
                return ["now/w", "now/w"]
            case "custom":
                throw new Error("Invalid time frame")
            default:
                assertUnreachable(selection.timeFrame)
        }
    })()

    const requestBody = {
        name: selection.name,
        sourceId: selection.dataSource,
        chartTypeId: selection.widgetType,
        eventTypeIds: selection.eventTypeIds,
        ...getFilteredStateIds(selection),
        vehicleClassIds: selection.vehicleClassIds,
        peopleClassIds: selection.peopleClassIds,
        ...getPeopleCountDirectionIds(selection),
        timespanFilterTypeRelativeFromGe: from,
        timespanFilterTypeRelativeToLe: to,
        timespanGroupBy: selection.resolution,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        limit: selection.limit,
        refreshIntervalSeconds: selection.refreshInterval,
        zoneIds: selection.zoneIds,
        cameraIds: selection.cameraIds,
        cameraGroupIds: selection.cameraGroupIds
    } as DataWidgetCreateUpdateDto

    return requestBody
}