import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate, useLocation, Outlet } from "react-router-dom"
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "react-query"
import {
    Stack,
    Paper,
    TextField,
    Typography,
    Divider,
    Grid,
    FormControl,
    Button,
    Select,
    MenuItem,
    SelectChangeEvent
} from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/pro-regular-svg-icons"

import ErrorBoundary from "utils/ErrorBoundary"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { IDataWithEtag } from "utils/queryHelpers"
import GoBackButton from "Components/DragonGoBackButton"
import { DragonConfirmationDialog, DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import { IScenarioGroupReadDto, IScenarioGroupCreateDto, IScenarioGroupUpdateDto, ITeamSelectDto } from "Types/admin"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"
import ScenarioList from "./Scenarios"

type IScenarioGroupDetails = IScenarioGroupReadDto
type IScenarioGroupCreate = IScenarioGroupCreateDto
type IScenarioGroupUpdate = IScenarioGroupUpdateDto
type IScenarioGroupDetailsWithEtag = IDataWithEtag<IScenarioGroupDetails>



const getScenarioGroupDetails = async ({ queryKey }: QueryFunctionContext<[string, string]>): Promise<IScenarioGroupDetailsWithEtag> => {
    const [, id] = queryKey
    const response = await axios.get<IScenarioGroupDetails>(`/api/scenario/v1/scenario-management/groups/${id}`)
    return {
        data: response.data,
        eTag: response.headers.etag
    }
}

const postScenarioGroupDetails = async (payload: IScenarioGroupCreate): Promise<AxiosResponse> => {
    return await axios.post("/api/scenario/v1/scenario-management/groups", payload)
}

const putScenarioGroupDetails = async (id: string, eTag: string, payload: IScenarioGroupUpdate): Promise<AxiosResponse> => {
    return await axios.put(`/api/scenario/v1/scenario-management/groups/${id}`, payload, { headers: { "If-Match": eTag } })
}

const deleteScenarioGroup = async (id: string, eTag: string): Promise<AxiosResponse> => {
    return await axios.delete(`/api/scenario/v1/scenario-management/groups/${id}`, { headers: { "If-Match": eTag } })
}

const getTeams = async ():Promise<ITeamSelectDto[]> => {
    const response = await axios.get<ITeamSelectDto[]>("/api/scenario/v1/team/list")
    return response.data
}
interface Props {
    scenarioGroup: IScenarioGroupDetailsWithEtag
    newScenarioGroup: boolean
    teams: ITeamSelectDto[]
}

export const ScenarioGroupDetails: FC<Props> = ({ scenarioGroup, newScenarioGroup,teams }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const initialState = useMemo<IScenarioGroupCreate>(() => ({
        name: scenarioGroup.data.name,
        description: scenarioGroup.data.description,
        teamId: scenarioGroup.data.teamId
        //scenarioIds: scenarioGroup.data.scenarios.map(({ id }) => id)
    }), [scenarioGroup.data])
    const [draft, setDraft] = useState<IScenarioGroupCreate>(initialState)
    const [saveDialog, setSaveDialog] = useState<boolean>(false)
    const [cancelDialog, setCancelDialog] = useState<boolean>(false)
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)

    const [saveError, setSaveError] = useState<string | null>(null)

    const isChanged = useMemo<boolean>(
        () => (
            initialState.name !== draft.name ||
            initialState.description !== draft.description
        ),
        [draft, initialState]
    )

    const validation = useMemo<Partial<Record<keyof IScenarioGroupCreate, boolean>>>(
        () => (
            {
                name: draft.name.length > 0 && draft.name.length <= 100,
                description: (draft.description?.length ?? 0)<= 1000,
                teamId: !!draft.teamId && draft.teamId.length > 0 && teams.some(t => t.id === draft.teamId)
            }
        ),
        [draft.name,draft.description, draft.teamId, teams]
    )

    const { mutateAsync: handleSaveChangesAsync, isLoading: putLoading } = useMutation(
        () => {
            if (newScenarioGroup) return postScenarioGroupDetails(draft)
            return putScenarioGroupDetails(scenarioGroup.data.id, scenarioGroup.eTag, draft)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("scenario-groups")
                if (!newScenarioGroup) queryClient.invalidateQueries(["scenario-group", scenarioGroup.data.id])
            },
            onError: (error: AxiosError<{title:string}>) => {
                if (error.response?.data?.title === "scenario.group.name.duplicate")
                    setSaveError(t("scenario-group-details-page.name-already-taken"))
                else
                    setSaveError(t("scenario-group-details-page.save-changes_failed"))
            }
        }
    )

    const { mutateAsync: handleDeleteScenarioGroup, isLoading: deleteLoading } = useMutation(
        () => deleteScenarioGroup(scenarioGroup.data.id, scenarioGroup.eTag),
        { onSuccess: () => { queryClient.invalidateQueries("scenario-groups") } }
    )

    const handleChange = useCallback<(key: keyof IScenarioGroupUpdate, value: unknown) => void>(
        (key, value) => {
            setDraft(prevState => ({ ...prevState, [key]: value }))
        }, [])
    const handleChangeTeam = useCallback<
        () => (event: SelectChangeEvent<string>) => void
            >(() => (event) => {
                if(newScenarioGroup) setDraft(prevState =>({ ...prevState, teamId:event.target.value }))
            }, [newScenarioGroup])

    return (
        <>
            <GoBackButton />
            <DragonTitle title={scenarioGroup.data.name || t("create-scenario-group")} />
            <Paper sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
            <Grid container padding={2} spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Typography variant="h6" fontWeight="bold">
                                    {t("scenario-group-details-page.name")}
                                </Typography>
                                <TextField
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: scenarioGroup.data.name === draft.name,
                                        endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                    }}
                                    value={draft.name}
                                    onChange={(event) => handleChange("name", event.target.value)}
                                    error={!validation.name}
                                    fullWidth
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <Typography variant="h6" fontWeight="bold">
                                    {t("scenario-group-details-page.description")}
                                </Typography>
                                <TextField
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: scenarioGroup.data.description === draft.description,
                                        endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                    }}
                                    value={draft.description}
                                    onChange={(event) => handleChange("description", event.target.value)}
                                    error={!validation.description}
                                    fullWidth
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <Typography variant="h6" fontWeight="bold">
                                    {t("scenario-group-details-page.team")}
                                </Typography>
                                <Select
                                            variant="filled"
                                            value={draft.teamId}
                                            onChange={handleChangeTeam()}
                                            disabled={!newScenarioGroup}
                                            disableUnderline={initialState.teamId === draft.teamId}
                                        >
                                            <MenuItem value={"00000000-0000-0000-0000-000000000000"} key={"00000000-0000-0000-0000-000000000000"}>{t("scenario-group-details-page.no-team")}</MenuItem>
                                            {
                                                teams.map(t =><MenuItem value={t.id} key={t.id}>{t.name}</MenuItem>)

                                            }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                <Divider flexItem />
                <Stack flex={1} spacing={2} p={2} overflow="hidden">
                        <ScenarioList />
                </Stack>
            </Paper>

            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                {!newScenarioGroup && <Button
                    variant="outlined"
                    color="error"
                    sx={{ minWidth: 150 }}
                    onClick={() => setDeleteDialog(true)}
                >
                    {t("scenario-group-details-page.delete-group")}
                </Button>}
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: 150 }}
                    onClick={() => setSaveDialog(true)}
                    disabled={!isChanged || Object.values(validation).some(v => !v)}
                >
                    {newScenarioGroup ? t("create-scenario-group") : t("save-changes")}
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ minWidth: 150 }}
                    onClick={() => {
                        if (isChanged) setCancelDialog(true)
                        else navigate("..")
                    }}
                >
                    {t("cancel")}
                </Button>
            </Stack>
            <DragonConfirmationDialogWithPromise
                open={saveDialog}
                variant="save"
                title={t("scenario-group-details-page.save-changes_confirmation")}
                onClose={() => {
                    setSaveDialog(false)
                    setSaveError(null)
                }}
                onConfirm={async () => {
                    await handleSaveChangesAsync()
                }}
                isLoading={putLoading}
                errorText={saveError}
            />
            <DragonConfirmationDialog
                open={cancelDialog}
                variant="warning"
                title={t("scenario-group-details-page.discard-changes_confirmation")}
                onClose={() => setCancelDialog(false)}
                onConfirm={() => {
                    setDraft(initialState)
                    navigate("..")
                }}
            />
            <DragonConfirmationDialogWithPromise
                open={deleteDialog}
                variant="delete"
                title={t(
                    "scenario-group-details-page.delete-scenario-group_confirmation",
                    { scenario_group: scenarioGroup.data.name}
                )}
                onClose={() => setDeleteDialog(false)}
                onConfirm={async () => {
                    await handleDeleteScenarioGroup()
                    navigate("..")
                }}
                isLoading={deleteLoading}
                errorText={t("scenario-group-details-page.delete-scenario-group_failed")}
            />
        </>
    )
}

export const ScenarioGroupDetailsContainer: FC = () => {
    const { t } = useTranslation()
    const { scenarioGroupId } = useParams<"scenarioGroupId">()
    const [error, setError] = useState("")

    const showGroup = useLocation().pathname.indexOf("/scenario/") === -1
    const {
        isLoading,
        isError,
        isSuccess,
        data
    } = useQuery<IScenarioGroupDetailsWithEtag, AxiosError, IScenarioGroupDetailsWithEtag, [string, string]>(
        ["scenario-group", scenarioGroupId ?? "default"],
        getScenarioGroupDetails,
        {
            onError: (error) => {
                if (error.response?.status === 404)
                    return setError(t("scenario-group-details.scenario-group-does-not-exist"))
                return setError(t("something-went-wrong"))
            }
        }
    )

    const {
        isLoading:teamLoading,
        isError:teamError,
        isSuccess:teamSuccess,
        data: teams
    } = useQuery<ITeamSelectDto[], AxiosError, ITeamSelectDto[], [string]>(
        ["scenario-teams"],
        getTeams,
        {
            onError: (error) => {
                if (error.response?.status === 404)
                    return setError(t("scenario-group-details.scenario-group-cannot-load-teams"))
                return setError(t("something-went-wrong"))
            }
        }
    )

    return (
        <>
        {showGroup &&<DragonPageWrapper>
            {(() => {
                if (isLoading || teamLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError || teamError)
                    return <h2>{error}</h2>

                if (isSuccess && teamSuccess)
                    return (
                        <ErrorBoundary>
                            <ScenarioGroupDetails scenarioGroup={data} newScenarioGroup={!scenarioGroupId} teams={teams} />
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>}
        <Outlet />
        </>
    )
}

export default ScenarioGroupDetailsContainer