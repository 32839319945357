import { useCallback, useEffect, useMemo, useState } from "react"
import axios from "axios"
import { useInfiniteQuery, QueryFunctionContext, useMutation } from "react-query"
import { HubConnectionBuilder } from "@microsoft/signalr"
import { useAuth } from "oidc-react"
import { useTranslation } from "react-i18next"
import {
    Typography,
    Paper,
    Checkbox,
    Button,
    keyframes,
    useTheme,
    Palette,
    Box,
    IconButton,
    Divider,
    Stack
} from "@mui/material"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"

import { HeaderCell, BodyRow } from "Components/DragonTable"
import EventDetailsWrapper from "Pages/EventDetails/EventDetails"
import { IEntityWithEtag } from "utils/queryHelpers"
import { formatDate, formatTime } from "utils/timedisplay"
import AcknowledgeAllModal from "./AcknowledgeAllModal"
import EventMgrMap from "./EventMgrMap"
import { useEnqueueSnackbar } from "utils/useEnqueueSnackbar"
import { InfiniteScrollDragonTable } from "Components/DragonTable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getEventTypeIcon } from "Components/EventTypeChip"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import DragonTitle from "Components/DragonTitle"
import { yellow } from "App/colors"
import DragonPageWrapper from "Components/DragonPageWrapper"
import { IAcknowledgeStateId } from "Types/event/IAcknowledgeStateId"
import { IEventManagerDto, IEventManagerEvent } from "./types"
import { useUserProfile } from "hooks/useUserProfile"

type IEventWithEtag = IEntityWithEtag<IEventManagerEvent>
type IEventDtoWithEtag = IEntityWithEtag<IEventManagerDto>

interface EventUpdateAcknowledgeBatchDto {
    id: string
    entity: {
        acknowledgeStateId: IAcknowledgeStateId
    }
    ifMatch: string
}

const fadeIn = (palette: Palette) => keyframes({
    from: {
        backgroundColor: palette.primary.main
    }
})

async function getEvents({ pageParam: lastStartedAt }: QueryFunctionContext): Promise<IEventWithEtag[]> {
    const limit = lastStartedAt ? 25 : 50
    const response = await axios.get<IEventDtoWithEtag[]>("/api/event/v1/events/event-manager", {
        params: {
            limit,
            lastStartedAt
        }
    })
    return response
        .data
        .map(({ eTag, entity }) => ({
            eTag: eTag,
            entity: mapEventDtoToEvent(entity)
        }))
}

export default function EventManager() {
    const { t } = useTranslation()
    const auth = useAuth()
    const enqueueSnackbar = useEnqueueSnackbar()
    const { palette } = useTheme()
    const navigate = useNavigate()
    const {features} = useUserProfile()
    const mapEnabled = features.some(f => f === "MapEnabled")
    const hideList = useLocation().pathname.startsWith("/event-manager/event")

    const [events, setEvents] = useState<IEventWithEtag[]>([])
    const [focusedEvent, setFocusedEvent] = useState<IEventManagerEvent>()

    const {
        data,
        fetchNextPage,
        isFetching,
        hasNextPage = false
    } = useInfiniteQuery("event-manager", getEvents, {
        getNextPageParam: (lastPage, _) => {
            if (lastPage.length === 0)
                return undefined
            return lastPage[lastPage.length - 1].entity.startedAt
        },
        keepPreviousData: true
    })

    useEffect(() => {
        if (!data)
            return

        setEvents(existingEvents => {
            const newEvents = data
                .pages
                .flat()
                .filter(event => !existingEvents.some(existingEvent => existingEvent.entity.id === event.entity.id))

            if (newEvents.length === 0)
                return existingEvents

            return [
                ...existingEvents,
                ...newEvents
            ].sort((a, b) => b.entity.startedAt.valueOf() - a.entity.startedAt.valueOf())
        })
    }, [data])

    const onSignalREvent = (eventDto: IEventDtoWithEtag): void => {
        const newEvent = {
            eTag: eventDto.eTag,
            entity: mapEventDtoToEvent(eventDto.entity)
        }

        setEvents(prevState => {
            const indexInEvents = prevState.findIndex(event => event.entity.id === newEvent.entity.id)

            const eventIsAlreadyInList = indexInEvents >= 0

            if (eventIsAlreadyInList) {
                if (newEvent.entity.isAcknowledged) {
                    // Clear related states that might contain the id which will be removed
                    setSelectedIds(prevState => prevState.filter(selectedId => selectedId !== newEvent.entity.id))
                    setFocusedEvent(prevState => prevState?.id !== newEvent.entity.id ? prevState : undefined)

                    // Remove the acknowledged event
                    return prevState.filter((_, i) => i !== indexInEvents)
                } else {
                    // Replace the existing event with the new event
                    prevState[indexInEvents] = newEvent
                    return prevState
                }
            }
            else {
                if (newEvent.entity.isAcknowledged) {
                    // Ignore all acknowledged events
                    return prevState
                }
                // Otherwise, add it to the top of the list
                return [newEvent, ...prevState]
            }
        })
    }

    useEffect(() => {
        const hubConnection = new HubConnectionBuilder()
            .withUrl("/hubs/event/v1/events/event-manager", {
                accessTokenFactory: () => {
                    if (!auth?.userData?.access_token)
                        throw new Error("User has no access token!")

                    return auth.userData.access_token
                }
            })
            .withAutomaticReconnect()
            .build()

        hubConnection
            .start()
            .then(() => hubConnection.on("EventManager", onSignalREvent))
            .catch(() => enqueueSnackbar(t("event-manager-page.event-manager-live-connection-failed"), { variant: "error" }))

        return () => {
            hubConnection.stop()
        }
    }, [auth, enqueueSnackbar, t])

    const [showAcknowledgeAllModal, setShowAcknowledgeAllModal] = useState(false)

    const [selectedIds, setSelectedIds] = useState<string[]>([])

    const { mutate: acknowledgeEvents } = useMutation((acknowledgedEvents: EventUpdateAcknowledgeBatchDto[]) => {
        return axios.put("/api/event/v1/events/acknowledge/batch", acknowledgedEvents)
    }, {
        onSuccess: (_, acknowledgedEvents) => {
            setEvents(events.filter(event => !acknowledgedEvents.find(e => e.id === event.entity.id)))
            setSelectedIds([])
        },
        onError: () => enqueueSnackbar(t("event-manager-page.failed-to-acknowledge"), { variant: "error" })
    })

    const handleAcknowledgeSelected = useCallback(() => {
        const selectedEvents = events.filter(event => selectedIds.includes(event.entity.id))
        const transformed: EventUpdateAcknowledgeBatchDto[] = selectedEvents.map(event => ({
            id: event.entity.id,
            entity: {
                acknowledgeStateId: "Acknowledged"
            },
            ifMatch: event.eTag
        }))

        acknowledgeEvents(transformed)
    }, [acknowledgeEvents, events, selectedIds])

    const handleAcknowledgeCheckboxChange = useCallback<(eventId: string, checked: boolean) => void>(
        (eventId, checked) => {
            setSelectedIds(prevState => checked ? [...prevState, eventId] : prevState.filter(selectedId => selectedId !== eventId))
        },
    []
    )

    const tableColumns = useMemo<HeaderCell[]>(() => ([
        { value: null, padding: "none", width: 15 },
        { value: t("event-manager-page.camera-zone") },
        { value: t("event-manager-page.event-type") },
        { value: t("event-manager-page.date-time") },
        { value: t("event-manager-page.acknowledge"), align: "center" },
        { value: null }
    ]), [t])

    const tableData = useMemo<BodyRow[]>(() => (
        events.map(({ entity: event }) => (
            {
                key: event.id,
                row: [
                    { value: null, sx: { bgcolor: event.eventTypeGroup.code === "SystemEvents" ? yellow[500] : "unset" }, width: 15, padding: "none" },
                    { value: [event.camera.name, event.zone?.name].filter(Boolean).join(" / ") },
                    {
                        value: <Stack direction="row" spacing={2}>
                            <FontAwesomeIcon icon={getEventTypeIcon(event.eventTypeId)} />
                            <Typography>{t(`event-types.${event.eventTypeId}`)}</Typography>
                        </Stack>
                    },
                    { value: `${formatDate(new Date(event.startedAt))} / ${formatTime(new Date(event.startedAt))}` },
                    {
                        value: <Checkbox
                            checked={selectedIds.includes(event.id)}
                            onChange={(e) => {
                                handleAcknowledgeCheckboxChange(event.id, e.target.checked)
                            }}
                            onClick={(e) => e.stopPropagation()}
                        />, align: "center"
                    },
                    {
                        value: <IconButton onClick={() => navigate(`event/${event.id}`)}>
                            <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: ".8em" }} />
                        </IconButton>, align: "right"
                    }
                ],
                sx: { animation: `${fadeIn(palette)} 1.4s linear` },
                onDoubleClick: () => navigate(`event/${event.id}`),
                onClick: () => setFocusedEvent(event),
                hover: true,
                selected: event === focusedEvent
            }
        ))
    ), [events, focusedEvent, handleAcknowledgeCheckboxChange, navigate, palette, selectedIds, t])

    return (
        <>
            <DragonPageWrapper hidden={hideList}>

                <DragonTitle title={t("event-manager")} />

                <Paper sx={{
                    display: "grid",
                    gridTemplateRows: "auto min-content min-content min-content min-content",
                    overflow: "hidden",
                    flexGrow: 1
                }}>
                    <InfiniteScrollDragonTable
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetching={isFetching}
                        columns={tableColumns}
                        data={tableData}
                        stickyHeader={true}
                        noDataText={t("event-manager-page.no-events")}
                    />
                    <Divider />

                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button variant="outlined" sx={{ m: 1 }} onClick={() => setShowAcknowledgeAllModal(true)} disabled={!events.length}>
                            {t("event-manager-page.acknowledge-all")}
                        </Button>
                        <Button variant="outlined" sx={{ m: 1 }} onClick={handleAcknowledgeSelected} disabled={!selectedIds.length}>
                            {t("event-manager-page.acknowledge-selected")}
                        </Button>
                    </Box>

                    <Divider />

                    { mapEnabled && <Box height={268}>
                        {!focusedEvent
                            ? <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", p: 2 }}>
                                <Typography variant="h6" component="p">{t("event-manager-page.click-on-an-event-to-show-map")}</Typography>
                            </Box>
                            : !focusedEvent.camera.position
                                ? <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", p: 2 }}>
                                    <Typography variant="h6" component="p">{t("event-manager-page.selected-event-is-missing-position")}</Typography>
                                </Box>
                                : <EventMgrMap
                                    position={focusedEvent.camera.position}
                                    snapshotUri={focusedEvent.eventAssets.find(({ assetTypeId }) => assetTypeId === "Snapshot")?.uri}
                                    eventId={focusedEvent.id}
                                />
                        }
                    </Box>}
                </Paper>
            </DragonPageWrapper>

            <Routes>
                <Route path="event/:eventId" element={<EventDetailsWrapper />} />
            </Routes>

            <AcknowledgeAllModal
                open={showAcknowledgeAllModal}
                handleClose={() => setShowAcknowledgeAllModal(false)}
                setEvents={setEvents}
            />
        </>
    )
}

function mapEventDtoToEvent(event: IEventManagerDto): IEventManagerEvent {
    return {
        ...event,
        startedAt: new Date(event.startedAt),
        endedAt: event.endedAt
            ? new Date(event.endedAt)
            : null,
        isAcknowledged: event.acknowledgeStateId === "Acknowledged"
    }
}