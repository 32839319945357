import i18next from "i18next"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { IResolution } from "Types/IResolution"
import { convertDatePartsToDateString, convertTimestampToParts, IDateParts } from "utils/generateTimestampsForTimeframeAndResolution"
import DetailedLineChart, {IChartData, IChartZoomArea } from "../DetailedLineChart"
import { IDragonChartCardActions } from "../DragonChartCardActions"
import { ITrafficData } from "../ITrafficData"

interface ITrafficLineChart {
    data: ITrafficData[]
    resolution: IResolution
    timestamps: IDateParts[]
    onZoomChange: (zoom: IChartZoomArea)=>void
    onZoomReset: ()=>void
    zoom:IChartZoomArea
    syncID?:string
    insertZeroIfNoDataAtTimeStamp?: boolean
    withTotals?:boolean
    cardActionsRef?: React.Dispatch<React.SetStateAction<IDragonChartCardActions | null>>
}

export default function TrafficLineChart({
    data,
    resolution,
    onZoomChange,
    onZoomReset,
    zoom,
    timestamps,
    insertZeroIfNoDataAtTimeStamp = false,
    syncID = undefined,
    withTotals = false,
    cardActionsRef = undefined
}: ITrafficLineChart) {
    const { t } = useTranslation()

    const formattedData = transformData(
        data,
        timestamps,
        resolution,
        insertZeroIfNoDataAtTimeStamp,
        withTotals
    )

    return <DetailedLineChart
        data={formattedData}
        onZoomChange={onZoomChange}
        onZoomReset={onZoomReset}
        zoom={zoom}
        syncID={syncID}
        resolution={resolution}
        legendFormatter={serie => formatSerieName(serie, t)}
        csvFormatter={(name)=>formatCSV(name,data,t)}
        cardActionsRef={cardActionsRef}
        tooltipFormatter={(value, serie) => {
            return [
                value,
                formatSerieName(serie, t)
            ]
        }}
    />
}

function formatSerieName(serie: string, t: typeof i18next.t) {
    const { camera, zone, vehicleClassId }: ITrafficData = JSON.parse(serie)

    const parts = [camera.name, zone.name]

    if (vehicleClassId)
        parts.push(t(`vehicle-classes.${vehicleClassId}`))

    return parts.join(" > ")
}
function formatCSV(name:string,data:ITrafficData[], t: typeof i18next.t):Record<string,unknown>[]{
    return data.map(d=>{
        const record = {} as Record<string,unknown>
        record[t("data-analytics-page.csv-export.time")] = new Date(d.timespanGroup).toLocaleString()
        record[t("data-analytics-page.csv-export.epoch")] = new Date(new Date(d.timespanGroup).toUTCString()).getTime()
        record[t("data-analytics-page.csv-export.camera")] = d.camera.name
        record[t("data-analytics-page.csv-export.zone")] = d.zone?.name || "-"
        record[t("data-analytics-page.csv-export.vehicle-class")] = t(`vehicle-classes.${d.vehicleClassId}`)
        record[name] = d.value
        return record
    })
}
interface ISerie {
    camera: {
        id: string
        name: string
    }
    zone: {
        id: string
        name: string
    }
    vehicleClassId: string | null
}

function transformData(
    data: ITrafficData[],
    timestamps: IDateParts[],
    resolution: IResolution,
    insertZeroIfNoDataAtTimeStamp: boolean,
    withTotals:boolean
) {
    const allSeries = data.reduce<ISerie[]>((acc, entry) => {
        if (!acc.some(x =>
            x.camera.id === entry.camera.id
            && x.zone.id === entry.zone.id
            && x.vehicleClassId === entry.vehicleClassId
        ))
            acc.push({
                camera: {
                    id: entry.camera.id,
                    name: entry.camera.name
                },
                zone: entry.zone,
                vehicleClassId: entry.vehicleClassId
            })
        if(withTotals){
            if (!acc.some(x =>
                x.camera.id === entry.camera.id
                && x.zone.id === entry.zone.id
                && x.vehicleClassId === null
            ))
            acc.push({
                camera: {
                    id: entry.camera.id,
                    name: entry.camera.name
                },
                zone: entry.zone,
                vehicleClassId: null
            })
        }

        return acc
    }, [])

    const emptySeries = allSeries.reduce<{ [serie: string]: number | null }>((acc, serie) => {
        acc[JSON.stringify(serie)] = insertZeroIfNoDataAtTimeStamp
            ? 0
            : null

        return acc
    }, {})

    const groupedByTimestamp = data
        .map(entry => ({
            camera: {
                id: entry.camera.id,
                name: entry.camera.name
            },
            zone: entry.zone,
            vehicleClassId: entry.vehicleClassId,
            value: parseFloat(entry.value.toFixed(2)),
            dateParts: convertTimestampToParts(new Date(entry.timespanGroup), resolution)
        }))
        .reduce<{ [key: string]: (ISerie & { value: number })[] }>((acc, { dateParts, ...rest }) => {
            const dateString = convertDatePartsToDateString(dateParts)

            acc[dateString] = [
                ...(acc[dateString] || []),
                rest
            ]
            return acc
        }, {})

    return timestamps.reduce<IChartData[]>((acc, timestamp) => {
        const dateString = convertDatePartsToDateString(timestamp)
        const values = groupedByTimestamp[dateString]

        if (!values) {
            acc.push({
                timestamp: moment(dateString).valueOf(),
                ...emptySeries
            })
        }
        else {
            acc.push({
                timestamp: moment(dateString).valueOf(),
                ...emptySeries,
                ...values.reduce<{ [serie: string]: number | null }>((acc, { value, ...serie }) => {
                    acc[JSON.stringify(serie)] = value
                    if(withTotals){
                        const totSerie = {...serie}
                        totSerie.vehicleClassId = null
                        if(acc[JSON.stringify(totSerie)] !== undefined) {
                            acc[JSON.stringify(totSerie)]! += value 
                        }else{
                            acc[JSON.stringify(totSerie)] =value
                        }
                        
                    }
                    return acc
                }, {})
             })
        }

        return acc
    }, [])
}