import { FC, useMemo, useState } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { Paper, Button } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"

import { DragonTableWithSearchField, HeaderCell, BodyRow } from "Components/DragonTable"
import TeamDetails from "Pages/TeamDetails/TeamDetails"
import { ITeamListDto } from "Types/admin"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"

type ITeamListItem = ITeamListDto

const getTeams = async ({ pageParam: offset = 0, queryKey }: QueryFunctionContext): Promise<ITeamListItem[]> => {
    const [, searchName] = queryKey
    const response = await axios.get<ITeamListItem[]>("/api/admin/v1/teams", {
        params: { limit: 25, offset, name: searchName || undefined }
    })
    return response.data
}

export const TeamManagement: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState<string>("")

    const showDetails = useLocation().pathname.startsWith("/team-management/")

    const {
        data, hasNextPage = false, fetchNextPage, isFetching
    } = useInfiniteQuery(["teams", searchValue], getTeams, {
        getNextPageParam: (lastPage, allPages) => (
            lastPage.length === 0 ? undefined : allPages.reduce((amount, page) => amount + page.length, 0)
        ),
        keepPreviousData: true
    })

    const tableColumns = useMemo<HeaderCell[]>(() => [
        { value: t("name") },
        { value: t("camera-groups") },
        { value: t("members") }
    ], [t])

    const tableData = useMemo<BodyRow[]>(() => {
        const list = data?.pages.flat() ?? []
        return list.map(({ id, name, users, cameraGroups }) => (
            {
                row: [
                    { value: name },
                    { value: `${cameraGroups.slice(0, 2).map(({ name }) => name).join(", ")}${cameraGroups.length > 2 ? t("and-more-with-amount", { amount: cameraGroups.length - 2 }) : ""}` },
                    { value: `${users.slice(0, 2).map(({ name }) => name).join(", ")}${users.length > 2 ? t("and-more-with-amount", { amount: users.length - 2 }) : ""}` }
                ],
                onClick: () => navigate(`team/${id}`),
                sx: { cursor: "pointer" },
                hover: true
            }
        ))
    }, [data?.pages, navigate, t])

    return (
        <>
            <DragonPageWrapper hidden={showDetails}>
                <DragonTitle title={t("team-management")} />
                <Paper sx={{ display: "flex", overflow: "hidden" }}>
                    <DragonTableWithSearchField
                        columns={tableColumns}
                        data={tableData}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetching={isFetching}
                        noDataText={t("no-team-found")}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        searchPlaceholder={t("search-for-team")}
                        toolbarAction={
                            <Button
                                variant="outlined"
                                startIcon={<FontAwesomeIcon icon={faPlus} name={faPlus.iconName} />}
                                onClick={() => navigate("new")}
                            >
                                {t("create-team")}
                            </Button>
                        }
                    />
                </Paper>
            </DragonPageWrapper>
            <Routes>
                <Route path="team/:teamId" element={<TeamDetails />} />
                <Route path="new" element={<TeamDetails />} />
            </Routes>
        </>
    )
}

export default TeamManagement
