import {
    Box,
    Avatar,
    Stack,
    Divider
} from "@mui/material"

import { IEventComment } from "./EventTypes"
import Comment from "./Comment"
import CommentBox from "./CommentBox"
import { useUserProfile } from "hooks/useUserProfile"
import getMaxTwoInitalsFromName from "utils/getMaxTwoInitalsFromName"

export default function CommentsTab({ comments, eventId }: { comments: IEventComment[], eventId: string }) {
    const { name } = useUserProfile()

    const initals = getMaxTwoInitalsFromName(name)

    return (
        <Box sx={{ overflow: "auto", p: { lg: "80px 100px", xs: "30px 40px" } }}>
            <Stack divider={<Divider />} spacing="26px">

                {comments.map(comment =>
                    <Comment key={comment.entity.id} comment={comment} eventId={eventId} />
                )}

                <Stack direction="row">
                    <Avatar sx={{ width: 47, height: 47, m: "5px", mr: "15px" }}>
                        <p style={{ margin: "4px 0 0 0" }}>
                            {initals}
                        </p>
                    </Avatar>
                    <CommentBox eventId={eventId} />
                </Stack>

            </Stack>
        </Box>
    )
}