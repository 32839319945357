import { Accordion, AccordionDetails, AccordionSummary, Box, ClickAwayListener, TextField } from "@mui/material"

import TreeSelector from "./TreeSelector"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import FilterControlLabel from "Components/Filter/FilterControlLabel"
import ICameraGroupHierarchy from "./ICameraGroupHierarchy"

interface ITreeSelectorAccordion {
    setSelectedIds: (ids: string[]) => void
    selectedIds: string[]
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
}

function getUniqueSelectedGroupAndCameraNames(cameraGroups: ICameraGroupHierarchy[], selectedCameraIds: string[]): string[] {
    // We return a single array with both group names and camera names mixed so the items matches their place in the hierarchy
    return Array.from(new Set(
        cameraGroups
            .reduce<string[]>((acc, group) => {
                // If all cameras in the group is selected, add group name
                if (group.cameras.every(camera => selectedCameraIds.includes(camera.id))) {
                    acc.push(group.name)
                }
                // If only some cameras in a group is selected, add the selected camera name(s)
                else if (group.cameras.some(camera => selectedCameraIds.includes(camera.id))) {
                    acc.push(...group
                        .cameras
                        .filter(camera => selectedCameraIds.includes(camera.id))
                        .map(camera => camera.name)
                    )
                }

                return acc
            }, [])
    ))
}

export default function TreeSelectorAccordion({ setSelectedIds, selectedIds, cameraGroupsHierarchy }: ITreeSelectorAccordion) {
    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useState("")
    const [isExpanded, setIsExpanded] = useState(false)

    const tree = useMemo(() =>
        cameraGroupsHierarchy.map(group => ({
            id: group.id,
            name: group.name,
            children: group.cameras.map(({ id, name }) => ({ id, name }))
        })),
    [cameraGroupsHierarchy]
    )

    function onUpdateSearchValue(searchValue: string) {
        setSearchValue(searchValue.toLowerCase())
    }

    return <ClickAwayListener onClickAway={() => setIsExpanded(false)}>
        <Box>
            <FilterControlLabel text={t("event-filter.camera-picker")} />
            <Accordion
                expanded={isExpanded}
                onChange={(_, isExpanded) => setIsExpanded(isExpanded)}
                disableGutters
                elevation={0}
                sx={{
                    margin: 0,
                    padding: 0
                }}
            >
                <AccordionSummary
                    aria-controls="camera-selector-content"
                    id="camera-selector-header"
                    sx={{
                        padding: 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        minHeight: "unset",
                        ".MuiAccordionSummary-content": {
                            padding: 0,
                            margin: 0
                        },
                        ".MuiAccordionSummary-expandIconWrapper": {
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            right: 7,
                            display: "flex",
                            alignItems: "center"
                        }
                    }}
                >
                    <TextField
                        onChange={event => onUpdateSearchValue(event.target.value)}
                        onFocus={event => {
                            event.stopPropagation()
                            if (!isExpanded)
                                setIsExpanded(true)
                        }}
                        onClick={event => {
                            event.stopPropagation()
                            if (!isExpanded)
                                setIsExpanded(true)
                        }}
                        fullWidth
                        placeholder={t("event-filter.search-for-cameras")}
                        value={isExpanded
                            ? searchValue
                            : getUniqueSelectedGroupAndCameraNames(cameraGroupsHierarchy, selectedIds).join(", ")
                        }
                    />
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: "0 0px",
                        maxHeight: "400px",
                        overflow: "auto",
                        backgroundColor: theme => theme.palette.background.default
                    }}
                >
                    <TreeSelector
                        tree={tree}
                        setSelectedIds={setSelectedIds}
                        selectedIds={selectedIds}
                        searchValue={searchValue}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    </ClickAwayListener>
}