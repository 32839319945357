import { useCallback, useEffect, useState } from "react"
import axios from "axios"
import { QueryFunctionContext, useQueries } from "react-query"
import ICameraGroupHierarchy from "Components/Filter/Filters/CameraSelector/ICameraGroupHierarchy"
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material"
import { IScenarioLevelCRUDto } from "Types/admin"
import CameraSelector from "./CameraSelector"
import LevelSelector from "./LevelSelector"
import EventTypeConfig, { IEventType } from "./EventTypeConfig"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { useTranslation } from "react-i18next"



async function getEventTypes() {
    const response = await axios.get<IEventType[]>("/api/scenario/v1/event-types")
    return response.data
}

async function getCameraHeirarchy({ queryKey }: QueryFunctionContext<[string, string]>) {
    const [, groupId] = queryKey
    const response = await axios.get<ICameraGroupHierarchy[]>(`/api/scenario/v1/scenario-management/groups/${groupId}/camera-groups`)
    return response.data
}

interface IScenarioConfigurationWrapper {
    levels: IScenarioLevelCRUDto[]
    disableChanges:boolean
    groupId: string
    onChange: (newLevels: IScenarioLevelCRUDto[]) => void
}

export default function ScenarioConfigurationWrapper({ levels,disableChanges, groupId,onChange }: IScenarioConfigurationWrapper) {
    const { t } = useTranslation()

    const result = useQueries([
        { queryKey: "traffic-event-types", queryFn: getEventTypes },
        { queryKey: ["camera-groups",groupId], queryFn:getCameraHeirarchy}
    ])

    const isLoading = result.some(query => query.isLoading)
    const isError = result.some(query => query.isError)

    const [{ data: trafficEventTypes }, { data: cameraGroups }] = result
    const isSuccess = !!(trafficEventTypes && cameraGroups)

    if (isLoading)
        return <DelayedCircularProgress delay={250} />

    if (isError || !isSuccess)
        return <Typography>{t("scenario-details-page.failed-to-load")}</Typography>

    return <ScenarioConfiguration
        levels={levels}
        disableChanges={disableChanges}
        onChange={onChange}
        eventTypes={trafficEventTypes}
        cameraGroupsHierarchy={cameraGroups}
    />
}

interface IScenarioConfiguration {
    levels: IScenarioLevelCRUDto[]
    disableChanges: boolean
    onChange: (newLevels: IScenarioLevelCRUDto[]) => void
    eventTypes: IEventType[]
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
}

function ScenarioConfiguration({ levels, disableChanges,onChange, eventTypes, cameraGroupsHierarchy }: IScenarioConfiguration) {
    const { t } = useTranslation()
    const [cameraTreeSelection, setCameraTreeSelection] = useState<string[]>(levels.flatMap(({ cameraId }) => [ 
        ...(cameraId ? [cameraId] : [])
    ]))

    const [activeLevel, setActiveLevel] = useState<IScenarioLevelCRUDto>()
    const [activeZoneName, setActiveZoneName] = useState<string>("")
    const handleLevelEventTypeChange = useCallback((eventTypeId: string, enable?: boolean) => {
        if(disableChanges) return
        const newLevels: IScenarioLevelCRUDto[] = levels.map(level => ({
            ...level,
            eventTypes: level.eventTypes.map(type => ({ ...type }))
        }))

        const levelIndex = newLevels.findIndex(level =>
            (level.cameraId === activeLevel?.cameraId) &&
            (level.zoneId === activeLevel?.zoneId)
        )

        const LevelIsAlreadyInList = levelIndex >= 0

        if (LevelIsAlreadyInList) {
            const eventTypeIndex = newLevels[levelIndex].eventTypes.findIndex(eventType => eventType.eventTypeId === eventTypeId)

            const eventTypeIsAlreadyInList = eventTypeIndex >= 0

            if (eventTypeIsAlreadyInList) {
                if (enable === undefined) {
                    newLevels[levelIndex].eventTypes.splice(eventTypeIndex, 1)

                    if (newLevels[levelIndex].eventTypes.length === 0) {
                        newLevels.splice(levelIndex, 1)
                    }
                }
                else {
                    newLevels[levelIndex].eventTypes[eventTypeIndex] = { eventTypeId, enable }
                }
            }
            else
                newLevels[levelIndex].eventTypes.push({ eventTypeId, enable })

        }
        else {
            newLevels.push({ ...activeLevel, eventTypes: [{ eventTypeId, enable }] } as IScenarioLevelCRUDto)
        }

        onChange(newLevels)

    }, [levels, activeLevel, onChange,disableChanges])

    const handleCloneLevel = useCallback(()=>{
        if(activeLevel && !disableChanges){
            let newLevels: IScenarioLevelCRUDto[] = levels.map(level => ({
                ...level,
                eventTypes: level.eventTypes.map(type => ({ ...type }))
            }))
            const eventToClone = newLevels.filter(level =>
                (level.cameraId === activeLevel?.cameraId) &&
                (level.zoneId === activeLevel?.zoneId)
            ).flatMap(l => [...l.eventTypes])
            if(activeLevel.zoneId){
                const cm = cameraGroupsHierarchy.flatMap(x=>x.cameras)
                .filter(c=> cameraTreeSelection.some(ct => ct === c.id))
                .flatMap(c=> {return {id: c.id, zones: c.zones.filter(z=>z.name === activeZoneName)}})
                cm.forEach(cam=>{
                    cam.zones.forEach(zone =>{
                        newLevels = [...newLevels.filter(l => l.zoneId !== zone.id)]
                        if(eventToClone.length > 0){
                            newLevels.push({
                                cameraId:cam.id,
                                zoneId:zone.id,
                                eventTypes:eventToClone.map(type => ({ ...type }))
                            })
                        }
                    })

                })

            }
            else{
                cameraTreeSelection.forEach(camId=>{
                    newLevels = [...newLevels.filter(l => l.cameraId !== camId || l.zoneId !== null)]
                    if(eventToClone.length >0)
                    newLevels.push({
                        cameraId:camId,
                        zoneId:null,
                        eventTypes:eventToClone.map(type => ({ ...type }))
                    })
                })
            }
            onChange(newLevels)
        }
    },[levels, activeLevel,activeZoneName, onChange,disableChanges,cameraTreeSelection,cameraGroupsHierarchy])

    useEffect(() => {
        if (!cameraTreeSelection.some(
            camId => activeLevel?.cameraId?.includes(camId) ||
                activeLevel?.cameraId === camId
        ))
            setActiveLevel(undefined)
    }, [activeLevel, cameraTreeSelection])

    useEffect(()=>{
        if(!activeLevel?.zoneId) setActiveZoneName("")
        else{
        const zn = cameraGroupsHierarchy.flatMap(x=>x.cameras)
                    .filter(c=> c.id === activeLevel?.cameraId)
                    .flatMap(c=> c.zones)
                    .find(z=> z.id === activeLevel?.zoneId)
            setActiveZoneName(zn?.name ?? "")
        }
    },[activeLevel,cameraGroupsHierarchy])

    return (
        <Stack display={"flex"} overflow={"auto"}>
            { disableChanges && 
            <Box display="flex" textOverflow={"ellipsis"} justifyContent={"center"}>
                <Typography variant="h6" padding={4} color={"InfoText"}>{t("scenario-details-page.configuration-disabled")}</Typography>
            </Box>}
            <Box display="flex" gap={5} padding={3} overflow="auto">

            <Grid container spacing={2}>
                <Grid item xs={12} lg={4} height="auto" maxHeight={1} minHeight="128px">
                    <Paper variant="outlined" sx={{ height: 1, overflow: "auto" }}>
                        <CameraSelector
                            selectedIds={cameraTreeSelection}
                            setSelectedIds={selectedCameraIds => {
                                if(!disableChanges)
                                setCameraTreeSelection(selectedCameraIds)
                            }}
                            cameraGroupsHierarchy={cameraGroupsHierarchy}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} lg={4} height="auto" maxHeight={1} minHeight="128px">
                    <Paper variant="outlined" sx={{ height: 1, overflow: "auto" }}>
                        {!cameraTreeSelection.length
                            ? <Typography variant="h6" padding={4}>{t("scenario-details-page.select-cameras")}</Typography>
                            : <LevelSelector
                                selectedLevel={activeLevel}
                                setSelectedLevel={newActiveLevel => {
                                    setActiveLevel(newActiveLevel)
                                }}
                                cameraGroupsHierarchy={cameraGroupsHierarchy}
                                cameraIds={cameraTreeSelection}
                                levelsData={levels}
                            />
                        }
                    </Paper>
                </Grid>

                <Grid item xs={12} lg={4} height="auto" maxHeight={1} minHeight="128px">
                    <Stack height={1} overflow={"hidden"}>
                        <Paper variant="outlined" sx={{ height: 1, overflow: "hidden" }}>
                            {!activeLevel
                                ? <Typography variant="h6" padding={4}>{t("scenario-details-page.select-zone-or-camera")}</Typography>
                                : <EventTypeConfig
                                    eventTypeList={eventTypes}
                                    levelEventTypes={levels.find(level =>
                                        (level.cameraId === activeLevel?.cameraId) &&
                                        (level.zoneId === activeLevel?.zoneId)
                                    )?.eventTypes ?? []}
                                    disabled={disableChanges}
                                    updateLevelEventTypes={handleLevelEventTypeChange}
                                    variant={activeLevel?.zoneId ? "Zone" : "Camera"}
                                />
                            }
                        </Paper>
                        { activeLevel && 
                            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ minWidth: 150 }}
                                    onClick={handleCloneLevel}
                                    disabled={disableChanges}
                                >
                                    {activeLevel?.zoneId ? t("scenario-details-page.clone-to-all-zones",{ zoneName: activeZoneName}) : t("scenario-details-page.clone-to-all-cameras")}
                                </Button>
                            </Stack>
                        }
                    </Stack>
                </Grid>
            </Grid>
        </Box>
        </Stack>
        
    )
}