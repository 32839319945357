import { useState } from "react"
import { Layout, Layouts, Responsive as ResponsiveGridLayout } from "react-grid-layout"
import "react-grid-layout/css/styles.css"
import { ResponsiveContainer } from "recharts"
import axios from "axios"
import { useMutation, useQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { useUserProfile } from "hooks/useUserProfile"

import {
    Box,
    Button,
    Typography
} from "@mui/material"
import { DashboardWidget } from "./WidgetBase"
import { IWidget } from "Components/Widgets/types"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import WidgetCreator from "Components/WidgetCreator/WidgetCreator"
import DragonTitle from "Components/DragonTitle"
import { IEntityWithEtag } from "utils/queryHelpers"
import DragonPageWrapper from "Components/DragonPageWrapper"

interface ILayoutItem {
    dataWidget: IEntityWithEtag<IWidget>
    x: number
    y: number
    width: number
    height: number
}

export type GridWidget = Layout & { dataWidget?: ILayoutItem["dataWidget"] }

async function getLayout() {
    const response = await axios.get<ILayoutItem[]>("/api/event/v1/widget-layouts")
    return response.data ? response.data.map(({ width, height, ...rest }) => ({
        ...rest,
        i: rest.dataWidget.entity.id,
        w: width,
        h: height
    } )) : []
}

async function updateLayout(newLayouts: Layouts) {
    return axios.put("/api/event/v1/widget-layouts",
        newLayouts.lg.map(({ i, x, y, w, h }) => ({
            dataWidgetId: i,
            x,
            y,
            width: w,
            height: h
        }))
    )
}

export default function Dashboard() {
    const { t } = useTranslation()
    const { name } = useUserProfile()

    const { data: layout, isLoading, isError, isSuccess } = useQuery("layout", getLayout)
    const { mutate: handleLayoutChange } = useMutation(updateLayout)

    const [showWidgetCreator, setShowWidgetCreator] = useState(false)

    return (
        <DragonPageWrapper scrollable>
            <DragonTitle
                title={t("dashboard-page.welcomeWithName", { name })}
                action={<Button variant="outlined" onClick={() => setShowWidgetCreator(true)}>{t("dashboard-page.add-widget")}</Button>}
            />
            {(() => {
                if (isLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{t("dashboard-page.failed-to-load-dashboard")}</h2>

                if (isSuccess && layout.length === 0)
                    return <Typography variant="h6">{t("dashboard-page.empty-dashboard-directions")}</Typography>

                if (isSuccess && layout.length > 0)
                    return <Box>
                        <ResponsiveContainer>
                            <ResponsiveGridLayout
                                className="layout"
                                layouts={{ lg: layout }}
                                compactType={"vertical"}
                                isResizable={false}
                                breakpoints={{ lg: 700, sm: 300 }}
                                cols={{ lg: 6, sm: 2 }}
                                rowHeight={370}
                                containerPadding={[0, 0]}
                                margin={[15, 15]}
                                width={100}
                                autoSize={false}
                                onLayoutChange={(_, newLayouts) => handleLayoutChange(newLayouts)}
                                draggableHandle=".MuiCardHeader-content"              
                            >
                                {layout.map((item) =>
                                    <DashboardWidget
                                        key={item.i}
                                        widget={item.dataWidget.entity}
                                        eTag={item.dataWidget.eTag}
                                    />
                                )}
                            </ResponsiveGridLayout>
                        </ResponsiveContainer>
                    </Box>
            })()}
            <WidgetCreator open={showWidgetCreator} handleClose={(wdg) => {
                setShowWidgetCreator(false)  
                if(wdg) {
                    //const lt = layout ?? []
                    const lt = !!layout ? [...layout] : []
                    const firstWidget = lt.length <= 0
                    lt.push(wdg)
                    if (firstWidget) handleLayoutChange({lg:lt})
                }
            }} />
        </DragonPageWrapper>
    )
}