import { CSSProperties, forwardRef } from "react"
import axios from "axios"
import { useQuery } from "react-query"

import {
    MentionsInput,
    Mention,
    SuggestionDataItem,
    MentionsInputProps
} from "react-mentions"

import {
    useTheme,
    MenuItem
} from "@mui/material"

import {
    mentionsInputStyle,
    mentionStyle
} from "./mentionsStyle"

interface IUser {
    id: string
    name: string
}

async function getUsers(): Promise<SuggestionDataItem[]> {
    const response = await axios.get<IUser[]>("/api/event/v1/users/select")
    return response.data.map(user => ({ display: user.name, id: user.id }))
}

const InputWithMentions = forwardRef(({
    value,
    onChange,
    readOnly,
    ControlStyle = {}
}: Omit<MentionsInputProps, "children"> & { ControlStyle?: CSSProperties }, ref: React.Ref<HTMLInputElement>) => {
    const theme = useTheme()
    const { data: users } = useQuery("mentionable-users", getUsers)

    return (
        <MentionsInput
            value={value}
            onChange={onChange}
            style={mentionsInputStyle(theme, ControlStyle)}
            inputRef={ref}
            readOnly={readOnly}
        >
            <Mention
                trigger="@"
                data={users || []}
                renderSuggestion={suggestion =>
                    <MenuItem component="div">
                        {suggestion.display}
                    </MenuItem>
                }
                displayTransform={(_, display) => `@${display}`}
                appendSpaceOnAdd
                style={mentionStyle(theme)}
            />
        </MentionsInput>
    )
})

export default InputWithMentions