import axios from "axios"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"

import { IEntityWithEtag } from "utils/queryHelpers"
import { DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import { IEventManagerEvent } from "./types"

type IEventWithEtag = IEntityWithEtag<IEventManagerEvent>
interface IUnacknowledgedCount {
    count: number
}

interface IAcknowledgeAllModal {
    open: boolean
    handleClose: () => void
    setEvents: (value: IEventWithEtag[]) => void
}

async function getUnacknowledgedCount() {
    const response = await axios.get<IUnacknowledgedCount>("/api/event/v1/events/event-manager/count")
    return response.data
}

export default function AcknowledgeAllModal({ open, handleClose, setEvents }: IAcknowledgeAllModal) {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const { data: { count } = {} } = useQuery(
        "unacknowledgedCount",
        getUnacknowledgedCount,
        { enabled: open }
    )

    const { mutateAsync: acknowledgeAll, isLoading: isAcknowledging } = useMutation(() => {
        return axios.put("/api/event/v1/events/acknowledge/all")
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries("event-manager").then(() => {
                setEvents([])
                handleClose()
            })
        }
    })

    return (
        <DragonConfirmationDialogWithPromise
            open={open && !!count}
            variant="warning"
            title={t(
                "event-manager-page.acknowledge-all-modal-text",
                { count: count }
            )}
            onClose={handleClose}
            onConfirm={async () => await acknowledgeAll()}
            isLoading={isAcknowledging}
            errorText={t("event-manager-page.failed-to-acknowledge")}
        />
    )
}