import i18n from "i18next"

export function formatDate(dateTime: Date): string {
    const year = dateTime.getFullYear()
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0")
    const date = dateTime.getDate().toString().padStart(2, "0")

    return `${year}-${month}-${date}`
}


export enum SecondsDisplay {
    HIDE_SECONDS,
    SHOW_SECONDS
}

export function formatTime(dateTime: Date, includeSeconds: SecondsDisplay = SecondsDisplay.SHOW_SECONDS): string {
    const hours = dateTime.getHours().toString().padStart(2, "0")
    const minutes = dateTime.getMinutes().toString().padStart(2, "0")
    const seconds = dateTime.getSeconds().toString().padStart(2, "0")

    const parts = [
        hours,
        minutes
    ]

    if (includeSeconds)
        parts.push(seconds)

    return parts.join(":")
}


export function relativeTimeDisplay(date: Date): string {
    const diff = (((new Date()).getTime() - date.getTime()) / 1000)
    const daydiff = Math.floor(diff / 86400)

    if (isNaN(daydiff) || daydiff < 0)
        return ""

    if (!daydiff) {
        if (diff < 60) return i18n.t("just-now")
        if (diff < 120) return `${i18n.t("minuteWithCount", { count: 1 })} ${i18n.t("ago")}`
        if (diff < 3600) return `${i18n.t("minuteWithCount", { count: Math.floor(diff / 60) })} ${i18n.t("ago")}`
        if (diff < 7200) return `${i18n.t("hourWithCount", { count: 1 })} ${i18n.t("ago")}`
        if (diff < 86400) return `${i18n.t("hourWithCount", { count: Math.floor(diff / 3600) })} ${i18n.t("ago")}`
    }

    if (daydiff === 1) return i18n.t("yesterday")
    if (daydiff < 7) return `${i18n.t("dayWithCount", { count: daydiff })} ${i18n.t("ago")}`
    if (daydiff === 7) return `${i18n.t("weekWithCount", { count: 1 })} ${i18n.t("ago")}`
    if (daydiff < 31) return `${i18n.t("weekWithCount", { count: Math.ceil(daydiff / 7) })} ${i18n.t("ago")}`

    return `${formatDate(date)} ${formatTime(date)}`
}