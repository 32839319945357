import { Box, Typography } from "@mui/material"
import axios from "axios"
import IEvent, { IEventDto } from "Pages/EventJournal/IEvent"
import { useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"
import { Link } from "react-router-dom"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import BookmarkItem from "./BookmarkItem"

export interface IBookmark {
    id: string,
    timeToLiveAt: Date,
    event: IEvent
}

async function getBookmarks({ pageParam: lastStartedAt }: QueryFunctionContext) {
    const limit = 48
    const response = await axios.get<{ id: string, timeToLiveAt:Date,event: IEventDto }[]>("/api/event/v1/event-bookmarks", {
        params: {
            limit,
            lastStartedAt
        }
    })
    return response.data.map<IBookmark>((bookmark) => ({
        ...bookmark,
        timeToLiveAt: new Date(bookmark.timeToLiveAt),
        event: {
            ...bookmark.event,
            startedAt: new Date(bookmark.event.startedAt),
            endedAt: bookmark.event.endedAt !== null
                ? new Date(bookmark.event.endedAt)
                : null
        }
    }))
}

export default function BookmarksTab({ onUpdateEventIds }: { onUpdateEventIds: (eventIds: string[]) => void }) {
    const { t } = useTranslation()

    const {
        data: bookmarks,
        isLoading,
        isError,
        isFetching,
        isFetchingNextPage,
        isSuccess,
        fetchNextPage,
        hasNextPage
    } = useInfiniteQuery("bookmarks", getBookmarks,
        {
            getNextPageParam: (lastPage, _) => {
                if (lastPage.length === 0)
                    return undefined

                return lastPage[lastPage.length - 1].event.startedAt
            }
        }
    )

    const allBookmarks = useMemo(() => bookmarks?.pages.flat() ?? [], [bookmarks])
    useEffect(() => {
        onUpdateEventIds(allBookmarks.map(bookmark => bookmark.event.id))
    }, [allBookmarks, onUpdateEventIds])

    function handleScroll({ currentTarget }: React.UIEvent<HTMLDivElement>) {
        if (isFetchingNextPage)
            return

        const bottom = currentTarget.scrollHeight
        const scrollY = currentTarget.scrollTop + currentTarget.clientHeight

        const isScrollEnd = bottom - scrollY <= (currentTarget.clientHeight * 2)

        if (isScrollEnd && hasNextPage && !isFetching)
            fetchNextPage()
    }

    const listRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!listRef?.current)
            return

        const list = listRef.current
        const myObserver = new ResizeObserver(entries => entries.forEach(({ target }) => {
            if (
                hasNextPage
                && !isFetching
                && target.clientHeight >= target.scrollHeight
            )
                fetchNextPage()
        }))
        myObserver.observe(list)
        return () => {
            myObserver.unobserve(list)
        }
    }, [fetchNextPage, hasNextPage, isFetching])


    return (
        <Box sx={{ overflow: "auto" }} onScroll={handleScroll} ref={listRef}>
            <Box
                sx={{
                    display: "grid",
                    gap: 2,
                    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                    p: 2
                }}
            >
                {(() => {
                    if (isLoading)
                        return <DelayedCircularProgress delay={250} />

                    if (isError)
                        return <Typography>{t("bookmarks.failed-to-load")}</Typography>

                    if (isSuccess && allBookmarks.length === 0)
                        return <Typography>{t("bookmarks.no-bookmarked-events")}</Typography>

                    if (isSuccess && allBookmarks.length > 0)
                        return allBookmarks.map((bookmark) => {
                            const snapshotUrl = bookmark.event.eventAssets.find(asset => asset.assetTypeId === "Snapshot")?.uri
                            return <Link
                                to={`event/${bookmark.event.id}`}
                                style={{ textDecoration: "none" }}
                                key={bookmark.id}
                            >
                                <BookmarkItem
                                    eventTypeId={bookmark.event.eventTypeId}
                                    snapshotUrl={snapshotUrl}
                                    ttl={bookmark.timeToLiveAt}
                                    cameraName={bookmark.event.camera.name}
                                    startedAt={bookmark.event.startedAt}
                                    bookmarkId={bookmark.id}
                                />
                            </Link>
                        })
                })()}
                {isFetchingNextPage &&
                    <Box sx={{ display: "flex", justifyContent: "center", gridColumn: "1 / -1" }}>
                        <DelayedCircularProgress delay={250} />
                    </Box>
                }
            </Box>
        </Box>
    )
}