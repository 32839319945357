import { ReactElement, useMemo } from "react"
import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom"

import { useUserProfile, IRole, IDragonFeatures } from "hooks/useUserProfile"

// Pages
import AccessDenied from "Pages/AccessDenied/AccessDenied"
import EventManager from "Pages/EventManager/EventManager"
import EventJournal from "Pages/EventJournal/EventJournal"
import UserManagement from "Pages/UserManagement/UserManagement"
import TeamManagement from "Pages/TeamManagement/TeamManagement"
import Cameras from "Pages/Cameras/Cameras"
import CameraGroups from "Pages/CameraGroups/CameraGroups"
import Servers from "Pages/Servers/Servers"
//import EventTypeGroups from "Pages/EventTypeGroups/EventTypeGroups"
import Account from "Pages/Account/Account"
import DataAnalytics from "Pages/DataAnalytics/DataAnalytics"
import EventDetails from "Pages/EventDetails/EventDetails"
import DeviceStatus from "Pages/DeviceStatus/DeviceStatus"
import Dashboard from "Pages/Dashboard/Dashboard"
import CameraOverview from "Pages/CameraOverview/CameraOverview"
import MapOverview from "Pages/MapOverview/MapOverview"
import Auditing from "Pages/Auditing/Auditing"
import UserAdministration from "Pages/UserAdministration/Users"
import DragonVersion from "Pages/DragonVersion/DragonVersion"
import Scenarios from "Pages/Scenarios/Scenarios"
//import ScenarioManagement from "Pages/ScenarioManagement/ScenarioManagement"
import ScenarioGroups from "Pages/ScenarioManagement/ScenarioGroups"
import MailConfiguration from "Pages/MailConfiguration/MailConfiguration"
import USPConfiguration from "Pages/USPConfiguration/USPConfiguration"
import OPCConfiguration from "Pages/OPCConfiguration/OPCConfiguration"
import EventRules from "Pages/RulesManagement/Rules"
import FailoverConfiguration from "Pages/FailoverConfiguration/FailoverConfiguration"
import GenetecConfiguration from "Pages/GenetecConfiguration/GenetecConfiguration"
import WaveConfiguration from "Pages/WaveConfiguration/WaveConfiguration"
import MilestoneConfiguration from "Pages/MilestoneConfiguration/MilesoneConfiguration"
import NXMetaConfiguration from "Pages/NXMetaConfiguration/NXMetaConfiguration"

export interface IRoute {
    path: string
    element: ReactElement
}
export type IPage = ({ claims: IRole[],features:IDragonFeatures[]} & IRoute)

const pages: IPage[] = [
    {
        path: "/access-denied/*",
        element: <AccessDenied />,
        claims: [],
        features:[]
    },
    {
        path: "/account/*",
        element: <Account />,
        claims: [],
        features:[]
    },
    {
        path: "/",
        element: <Dashboard />,
        claims: ["Operator"],
        features:["DashboardEnabled"]
    },
    {
        path: "/map-overview",
        element: <MapOverview />,
        claims: ["Operator"],
        features:["MapEnabled"]
    },
    {
        path: "/event-journal/*",
        element: <EventJournal />,
        claims: ["Operator"],
        features:["EventJournalEnabled"]
    },
    {
        path: "/event/:eventId",
        element: <EventDetails />,
        claims: ["Operator"],
        features:["EventJournalEnabled"]
    },
    {
        path: "/event-manager/*",
        element: <EventManager />,
        claims: ["Operator"],
        features:["RealtimeEventsEnabled"]
    },
    {
        path: "/data-analytics/*",
        element: <DataAnalytics />,
        claims: ["Operator"],
        features:["DataAnalyticsEnabled"]
    },
    {
        path: "/device-status",
        element: <DeviceStatus />,
        claims: ["Operator"],
        features:["DeviceStatusEnabled"]
    },
    {
        path: "/camera-overview",
        element: <CameraOverview />,
        claims: ["Operator"],
        features:["CameraOverviewEnabled"]
    },
    {
        path: "/scenarios/*",
        element: <Scenarios />,
        claims: ["Scenarios"],
        features:["ScenarioEnabled"]
    },
    {
        path: "/scenario-groups/*",
        element: <ScenarioGroups />,
        claims: ["Administrator"],
        features:["ScenarioEnabled"]
    },
    {
        path: "/event-rules/*",
        element: <EventRules />,
        claims: ["Administrator"],
        features:["RulesEnabled"]
    },
    {
        path: "/user-management/*",
        element: <UserManagement />,
        claims: ["Administrator"],
        features:[]
    },
    {
        path: "/team-management/*",
        element: <TeamManagement />,
        claims: ["Administrator"],
        features:[]
    },
    {
        path: "/cameras/*",
        element: <Cameras />,
        claims: ["Administrator"],
        features:[]
    },
    {
        path: "/camera-groups/*",
        element: <CameraGroups />,
        claims: ["Administrator"],
        features:[]
    },
    {
        path: "/servers/*",
        element: <Servers />,
        claims: ["Administrator"],
        features:[]
    },{
        path: "/failover-settings/*",
        element: <FailoverConfiguration />,
        claims: ["Administrator"],
        features:["FailoverEnabled"]
    },
    {
        path: "/smtp-settings",
        element: <MailConfiguration />,
        claims: ["Administrator"],
        features:["EmailEnabled"]
    },
    {
        path: "/milestone-settings",
        element: <MilestoneConfiguration />,
        claims: ["Administrator"],
        features:["MilestoneEnabled"]
    },
    {
        path: "/genetec-settings",
        element: <GenetecConfiguration />,
        claims: ["Administrator"],
        features:["GenetecEnabled"]
    },
    {
        path: "/wave-settings",
        element: <WaveConfiguration />,
        claims: ["Administrator"],
        features:["WaveEnabled"]
    },
    {
        path: "/nxmeta-settings",
        element: <NXMetaConfiguration />,
        claims: ["Administrator"],
        features:["NXMetaEnabled"]
    },
    {
        path: "/usp-settings",
        element: <USPConfiguration />,
        claims: ["Administrator"],
        features:["USPEnabled"]
    },
    {
        path: "/opc-settings",
        element: <OPCConfiguration />,
        claims: ["Administrator"],
        features:["OPCEnabled"]
    },{
        path: "/auditing/*",
        element: <Auditing />,
        claims: ["Administrator"],
        features:[]
    },
    {
        path: "/user-administration/*",
        element: <UserAdministration />,
        claims: ["UserAdministrator"],
        features:[]
    }
]

export const filterPages = (roles: IRole[],dragonFeatures: IDragonFeatures[]): IPage[] => (
    pages.filter(({features})=>(features.every(feature => dragonFeatures.includes(feature))))
    .filter(({ claims }) => (claims.every(claim => roles.includes(claim))))
)

export default function Routes() {
    const { roles ,features} = useUserProfile()

    const fallback = useMemo<string>(() => {
        if (roles.includes("Operator")) {
            if(features.includes("DashboardEnabled")) return "/"
            else return `/${process.env.REACT_APP_DRAGON_OPERATOR_HOME}`
        }
        if (roles.includes("Administrator")) return "/user-management"
        if (roles.includes("UserAdministrator")) return "/user-administration"
        return "/access-denied"
    }, [roles,features])

    return (
        <ReactRoutes>
            {filterPages(roles,features).map(({ path, element }) => (
                <Route key={path} path={path} element={element}  />
            ))}
            <Route path="/version" element={<DragonVersion/>} />
            <Route path="*" element={<Navigate to={fallback} replace />} />
        </ReactRoutes>
    )
}