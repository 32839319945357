import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MapControlsFab from "./ControlsFab"

export function ZoomIn({ zoomIn }: { zoomIn: () => void }) {
    function _zoomIn(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation()
        zoomIn()
    }

    return <MapControlsFab
        onClick={_zoomIn}
        onDoubleClick={_zoomIn}
    >
        <FontAwesomeIcon icon={faPlus} />
    </MapControlsFab>
}