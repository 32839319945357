import { Suspense } from "react"

import { BrowserRouter as Router } from "react-router-dom"
import { SnackbarProvider } from "notistack"

import {
    AuthWrapper,
    ThemeWrapper,
    QueryClientProviderWrapper,
    App,
    CenteredDelayedSpinner
} from "."
import UserSettingsProvider from "./UserSettingsProvider"
import { GlobalEventsContextProvider } from "Contexts/GlobalEventsContext"


export default function AppWrapper() {
    return (
        <ThemeWrapper>
            {/* This is required to allow the translations to be loaded before continuing */}
            <Suspense fallback={<CenteredDelayedSpinner />}>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                >
                    <QueryClientProviderWrapper>
                        <AuthWrapper>
                            <UserSettingsProvider>
                                <GlobalEventsContextProvider>
                                    <Router>
                                        <div className="app">
                                            <App />
                                        </div>
                                    </Router>
                                </GlobalEventsContextProvider>
                            </UserSettingsProvider>
                        </AuthWrapper>
                    </QueryClientProviderWrapper>
                </SnackbarProvider>
            </Suspense>
        </ThemeWrapper>
    )
}