import { forwardRef } from "react"
import { SnackbarContent as SnackbarContainer } from "notistack"

import {
    Paper,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    useTheme
} from "@mui/material"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faExclamationTriangle, faInfo, faTimes } from "@fortawesome/pro-regular-svg-icons"

interface IDragonSnackbar {
    message: string
    variant: "success" | "error" | "warning" | "info"
}

const DragonSnackbar = forwardRef<HTMLDivElement, IDragonSnackbar>(({ message, variant }, ref) => {
    const { palette } = useTheme()

    const icon = {
        success: <FontAwesomeIcon icon={faCheck} style={{ color: palette.success.main }} />,
        error: <FontAwesomeIcon icon={faTimes} style={{ color: palette.error.main }} />,
        warning: <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: palette.warning.main }} />,
        info: <FontAwesomeIcon icon={faInfo} />
    }

    return (
        <SnackbarContainer ref={ref}>
            <Paper>
                <ListItem sx={{ p: "10px" }}>
                    <ListItemAvatar>
                        <Avatar variant="rounded">
                            {icon[variant]}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={message} />
                </ListItem>
            </Paper>
        </SnackbarContainer>
    )
})

export default DragonSnackbar