import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { QueryFunctionContext, useMutation, useQueries, useQueryClient } from "react-query"
import {
    Box,
    Stack,
    Paper,
    TextField,
    Typography,
    Divider,
    Grid,
    FormControl,
    Button,
    Select,
    MenuItem
} from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faFileAlt, faVideoPlus } from "@fortawesome/pro-regular-svg-icons"
import { faFileAlt as faFileAltSolid, faVideoPlus as faVideoPlusSolid } from "@fortawesome/pro-solid-svg-icons"

import ErrorBoundary from "utils/ErrorBoundary"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { IDataWithEtag } from "utils/queryHelpers"
import GoBackButton from "Components/DragonGoBackButton"
import { DragonConfirmationDialog, DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import { DragonTab, DragonTabs } from "Components/DragonTabs"
import CameraGroupAssignmentForm from "Pages/RulesManagement/EventRuleDetails/CameraGroupAssignmentForm"
import { IEventRuleReadDto, IEventRuleCreateUpdateDto, EventRuleType } from "Types/admin"
import Icon from "Components/Icon"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"

type IEventRuleDetails = IEventRuleReadDto
type IEventRuleUpdate = IEventRuleCreateUpdateDto & { cameraGroupIds: NonNullable<IEventRuleCreateUpdateDto["cameraGroupIds"]> }
type IEventRuleDetailsWithEtag = IDataWithEtag<IEventRuleDetails>
interface IEventTypeDto {
    id:string
}
enum Tab {
    DESCRIPTIONS = "descriptions",
    CAMERAGROUPS = "camera-groups"
}

const getEventRuleDetails = async ({ queryKey }: QueryFunctionContext<[string, string]>): Promise<IEventRuleDetailsWithEtag> => {
    const [, id] = queryKey
    const response = await axios.get<IEventRuleDetails>(`/api/admin/v1/event-rules/${id}`)
    return {
        data: response.data,
        eTag: response.headers.etag
    }
}

async function getEventType() {
    const response = await axios.get<IEventTypeDto[]>("/api/admin/v1/event-types")
    return response.data
}

const postEventRuleDetails = async (payload: IEventRuleUpdate): Promise<AxiosResponse> => {
    return await axios.post("/api/admin/v1/event-rules", payload)
}

const putEventRuleDetails = async (id: string, eTag: string, payload: IEventRuleUpdate): Promise<AxiosResponse> => {
    return await axios.put(`/api/admin/v1/event-rules/${id}`, payload, { headers: { "If-Match": eTag } })
}

const deleteEventRule = async (id: string, eTag: string): Promise<AxiosResponse> => {
    return await axios.delete(`/api/admin/v1/event-rules/${id}`, { headers: { "If-Match": eTag } })
}

interface Props {
    eventRule: IEventRuleDetailsWithEtag
    newEventRule: boolean
    eventList: IEventTypeDto[]
}

export const EventRuleDetails: FC<Props> = ({ eventRule, newEventRule,eventList }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const queryClient = useQueryClient()
    const initialState = useMemo<IEventRuleUpdate>(() => ({
        name: eventRule.data.name,
        description: eventRule.data.description ?? "",
        triggerEvent: eventRule.data.triggerEvent,
        triggerCamera: eventRule.data.triggerCamera ?? -1,
        triggerZone: eventRule.data.triggerZone ?? -1,
        subEvent: eventRule.data.subEvent,
        subCamera: eventRule.data.subCamera ?? -1,
        rangeFrom: eventRule.data.rangeFrom ?? 0,
        rangeTo: eventRule.data.rangeTo ?? 0,
        subZone: eventRule.data.subZone ?? -1,
        ttl: eventRule.data.ttl ?? 0,
        type: eventRule.data.type ?? EventRuleType.Block ,
        cameraGroupIds: eventRule.data.cameraGroups.map(({ id }) => id)
    }), [eventRule.data])
    const [draft, setDraft] = useState<IEventRuleUpdate>(initialState)
    const [activeTab, setActiveTab] = useState<Tab>(Tab.DESCRIPTIONS)
    const [saveDialog, setSaveDialog] = useState<boolean>(false)
    const [cancelDialog, setCancelDialog] = useState<boolean>(false)
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)

    const [saveError, setSaveError] = useState<string | null>(null)

    const isChanged = useMemo<boolean>(
        () => (
            initialState.name !== draft.name ||
            initialState.description !== draft.description ||
            initialState.triggerEvent !== draft.triggerEvent ||
            initialState.triggerCamera !== draft.triggerCamera ||
            initialState.triggerZone !== draft.triggerZone ||
            initialState.subEvent !== draft.subEvent ||
            initialState.subCamera !== draft.subCamera ||
            initialState.rangeFrom !== draft.rangeFrom ||
            initialState.rangeTo !== draft.rangeTo ||
            initialState.ttl !== draft.ttl ||
            initialState.type !== draft.type ||
            initialState.cameraGroupIds.length !== draft.cameraGroupIds.length ||
            JSON.stringify(initialState.cameraGroupIds) !== JSON.stringify(draft.cameraGroupIds)
        ),
        [draft, initialState]
    )

    const validation = useMemo<Partial<Record<keyof IEventRuleUpdate, boolean>>>(
        () => (
            {
                name: draft.name.length > 0 && draft.name.length <= 100,
                description: true
            }
        ),
        [draft.name]
    )

    const { mutateAsync: handleSaveChangesAsync, isLoading: putLoading } = useMutation(
        () => {
            if (newEventRule) return postEventRuleDetails(draft)
            return putEventRuleDetails(eventRule.data.id, eventRule.eTag, draft)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("event-rules")
                if (!newEventRule) queryClient.invalidateQueries(["event-rule", eventRule.data.id])
            },
            onError: (error: AxiosError<{errors:{validationRule:string}}>) => {
                if (error.response?.data?.errors?.validationRule.includes("EventRuleNameDuplicate"))
                    setSaveError(t("event-rule-details.name-already-taken"))
                else
                    setSaveError(t("event-rule-details.save-changes_failed"))
            }
        }
    )

    const { mutateAsync: handleDeleteEventRule, isLoading: deleteLoading } = useMutation(
        () => deleteEventRule(eventRule.data.id, eventRule.eTag),
        { onSuccess: () => { queryClient.invalidateQueries("event-rules") } }
    )

    const handleChange = useCallback((key: keyof IEventRuleUpdate, value: unknown) => {
        setDraft(prevState => ({ ...prevState, [key]: value }))
    }, [])

    useEffect(() => {
        const hash = location.hash.replace("#", "")
        let selectedTab: Tab | undefined
        switch (hash) {
            case "":
                selectedTab = Tab.DESCRIPTIONS
                break
            case Tab.CAMERAGROUPS:
                selectedTab = Tab.CAMERAGROUPS
                break
            default:
                selectedTab = undefined
        }
        if (selectedTab) {
            setActiveTab(selectedTab)
        } else {
            navigate(".")
        }
    }, [location.hash, navigate])

    return (
        <>
            <GoBackButton />
            <DragonTitle title={eventRule.data.name || t("create-event-rule")} />
            <Paper sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <Stack direction="row">
                    <DragonTabs value={activeTab}>
                        <DragonTab
                            label={t("event-rule-details.descriptions")}
                            value={Tab.DESCRIPTIONS}
                            icon={
                                <Icon
                                    isSelected={activeTab === Tab.DESCRIPTIONS}
                                    icon={faFileAlt}
                                    selectedIcon={faFileAltSolid}
                                />
                            }
                            to="."
                        />
                        <DragonTab
                            label={t("event-rule-details.camera-groups")}
                            value={Tab.CAMERAGROUPS}
                            icon={
                                <Icon
                                    isSelected={activeTab === Tab.CAMERAGROUPS}
                                    icon={faVideoPlus}
                                    selectedIcon={faVideoPlusSolid}
                                />
                            }
                            to="#camera-groups"
                        />
                    </DragonTabs>
                </Stack>
                <Divider flexItem />
                {/* DESCRIPTION */}
                {activeTab === Tab.DESCRIPTIONS && (
                    <Box padding={2} overflow="auto">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Typography variant="h6" fontWeight="bold">
                                        {t("event-rule-details.name")}
                                    </Typography>
                                    <TextField
                                        variant="filled"
                                        InputProps={{
                                            disableUnderline: eventRule.data.name === draft.name,
                                            endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                        }}
                                        value={draft.name}
                                        onChange={(event) => handleChange("name", event.target.value)}
                                        error={!validation.name}
                                        fullWidth
                                    />
                                </FormControl>

                                <FormControl fullWidth>
                                    <Typography variant="h6" fontWeight="bold">
                                        {t("event-rule-details.description")}
                                    </Typography>
                                    <TextField
                                        variant="filled"
                                        InputProps={{
                                            disableUnderline: eventRule.data.description === draft.description,
                                            endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                        }}
                                        value={draft.description}
                                        onChange={(event) => handleChange("description", event.target.value)}
                                        error={!validation.description}
                                        fullWidth
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" fontWeight="bold">
                                        {t("event-rule-details.rule-type.title")}
                                </Typography>
                                <FormControl>
                                        <Select
                                            variant="filled"
                                            value={draft.type}
                                            onChange={e => handleChange("type",e.target.value)}
                                            disableUnderline={initialState.type === draft.type}
                                        >
                                            <MenuItem value={EventRuleType.Block} key={"rule-type-block"}>{t("event-rule-details.rule-type.block")}</MenuItem>
                                            {/* <MenuItem value={EventRuleType.Allow} key={"rule-type-allow"}>{t("event-rule-details.rule-type.allow")}</MenuItem>
                                            <MenuItem value={EventRuleType.Trigger} key={"rule-type-trigger"}>{t("event-rule-details.rule-type.trigger")}</MenuItem> */}
                                        </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" fontWeight="bold">
                                        {t("event-rule-details.trigger.title")}
                                </Typography>
                                <Stack direction={"row"} spacing={2}>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography fontWeight="bold">{t("event-rule-details.trigger.event")}</Typography>
                                        <Select
                                            variant="filled"
                                            value={draft.triggerEvent}
                                            onChange={e => handleChange("triggerEvent",e.target.value)}
                                            disableUnderline={initialState.triggerEvent === draft.triggerEvent}
                                            
                                        >
                                            {eventList.map(ev =><MenuItem value={ev.id} key={`trigger-event-${ev.id}`}>{t(`event-types.${ev.id}`)}</MenuItem>)}
                                        </Select>
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography fontWeight="bold">{t("event-rule-details.trigger.camera")}</Typography>
                                        <Select
                                            variant="filled"
                                            value={draft.triggerCamera}
                                            onChange={e => handleChange("triggerCamera",e.target.value)}
                                            disableUnderline={initialState.triggerCamera === draft.triggerCamera}
                                        >
                                            <MenuItem value={-1} key={"trigger-camera-any"}>{t("event-rule-details.any")}</MenuItem>
                                            {/* {[...Array(400)].map((_,i) =><MenuItem value={i} key={`trigger-camera-${i}`}>{i}</MenuItem>)} */}
                                        </Select>
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography fontWeight="bold">{t("event-rule-details.trigger.zone")}</Typography>
                                        <Select
                                            variant="filled"
                                            value={draft.triggerZone}
                                            onChange={e => handleChange("triggerZone",e.target.value)}
                                            disableUnderline={initialState.triggerZone === draft.triggerZone}
                                        >
                                            <MenuItem value={-1} key={"trigger-zone-any"}>{t("event-rule-details.any")}</MenuItem>
                                            {[...Array(10)].map((_,i) =><MenuItem value={i} key={`trigger-zone-${i}`}>{i}</MenuItem>)}
                                        </Select>
                                    </Stack>
                                </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" fontWeight="bold">
                                        {t("event-rule-details.sub.title")}
                                </Typography>
                                <Stack direction={"row"} spacing={2}>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography fontWeight="bold">{t("event-rule-details.sub.event")}</Typography>
                                        <Select
                                            variant="filled"
                                            value={draft.subEvent}
                                            onChange={e => handleChange("subEvent",e.target.value)}
                                            disableUnderline={initialState.subEvent === draft.subEvent}
                                            
                                        >
                                            {eventList.map(ev =><MenuItem value={ev.id} key={`sub-event-${ev.id}`}>{t(`event-types.${ev.id}`)}</MenuItem>)}
                                        </Select>
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography fontWeight="bold">{t("event-rule-details.sub.camera")}</Typography>
                                        <Select
                                            variant="filled"
                                            value={draft.subCamera}
                                            onChange={e => handleChange("subCamera",e.target.value)}
                                            disableUnderline={initialState.subCamera === draft.subCamera}
                                        >
                                            <MenuItem value={-2} key={"sub-camera-same"}>{t("event-rule-details.same")}</MenuItem>
                                            <MenuItem value={-1} key={"sub-camera-any"}>{t("event-rule-details.any")}</MenuItem>
                                            {/* {[...Array(40)].map((_,i) =><MenuItem value={i} key={`sub-camera-${i}`}>{i}</MenuItem>)} */}
                                        </Select>
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography fontWeight="bold">{t("event-rule-details.sub.range-from")}</Typography>
                                        <Select
                                            variant="filled"
                                            value={draft.rangeFrom}
                                            onChange={e => handleChange("rangeFrom",e.target.value)}
                                            disableUnderline={initialState.rangeFrom === draft.rangeFrom}
                                        >
                                            {[...Array(10)].map((_,i) =><MenuItem value={0-i} key={`sub-camera-from${0-i}`}>{0-i}</MenuItem>)}
                                        </Select>
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography fontWeight="bold">{t("event-rule-details.sub.range-to")}</Typography>
                                        <Select
                                            variant="filled"
                                            value={draft.rangeTo}
                                            onChange={e => handleChange("rangeTo",e.target.value)}
                                            disableUnderline={initialState.rangeTo === draft.rangeTo}
                                        >
                                            {[...Array(10)].map((_,i) =><MenuItem value={i} key={`sub-camera-to${i}`}>{i}</MenuItem>)}
                                        </Select>
                                    </Stack>
                                </FormControl>

                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography fontWeight="bold">{t("event-rule-details.sub.zone")}</Typography>
                                        <Select
                                            variant="filled"
                                            value={draft.subZone}
                                            onChange={e => handleChange("subZone",e.target.value)}
                                            disableUnderline={initialState.subZone === draft.subZone}
                                        >
                                            <MenuItem value={-2} key={"sub-zone-same"}>{t("event-rule-details.same")}</MenuItem>
                                            <MenuItem value={-1} key={"sub-zone-any"}>{t("event-rule-details.any")}</MenuItem>
                                            {[...Array(10)].map((_,i) =><MenuItem value={i} key={`sub-zone-${i}`}>{i}</MenuItem>)}
                                        </Select>
                                    </Stack>
                                </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" fontWeight="bold">
                                        {t("event-rule-details.time.title")}
                                </Typography>
                                <FormControl>
                                        <Select
                                            variant="filled"
                                            value={draft.ttl}
                                            onChange={e => handleChange("ttl",e.target.value)}
                                            disableUnderline={initialState.ttl === draft.ttl}
                                        >
                                            <MenuItem value={0} key={"time-none"}>{"-"}</MenuItem>
                                            <MenuItem value={5} key={"time-5s"}>{t("event-rule-details.time.seconds",{value:5})}</MenuItem>
                                            <MenuItem value={15} key={"time-15s"}>{t("event-rule-details.time.seconds",{value:15})}</MenuItem>
                                            <MenuItem value={30} key={"time-30s"}>{t("event-rule-details.time.seconds",{value:30})}</MenuItem>
                                            <MenuItem value={60} key={"time-1m"}>{t("event-rule-details.time.minute",{value:1})}</MenuItem>
                                            <MenuItem value={120} key={"time-2m"}>{t("event-rule-details.time.minutes",{value:2})}</MenuItem>
                                            <MenuItem value={180} key={"time-3m"}>{t("event-rule-details.time.minutes",{value:3})}</MenuItem>
                                            <MenuItem value={240} key={"time-4m"}>{t("event-rule-details.time.minutes",{value:4})}</MenuItem>
                                            <MenuItem value={300} key={"time-5m"}>{t("event-rule-details.time.minutes",{value:5})}</MenuItem>
                                            <MenuItem value={360} key={"time-6m"}>{t("event-rule-details.time.minutes",{value:6})}</MenuItem>
                                            <MenuItem value={420} key={"time-7m"}>{t("event-rule-details.time.minutes",{value:7})}</MenuItem>
                                            <MenuItem value={480} key={"time-8m"}>{t("event-rule-details.time.minutes",{value:8})}</MenuItem>
                                            <MenuItem value={540} key={"time-9m"}>{t("event-rule-details.time.minutes",{value:9})}</MenuItem>
                                            <MenuItem value={600} key={"time-10m"}>{t("event-rule-details.time.minutes",{value:10})}</MenuItem>
                                        </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {/* CAMERA GROUPS */}
                {activeTab === Tab.CAMERAGROUPS && (
                    <Stack flex={1} spacing={2} p={2} overflow="hidden">
                        <CameraGroupAssignmentForm
                            cameraGroups={eventRule.data.cameraGroups ?? []}
                            onChange={(cameraGroupIds) => setDraft(prevState => ({ ...prevState, cameraGroupIds }))}
                        />
                    </Stack>
                )}
            </Paper>
            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                {!newEventRule && <Button
                    variant="outlined"
                    color="error"
                    sx={{ minWidth: 150 }}
                    onClick={() => setDeleteDialog(true)}
                >
                    {t("event-rule-details.delete-event-rule")}
                </Button>}
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: 150 }}
                    onClick={() => setSaveDialog(true)}
                    disabled={!isChanged || Object.values(validation).some(v => !v)}
                >
                    {newEventRule ? t("create-event-rule") : t("save-changes")}
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ minWidth: 150 }}
                    onClick={() => {
                        if (isChanged) setCancelDialog(true)
                        else navigate("..")
                    }}
                >
                    {t("cancel")}
                </Button>
            </Stack>

            <DragonConfirmationDialogWithPromise
                open={saveDialog}
                variant="save"
                title={t("event-rule-details.save-changes_confirmation")}
                onClose={() => {
                    setSaveDialog(false)
                    setSaveError(null)
                }}
                onConfirm={async () => {
                    await handleSaveChangesAsync()
                    navigate("..")
                }}
                isLoading={putLoading}
                errorText={saveError}
            />
            <DragonConfirmationDialog
                open={cancelDialog}
                variant="warning"
                title={t("event-rule-details.discard-changes_confirmation")}
                onClose={() => setCancelDialog(false)}
                onConfirm={() => {
                    setDraft(initialState)
                    navigate("..")
                }}
            />
            <DragonConfirmationDialogWithPromise
                open={deleteDialog}
                variant="delete"
                title={t(
                    "event-rule-details.delete-event-rule_confirmation",
                    { event_rule: eventRule.data.name, count: eventRule.data.cameraGroups.length }
                )}
                onClose={() => setDeleteDialog(false)}
                onConfirm={async () => {
                    await handleDeleteEventRule()
                    navigate("..")
                }}
                isLoading={deleteLoading}
                errorText={t("event-rule-details.delete-event-rule_failed")}
            />
        </>
    )
}

export const EventRuleDetailsContainer: FC = () => {
    const { t } = useTranslation()
    const { eventRuleId } = useParams<"eventRuleId">()
    //const [error, setError] = useState("")

    
    const result = useQueries([
        { queryKey: "event-types", queryFn: getEventType },
        { queryKey: ["event-rule", eventRuleId ?? "default"], queryFn: getEventRuleDetails }
    ])

    const isLoading = result.some(query => query.isLoading)
    const isError = result.some(query => query.isError)

    const [{ data: eventTypes }, { data: eventRule }] = result
    const isSuccess = !!(eventTypes && eventRule)

    // const {
    //     data
    // } = useQuery<IEventRuleDetailsWithEtag, AxiosError, IEventRuleDetailsWithEtag, [string, string]>(
    //     ["event-rule", eventRuleId ?? "default"],
    //     getEventRuleDetails,
    //     {
    //         onError: (error) => {
    //             if (error.response?.status === 404)
    //                 return setError(t("event-rule-details.event-rule-does-not-exist"))
    //             return setError(t("something-went-wrong"))
    //         }
    //     }
    // )

    return (
        <DragonPageWrapper>
            {(() => {
                if (isLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                return <Typography>{t("something-went-wrong")}</Typography>

                if (isSuccess)
                    return (
                        <ErrorBoundary>
                            <EventRuleDetails eventRule={eventRule} newEventRule={!eventRuleId} eventList={eventTypes}/>
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>
    )
}

export default EventRuleDetailsContainer