import { Box, Typography } from "@mui/material"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"
import React, { memo, useEffect, useMemo, useRef } from "react"

import { DelayedCircularProgress } from "utils/DelayedProgress"
import EventListItem from "./EventListItem"
import IEvent, { IEventDto } from "./IEvent"
import IEventJournalFilterSelection from "./types/IEventJournalFilterSelection"
import { IOrderBy } from "./IOrderBy"
import { Link } from "react-router-dom"
import axios from "axios"
import getMostSpecificIdsFromFilter from "./utils/getMostSpecificIdsFromFilter"
import getStartAndEndDateFromTimeFrame from "../../Components/Filter/utils/getTimeFrameFromFilter"
import { useTranslation } from "react-i18next"
import getFilteredStateIds from "./utils/getFilteredStateIds"
import { assertUnreachable } from "utils/assertUnrechable"

interface IEventList {
    selection: IEventJournalFilterSelection
    orderBy: IOrderBy
    onUpdateEventIds: (eventIds: string[]) => void
}

function EventList({ selection, orderBy, onUpdateEventIds }: IEventList) {
    const { t } = useTranslation()

    const {
        data: events,
        isLoading,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage
    } = useInfiniteQuery(
        ["events", selection, orderBy],
        getFilteredEvents,
        {
            getNextPageParam: (lastPage, _) => {
                if (lastPage.length === 0)
                    return undefined

                return lastPage[lastPage.length - 1].startedAt
            }
        }
    )

    function handleScroll({ currentTarget }: React.UIEvent<HTMLDivElement>) {
        if (isFetchingNextPage)
            return

        const bottom = currentTarget.scrollHeight
        const scrollY = currentTarget.scrollTop + currentTarget.clientHeight

        const isScrollEnd = bottom - scrollY <= (currentTarget.clientHeight * 2)

        if (isScrollEnd && hasNextPage && !isFetching)
            fetchNextPage()
    }

    const listRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!listRef?.current)
            return

        const list = listRef.current
        const myObserver = new ResizeObserver(entries => entries.forEach(({ target }) => {
            if (
                hasNextPage
                && !isFetching
                && target.clientHeight >= target.scrollHeight
            )
                fetchNextPage()
        }))
        myObserver.observe(list)
        return () => {
            myObserver.unobserve(list)
        }
    }, [fetchNextPage, hasNextPage, isFetching])


    const allEvents = useMemo(() => events?.pages.flat() ?? [], [events])
    const anyEvents = allEvents.length > 0

    useEffect(() => {
        onUpdateEventIds(allEvents.map(event => event.id))
    }, [allEvents, onUpdateEventIds])

    return <Box
        sx={{ overflow: "auto" }}
        onScroll={handleScroll}
        ref={listRef}
    >
        <Box
            sx={{
                display: "grid",
                gap: 2,
                gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
                p: 2
            }}
        >
            {!anyEvents
                ? isLoading
                    ? <Box sx={{ display: "flex", justifyContent: "center", gridColumn: "1 / -1" }}>
                        <DelayedCircularProgress delay={250} />
                    </Box>
                    : <Typography>{t("event-list.no-events")}</Typography>
                : <>
                    {allEvents.map(event => {
                        const snapshotUrl = event.eventAssets.find(asset => asset.assetTypeId === "Snapshot")?.uri

                        return <Link
                            to={`event/${event.id}`}
                            style={{ textDecoration: "none" }}
                            key={event.id}
                        >
                            <EventListItem
                                eventTypeId={event.eventTypeId}
                                snapshotUrl={snapshotUrl}
                                cameraName={event.camera.name}
                                startedAt={event.startedAt}
                            />
                        </Link>
                    })
                    }
                    {isFetchingNextPage &&
                        <Box sx={{ display: "flex", justifyContent: "center", gridColumn: "1 / -1" }}>
                            <DelayedCircularProgress delay={250} />
                        </Box>
                    }
                </>
            }
        </Box>
    </Box>
}

export default memo(EventList)

async function getFilteredEvents({ queryKey, pageParam }: QueryFunctionContext<[string, IEventJournalFilterSelection, IOrderBy], Date>) {
    const [_key, selection, orderBy] = queryKey
    const lastStartedAt = pageParam
    const limit = 48

    const [startedAt, endedAt] = getStartAndEndDateFromTimeFrame(selection.timeFrame)

    const orderByValue = (() => {
        switch (orderBy) {
            case IOrderBy.ASCENDING:
                return "StartedAtAscending"
            case IOrderBy.DESCENDING:
                return "StartedAtDescending"
            default:
                assertUnreachable(orderBy)
        }
    })()

    const response = await axios.put<IEventDto[]>(
        "/api/event/v1/events/event-journal",
        {
            ...getMostSpecificIdsFromFilter(selection),
            ...getFilteredStateIds(selection),
            "limit": limit,
            "startedAtGe": startedAt,
            "endedAtLe": endedAt,
            "orderBy": orderByValue,
            "lastStartedAt": lastStartedAt,
            "eventTypeIds": selection.eventTypeIds
        }
    )

    return response.data.map<IEvent>(event => ({
        ...event,
        startedAt: new Date(event.startedAt),
        endedAt: event.endedAt !== null
            ? new Date(event.endedAt)
            : null
    }))
}