import IWidgetSelection from "../types/IWidgetSelection"

export const defaultSelection: IWidgetSelection = {
    name: "",
    dataSource: null,
    widgetType: null,
    cameraGroupIds: [],
    cameraIds: [],
    zoneIds: [],
    vehicleClassIds: [],
    peopleClassIds: [],
    resolution: "h",
    timeFrame: { type: "today" },
    eventTypeIds: [],
    includeSimulated: false,
    includeFiltered: false,
    includeMarkedAsFalse: true,
    includeMarkedAsTrue: true,
    includeUnmarked: true,
    limit: 10,
    refreshInterval: 60,
    includeComing: true,
    includeGoing: true
}