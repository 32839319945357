import React from "react"
import { SelectChangeEvent, FormControl, Select, MenuItem, ListItemText, Checkbox } from "@mui/material"
import { useTranslation } from "react-i18next"
import FilterControlLabel from "../../FilterControlLabel"

interface IMultipleSelector {
    values: string[]
    selectedValues: string[]
    onChange: (values: string[]) => void
    label: string
    valueFormatter: (value: string) => string
}

export default function MultipleSelector({
    values,
    selectedValues,
    onChange,
    label,
    valueFormatter
}: IMultipleSelector) {
    const { t } = useTranslation()

    const allSelected = values
        .every(value => selectedValues.includes(value))

    function _onChange(event: SelectChangeEvent<string[]>) {
        const value = event.target.value

        if (value.includes("select-all")) {
            if (allSelected)
                onChange([])
            else
                onChange(values)
            return
        }

        onChange(
            typeof value === "string" ? value.split(",") : value
        )
    }

    return <FormControl sx={{ width: "100%" }}>
        <FilterControlLabel text={label} />
        <Select
            multiple
            value={selectedValues}
            onChange={_onChange}
            variant="filled"
            disableUnderline
            renderValue={() =>
                values
                    .filter(value => selectedValues.includes(value))
                    .map((value) => valueFormatter(value))
                    .join(", ")
            }
        >
            <MenuItem
                value="select-all"
                sx={{ padding: 0 }}
            >
                <Checkbox
                    checked={allSelected}
                    indeterminate={selectedValues.length > 0 && !allSelected}
                />
                <ListItemText primary={t("event-filter.select-all")} />
            </MenuItem>
            {values.map(value => (
                <MenuItem
                    key={value}
                    value={value}
                    sx={{ padding: 0 }}
                >
                    <Checkbox checked={selectedValues.includes(value)} />
                    <ListItemText primary={valueFormatter(value)} />
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}