import { IAcknowledgeStateId } from "Types/event/IAcknowledgeStateId"
import { IAssetTypeId } from "Types/event/IAssetTypeId"
import { IFilteredStateId } from "Types/event/IFilteredStateId"
import { ISimulatedStateId } from "Types/event/ISimulatedStateId"
import { IValidStateId } from "Types/event/IValidStateId"
import { IPosition } from "Types/IPosition"
import { assertUnreachable } from "utils/assertUnrechable"

export interface ICameraGroup {
    id: string
    name: string
}

export interface ICamera {
    id: string
    name: string
    position: IPosition | null
}

export interface IZone {
    id: string
    name: string
}

export interface IEventAsset {
    assetTypeId: IAssetTypeId
    uri: string
}

export interface IEventCommentReaction {
    entity: {
        id: string
        reactionTypeId: string
        createdByCurrentUser: boolean
        createdName: string
    }
    eTag: string
}

export interface IEventComment {
    entity: {
        id: string
        comment: string
        eventCommentReactions: IEventCommentReaction[]
        createdByCurrentUser: boolean
        createdAt: Date
        createdName: string
        updatedAt?: Date
        updatedName?: string
    }
    eTag: string
}

export interface IEventDto {
    id: string
    cameraGroups: ICameraGroup[]
    camera: ICamera
    zone: IZone | null
    eventTypeId: string
    eventTypeGroup: {
        id: string
        name: string
        code: string
    }
    startedAt: Date
    endedAt: Date | null
    extraData: string | null
    objectClassId: string | null
    color: string | null
    acknowledgeStateId: IAcknowledgeStateId
    validStateId: IValidStateId
    simulatedStateId: ISimulatedStateId
    filteredStateId: IFilteredStateId
    bookmarked: boolean
    eventBookmarkId: string | null
    eventComments: IEventComment[]
    eventAssets: IEventAsset[]
    createdAt: Date | null
    createdName: string | null
    updatedAt: Date | null
    updatedName: string | null
    timeToLiveAt: Date
}

export interface IEvent extends Omit<IEventDto, "acknowledgeStateId" | "validStateId" | "filteredStateId" | "simulatedStateId"> {
    isAcknowledged: boolean | null
    markedAs: "true" | "false" | null
    isSimulated: boolean
    isFiltered: boolean
}

export function mapEventDtoToEvent(event: IEventDto): IEvent {
    const { acknowledgeStateId, simulatedStateId, filteredStateId, validStateId, ...rest } = event

    return ({
        ...rest,
        isAcknowledged: (() => {
            switch (acknowledgeStateId) {
                case "Acknowledged":
                    return true
                case "NotAcknowledged":
                    return false
                case "Undefined":
                    return null
                default:
                    assertUnreachable(acknowledgeStateId)
            }
        })(),
        isSimulated: simulatedStateId === "Simulated",
        isFiltered: filteredStateId === "Filtered",
        markedAs: (() => {
            switch (validStateId) {
                case "Valid":
                    return "true"
                case "NotValid":
                    return "false"
                case "Undefined":
                    return null
                default:
                    assertUnreachable(validStateId)
            }
        })()
    })
}