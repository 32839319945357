import { FC, useMemo, useState } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { Paper } from "@mui/material"

import { DragonTableWithSearchField, HeaderCell, BodyRow } from "Components/DragonTable"
import { ICameraListDto } from "Types/admin"
import CameraDetails from "Pages/CameraDetails/CameraDetails"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"

type ICameraListItem = ICameraListDto

const getCameras = async ({ pageParam: offset = 0, queryKey }: QueryFunctionContext): Promise<ICameraListItem[]> => {
    const [, searchName] = queryKey
    const response = await axios.get<ICameraListItem[]>("/api/admin/v1/cameras", {
        params: { limit: 25, offset, name: searchName || undefined }
    })
    return response.data
}

export const Cameras: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState<string>("")

    const showDetails = useLocation().pathname.startsWith("/cameras/camera")

    const {
        data, hasNextPage = false, fetchNextPage, isFetching
    } = useInfiniteQuery(["cameras", searchValue], getCameras, {
        getNextPageParam: (lastPage, allPages) => (
            lastPage.length === 0 ? undefined : allPages.reduce((amount, page) => amount + page.length, 0)
        ),
        keepPreviousData: true
    })

    const allCameras = useMemo(() => data?.pages.flat() ?? [], [data])
    const cameraIds = allCameras.map(camera => camera.id)

    const tableColumns = useMemo<HeaderCell[]>(() => [
        { value: t("name") },
        { value: t("zones") },
        { value: t("position") }
    ], [t])

    const tableData = useMemo<BodyRow[]>(() => {
        return allCameras.map(({ id, name, latitude: lat, longitude: lng, zones,server }) => (
            {
                row: [
                    { value: `${name} (${server?.name ?? "-"})` },
                    { value: `${zones.slice(0, 2).map(({ name }) => name).join(", ")}${zones.length > 2 ? t("and-more-with-amount", { amount: zones.length - 2 }) : ""}` },
                    { value: lat && lng ? t("position-set") : t("no-set-position"), sx: { color: lat && lng ? "success.main" : "error.main" } }
                ],
                onClick: () => navigate(`camera/${id}`),
                sx: { cursor: "pointer" },
                hover: true
            }
        ))

    }, [allCameras, navigate, t])

    return (
        <>
            <DragonPageWrapper hidden={showDetails}>
                <DragonTitle title={t("cameras")} />
                <Paper sx={{ display: "flex", overflow: "hidden" }}>
                    <DragonTableWithSearchField
                        columns={tableColumns}
                        data={tableData}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetching={isFetching}
                        noDataText={t("no-camera-found")}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        searchPlaceholder={t("search-for-camera")}
                    />
                </Paper>
            </DragonPageWrapper>

            <Routes>
                <Route path="camera/:cameraId/*" element={<CameraDetails cameraIds={cameraIds} />} />
            </Routes>
        </>
    )
}

export default Cameras
