import React from "react"
import { useState } from "react"
import FilterControlLabel from "Components/Filter/FilterControlLabel"
import { FormControl, Checkbox, TextField, Box, Stack, Typography, FormControlLabel, Button } from "@mui/material"
import IEventTypeGroup from "./IEventTypeGroup"
import { useTranslation } from "react-i18next"
import DragonDialog from "Components/DragonDialog"

interface IEventTypesSelector {
    values: IEventTypeGroup[]
    selectedValues: string[]
    onChange: (values: string[]) => void
}

export default function EventTypesSelector({
    values,
    selectedValues,
    onChange
}: IEventTypesSelector) {
    const { t } = useTranslation()
    const [showSelection, setShowSelection] = useState(false)

    const allIds = values
        .flatMap(group => group.eventTypeGroupItems)
        .map(type => type.eventTypeId)

    const closeDialog = () => setShowSelection(false)

    const allSelected = allIds.length === selectedValues.length
    const anySelected = selectedValues.length !== 0

    return <FormControl
        sx={{ width: "100%" }}
        onClick={event => {
            event.stopPropagation()
            if (!showSelection)
                setShowSelection(true)
        }}
    >
        <FilterControlLabel text={t("event-filter.event-types")} />
        <TextField
            variant="filled"
            InputProps={{
                disableUnderline: true,
                inputProps: {
                    readOnly: true,
                    style: { cursor: "pointer" }
                }
            }}
            fullWidth
            placeholder={t("event-filter.click-to-select-event-types")}
            value={selectedValues.map(id => t(`event-types.${id}`)).join(", ")}
        />
        <DragonDialog
            open={showSelection}
            onClose={closeDialog}
            actions={
                <Button
                    variant="contained"
                    onClick={closeDialog}
                >
                    {t("event-filter.done")}
                </Button>
            }
        >
            <Box overflow="hidden" sx={{
                display: "flex",
                flexDirection: "column",
                borderBottom: theme => `1px solid ${theme.palette.divider}`
            }}>
                <Stack direction="row" sx={{
                    p: 2,
                    pt: 0,
                    borderBottom: theme => `1px solid ${theme.palette.divider}`
                }}>
                    <Box flexGrow={1}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={allSelected}
                                indeterminate={!allSelected && anySelected}
                                onChange={() => allSelected
                                    ? onChange([])
                                    : onChange(allIds)
                                }
                            />}
                            label={t("event-filter.select-all") as string}
                        />
                        <Stack
                            direction="row"
                            spacing={2}
                        >
                            {values.map(group => {
                                const groupIds = group.eventTypeGroupItems.map((type => type.eventTypeId))

                                const allSelectedInGroup = groupIds.every(id => selectedValues.includes(id))
                                const anySelectedInGroup = groupIds.some(id => selectedValues.includes(id))

                                return <Box
                                    key={group.id}
                                    sx={{
                                        position: "relative",
                                        flex: "1 1 0px"
                                    }}
                                >
                                    <Typography fontWeight="bold">{group.name}</Typography>
                                    <ul style={{
                                        listStyle: "none",
                                        padding: 0,
                                        margin: 0
                                    }}>
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={allSelectedInGroup}
                                                        indeterminate={!allSelectedInGroup && anySelectedInGroup}
                                                        onChange={() => !anySelectedInGroup
                                                            ? onChange([...selectedValues, ...groupIds])
                                                            : allSelectedInGroup
                                                                ? onChange(selectedValues.filter(id => !groupIds.includes(id)))
                                                                : onChange([...selectedValues.filter(id => !groupIds.includes(id)), ...groupIds])
                                                        }
                                                        color="primary"
                                                    />
                                                }
                                                label={t("event-filter.select-all") as string}
                                            />
                                        </li>

                                    </ul>
                                </Box>
                            })}
                        </Stack>
                    </Box>
                    <Box sx={{ overflow: "auto", scrollbarGutter: "stable" }} />
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    p={2}
                    overflow="auto"
                    sx={{ scrollbarGutter: "stable" }}
                >
                    {values.map(group =>
                        <Box
                            key={group.id}
                            sx={{
                                flex: "1 1 0px"
                            }}
                        >
                            <ul
                                style={{
                                    listStyle: "none",
                                    padding: 0,
                                    margin: 0
                                }}
                            >
                                {group.eventTypeGroupItems.map(type =>
                                    <li key={type.eventTypeId}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedValues.includes(type.eventTypeId)}
                                                    onChange={() => selectedValues.includes(type.eventTypeId)
                                                        ? onChange(selectedValues.filter(id => id !== type.eventTypeId))
                                                        : onChange([...selectedValues, type.eventTypeId])
                                                    }
                                                    color="secondary"
                                                />
                                            }
                                            label={t(`event-types.${type.eventTypeId}`) as string}
                                        />
                                    </li>
                                )}
                            </ul>
                        </Box>
                    )}
                </Stack>
            </Box>
        </DragonDialog >
    </FormControl >
}