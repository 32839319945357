
import Header from "BaseLayout/Header/Header"
import Sidebar from "BaseLayout/Sidebar"
import { Routes } from "."
import ErrorBoundary from "utils/ErrorBoundary"
import { useLocation } from "react-router-dom"
import { Box } from "@mui/material"

export default function App() {
    const location = useLocation()
    const page = location.pathname.split("/")[1]

    return <>
        <Header systemName={process.env.REACT_APP_DRAGON_SYSTEM_NAME} />
        <Sidebar />
        <Box component="main" gridArea="main-content" overflow="hidden" display="flex" flexDirection="column">
            {/* The key is here is used to clear the error boundary if the route changes */}
            <ErrorBoundary key={page}>
                <Routes />
            </ErrorBoundary>
        </Box>
    </>
}