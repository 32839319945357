import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Stack, Typography } from "@mui/material"
import React, { FormEvent, memo, useState } from "react"

import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faFilter, faTimes } from "@fortawesome/pro-regular-svg-icons"
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons"

interface IFilter {
    onSubmit: () => void
    onReset: () => void,
    hasActiveFilters: boolean
    children: React.ReactNode | React.ReactNode[]
    customActions?: React.ReactNode | React.ReactNode[]
    variant?: "elevation" | "outlined"
    isOpened?: boolean
}

function Filter({
    onSubmit,
    onReset,
    hasActiveFilters,
    children,
    customActions,
    variant,
    isOpened = false
}: IFilter) {
    const { t } = useTranslation()
    const [isExpanded, setIsExpanded] = useState(isOpened)

    function _onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()
        setIsExpanded(false)
        onSubmit()
    }

    return <Accordion
        disableGutters
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
        sx={{ mb: 2, borderRadius: 1, "&:before": { display: "none" } }}
        variant={variant}
    >
        <AccordionSummary
            aria-controls="filter-content"
            id="filter-header"
        >
            <FontAwesomeIcon
                icon={hasActiveFilters
                    ? faFilterSolid
                    : faFilter
                }
                style={{
                    fontSize: "1.2em",
                    color: hasActiveFilters ? "green" : "inherit"
                }} />
            <Typography sx={{ marginLeft: 1 }}>
                {t("event-filter.filter-settings")}
            </Typography>
        </AccordionSummary>
        <AccordionDetails
            sx={{ borderTop: theme => `1px solid ${theme.palette.divider}` }}
        >
            <Box sx={{ marginTop: 2 }}>
                <form onSubmit={_onSubmit}>
                    <Grid container spacing={2}>
                        {React.Children.map(children, child =>
                            <Grid item xs={12} lg={6}>
                                {child}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Stack
                                spacing={{ xs: 1, md: 2 }}
                                direction={{
                                    xs: "column-reverse",
                                    md: "row"
                                }}
                            >
                                <Button
                                    variant="contained"
                                    type="submit"
                                    startIcon={<FontAwesomeIcon icon={faCheck} style={{ fontSize: "1.25em" }} />}
                                    sx={{ justifySelf: "flex-start" }}
                                >
                                    {t("event-filter.apply-filter")}
                                </Button>
                                <div style={{ flexGrow: 1 }} />
                                {customActions}
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    type="button"
                                    onClick={onReset}
                                    startIcon={<FontAwesomeIcon icon={faTimes} style={{ fontSize: "1.25em" }} />}
                                >
                                    {t("event-filter.reset-filters")}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </AccordionDetails>
    </Accordion>
}

export default memo(Filter)