import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr"
import { useUserProfile } from "hooks/useUserProfile"
import { useAuth } from "oidc-react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useContext } from "react"

type ICallback<T> = (data: T) => void

interface IGlobalEventsContext {
    subscribeToEvents: <T>(eventName: string, callback: ICallback<T>) => void
}

const GlobalEventsContext = React.createContext<IGlobalEventsContext>({} as IGlobalEventsContext)

export function useGlobalEvents() {
    const value = useContext(GlobalEventsContext)

    if (!value)
        throw new Error("Tried to access GlobalEventsContext outside of provider!")

    return value
}

export function GlobalEventsContextProvider({ children }: { children: React.ReactNode }) {
    const canConnectToHub = useUserProfile().roles.includes("Operator")

    return canConnectToHub
        ? <HasAccessToHub>{children}</HasAccessToHub>
        : <NoAccessToHub>{children}</NoAccessToHub>
}

function HasAccessToHub({ children }: { children: React.ReactNode }) {
    const auth = useAuth()
    const [hubConnection, setHubConnection] = useState<HubConnection | null>(null)

    useEffect(() => {
        const hubConnection = new HubConnectionBuilder()
            .withUrl("/hubs/event/v1/global", {
                accessTokenFactory: () => {
                    if (!auth?.userData?.access_token)
                        throw new Error("User has no access token!")

                    return auth.userData.access_token
                }
            })
            .withAutomaticReconnect()
            .build()

        hubConnection
            .start()
            .then(() =>
                setHubConnection(hubConnection)
            )

        return () => {
            hubConnection
                .stop()
                .then(() =>
                    setHubConnection(null)
                )
        }
    }, [auth])

    const subscribeToEvents: IGlobalEventsContext["subscribeToEvents"] = useCallback((eventName, callback) => {
        if (!hubConnection) {
            setTimeout(() => subscribeToEvents(eventName, callback), 500)
            return
        }

        switch (hubConnection.state) {
            case HubConnectionState.Connected:
                hubConnection.on(eventName, callback)
                break
            case HubConnectionState.Connecting:
                setTimeout(() => subscribeToEvents(eventName, callback), 250)
                break
            default:
                break
        }
    }, [hubConnection])

    const values = useMemo(() => ({
        subscribeToEvents: subscribeToEvents
    }), [subscribeToEvents])

    return <GlobalEventsContext.Provider
        value={values}
    >
        {children}
    </GlobalEventsContext.Provider>
}

function NoAccessToHub({ children }: { children: React.ReactNode }) {
    return <GlobalEventsContext.Provider
        value={{ subscribeToEvents: () => console.error("Tried to subscribe to event but has no access to global hub") }}
    >
        {children}
    </GlobalEventsContext.Provider>
}