import { IPeopleOccupancy } from "./types"
import PeopleOccupancyCameraPieChart, { IPeopleOccupancyCamera } from "Pages/DataAnalytics/PeopleOccupancyTab/PeopleOccupancyCameraPieChart"


export default function PeopleOccupancyPieChartWidget({ data }: { data: IPeopleOccupancy[] }) {
    const formattedData = transformData(data)
    if(!formattedData) return null
    return <PeopleOccupancyCameraPieChart camera={formattedData[0] } showHeatmap={true}/>

}

function transformData(
    data: IPeopleOccupancy[]
) {
    return data.reduce<IPeopleOccupancyCamera[]>((acc, entry) => {
        const camName = entry.camera.name
        const zoneName = entry.zone?.name 
        let cam = acc.find(x=>x.name === camName)
        if (!cam){
            cam = {
                id: entry.camera.id,
                name: camName,
                zones: []
            }
            acc.push(cam)
        } 
        const zone = cam!.zones.find(z => z.name === zoneName)
        if(!zone){
            cam!.zones.push({
                name: zoneName,
                occupancy: entry.occupancy,
                pointCloud: [...entry.pointCloud]
            })
        } else{
            zone!.occupancy += entry.occupancy
            zone!.pointCloud = [...entry.pointCloud,...zone.pointCloud]
        }
         return acc.sort((a,b)=> a.name>b.name? 1: -1)
    }, [])
}