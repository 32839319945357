import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate } from "react-router-dom"
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "react-query"
import {
    Stack,
    Paper,
    TextField,
    Typography,
    Divider,
    FormControl,
    Button
} from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/pro-regular-svg-icons"

import ErrorBoundary from "utils/ErrorBoundary"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { IDataWithEtag } from "utils/queryHelpers"
import GoBackButton from "Components/DragonGoBackButton"
import { DragonConfirmationDialog, DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import { IScenarioReadDto, IScenarioCreateUpdateDto, IScenarioLevelCRUDto } from "Types/admin"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"
import ScenarioConfiguration from "./ScenarioConfiguration"

type IScenarioDetails = IScenarioReadDto
type IScenarioUpdate = IScenarioCreateUpdateDto
type IScenarioDetailsWithEtag = IDataWithEtag<IScenarioDetails>

const BASE_SC_URL = ""
const getScenarioDetails = async ({ queryKey }: QueryFunctionContext<[string, string]>): Promise<IScenarioDetailsWithEtag> => {
    const [, id] = queryKey
    const response = await axios.get<IScenarioDetails>(`${BASE_SC_URL}/api/scenario/v1/scenario-management/scenarios/${id}`)
    return {
        data: response.data,
        eTag: response.headers.etag
    }
}

const postScenarioDetails = async (payload: IScenarioUpdate): Promise<AxiosResponse> => {
    return await axios.post(`${BASE_SC_URL}/api/scenario/v1/scenario-management/scenarios`, payload)
}

const putScenarioDetails = async (id: string, eTag: string, payload: IScenarioUpdate): Promise<AxiosResponse> => {
    return await axios.put(`${BASE_SC_URL}/api/scenario/v1/scenario-management/scenarios/${id}`, payload, { headers: { "If-Match": eTag } })
}

const deleteScenario = async (id: string, eTag: string): Promise<AxiosResponse> => {
    return await axios.delete(`${BASE_SC_URL}/api/scenario/v1/scenario-management/scenarios/${id}`, { headers: { "If-Match": eTag } })
}

interface Props {
    groupId: string,
    scenario: IScenarioDetailsWithEtag
    newScenario: boolean
}

export const ScenarioDetails: FC<Props> = ({ groupId,scenario, newScenario }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const initialState = useMemo<IScenarioUpdate>(() => ({
        name: scenario.data.name,
        description: scenario.data.description ?? "",
        opc_Code: scenario.data.opc_Code ?? "",
        subGroup:scenario.data.subGroup??"",
        scenarioGroupId: groupId,
        levels: scenario.data.levels
    }), [scenario.data,groupId])
    const [draft, setDraft] = useState<IScenarioUpdate>(initialState)
    const [saveDialog, setSaveDialog] = useState<boolean>(false)
    const [cancelDialog, setCancelDialog] = useState<boolean>(false)
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)

    const [saveError, setSaveError] = useState<string | null>(null)

    const isChanged = useMemo<boolean>(
        () => (
            initialState.name !== draft.name ||
            initialState.description !== draft.description ||
            initialState.subGroup !== draft.subGroup ||
            initialState.opc_Code !== draft.opc_Code || 
            JSON.stringify(initialState.levels) !== JSON.stringify(draft.levels)
        ),
        [draft, initialState]
    )

    const validation = useMemo<Partial<Record<keyof IScenarioUpdate, boolean>>>(
        () => (
            {
                name: draft.name.length > 0 && draft.name.length <= 100,
                description: !draft.description || (draft.description.length > 0 && draft.description.length <= 1000),
                opc_Code:true,
                subGroup:true,
                levels: draft.levels.length > 0
            }
        ),
        [draft.name, draft.description,draft.levels]
    )

    const { mutateAsync: handleSaveChangesAsync, isLoading: putLoading } = useMutation(
        () => {
            if (newScenario) return postScenarioDetails(draft)
            return putScenarioDetails(scenario.data.id, scenario.eTag, draft)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("scenario-management")
                if (!newScenario) queryClient.invalidateQueries(["scenario", scenario.data.id])
            },
            onError: (error: AxiosError<{title:string}>) => {
                if (error.response?.data?.title === "scenario.name.duplicate")
                    setSaveError(t("scenario-details-page.name-already-taken"))
                else if (error.response?.data?.title === "scenario.code.duplicate")
                    setSaveError(t("scenario-details-page.opc-code-already-taken"))
                else
                    setSaveError(t("scenario-details-page.save-changes_failed"))
            }
        }
    )

    const { mutateAsync: handleDeleteScenario, isLoading: deleteLoading } = useMutation(
        () => deleteScenario(scenario.data.id, scenario.eTag),
        { onSuccess: () => { queryClient.invalidateQueries("scenario-management") } }
    )

    const handleChange = useCallback((key: keyof IScenarioUpdate, value: unknown) => {
        setDraft(prevState => ({ ...prevState, [key]: value }))
    }, [])

    const handleLevelChange = useCallback((value: IScenarioLevelCRUDto[]) => {
        setDraft(prevState => ({ ...prevState, levels: [...value] }))
    }, [])

    return (
        <>
            <GoBackButton />
            <DragonTitle title={scenario.data.name || t("create-scenario")} />
            <Paper sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <Stack padding={2} spacing={2} overflow="auto">
                        <FormControl fullWidth>
                            <Typography variant="h6" fontWeight="bold">
                                {t("scenario-details-page.name")}
                            </Typography>
                            <TextField
                                variant="filled"
                                InputProps={{
                                    disableUnderline: initialState.name === draft.name,
                                    endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                }}
                                value={draft.name}
                                onChange={(event) => handleChange("name", event.target.value)}
                                error={!validation.name}
                                fullWidth
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography variant="h6" fontWeight="bold">
                                {t("scenario-details-page.description")}
                            </Typography>
                            <TextField
                                variant="filled"
                                multiline
                                InputProps={{
                                    disableUnderline: initialState.description === draft.description,
                                    endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                }}
                                value={draft.description}
                                onChange={(event) => handleChange("description", event.target.value)}
                                error={!validation.description}
                                fullWidth
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography variant="h6" fontWeight="bold">
                                {t("scenario-details-page.sub-group")}
                            </Typography>
                            <TextField
                                variant="filled"
                                multiline
                                InputProps={{
                                    disableUnderline: initialState.subGroup === draft.subGroup,
                                    endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                }}
                                value={draft.subGroup}
                                onChange={(event) => handleChange("subGroup", event.target.value)}
                                error={!validation.subGroup}
                                fullWidth
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography variant="h6" fontWeight="bold">
                                {t("scenario-details-page.opc_code")}
                            </Typography>
                            <TextField
                                variant="filled"
                                multiline
                                InputProps={{
                                    disableUnderline: initialState.opc_Code === draft.opc_Code,
                                    endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                }}
                                value={draft.opc_Code}
                                onChange={(event) => handleChange("opc_Code", event.target.value)}
                                error={!validation.opc_Code}
                                fullWidth
                            />
                        </FormControl>
                        <ScenarioConfiguration levels={draft.levels} disableChanges={scenario.data.isActive} groupId={groupId}  onChange={handleLevelChange} />
                    </Stack>
                <Divider flexItem />
            </Paper>
            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                {!newScenario && <Button
                    variant="outlined"
                    color="error"
                    disabled={scenario.data.isActive}
                    sx={{ minWidth: 150 }}
                    onClick={() => setDeleteDialog(true)}
                >
                    {t("scenario-details-page.delete-scenario")}
                </Button>}
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: 150 }}
                    onClick={() => setSaveDialog(true)}
                    disabled={!isChanged || Object.values(validation).some(v => !v)}
                >
                    {newScenario ? t("create-scenario") : t("save-changes")}
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ minWidth: 150 }}
                    onClick={() => {
                        if (isChanged) setCancelDialog(true)
                        else navigate("..")
                    }}
                >
                    {t("cancel")}
                </Button>
            </Stack>
            <DragonConfirmationDialogWithPromise
                open={saveDialog}
                variant="save"
                title={t("scenario-details-page.save-changes_confirmation")}
                onClose={() => {
                    setSaveDialog(false)
                    setSaveError(null)
                }}
                onConfirm={async () => {
                    await handleSaveChangesAsync()
                }}
                isLoading={putLoading}
                errorText={saveError}
            />
            <DragonConfirmationDialog
                open={cancelDialog}
                variant="warning"
                title={t("scenario-details-page.discard-changes_confirmation")}
                onClose={() => setCancelDialog(false)}
                onConfirm={() => {
                    setDraft(initialState)
                    navigate("..")
                }}
            />
            <DragonConfirmationDialogWithPromise
                open={deleteDialog}
                variant="delete"
                title={t(
                    "scenario-details-page.delete-scenario_confirmation",
                    { scenario: scenario.data.name }
                )}
                onClose={() => setDeleteDialog(false)}
                onConfirm={async () => {
                    await handleDeleteScenario()
                    navigate("..")
                }}
                isLoading={deleteLoading}
                errorText={t("scenario-details-page.delete-scenario_failed")}
            />
        </>
    )
}

export const ScenarioDetailsContainer: FC = () => {
    const { t } = useTranslation()
    const { scenarioGroupId,scenarioId } = useParams()
    const [error, setError] = useState("")

    const {
        isLoading,
        isError,
        isSuccess,
        data
    } = useQuery<IScenarioDetailsWithEtag, AxiosError, IScenarioDetailsWithEtag, [string, string]>(
        ["scenario", scenarioId ?? "default"],
        getScenarioDetails,
        {
            onError: (error) => {
                if (error.response?.status === 404)
                    return setError(t("scenario-details-page.scenario-does-not-exist"))
                return setError(t("something-went-wrong"))
            }
        }
    )

    return (
        <DragonPageWrapper>
            {(() => {
                if (isLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{error}</h2>

                if (isSuccess)
                    return <ErrorBoundary>
                        <ScenarioDetails groupId={scenarioGroupId ?? ""} scenario={data} newScenario={!scenarioId} />
                    </ErrorBoundary>
            })()}
        </DragonPageWrapper>
    )
}

export default ScenarioDetailsContainer