import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, FormControl, FormControlLabel, Grid, Paper, Stack, Switch, TextField, Typography } from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import DragonPageWrapper from "Components/DragonPageWrapper"
import DragonTitle from "Components/DragonTitle"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { faEdit } from "@fortawesome/pro-regular-svg-icons"
import ErrorBoundary from "utils/ErrorBoundary"
import { DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import FailoverServersTable from "./FailoverServerListConfiguration"
import { IFailoverClusterDetails } from "./CreateFailoverServerDialog"



interface IFailoverSettings{
    isEnabled:boolean,
    checkPeriod: number,
    checkRetry: number,
    automaticRestore: boolean,
    getStatusTimeout: number,
    failoverClusterDetails: IFailoverClusterDetails[]
}

const getFailoverSettings = async (): Promise<IFailoverSettings> => {
    const response = await axios.get<IFailoverSettings>("/api/v1/fail/config")
    return response.data
}
const putFailoverSettings = async (payload: IFailoverSettings): Promise<AxiosResponse> => {
    return await axios.put("/api/v1/fail/config", payload)
}
export default function FailoverConfiguration() {
    const { t } = useTranslation()
    const [error, setError] = useState("")
    const [saveDialog, setSaveDialog] = useState<boolean>(false)
    const queryClient = useQueryClient()

    const {
        isLoading,
        isError,
        isSuccess,
        isFetching,
        data
    } = useQuery(
        "failover-settings",
        getFailoverSettings,
        { 
            onError: (error:AxiosError) => {
                if (error.response?.status === 404)
                    return setError(t("failover-settings.error-retrieve"))
                return setError(t("something-went-wrong"))
            },
            retry(failureCount, error) {
                if(failureCount<=10 && error.response?.status === 502) return true
                return false
            }
        }
    )

    const initialState= useMemo<IFailoverSettings>(() => ({
        isEnabled: data?.isEnabled ?? false,
        checkPeriod: data?.checkPeriod ?? 60,
        checkRetry: data?.checkRetry ?? 5,
        automaticRestore: data?.automaticRestore ?? false,
        getStatusTimeout: data?.getStatusTimeout ?? 1,
        failoverClusterDetails: data?.failoverClusterDetails ?? []
    }),[data])


    const [draft, setDraft] = useState<IFailoverSettings>(initialState)
    
    useEffect(()=>{
        setDraft(initialState)
    },[initialState])

    const isChanged = useMemo<boolean>(
        () => (
            Object.entries(draft).some(
                ([key, value]) => initialState[key as keyof IFailoverSettings] !== value
            ) || JSON.stringify(draft.failoverClusterDetails) !== JSON.stringify(initialState.failoverClusterDetails)
        ),
        [draft, initialState]
    )

    const validation = useMemo<Partial<Record<keyof IFailoverSettings, boolean>>>(
        () => (
            {
                isEnabled: true,
                checkFailover: true,
                checkPeriod: !isNaN(draft.checkPeriod) && typeof draft.checkPeriod === "number" && draft.checkPeriod > 0,
                checkRetry: !isNaN(draft.checkRetry) && typeof draft.checkRetry === "number" && draft.checkRetry >= 0,
                automaticRestore: true,
                getStatusTimeout: !isNaN(draft.getStatusTimeout) && typeof draft.getStatusTimeout === "number" && draft.getStatusTimeout > 0,
                failoverClusterDetails: Array.isArray(draft.failoverClusterDetails)
            }
        ),
        [draft]
    )

    const { mutateAsync: handleSaveChangesAsync, isLoading: isLoadingSave } = useMutation(() => putFailoverSettings(draft),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("failover-settings")
            }
        }
    )    
    const handleChangeNumber = useCallback<
        (key: string) => (event: ChangeEvent<HTMLInputElement>) => void
            >((key) => (event) => {
                const num = parseInt(event.target.value)
                const updateDraft = (updated :{ [key: string]: number }) => setDraft({ ...draft, ...updated })
                updateDraft(({ [key]: num }))
            }, [draft])
    const handleChangeBoolean = useCallback<
    (key: string) => (event: ChangeEvent<HTMLInputElement>,checked:boolean) => void
        >((key) => (event,checked) => {
            const updateDraft = (updated :{ [key: string]: boolean }) => setDraft({ ...draft, ...updated })
            updateDraft(({ [key]: checked }))
        }, [draft])

    const handleServersChange = useCallback((failoverClusterDetails: IFailoverClusterDetails[])=>{
        setDraft({...draft, failoverClusterDetails})
    },[draft])
    return (
        <DragonPageWrapper>
            <DragonTitle title={t("failover-settings.title")} />
            {(() => {
                if (isLoading || isFetching || isLoadingSave)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{error}</h2>

                if (isSuccess)
                    return (
                        <ErrorBoundary>
                            <Grid container component={Paper} overflow="auto" flex={1}>
                                <Grid item xs={12} md={4} p={2}>
                            <Stack spacing={2}>
                                <FormControl>
                                <FormControlLabel 
                                    control={
                                        <Switch
                                        checked={draft.isEnabled}
                                        onChange={handleChangeBoolean("isEnabled")}
                                        
                                        />
                                    }
                                    label={t("failover-settings.is-enabled")}
                                />
                                </FormControl>
{/* <FormControl>
<FormControlLabel 
                                    control={
                                        <Switch
                                        checked={draft.automaticRestore}
                                        onChange={handleChangeBoolean("automaticRestore")}
                                        
                                        />
                                    }
                                    label={t("failover-settings.automatic-restore")}
                                />
</FormControl> */}
                                
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("failover-settings.server-check-period")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="number"
                                            InputProps={{
                                                disableUnderline: initialState.checkPeriod === draft.checkPeriod,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                inputMode: "numeric",
                                                type: "number"
                                            }}
                                            value={draft.checkPeriod ?? 60}
                                            onChange={handleChangeNumber("checkPeriod")}
                                            error={!validation.checkPeriod}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("failover-settings.server-check-retry-count")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="number"
                                            InputProps={{
                                                disableUnderline: initialState.checkRetry === draft.checkRetry,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                inputMode: "numeric",
                                                type: "number"
                                            }}
                                            value={draft.checkRetry ?? 5}
                                            onChange={handleChangeNumber("checkRetry")}
                                            error={!validation.checkRetry}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("failover-settings.server-timeout")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="number"
                                            InputProps={{
                                                disableUnderline: initialState.getStatusTimeout === draft.getStatusTimeout,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                inputMode: "numeric",
                                                type: "number"
                                            }}
                                            value={draft.getStatusTimeout ?? 4}
                                            onChange={handleChangeNumber("getStatusTimeout")}
                                            error={!validation.getStatusTimeout}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                            </Stack>
                            
                            </Grid>
                            <Grid item xs={12} md={8} p={2}>
                                <FailoverServersTable data={draft.failoverClusterDetails} onChange={handleServersChange} />
                            </Grid>
                            </Grid>
                            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ minWidth: 150 }}
                                    onClick={() => setSaveDialog(true)}
                                    disabled={!isChanged || Object.values(validation).some(v => !v)}
                                >
                                    {t("save-changes")}
                                </Button>
                            </Stack>
                           <DragonConfirmationDialogWithPromise
                                open={saveDialog}
                                variant="save"
                                title={t("failover-settings.save-changes_confirmation")}
                                onClose={() => setSaveDialog(false)}
                                onConfirm={async () => {
                                    await handleSaveChangesAsync()
                                }}
                                isLoading={isLoading}
                                errorText={t("failover-settings.save-changes_failed")}
                            />
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>
        
    )
}