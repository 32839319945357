import { forwardRef } from "react"
import { List, ListItem, ListItemButton, ListItemText, Stack, Typography } from "@mui/material"

import SearchField from "Components/DragonSearchFieldWithDebouncer"
import { DelayedLinearProgress } from "utils/DelayedProgress"

interface ISelectItem {
    id: string
    name: string
}
interface IDragonListWithSearchField {
    title: string
    list: ISelectItem[]
    searchPhrase: string
    isSelected: (item: ISelectItem) => boolean
    onSelect: (item: ISelectItem) => void
    onSearch: (searchPhrase: string) => void
    loading?: boolean
    onScroll?: (event: React.UIEvent<HTMLElement, UIEvent>) => void
}
export const DragonListWithSearchField = forwardRef<HTMLUListElement, IDragonListWithSearchField>(
    ({ title, list, searchPhrase, isSelected, onSelect, onSearch, loading, onScroll }, ref) => {
        return (
            <Stack flex={1} spacing={1}>
                <Typography variant="h6" fontWeight="bold" align="center">
                    {title}
                </Typography>
                <SearchField value={searchPhrase} onValueChange={onSearch} sx={{ borderEndEndRadius: "0" }} />
                {loading ? (
                    <DelayedLinearProgress delay={250} />
                ) : (
                    <List
                        ref={ref}
                        onScroll={onScroll}
                        sx={{
                            height: 1,
                            width: 1,
                            overflowY: "scroll",
                            overflowX: "hidden",
                            backgroundColor: "background.default"
                        }}
                        disablePadding
                    >
                        {list.map((item) => (
                            <ListItem
                                key={item.id}
                                sx={{ backgroundColor: isSelected(item) ? "primary.main" : undefined }}
                                disablePadding
                            >
                                <ListItemButton onClick={() => onSelect(item)}>
                                    <ListItemText>
                                        {item.name}
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Stack>
        )
    }
)

export default DragonListWithSearchField