import { useQueries } from "react-query"

import {
    Box,
    Stack
} from "@mui/material"
import { ITrafficFilterSelection } from "../types/IDataAnalyticsFilterSelection"

import {getVehicleCount } from "./getCountingData"
import DragonChartCard from "../DragonChartCard"
import { useTranslation } from "react-i18next"
import { useMemo, useState } from "react"
import { defaultSelection } from "./defaultSelection"
import CountingLineChart from "./CountingLineChart"
import CountingFilter from "./Filter"
import { convertDatePartsToDateString, generateTimestampsForTimeframeAndResolution } from "utils/generateTimestampsForTimeframeAndResolution"
import { IChartZoomArea } from "../DetailedLineChart"
import DragonChartCardActions, { IDragonChartCardActions } from "../DragonChartCardActions"
import CountingBarChart from "./CountingBarChart"
import CountingCameraList from "./CountingCameraList"

const SYNC_ID = "Traffic_Data_Sync"
export default function TrafficCountingWrapper() {
    const [selection, setSelection] = useState<ITrafficFilterSelection>(defaultSelection)
    function onSubmit(selection: ITrafficFilterSelection) {
        setSelection(selection)
    }

    return <>
        <Box mx={3} mt={3}>
            <CountingFilter onSubmit={onSubmit} />
        </Box>
        <TrafficCounting filter={selection} />
    </>
}


export function TrafficCounting({ filter }: { filter: ITrafficFilterSelection }) {
    const { t } = useTranslation()

    const [
        { data: vehicleCountData, status: vehicleCountStatus }
    ] = useQueries([
        { queryKey: ["vehicleCount", filter], queryFn: () => getVehicleCount(filter) }
    ])

    const timestamps = useMemo(
        () => generateTimestampsForTimeframeAndResolution(filter.timeFrame, filter.resolution),
        [filter.timeFrame, filter.resolution]
    )
    const [zoom,setZoom] = useState<IChartZoomArea>({leftLimit:convertDatePartsToDateString(timestamps[0]),rightLimit:convertDatePartsToDateString(timestamps[timestamps.length-1])})
    const resetZoom= () =>{
        if(timestamps){
            setZoom({leftLimit:convertDatePartsToDateString(timestamps[0]),rightLimit:convertDatePartsToDateString(timestamps[timestamps.length-1])})
        }
    }
    const [vehicleCountRef,setVehicleCountRef] = useState<IDragonChartCardActions|null>(null)

    return (
        <Box sx={{ overflow: "auto", p: 3 }}>
            <Stack gap="20px">
                <DragonChartCard
                    title={t("data-analytics-page.vehicle-total-count")}
                    data={vehicleCountData}
                    status={vehicleCountStatus}
                >
                    <CountingBarChart
                        data={vehicleCountData ?? []}
                    />
                </DragonChartCard>
                <DragonChartCard
                    title={t("data-analytics-page.vehicle-count")}
                    data={vehicleCountData}
                    status={vehicleCountStatus}
                    actions={<DragonChartCardActions reference={vehicleCountRef} name={t("data-analytics-page.vehicle-count")} />}
                >
                    <CountingLineChart
                        data={vehicleCountData ?? []}
                        resolution={filter.resolution}
                        zoom={zoom}
                        syncID={SYNC_ID}
                        onZoomChange={setZoom}
                        onZoomReset={resetZoom}
                        timestamps={timestamps}
                        insertZeroIfNoDataAtTimeStamp
                        cardActionsRef={setVehicleCountRef}
                    />
                </DragonChartCard>
                <CountingCameraList data={vehicleCountData ?? []} />

            </Stack>
        </Box>
    )
}