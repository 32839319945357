import axios from "axios"
import getMostSpecificIdsFromFilter from "Pages/EventJournal/utils/getMostSpecificIdsFromFilter"
import { IPosition } from "Types/IPosition"
import { IPeopleOccupancyFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import convertTimeFrameToTimespanFilter from "../utils/convertTimeFrameToTimespanFilter"


const filterFromSelection = (selection: IPeopleOccupancyFilterSelection) => {
    return {
        ...getMostSpecificIdsFromFilter(selection),
        ...convertTimeFrameToTimespanFilter(selection.timeFrame),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timespanGroupBy: selection.resolution
    }

}

interface IPeopleOccupancyResponse {
    cameraGroups: { id: string, name: string }[]
    camera: {
        id: string
        name: string
        position: IPosition | null
    }
    zone: {
        id: string
        name: string
    },
    timespanGroup: string,
    occupancy: number,
    pointCloud: {
        x: number,
        y: number
    }[]
}

export type IPeopleOccupancyData = IPeopleOccupancyResponse

export async function getPeopleOccupancy(selection: IPeopleOccupancyFilterSelection): Promise<IPeopleOccupancyData[]> {
    const filter = filterFromSelection(selection)
    if(Object.keys(filter).some(k=> k === "cameraIds" || k === "camerGroupIds" || k === "zoneIds")){
    const response = await axios.put<IPeopleOccupancyData[]>("/api/event/v1/data/people-occupancy", filter)
    return response.data
    } else return []
}