import { faLinkHorizontal, faLinkHorizontalSlash } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Card, CardHeader, Stack, Typography, useTheme } from "@mui/material"
import axios from "axios"
import { useQuery, useQueryClient } from "react-query"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import DragonTitle from "Components/DragonTitle"
import { yellow, red } from "App/colors"
import { assertUnreachable } from "utils/assertUnrechable"

interface IServer {
    id: string
    name: string
    serverIP: string | null
    status: "Unknown" | "Ok" | "Disconnected" | "Failure"
}


async function getServerStatus() {
    const response = await axios.get<IServer[]>("/api/event/v1/servers/status")
    return response.data
}

export default function CameraStatus() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const { data: servers, isLoading, isError, isSuccess } = useQuery("server-status", getServerStatus)

    useEffect(() => {
        const interval = setInterval(() => {
            queryClient.refetchQueries("server-status")
        }, 5 * 60000)
        return () => clearInterval(interval)
    }, [queryClient])

    return (
        <Box
        display={"flex"}
        flexDirection="column"
        overflow={"hidden"}
        height={1}
        width={1}
        flex={{xs: 1, md: 1}}
                >
            <DragonTitle
                title={t("device-status-page.server-status")}
            /> 

            <Stack spacing={3} flex={1} overflow={"auto"}>
                {(() => {
                    if (isLoading)
                        return <DelayedCircularProgress delay={250} />

                    if (isError)
                        return <Typography>{t("device-status-page.failed-to-load-server")}</Typography>

                    if (isSuccess && servers.length === 0)
                        return <Typography>{t("device-status-page.no-servers")}</Typography>

                    if (isSuccess && servers.length > 0)
                        return [...servers]
                            .sort((_, b) => {
                                return b.status !== "Ok" ? 1 : -1
                            })
                            .map((server) => <ServerCard key={server.id} server={server} />)
                })()}
            </Stack>
        </Box>
    )
}

function ServerCard({ server }: { server: IServer }) {
    const { palette } = useTheme()
    const { t } = useTranslation()
    return (
        <Card elevation={0} sx={{flexShrink:0, bgcolor: palette.background.default }}>
            <CardHeader
                avatar={(() => {
                    switch (server.status) {
                        case "Ok":
                            return <FontAwesomeIcon icon={faLinkHorizontal} style={{ fontSize: "2em", color: palette.success.main }} />
                        case "Disconnected":
                            return <FontAwesomeIcon icon={faLinkHorizontalSlash} style={{ fontSize: "2em", color: yellow[500] }} />
                        case "Failure":
                            return <FontAwesomeIcon icon={faLinkHorizontalSlash} style={{ fontSize: "2em", color: red[500] }} />
                        case "Unknown":
                            return <FontAwesomeIcon icon={faLinkHorizontal} style={{ fontSize: "2em" }} />
                        default:
                            assertUnreachable(server.status)
                    }
                })()}
                title={server.name}
                subheader={(() => {
                    switch (server.status) {
                        case "Ok":
                            return t("device-status-page.server-status-ok")
                        case "Disconnected":
                            return t("device-status-page.server-status-disconnected")
                        case "Failure":
                            return t("device-status-page.server-status-failure")
                        case "Unknown":
                            return t("device-status-page.server-status-unknown")
                        default:
                            assertUnreachable(server.status)
                    }
                })()}
                sx={{ p: "8px 15px", height: "53px" }}
            />
        </Card>
    )
}