import { FC, useMemo, useState } from "react"
import axios from "axios"
import { useMutation } from "react-query"
import { useTranslation } from "react-i18next"
import { Button } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRotate } from "@fortawesome/pro-regular-svg-icons"

import { DragonTableWithSearchField, HeaderCell, BodyRow } from "Components/DragonTable"
import { IWaveSettings, WaveCamera } from "./WaveConfiguration"

const syncCameras = async (payload: IWaveSettings): Promise<WaveCamera[]> => {
    const response = await axios.post<WaveCamera[]>("/api/v1/wave/sync", payload)
    return response.data
}

interface IWaveCameraConfig {
    waveConfig: IWaveSettings
    onCameraSync: (cameras:WaveCamera[]) => void
}

export const WaveCamerasConfig: FC<IWaveCameraConfig> = ({waveConfig,onCameraSync}) => {
    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useState<string>("")

    const syncEnabled = useMemo<boolean>(
        () => (
                waveConfig.host.length >0 && waveConfig.host.length <300 &&
                !isNaN(waveConfig.port) && typeof waveConfig.port === "number" &&
                !isNaN(waveConfig.waveVersion) && typeof waveConfig.waveVersion === "number" &&
                (waveConfig.waveVersion>4 ? waveConfig.username.length >0 && waveConfig.username.length <300 : true) &&
                (waveConfig.waveVersion>4 ? waveConfig.password.length >0 && waveConfig.password.length <300 : true)
        ),
        [waveConfig]
    )

    const { mutateAsync: syncCameraAsync, isLoading } = useMutation(() => syncCameras(waveConfig),
        {
            onSuccess: (data) => {

                onCameraSync(data ?? [])
            },
            onError: () =>{
                onCameraSync([])
            }
        }
    )   

    const tableColumns = useMemo<HeaderCell[]>(() => [
        { value: t("wave-settings.cameras.name") },
        { value: t("wave-settings.cameras.ip") },
        { value: t("wave-settings.cameras.url") }
    ], [t])

    const tableData = useMemo<BodyRow[]>(() => {
        return waveConfig?.cameras?.map(({ name,ip,url }) => (
            {
                row: [
                    { value: name },
                    { value: ip },
                    { value: url}
                ],
                hover: true
            }
        )) ?? []

    }, [waveConfig.cameras])

    return (
            <DragonTableWithSearchField
                columns={tableColumns}
                data={tableData}
                hasNextPage={false}
                fetchNextPage={()=>null}
                isFetching={isLoading}
                noDataText={t("wave-settings.cameras.no-camera-found")}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                searchPlaceholder={t("wave-settings.cameras.search-camera")}
                toolbarAction={
                    <Button
                        variant="outlined"
                        startIcon={<FontAwesomeIcon icon={faRotate} name={faRotate.iconName} />}
                        onClick={() => syncCameraAsync()}
                        disabled={!syncEnabled}
                    >
                        {t("wave-settings.cameras.sync-cameras")}
                    </Button>
                }
            />
    )
}

export default WaveCamerasConfig
