import axios from "axios"
import getFilteredStateIds from "Pages/EventJournal/utils/getFilteredStateIds"
import getMostSpecificIdsFromFilter from "Pages/EventJournal/utils/getMostSpecificIdsFromFilter"
import { IFilteredStateId } from "Types/event/IFilteredStateId"
import { ISimulatedStateId } from "Types/event/ISimulatedStateId"
import { IValidStateId } from "Types/event/IValidStateId"
import { IPosition } from "Types/IPosition"
import { IEventFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import convertTimeFrameToTimespanFilter from "../utils/convertTimeFrameToTimespanFilter"

const filterFromSelection = (selection: IEventFilterSelection) => ({
    ...getMostSpecificIdsFromFilter(selection),
    ...convertTimeFrameToTimespanFilter(selection.timeFrame),
    ...getFilteredStateIds(selection),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timespanGroupBy: selection.resolution,
    eventTypeIds: selection.eventTypeIds
})

export interface IEventCountResponse {
    cameraGroups: { id: string, name: string }[]
    camera: {
        id: string
        name: string
        position: IPosition | null
    }
    zone: {
        id: string
        name: string
    } | null
    timespanGroup: string
    count: number
    eventTypeId: string
    eventTypeGroup: {
        id: string
        name: string
        code: string
    }
    validStateId: IValidStateId
    simulatedStateId: ISimulatedStateId
    filteredStateId: IFilteredStateId
}

export type IEventData  = IEventCountResponse

export async function getEventsCount(selection: IEventFilterSelection): Promise<IEventData[]> {
    const filter = filterFromSelection(selection)
    if(Object.keys(filter).some(k=> k === "cameraIds" || k === "camerGroupIds" || k === "zoneIds")){
        const response = await axios.put<IEventCountResponse[]>("/api/event/v1/data/event-count", filter)
        return response.data
    }else return []

}
