import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { ITimeFrame } from "Components/Filter/Filters/TimeFrameSelector/ITimeFrame"
import moment from "moment"
import { useEffect, useState } from "react"

export interface ITimeSelector{
    timeFrame: ITimeFrame
    onChange:(start:Date,end:Date)=>void
}

const TIME_SLOT = 240

export default function TimeSelector({timeFrame,onChange}:ITimeSelector){
    const [timeSet, setTimeSet] = useState<number[]>([0, 1439])
    const [currentFrame,setCurrentFrame] = useState(1439)
    const [day,setDay] = useState(moment())

    const calculateTimeButtons = (currFrame:number)=>{
        const setNumber = currFrame / TIME_SLOT
        const timeButtons = []
        if(setNumber >0){
            for (let i=1; i< setNumber; i++){
                timeButtons.push(TIME_SLOT*i)
            }
        }
        if(!timeButtons.includes(currFrame))timeButtons.push(currFrame)
        setTimeSet(timeButtons)
        setCurrentFrame(currFrame)
    }

    useEffect(()=>{
        if(timeFrame.type === "today") {
            setDay(moment().clone().startOf("day"))
            calculateTimeButtons(moment().diff(moment().clone().startOf("day"),"minutes"))
            
        }
        else if(timeFrame.type === "yesterday") {
            setDay(moment().clone().subtract(1,"days").startOf("day"))
            calculateTimeButtons(1439)
        }
        else if(timeFrame.type === "custom"){
            setDay(moment(timeFrame.from).clone().startOf("day"))
            calculateTimeButtons(1439)
        }
    },[timeFrame])

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        selectedTime: number
      ) => {
        setCurrentFrame(selectedTime)
        onChange(day.clone().toDate(),day.clone().add(selectedTime,"minute").toDate())
      }
    return<Stack gap={3} direction={"row"} >
        <ToggleButtonGroup size={"large"}  value={currentFrame} exclusive onChange={handleChange} fullWidth>
            {timeSet.map((time)=>(<ToggleButton value={time} key={`timeframe-${time}`}>{moment.utc(time*60*1000).format("HH:mm")}</ToggleButton>))}
        </ToggleButtonGroup>
    </Stack>
}