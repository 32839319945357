import { Typography } from "@mui/material"

export default function FilterControlLabel({ text }: { text: string }) {
    return <Typography
        sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            mb: 1,
            fontSize: "12px"
        }}
    >
        {text}
    </Typography>
}