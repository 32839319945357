import React from "react"
import {createRoot} from "react-dom/client"
import "./index.css"
import App from "App"
import reportWebVitals from "./reportWebVitals"

import "./fonts/BasisGrotesque-Regular.ttf"
import "./fonts/BasisGrotesque-Bold.ttf"

import "maplibre-gl/dist/maplibre-gl.css"

import "./i18n"

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode> 
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
