import { ITimeFrame } from "Components/Filter/Filters/TimeFrameSelector/ITimeFrame"
import IWidgetSelection from "../types/IWidgetSelection"
import IWidgetDetails from "../types/IWidgetDetails"

export function selectionFromFilter(data: IWidgetDetails) {

    const timeFrame = (from: string, to: string): ITimeFrame => {
        if (from === "now/d" && to === "now/d")
            return { type: "today" }
        if (from === "now-1d/d" && to === "now-1d/d")
            return { type: "yesterday" }
        if (from === "now/w" && to === "now/w")
            return { type: "this-week" }
        throw new Error("Invalid timeframe type")
    }

    const selection: IWidgetSelection = {
        name: data.name,
        dataSource: data.sourceId,
        widgetType: data.chartTypeId,
        cameraGroupIds: data.cameraGroups.map(x => x.id),
        cameraIds: data.cameras.map(x => x.id),
        zoneIds: data.zones.map(x => x.id),
        eventTypeIds: data.eventTypeIds,
        includeFiltered: data.filteredStateIds.includes("Filtered"),
        includeMarkedAsTrue: data.validStateIds.includes("Valid"),
        includeMarkedAsFalse: data.validStateIds.includes("NotValid"),
        includeUnmarked: data.validStateIds.includes("Undefined"),
        includeSimulated: data.simulatedStateIds.includes("Simulated"),
        vehicleClassIds: data.vehicleClassIds,
        peopleClassIds: data.peopleClassIds,
        resolution: data.timespanGroupBy,
        timeFrame: timeFrame(data.timespanFilterTypeRelativeFromGe, data.timespanFilterTypeRelativeToLe),
        limit: data.limit,
        refreshInterval: data.refreshIntervalSeconds,
        includeComing: data.peopleCountDirectionIds.includes("PeopleCountDirectionIn"),
        includeGoing: data.peopleCountDirectionIds.includes("PeopleCountDirectionOut")
    }

    return selection
}