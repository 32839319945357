import { forwardRef } from "react"
import { List, ListItem, ListItemButton, ListItemText, Stack, Typography } from "@mui/material"

import SearchField from "Components/DragonSearchFieldWithDebouncer"
import { DelayedLinearProgress } from "utils/DelayedProgress"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { DropResult } from "react-beautiful-dnd"

interface ISelectItem {
    id: string
    name: string
}
interface IDragonListWithSearchField {
    title: string
    list: ISelectItem[]
    searchPhrase: string
    isSelected: (item: ISelectItem) => boolean
    onSelect: (item: ISelectItem) => void
    onSearch: (searchPhrase: string) => void
    loading?: boolean
    onScroll?: (event: React.UIEvent<HTMLElement, UIEvent>) => void
    onReorder?: (list:ISelectItem[]) => void
}

export const DragonOrderableListWithSearchField = forwardRef<HTMLUListElement, IDragonListWithSearchField>(
    ({ title, list, searchPhrase, isSelected, onSelect, onSearch, onReorder, loading, onScroll }, ref) => {

        const reorder = (list:ISelectItem[], startIndex:number, endIndex:number) => {
            const result = Array.from(list)
            const [removed] = result.splice(startIndex, 1)
            result.splice(endIndex, 0, removed)
            return result
        }

          
        const onDragEnd = (result:DropResult)=>{
            // dropped outside the list
            if (!result.destination) return
            if(onReorder){
                const items = reorder(
                    list,
                    result.source.index,
                    result.destination.index
                )
                onReorder(items)
            }
        }
        return (
            <Stack flex={1} spacing={1}>
                <Typography variant="h6" fontWeight="bold" align="center">
                    {title}
                </Typography>
                <SearchField value={searchPhrase} onValueChange={onSearch} sx={{ borderEndEndRadius: "0" }} />
                {loading ? (
                    <DelayedLinearProgress delay={250} />
                ) : (
                    <DragDropContext onDragEnd={onDragEnd}>
                    <List
                        ref={ref}
                        onScroll={onScroll}
                        sx={{
                            height: 1,
                            width: 1,
                            overflowY: "scroll",
                            overflowX: "hidden",
                            backgroundColor: "background.default"
                        }}
                        disablePadding
                    > 
                        <Droppable droppableId="droppable">
                            {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                
                            >
                        {list.map((item,index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                            <ListItem
                                key={item.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{ backgroundColor: isSelected(item) ? "primary.main" : undefined }}
                                disablePadding
                            >
                                <ListItemButton onClick={() => onSelect(item)}>
                                    <ListItemText>
                                        {list.indexOf(item)} - {item.name}
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>

                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                            </div>
                        )}
                        </Droppable>
                    </List>
                    </DragDropContext>
                )}
            </Stack>
        )
    }
)

export default DragonOrderableListWithSearchField