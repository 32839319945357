import { Box, Stack, Typography, useTheme } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { colorsList } from "App/colors"
import { Cell, Customized, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, PieProps } from "recharts"
import useImage from "hooks/useImage"
import Konva from "konva"
import { Image as kImage } from "konva/lib/shapes/Image"
import { Image, Layer, Stage } from "react-konva"
import Heatmap, { IHeatmapDatum } from "Components/Heatmap/Heatmap"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { useTranslation } from "react-i18next"

const DISABLED_OPACITY = 0.3

interface IPeopleOccupancyZoneData {
    name: string
    occupancy:number
    pointCloud:{
        x: number,
        y: number
    }[]
}
export interface IPeopleOccupancyCamera{
    id:string
    name: string
    zones: IPeopleOccupancyZoneData[]
}
interface IPeopleOccupancyCameraPieChart {
    camera:IPeopleOccupancyCamera
    showHeatmap?: boolean
}


const CustomPieChartLabel = (props:PieProps) => {
  const {
    cx,
    cy,
    fill,
    data
  } = props
  const totValue = data?.reduce((prev:number,curr:{value:number})=>{
    return prev + curr.value
  },0) ?? 0
  return<g>
      <text x={cx} y={cy} dy={-25} fontWeight={700} fontSize={20} textAnchor="middle" fill={fill}>
        {totValue.toLocaleString()}
      </text>
    </g>
}

export default function PeopleOccupancyCameraPieChart({
    camera,
    showHeatmap = true
}: IPeopleOccupancyCameraPieChart) {
    const theme = useTheme()
    const [hoveredEventType, setHoveredEventType] = useState<string | null>(null)
    const [imageLoaded, setImageLoaded] = useState(false)
    const [img,imgState] = useImage(camera.id? `/api/event/v1/cameras/${camera.id}/snapshot`: undefined )
    const imageRef = useRef<kImage>(null)
    const [canvasW, setCanvasW] = useState(400)
    const [canvasH, setCanvasH] = useState(275)
    const [scaleFactor,setScaleFactor] = useState(1)
    const [hiddenDataPoints, setHiddenDataPoints] = useState(
        camera.zones.reduce<{ [dataKey: string]: boolean }>((acc, entry) => {
            acc[entry.name] = false
            return acc
        }, {})
    )

    useEffect(()=>{
        if(imgState === "loaded"){
            const ratio = 303 / (img?.height ?? 303)
            setCanvasW(img!.width * ratio)
            setCanvasH(img!.height * ratio)
            setScaleFactor(ratio)
            setImageLoaded(true)
        }

    },[img,imgState])

    useEffect(() => {
        if (img) {
            imageRef?.current?.cache()
            imageRef?.current?.filters([Konva.Filters.Brighten])
            imageRef?.current?.brightness(-0.1)
        }
    }, [img])
    const outerRadius = 85
    const innerRadius = outerRadius * .55
    return (

        <Stack direction={{s:"column", md:"row"}} gap={5}>
            <ResponsiveContainer minHeight={303} minWidth={"30%"}>
                <PieChart>
                    {camera.zones.length === 0 &&
                        <Pie
                            data={[{ value: 1 }]}
                            dataKey="value"
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            fill="rgba(128, 128, 128, 0.5)"
                            stroke="none"
                            legendType="none"
                        />
                    }
                    <Pie
                        data={camera.zones.map(({ name, occupancy }) => ({
                            name,
                            value: hiddenDataPoints[name] ? 0 : occupancy
                        }))}
                        dataKey="value"
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        stroke="none"
                        onMouseEnter={data => setHoveredEventType(data.name)}
                        onMouseLeave={() => setHoveredEventType(null)}
                    >
                        {camera.zones.map((entry, index) =>
                            <Cell
                                key={index}
                                fill={colorsList[index % colorsList.length]}
                                opacity={(hoveredEventType && entry.name !== hoveredEventType)
                                    ? DISABLED_OPACITY
                                    : 1.0
                                }
                            />
                        )}
                        
                    </Pie>
                    <Customized component={<CustomPieChartLabel fill={theme.palette.text.primary} dataKey={"value"} data={camera.zones.map(({ name, occupancy }) => ({
                            name,
                            value: hiddenDataPoints[name] ? 0 : occupancy
                        }))}/>}/>
                    <Legend
                        wrapperStyle={{
                            height: "5em",
                            overflow: "auto"
                        }}
                        formatter={(value: string, entry) => {

                            return <span
                                key={entry.id}
                                style={{
                                    color: theme.palette.text.primary,
                                    opacity: (hoveredEventType !== value && hiddenDataPoints[value]) ? DISABLED_OPACITY : 1,
                                    textDecoration: hiddenDataPoints[value] ? "line-through" : "none"
                                }}
                            >
                                {value}: {camera.zones.find(z=>z.name === value)!.occupancy}
                            </span>
                        }}
                        onMouseEnter={data => setHoveredEventType(data.value)}
                        onMouseLeave={() => setHoveredEventType(null)}
                        onClick={data => setHiddenDataPoints({
                            ...hiddenDataPoints,
                            [data.value]: !hiddenDataPoints[data.value]
                        })}
                    />
                    {camera.zones.length > 0 && <Tooltip
                        formatter={(value: number, name: string) =>
                            ([value, name])
                        }
                    />}
                </PieChart>
            </ResponsiveContainer>

            {showHeatmap &&<Box p={0} minHeight={303} >
                {imgState === "loading" && <Loading />}
                {imgState === "failed" && <Error />}
                {imageLoaded && <Box width={canvasW} height={canvasH} position={"relative"}>
                    <Stage listening={false} width={canvasW} height={canvasH} style={{maxWidth:"100%", overflow:"hidden", position:"absolute"}} >
                        <Layer listening={false} draggable={false} scale={{x:scaleFactor,y:scaleFactor}}>
                            <Image ref={imageRef} image={img} listening={false} ></Image>
                        </Layer>
                    </Stage>
                    <Heatmap width={canvasW} height={canvasH} blur={10} radius={12} maxOccurances={100} style={{position:"absolute"}} data={dataToHeatDots(camera.zones.filter(z => !hiddenDataPoints[z.name]))} />
                </Box>}
            </Box>}
        </Stack>)
}

function dataToHeatDots(data: IPeopleOccupancyZoneData[]): IHeatmapDatum[]{
    return data.flatMap((t) => {
        return t.pointCloud.flatMap( (z) =>{
           return{ 
            x: z.x,
            y: z.y,
            weight: 1
        }})
    })
}
function Loading() {
    return <Box sx={{ display: "flex", justifyContent: "center" }}>
        <DelayedCircularProgress delay={250} />
    </Box>
}

function Error() {
    const { t } = useTranslation()
    return <Typography variant="h5" color="text.secondary" textAlign="center" lineHeight={3}>
        {t("data-analytics-page.vehicle-trace-failed-image")}
    </Typography>
}