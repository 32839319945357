import { faBell } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Badge, IconButton, ListItem, ListItemButton, ListItemText, Popover, Typography } from "@mui/material"
import axios from "axios"
import MentionsWidget from "Components/Widgets/MentionsWidget"
import { IEventComment } from "Components/Widgets/types"
import { useGlobalEvents } from "Contexts/GlobalEventsContext"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

async function getCommentsWhereUserWasMentioned(): Promise<IEventComment[]> {
    const response = await axios.get("/api/event/v1/event-comments", {
        params: {
            limit: 20
        }
    })
    return response.data
}

const LAST_MARKED_LOCAL_STORAGE_KEY = "notification-center.last-marked-all-as-seen"
const LAST_OPENED_LOCAL_STORAGE_KEY = "notification-center.last-opened"

export default function NotificationCenter() {
    const { t } = useTranslation()
    const anchorRef = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)
    const [mentions, setMentions] = useState<(IEventComment & { new: boolean })[]>([])
    const { subscribeToEvents } = useGlobalEvents()

    const lastMarkedAllAsSeen = ((): Date | null => {
        const lastMarkedAsSeen = localStorage.getItem(LAST_MARKED_LOCAL_STORAGE_KEY)
        if (lastMarkedAsSeen !== null)
            return new Date(lastMarkedAsSeen)

        return null
    })()

    const lastOpenedNotificationCenter = ((): Date | null => {
        const lastOpened = localStorage.getItem(LAST_OPENED_LOCAL_STORAGE_KEY)
        if (lastOpened !== null)
            return new Date(lastOpened)

        return null
    })()

    const markAllAsRead = () => {
        localStorage.setItem(LAST_MARKED_LOCAL_STORAGE_KEY, (new Date()).toISOString())
        setMentions([])
    }

    const setOpenedDate = (date: Date) => {
        localStorage.setItem(LAST_OPENED_LOCAL_STORAGE_KEY, date.toISOString())
    }

    useQuery(
        "user-mentions",
        getCommentsWhereUserWasMentioned,
        {
            onSuccess: (mentions) => {
                setMentions(mentions
                    .filter(mention => !lastMarkedAllAsSeen || new Date(mention.mentionedAt) > lastMarkedAllAsSeen)
                    .map(mention => ({
                        ...mention,
                        new: lastOpenedNotificationCenter
                            ? new Date(mention.mentionedAt) > lastOpenedNotificationCenter
                            : true
                    }))
                )
            },
            onError: () => {
                // Override default onError handler
            }
        }
    )

    const onNewMention = (newMention: IEventComment) => {
        setMentions(previousMentions => {
            const alreadyExists = previousMentions.some(mention => mention.id === newMention.id)
            if (alreadyExists)
                return previousMentions

            return ([{ ...newMention, new: true }, ...previousMentions])
        })
    }

    useEffect(() => {
        subscribeToEvents("EventComment", onNewMention)
    }, [subscribeToEvents])

    const icon = <FontAwesomeIcon icon={faBell} style={{ fontSize: ".75em" }} />

    const anyNewMention = mentions.some(mention => mention.new)

    return (
        <>
            <IconButton
                size="large"
                onClick={() => setOpen(true)}
                ref={anchorRef}
            >
                {anyNewMention
                    ? <Badge variant="dot" color="success">
                        {icon}
                    </Badge>
                    : icon
                }
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorRef.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                TransitionProps={{
                    onExiting: () => {
                        setOpenedDate(new Date())

                        // Marks all notifications as seen when closing notification center
                        // So notifications added during the notification center was open
                        // are not marked as new
                        setMentions(mentions => mentions
                            .map(mention => ({ ...mention, new: false }))
                        )
                    },
                    onEntering: () => {
                        setOpenedDate(new Date())

                        // Marks all notifications as seen when opening notification center
                        setMentions(mentions => mentions
                            .map(mention => ({ ...mention, new: false }))
                        )
                    }
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                PaperProps={{
                    sx: {
                        width: mentions.length === 0
                            ? 250
                            : 500,
                        overflow: "auto"
                    }
                }}
            >
                {mentions.length === 0
                    ? <Typography p={2}>{t("notification-center.no-unread-notifications")}</Typography>
                    : <>
                        <MentionsWidget
                            data={mentions}
                            onClick={() => setOpen(false)}
                        />
                        <ListItem
                            disablePadding
                        >
                            <ListItemButton
                                onClick={markAllAsRead}
                                disabled={mentions.length === 0}
                            >
                                <ListItemText>{t("notification-center.mark-all-as-seen")}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </>
                }
            </Popover>
        </>
    )
}