import { IFilteredStateId } from "Types/event/IFilteredStateId"
import { ISimulatedStateId } from "Types/event/ISimulatedStateId"
import { IValidStateId } from "Types/event/IValidStateId"

interface IGetFilteredStateIds {
    includeSimulated: boolean
    includeFiltered: boolean
    includeMarkedAsTrue: boolean
    includeMarkedAsFalse: boolean
    includeUnmarked: boolean
}

export default function getFilteredStateIds({ 
    includeSimulated,
    includeFiltered,
    includeMarkedAsTrue,
    includeMarkedAsFalse,
    includeUnmarked
}: IGetFilteredStateIds) {
    const validStateIds: IValidStateId[] = []
    const simulatedStateIds: ISimulatedStateId[] = []
    const filteredStateIds: IFilteredStateId[] = []

    if (includeMarkedAsTrue)
        validStateIds.push("Valid")
    
    if (includeMarkedAsFalse)
        validStateIds.push("NotValid")

    if (includeUnmarked)
        validStateIds.push("Undefined")

    if (includeSimulated)
        simulatedStateIds.push("Simulated")

    if (includeFiltered)
        filteredStateIds.push("Filtered")

    return {
        "simulatedStateIds": simulatedStateIds.length > 0 
            ? simulatedStateIds
            : null,
        "filteredStateIds": filteredStateIds.length > 0
            ? filteredStateIds
            : null,
        "validStateIds": validStateIds.length > 0
            ? validStateIds
            : null
    }
}