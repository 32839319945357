import { useTheme } from "@mui/material"
import { colorsList } from "App/colors"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { ResponsiveContainer, BarChart, CartesianGrid, YAxis, Tooltip, Legend, Bar, XAxis } from "recharts"
import { IEventCount } from "./types"

interface IBarData {
    camera: string
    "simulated"?: number
    "filtered"?: number
    "marked-as-true"?: number
    "marked-as-false"?: number
    "unmarked"?: number
}

const DISABLED_OPACITY = 0.3

export default function EventBarChartWidget({ data }: { data: IEventCount[] }) {
    const { t } = useTranslation()
    const theme = useTheme()

    const groupedByCamera = data.reduce<{ [cameraName: string]: IEventCount[] }>((acc, item) => {
        acc[item.camera.name] = acc[item.camera.name] || []
        acc[item.camera.name].push(item)
        return acc
    }, {})

    const result = Object.entries(groupedByCamera).map(([camera, items]) => ({
        camera,
        ...groupByEventStateId(items)
    })) as IBarData[]

    const eventStateIds = Object.entries(groupByEventStateId(Object.values(groupedByCamera).flat()))
        .filter(([_, values]) => !!values)
        .map(item => item[0])

    const [hiddenDataPoints, setHiddenDataPoints] = useState(
        eventStateIds.reduce<{ [dataKey: string]: boolean }>((acc, entry) => {
            acc[entry] = false
            return acc
        }, {})
    )

    const filtered = result.map(res => Object.fromEntries(Object.entries(res).filter(([key]) => !hiddenDataPoints[key])))

    return (
        <ResponsiveContainer>
            <BarChart
                width={500}
                height={300}
                data={filtered}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                <CartesianGrid vertical={false} />
                <YAxis />
                <XAxis dataKey="camera" />
                <Tooltip
                    formatter={(value: number, name: string) =>
                        ([value, t(`event-filter.${name}`) as string])
                    }
                    contentStyle={{
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: theme.shape.borderRadius,
                        boxShadow: theme.shadows[1],
                        border: "none",
                        maxHeight: "300px",
                        width: "100%",
                        overflowX: "hidden",
                        overflowY: "auto",
                        pointerEvents: "auto"
                    }}
                />
                <Legend
                    verticalAlign="top"
                    align="right"
                    formatter={(value) => {
                        return <span
                            style={{
                                color: theme.palette.text.primary,
                                opacity: hiddenDataPoints[value] ? DISABLED_OPACITY : 1,
                                textDecoration: hiddenDataPoints[value] ? "line-through" : "none"
                            }}
                        >
                            {t(`event-filter.${value}`)}
                        </span>
                    }}
                    onClick={data => setHiddenDataPoints({
                        ...hiddenDataPoints,
                        [data.value]: !hiddenDataPoints[data.value]
                    })}
                />

                {eventStateIds.map((state, index) => (
                    <Bar
                        key={state}
                        dataKey={state}
                        fill={colorsList[index % colorsList.length]}
                        background={{ fill: "rgba(128, 128, 128, 0.1)" }}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    )
}


const groupByEventStateId = (data: IEventCount[]) => data.reduce<{ [eventStateId: string]: number }>((acc, event) => {
    let eventStateId: "simulated" | "filtered" | "marked-as-true" | "marked-as-false" | "unmarked"

    if (event.simulatedStateId === "Simulated")
        eventStateId = "simulated"
    else if (event.filteredStateId === "Filtered")
        eventStateId = "filtered"
    else if (event.validStateId === "Valid")
        eventStateId = "marked-as-true"
    else if (event.validStateId === "NotValid")
        eventStateId = "marked-as-false"
    else if (event.validStateId === "Undefined")
        eventStateId = "unmarked"
    else
        return acc

    acc[eventStateId] = (acc[eventStateId] ?? 0) + event.count
    return acc
}, {})