import React, { ErrorInfo } from "react"
import { Typography } from "@mui/material"
import { TFunction, withTranslation } from "react-i18next"
import DragonPageWrapper from "Components/DragonPageWrapper"

interface IErrorBoundary {
    t: TFunction<"translation">
    children: React.ReactNode
}

interface IState {
    error: Error | null
}

class ErrorBoundary extends React.Component<IErrorBoundary, IState> {
    state: IState = {
        error: null
    }

    static getDerivedStateFromError(error: Error) {
        return { error }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // TODO: Report this somewhere?
        console.error("Uncaught error:", error, errorInfo)
    }

    render() {
        const { t } = this.props

        if (this.state.error) {
            return <DragonPageWrapper>
                <Typography
                    variant="h1"
                >
                    {t("something-went-wrong")}
                </Typography>
                <code>
                    {this.state.error.name}
                    <br />
                    {this.state.error.message}
                </code>
            </DragonPageWrapper>
        }

        return this.props.children
    }
}

export default withTranslation()(ErrorBoundary)