import { CSSProperties } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
    IconProp
} from "@fortawesome/fontawesome-svg-core"

interface IIcon {
    icon: IconProp
    selectedIcon: IconProp
    isSelected: boolean
    style?: CSSProperties
}

export default function Icon({
    icon,
    selectedIcon,
    isSelected,
    style
}: IIcon) {
    const i = isSelected
        ? selectedIcon
        : icon

    return <FontAwesomeIcon icon={i} style={style} />
}