import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "react-query"
import {
    Box,
    Stack,
    Paper,
    TextField,
    Typography,
    Divider,
    Grid,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button
} from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faFileAlt, faVideo } from "@fortawesome/pro-regular-svg-icons"
import { faFileAlt as faFileAltSolid, faVideo as faVideoSolid } from "@fortawesome/pro-solid-svg-icons"

import ErrorBoundary from "utils/ErrorBoundary"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { IDataWithEtag } from "utils/queryHelpers"
import GoBackButton from "Components/DragonGoBackButton"
import { DragonConfirmationDialog, DragonConfirmationDialogWithPromise } from "Components/DragonDialog"
import { DragonTab, DragonTabs } from "Components/DragonTabs"
import CameraAssignmentForm from "Pages/CameraGroupDetails/CameraAssignmentForm"
import CloneCameraGroupDialog from "Pages/CameraGroupDetails/CloneCameraGroupDialog"
import { ICameraGroupReadDto, ICameraGroupCreateUpdateDto } from "Types/admin"
import Icon from "Components/Icon"
import DragonTitle from "Components/DragonTitle"
import DragonPageWrapper from "Components/DragonPageWrapper"

type ICameraGroupDetails = ICameraGroupReadDto
type ICameraGroupUpdate = ICameraGroupCreateUpdateDto & { cameraIds: NonNullable<ICameraGroupCreateUpdateDto["cameraIds"]> }
type ICameraGroupDetailsWithEtag = IDataWithEtag<ICameraGroupDetails>

enum Tab {
    DESCRIPTIONS = "descriptions",
    CAMERAS = "cameras"
}

const getCameraGroupDetails = async ({ queryKey }: QueryFunctionContext<[string, string]>): Promise<ICameraGroupDetailsWithEtag> => {
    const [, id] = queryKey
    const response = await axios.get<ICameraGroupDetails>(`/api/admin/v1/camera-groups/${id}`)
    return {
        data: response.data,
        eTag: response.headers.etag
    }
}

const postCameraGroupDetails = async (payload: ICameraGroupUpdate): Promise<AxiosResponse> => {
    return await axios.post("/api/admin/v1/camera-groups", payload)
}

const putCameraGroupDetails = async (id: string, eTag: string, payload: ICameraGroupUpdate): Promise<AxiosResponse> => {
    return await axios.put(`/api/admin/v1/camera-groups/${id}`, payload, { headers: { "If-Match": eTag } })
}

const deleteCameraGroup = async (id: string, eTag: string): Promise<AxiosResponse> => {
    return await axios.delete(`/api/admin/v1/camera-groups/${id}`, { headers: { "If-Match": eTag } })
}

interface Props {
    cameraGroup: ICameraGroupDetailsWithEtag
    newCameraGroup: boolean
}

export const CameraGroupDetails: FC<Props> = ({ cameraGroup, newCameraGroup }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const queryClient = useQueryClient()
    const initialState = useMemo<ICameraGroupUpdate>(() => ({
        name: cameraGroup.data.name,
        description: cameraGroup.data.description,
        deviceStatusUse: cameraGroup.data.deviceStatusUse,
        cameraIds: cameraGroup.data.cameras.map(({ id }) => id)
    }), [cameraGroup.data])
    const [draft, setDraft] = useState<ICameraGroupUpdate>(initialState)
    const [activeTab, setActiveTab] = useState<Tab>(Tab.DESCRIPTIONS)
    const [saveDialog, setSaveDialog] = useState<boolean>(false)
    const [cancelDialog, setCancelDialog] = useState<boolean>(false)
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
    const [cloneDialog, setCloneDialog] = useState<boolean>(false)

    const [saveError, setSaveError] = useState<string | null>(null)

    const isChanged = useMemo<boolean>(
        () => (
            initialState.name !== draft.name ||
            initialState.description !== draft.description ||
            initialState.deviceStatusUse !== draft.deviceStatusUse ||
            initialState.cameraIds.length !== draft.cameraIds.length ||
            JSON.stringify(initialState.cameraIds) !== JSON.stringify(draft.cameraIds)
        ),
        [draft, initialState]
    )

    const validation = useMemo<Partial<Record<keyof ICameraGroupUpdate, boolean>>>(
        () => (
            {
                name: draft.name.length > 0 && draft.name.length <= 100
            }
        ),
        [draft.name]
    )

    const { mutateAsync: handleSaveChangesAsync, isLoading: putLoading } = useMutation(
        () => {
            if (newCameraGroup) return postCameraGroupDetails(draft)
            return putCameraGroupDetails(cameraGroup.data.id, cameraGroup.eTag, draft)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("camera-groups")
                if (newCameraGroup) queryClient.invalidateQueries(["camera-group", cameraGroup.data.id])
            },
            onError: (error: AxiosError<{errors:{validationRule:string}}>) => {
                if (error.response?.data?.errors?.validationRule.includes("CameraGroupNameDuplicate"))
                    setSaveError(t("camera-group-details-page.name-already-taken"))
                else
                    setSaveError(t("camera-group-details-page.save-changes_failed"))
            }
        }
    )

    const { mutateAsync: handleDeleteCameraGroup, isLoading: deleteLoading } = useMutation(
        () => deleteCameraGroup(cameraGroup.data.id, cameraGroup.eTag),
        { onSuccess: () => { queryClient.invalidateQueries("camera-groups") } }
    )

    const handleChange = useCallback((key: keyof ICameraGroupUpdate, value: unknown) => {
        setDraft(prevState => ({ ...prevState, [key]: value }))
    }, [])

    useEffect(() => {
        const hash = location.hash.replace("#", "")
        let selectedTab: Tab | undefined
        switch (hash) {
            case "":
                selectedTab = Tab.DESCRIPTIONS
                break
            case Tab.CAMERAS:
                selectedTab = Tab.CAMERAS
                break
            default:
                selectedTab = undefined
        }
        if (selectedTab) {
            setActiveTab(selectedTab)
        } else {
            navigate(".")
        }
    }, [location.hash, navigate])

    return (
        <>
            <GoBackButton />
            <DragonTitle title={cameraGroup.data.name || t("create-camera-group")} />
            <Paper sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <Stack direction="row">
                    <DragonTabs value={activeTab}>
                        <DragonTab
                            label={t("camera-group-details-page.descriptions")}
                            value={Tab.DESCRIPTIONS}
                            icon={
                                <Icon
                                    isSelected={activeTab === Tab.DESCRIPTIONS}
                                    icon={faFileAlt}
                                    selectedIcon={faFileAltSolid}
                                />
                            }
                            to="."
                        />
                        <DragonTab
                            label={t("camera-group-details-page.cameras")}
                            value={Tab.CAMERAS}
                            icon={
                                <Icon
                                    isSelected={activeTab === Tab.CAMERAS}
                                    icon={faVideo}
                                    selectedIcon={faVideoSolid}
                                />
                            }
                            to="#cameras"
                        />
                    </DragonTabs>
                </Stack>
                <Divider flexItem />
                {/* DESCRIPTION */}
                {activeTab === Tab.DESCRIPTIONS && (
                    <Box padding={2} overflow="auto">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Typography variant="h6" fontWeight="bold">
                                        {t("camera-group-details-page.name")}
                                    </Typography>
                                    <TextField
                                        variant="filled"
                                        InputProps={{
                                            disableUnderline: cameraGroup.data.name === draft.name,
                                            endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                        }}
                                        value={draft.name}
                                        onChange={(event) => handleChange("name", event.target.value)}
                                        error={!validation.name}
                                        fullWidth
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ alignItems: "flex-start" }}>
                                    <FormControlLabel
                                        label={t("camera-group-details-page.include-device-status-page") as string}
                                        labelPlacement="start"
                                        componentsProps={{ typography: { variant: "h6", fontWeight: "bold", sx: { userSelect: "none" } } }}
                                        sx={{ m: 0 }}
                                        control={<Checkbox
                                            checked={draft.deviceStatusUse}
                                            onChange={(event) => handleChange("deviceStatusUse", event.target.checked)}
                                        />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {/* CAMERAS */}
                {activeTab === Tab.CAMERAS && (
                    <Stack flex={1} spacing={2} p={2} overflow="hidden">
                        <CameraAssignmentForm
                            cameras={cameraGroup.data.cameras ?? []}
                            onChange={(cameraIds) => setDraft(prevState => ({ ...prevState, cameraIds }))}
                        />
                    </Stack>
                )}
            </Paper>
            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                {!newCameraGroup && <Button
                    variant="outlined"
                    color="primary"
                    sx={{ minWidth: 150 }}
                    onClick={() => setCloneDialog(true)}
                >
                    {t("camera-group-details-page.clone-group")}
                </Button>}
                {!newCameraGroup && <Button
                    variant="outlined"
                    color="error"
                    sx={{ minWidth: 150 }}
                    onClick={() => setDeleteDialog(true)}
                >
                    {t("camera-group-details-page.delete-group")}
                </Button>}
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: 150 }}
                    onClick={() => setSaveDialog(true)}
                    disabled={!isChanged || Object.values(validation).some(v => !v)}
                >
                    {newCameraGroup ? t("create-camera-group") : t("save-changes")}
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ minWidth: 150 }}
                    onClick={() => {
                        if (isChanged) setCancelDialog(true)
                        else navigate("..")
                    }}
                >
                    {t("cancel")}
                </Button>
            </Stack>

            <DragonConfirmationDialogWithPromise
                open={saveDialog}
                variant="save"
                title={t("camera-group-details-page.save-changes_confirmation")}
                onClose={() => {
                    setSaveDialog(false)
                    setSaveError(null)
                }}
                onConfirm={async () => {
                    await handleSaveChangesAsync()
                    navigate("..")
                }}
                isLoading={putLoading}
                errorText={saveError}
            />
            <DragonConfirmationDialog
                open={cancelDialog}
                variant="warning"
                title={t("camera-group-details-page.discard-changes_confirmation")}
                onClose={() => setCancelDialog(false)}
                onConfirm={() => {
                    setDraft(initialState)
                    navigate("..")
                }}
            />
            <DragonConfirmationDialogWithPromise
                open={deleteDialog}
                variant="delete"
                title={t(
                    "camera-group-details-page.delete-camera-group_confirmation",
                    { camera_group: cameraGroup.data.name, count: cameraGroup.data.teams.length }
                )}
                onClose={() => setDeleteDialog(false)}
                onConfirm={async () => {
                    await handleDeleteCameraGroup()
                    navigate("..")
                }}
                isLoading={deleteLoading}
                errorText={t("camera-group-details-page.delete-camera-group_failed")}
            />
            <CloneCameraGroupDialog
                open={cloneDialog}
                cameraGroup={cameraGroup.data}
                onClose={() => setCloneDialog(false)}
                onSuccess={() => {
                    setDraft(initialState)
                }}
            />
        </>
    )
}

export const CameraGroupDetailsContainer: FC = () => {
    const { t } = useTranslation()
    const { cameraGroupId } = useParams<"cameraGroupId">()
    const [error, setError] = useState("")

    const {
        isLoading,
        isError,
        isSuccess,
        data
    } = useQuery<ICameraGroupDetailsWithEtag, AxiosError, ICameraGroupDetailsWithEtag, [string, string]>(
        ["camera-group", cameraGroupId ?? "default"],
        getCameraGroupDetails,
        {
            onError: (error) => {
                if (error.response?.status === 404)
                    return setError(t("camera-group-details.camera-group-does-not-exist"))
                return setError(t("something-went-wrong"))
            }
        }
    )

    return (
        <DragonPageWrapper>
            {(() => {
                if (isLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{error}</h2>

                if (isSuccess)
                    return (
                        <ErrorBoundary>
                            <CameraGroupDetails cameraGroup={data} newCameraGroup={!cameraGroupId} />
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>
    )
}

export default CameraGroupDetailsContainer