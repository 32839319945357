import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import axios, { AxiosError } from "axios"
import { useQuery, useQueryClient } from "react-query"
import { useParams, useNavigate, useLocation } from "react-router-dom"

import {
    Paper,
    Button,
    Badge,
    Box,
    Divider,
    useTheme
} from "@mui/material"

import { IDataWithEtag } from "utils/queryHelpers"
import { IEvent, IEventDto, mapEventDtoToEvent } from "./EventTypes"

import { DragonTab, DragonTabs } from "Components/DragonTabs"
import GoBackButton from "Components/DragonGoBackButton"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import ErrorBoundary from "utils/ErrorBoundary"

import EventTab from "./EventTab"
import MapTab from "./MapTab"
import CommentsTab from "./CommentsTab"
import { faArrowLeft, faArrowRight, faCommentsAlt, faImages, faMap, faFileAlt } from "@fortawesome/pro-regular-svg-icons"
import {
    faCommentsAlt as faCommentsAltSolid,
    faImages as faImagesSolid,
    faMap as faMapSolid,
    faFileAlt as faFileAltSolid
} from "@fortawesome/pro-solid-svg-icons"
import Icon from "Components/Icon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useUserProfile } from "hooks/useUserProfile"
import DragonTitle from "Components/DragonTitle"
import ExtraDataTab from "./ExtraDataTab"
import DragonPageWrapper from "Components/DragonPageWrapper"

async function getEventDetails(eventId: string): Promise<IDataWithEtag<IEvent>> {
    const response = await axios.get<IEventDto>(`/api/event/v1/events/${eventId}`)
    return {
        data: mapEventDtoToEvent(response.data),
        eTag: response.headers.etag
    }
}

enum Tab {
    EVENT = "event",
    MAP = "map",
    COMMENTS = "comments",
    EXTRA_DATA = "extra-data"
}

const getPreviousEventId = (eventIds: string[], eventId: string) => {
    if (eventIds.length === 0)
        return undefined

    const index = eventIds.findIndex(id => id === eventId)
    if (index === -1)
        return undefined

    if (index === 0)
        return undefined

    return eventIds[index - 1]
}

const getNextEventId = (eventIds: string[], eventId: string) => {
    if (eventIds.length === 0)
        return undefined

    const index = eventIds.findIndex(id => id === eventId)
    if (index === -1)
        return undefined

    if (index === eventIds.length - 1)
        return undefined

    return eventIds[index + 1]
}

const EVENT_DETAILS_CACHE_TIME = 120 * 1000

export default function EventDetailsWrapper({ eventIds = [] }: { eventIds?: string[] }) {
    const { t } = useTranslation()
    const { eventId } = useParams<"eventId">()
    const [error, setError] = useState("")
    const queryClient = useQueryClient()

    if (!eventId)
        throw new Error("Event id is not valid")

    const { data: eventDetails, isLoading, isError, isSuccess } = useQuery<IDataWithEtag<IEvent>, AxiosError>(
        ["event", eventId],
        () => getEventDetails(eventId), {
            onError: (error) => {
                if (error.response?.status === 404)
                    return setError(t("event-details.event-does-not-exist"))
                if (error.response?.status === 403)
                    return setError(t("event-details.no-permissions-to-see-event"))
                return setError(t("something-went-wrong"))
            },
            staleTime: EVENT_DETAILS_CACHE_TIME
        })

    const prefetchEventDetails = useCallback(
        (eventId: string) => queryClient.prefetchQuery(
            ["event", eventId],
            () => getEventDetails(eventId),
            {
                staleTime: EVENT_DETAILS_CACHE_TIME
            }
        ),
        [queryClient]
    )

    const previousEventId = useMemo(
        () => getPreviousEventId(eventIds, eventId),
        [eventIds, eventId]
    )

    const nextEventId = useMemo(
        () => getNextEventId(eventIds, eventId),
        [eventIds, eventId]
    )

    useEffect(() => {
        if (previousEventId)
            prefetchEventDetails(previousEventId)

        if (nextEventId)
            prefetchEventDetails(nextEventId)

    }, [previousEventId, nextEventId, prefetchEventDetails])

    return (
        <DragonPageWrapper>
            {(() => {
                if (isLoading)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{error}</h2>

                if (isSuccess)
                    return (
                        <ErrorBoundary>
                            <EventDetails
                                event={eventDetails.data}
                                previousEventId={previousEventId}
                                nextEventId={nextEventId}
                            />
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>
    )
}

function EventDetails({
    event,
    previousEventId,
    nextEventId
}: {
    event: IEvent,
    previousEventId: string | undefined,
    nextEventId: string | undefined
}) {
    const { t } = useTranslation()
    const { palette } = useTheme()
    const navigate = useNavigate()
    const location = useLocation()
    const { permissions,features } = useUserProfile()

    const hasPosition = event.camera?.position ?? false
    const mapEnabled = features.some(f=>f === "MapEnabled")

    let selectedTab: Tab | undefined = undefined

    switch (location.hash) {
        case "":
            selectedTab = Tab.EVENT
            break
        case "#map":
            selectedTab = Tab.MAP
            break
        case "#comments":
            selectedTab = Tab.COMMENTS
            break
        case "#extra-data":
            selectedTab = Tab.EXTRA_DATA
            break
    }

    useEffect(
        () => {
            if (!selectedTab)
                navigate(".")

            if (selectedTab === Tab.COMMENTS && !permissions.canSeeCommentsOnEvent)
                navigate("", { replace: true })

            if (selectedTab === Tab.EXTRA_DATA && !permissions.canSeeEventExtraData)
                navigate("", { replace: true })

            if (selectedTab === Tab.MAP && (!hasPosition || !mapEnabled))
                navigate("", { replace: true })
        }, [selectedTab, navigate, hasPosition, mapEnabled, permissions]
    )

    // Return null here while we wait for the navigate to occur
    if (!selectedTab)
        return null

    const title = (() => {
        if (event.camera && event.zone)
            return `${event.camera.name} / ${event.zone.name}`

        if (event.camera)
            return `${event.camera.name}`

        if (event.zone)
            return `${event.zone.name}`

        return t("event-details.no-event-title")
    })()

    return (
        < >
            <GoBackButton />
            <DragonTitle title={title} />
            <Paper sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <Box display="flex">
                    <DragonTabs
                        value={selectedTab}
                    >
                        <DragonTab
                            label={t("event-details.event")}
                            value={Tab.EVENT}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.EVENT}
                                    icon={faImages}
                                    selectedIcon={faImagesSolid}
                                />
                            }
                            to="."
                        />
                        <DragonTab
                            label={t("event-details.map")}
                            value={Tab.MAP}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.MAP}
                                    icon={faMap}
                                    selectedIcon={faMapSolid}
                                />
                            }
                            to="#map"
                            disabled={!hasPosition || !mapEnabled}
                        />
                        {permissions.canSeeCommentsOnEvent && <DragonTab
                            label={t("event-details.comments")}
                            value={Tab.COMMENTS}
                            icon={
                                <Badge color="success" variant="dot" badgeContent={event.eventComments.length}>
                                    <Icon
                                        isSelected={selectedTab === Tab.COMMENTS}
                                        icon={faCommentsAlt}
                                        selectedIcon={faCommentsAltSolid}
                                    />
                                </Badge>
                            }
                            to="#comments"
                        />}
                        {permissions.canSeeEventExtraData && <DragonTab
                            label={t("event-details.extra-data")}
                            value={Tab.EXTRA_DATA}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.EXTRA_DATA}
                                    icon={faFileAlt}
                                    selectedIcon={faFileAltSolid}
                                />
                            }
                            disabled={!event.extraData}
                            to="#extra-data"
                        />}
                    </DragonTabs>
                    {(previousEventId || nextEventId) &&
                        <Box display="flex" padding="13px" gap="15px">
                            <Button
                                onClick={() => navigate(`../event/${previousEventId}`)}
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "1.1em", color: palette.text.primary }} />}
                                disabled={!previousEventId}
                            >
                                {t("previous")}
                            </Button>
                            <Button
                                onClick={() => navigate(`../event/${nextEventId}`)}
                                endIcon={<FontAwesomeIcon icon={faArrowRight} style={{ fontSize: "1.1em", color: palette.text.primary }} />}
                                disabled={!nextEventId}
                            >
                                {t("next")}
                            </Button>
                        </Box>
                    }
                </Box>

                <Divider flexItem />

                <Box
                    sx={{
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <Box sx={{ display: selectedTab === Tab.EVENT ? "block" : "none" }}>
                        <EventTab eventData={event} />
                    </Box>
                    {event.camera?.position &&
                        <Box sx={{ display: selectedTab === Tab.MAP ? "block" : "none", height: "100vh" }}>
                            <MapTab
                                position={event.camera.position}
                                eventTypeId={event.eventTypeId}
                            />
                        </Box>
                    }
                    {permissions.canSeeCommentsOnEvent && <Box sx={{ display: selectedTab === Tab.COMMENTS ? "block" : "none" }}>
                        <CommentsTab comments={event.eventComments} eventId={event.id} />
                    </Box>}
                    {permissions.canSeeEventExtraData && <Box sx={{ display: selectedTab === Tab.EXTRA_DATA ? "block" : "none" }}>
                        <ExtraDataTab data={event.extraData} />
                    </Box>}
                </Box>
            </Paper>
        </>
    )
}
