import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { styled } from "@mui/system"

export const DragonToggleButtonGroup = styled(ToggleButtonGroup)(
    ({ theme }) => ({
        gap: "10px",
        "& .MuiToggleButtonGroup-grouped": {
            borderRadius: "10px 10px 10px 10px",
            border: "1px solid  !important",
            "&:not(:first-of-type)": {
                borderRadius: theme.shape.borderRadius
            },
            "&:first-of-type": {
                borderRadius: theme.shape.borderRadius
            }
        }
    })
)

export const DragonToggleButton = styled(ToggleButton)(
    ({ theme }) => ({
        "&.Mui-selected": {
            // TODO: Style selected style
            // Maybe should be in theme wrapper directly
            // instead of here to get day/night to work?
        },
        borderColor: theme.palette.divider
    })
)