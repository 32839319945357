import { Box, Stack, Typography } from "@mui/material"
import { ITrafficDataVehicleTrace } from "../ITrafficData"
import { Image, Layer, Stage } from "react-konva"
import { useState, useEffect, useCallback, useRef } from "react"
import CountingVehicleDetail, { ICountingVehicleData } from "../CountingVehicleDetail"
import { ITimeFrame } from "Components/Filter/Filters/TimeFrameSelector/ITimeFrame"
import TimeSlider from "./TimeSlider"
import useImage from "hooks/useImage"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { useTranslation } from "react-i18next"
import Heatmap, { IHeatmapDatum } from "Components/Heatmap/Heatmap"
import Konva from "konva"
import { Image as kImage } from "konva/lib/shapes/Image"
import Heatlines, { IHeatmapTraceDatum } from "Components/Heatmap/Heatlines"
import TraceTypeSelector from "Components/Filter/Filters/TraceTypeSelector/TraceTypeSelector"

export enum HeatType{Dot,Lines}
export interface IVehicleTracingProps{
    image: string
    timeFrame: ITimeFrame
    traces?: ITrafficDataVehicleTrace[],
}

export default function VehicleTracingCanvas({image,timeFrame,traces}: IVehicleTracingProps){
    const [canvasW, setCanvasW] = useState(400)
    const [canvasH, setCanvasH] = useState(275)
    const [imageLoaded, setImageLoaded] = useState(false)
    const [vehicleCount,setVehicleCount] = useState<ICountingVehicleData>({})
    const [filteredTraces,setFilteredTraces] = useState(!!traces ? [...traces]: [])
    const [img,imgState] = useImage(image)
    const imageRef = useRef<kImage>(null)
    const [mapType, setMapType] =useState(HeatType.Lines)
    useEffect(() => {
        if (img) {
            imageRef?.current?.cache()
            imageRef?.current?.filters([Konva.Filters.Brighten])
            imageRef?.current?.brightness(-0.1)
        }
        }, [img])

    useEffect(()=>{
        setVehicleCount(transformData(filteredTraces))
    },[filteredTraces])
    useEffect(()=>{
        if(imgState === "loaded"){
            setCanvasW(img!.width)
            setCanvasH(img!.height)
            setImageLoaded(true)
        }

    },[img,imgState])

    const handleTimeChange = useCallback((start:Date,end:Date)=>{
        setFilteredTraces(traces?.filter(t => new Date(`${t.timespanGroup}Z`) >= start && new Date(`${t.timespanGroup}Z`)<= end )?? [])
    },[traces])

    return <Stack gap={"20px"} height={"inherit"}>
        <TraceTypeSelector
            selectedValue={mapType}
            onChange={setMapType}
        />
        <Stack direction={"row"} gap="10px" height={canvasH} >
            <Box p={0} minHeight={300} overflow={"hidden"} >
                {imgState === "loading" && <Loading />}
                {imgState === "failed" && <Error />}
                {imageLoaded && <Box width={canvasW} height={canvasH} position={"relative"}>
                    <Stage listening={false} width={canvasW} height={canvasH} style={{maxWidth:"100%", overflow:"hidden", position:"absolute"}} >
                        <Layer listening={false} draggable={false}>
                            <Image ref={imageRef} image={img} listening={false}></Image>
                        </Layer>
                    </Stage>
                {mapType === HeatType.Dot && <Heatmap width={canvasW} height={canvasH} blur={1} radius={2} maxOccurances={1000} style={{position:"absolute"}} data={dataToHeatDots(filteredTraces)} />}
                {mapType === HeatType.Lines && <Heatlines width={canvasW} height={canvasH} stroke={1} alpha={0.01} maxOccurances={10000} style={{position:"absolute"}}
                data={dataToHeatLines(filteredTraces)}/>}
                </Box>}
            </Box>
            <CountingVehicleDetail data={vehicleCount}></CountingVehicleDetail>
        </Stack>
        <TimeSlider 
            timeFrame={timeFrame}
            onChange={handleTimeChange}
        />
   </Stack>
}

function transformData(data: ITrafficDataVehicleTrace[]): ICountingVehicleData{
    const vehicleClasses = data.reduce<string[]>((acc,curr)=>{
        if(!acc.some(x=>x === curr.vehicleClassId)) acc.push(curr.vehicleClassId!)
        return acc
    },[]).sort((a,b)=>{return a>b? 1: -1})
    const counting: ICountingVehicleData = {}
    vehicleClasses.forEach( vc=> {
        counting[vc] = data.filter(d => d.vehicleClassId === vc).length
    })
    return counting
}

// function transformVehicleCount(data: ITrafficDataVehicleTrace[]): IVehicleCountCamera{
//     return data.reduce<IVehicleCountCamera[]>((acc, entry) => {
//         const camName = entry.camera.name
//         const zoneName = entry.zone?.name 
//         const vClass = entry.vehicleClassId!
//         if(vClass === "Partial" || vClass === "Total") return acc
//         let cam = acc.find(x=>x.name === camName)
//         if (!cam){
//             cam = {
//                 name: camName,
//                 zones: []
//             }
//             acc.push(cam)
//         } 
//         let zone = cam!.zones.find(z => z.name === zoneName)
//         if(!zone){
//             zone = {
//                 name: zoneName,
//                 vehicles: []
//             }
//             cam!.zones.push(zone)
//         }
//         const vehicle = zone.vehicles.find(v=> v.name === vClass)
//         if(!vehicle){
//             zone.vehicles.push({
//                 name: vClass,
//                 count: 1
//             })
//         }else{
//             vehicle.count += 1
//         }
//         return acc
//     }, [])[0]
// }

function dataToHeatDots(data: ITrafficDataVehicleTrace[]): IHeatmapDatum[]{
    return data.flatMap((t) => {
        return t.trace.flatMap( (z) =>{
           return{ 
            x: z.x,
            y: z.y,
            weight: 1
        }})
    })
}

function dataToHeatLines(data: ITrafficDataVehicleTrace[]):IHeatmapTraceDatum[]{
    return data.map((t)=>{
        return {
            trace: [...t.trace]
        }
      })
}
function Loading() {
    return <Box sx={{ display: "flex", justifyContent: "center" }}>
        <DelayedCircularProgress delay={250} />
    </Box>
}

function Error() {
    const { t } = useTranslation()
    return <Typography variant="h5" color="text.secondary" textAlign="center" lineHeight={3}>
        {t("data-analytics-page.vehicle-trace-failed-image")}
    </Typography>
}