import { Box, Typography } from "@mui/material"
import { red } from "App/colors"
import Map from "Components/Map/Map"
import { Theme, useThemeContext } from "Contexts/ThemeContext"
import { useTranslation } from "react-i18next"
import { Marker } from "react-map-gl"
import { IPosition } from "Types/IPosition"

interface IMapTab {
    position: IPosition
    eventTypeId: string
}

const DEFAULT_ZOOM_LEVEL = 16
const TRAFFIC_EVENT_COLOR = red[500]

export default function MapTab({ position, eventTypeId }: IMapTab) {
    const { mode } = useThemeContext()
    const { t } = useTranslation()
    const markerIconSize = 24
    const markerIconOffset = -(markerIconSize / 2)

    return <Map
        position={position}
        allowInteraction
        defaultZoomLevel={DEFAULT_ZOOM_LEVEL}
        mapCenter={{ ...position, zoom: DEFAULT_ZOOM_LEVEL }}
    >
        <Marker
            longitude={position.longitude}
            latitude={position.latitude}
            draggable={false}
        >
            <div style={{
                width: markerIconSize,
                height: markerIconSize,
                backgroundColor: TRAFFIC_EVENT_COLOR,
                borderRadius: "50%"
            }}
            />
            <Box sx={{
                position: "absolute",
                left: markerIconSize + Math.abs(markerIconOffset),
                top: markerIconOffset,
                background: mode === Theme.dark
                    ? "#FFF"
                    : "#000",
                color: mode === Theme.dark
                    ? "#000"
                    : "#FFF",
                padding: "10px",
                borderRadius: 0
            }}>
                <Typography fontSize={16} noWrap>
                    {t(`event-types.${eventTypeId}`)}
                </Typography>
            </Box>
        </Marker >

    </Map>
}