import { ReactNode } from "react"

import { InfiniteScrollDragonTable, IInfiniteScrollDragonTable } from "."
import SearchField from "Components/DragonSearchFieldWithDebouncer"
interface IDragonTableWithSearchField extends Omit<IInfiniteScrollDragonTable, "toolbar"> {
    searchValue: string;
    setSearchValue: (v: string) => void;
    searchPlaceholder: string;
    toolbarAction?: ReactNode
}

export default function DragonTableWithSearchField({ searchValue, setSearchValue, searchPlaceholder, toolbarAction, ...tableProps }: IDragonTableWithSearchField) {

    return <InfiniteScrollDragonTable
        {...tableProps}
        toolbar={
            <>
                <SearchField
                    onValueChange={setSearchValue}
                    value={searchValue}
                    placeholder={searchPlaceholder}
                    sx={{ width: { xs: 1, sm: 1 / 2 }, minWidth: 200, maxWidth: 300, pb: { xs: 1, sm: 0 } }}
                />
                {toolbarAction}
            </>
        }
    />
}
