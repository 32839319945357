import { Box, Button, Slider, Stack, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import moment from "moment"
import { ITimeFrame } from "Components/Filter/Filters/TimeFrameSelector/ITimeFrame"
import useDebounce from "hooks/useDebounce"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPause, faPlay, faStop } from "@fortawesome/pro-regular-svg-icons"
export interface ITimeSlider{
    timeFrame: ITimeFrame
    onChange:(start:Date,end:Date)=>void
}
export default function TimeSlider({timeFrame,onChange}:ITimeSlider){
    const [maxVal,setMaxVal] = useState(1439)
    const [value, setValue] = useState<number[]>([0, 1439])
    const debouncedValue = useDebounce<number[]>(value,300)
    const [day,setDay] = useState(moment())
    const [visible,setVisible] = useState(false)
    const [play,setPlay]=useState(false)
    const [currentFrame,setCurrentFrame] = useState(1439)
    const timer = useRef<NodeJS.Timer>()
    useEffect(()=>{
        if(timeFrame.type === "today") {
            setDay(moment().clone().startOf("day"))
            setMaxVal(moment().diff(moment().clone().startOf("day"),"minutes"))
            setCurrentFrame(moment().diff(moment().clone().startOf("day"),"minutes"))
            setVisible(true)
        }
        else if(timeFrame.type === "yesterday") {
            setDay(moment().clone().subtract(1,"days").startOf("day"))
            setVisible(true)
        }
        else if(timeFrame.type === "custom"){
            setDay(moment(timeFrame.from).clone().startOf("day"))
            setVisible(true)
        }else setVisible(false)
    },[timeFrame])

    useEffect(()=>{
        setValue([0,maxVal,maxVal])
        setCurrentFrame(maxVal)
    },[maxVal])

    const handleChange = (event: Event, newValue: number | number[],activeThumb: number) => {
        let change = newValue as number[]
        if (activeThumb === 0) {
            change = [Math.min(change[0], value[1] - 15), value[1]]
          } 
        //   else if(activeThumb === 1){
        //     change = [value[0],change[1],value[2]]
        //   } 
          else {
            change = [value[0], Math.max(change[1], value[0] + 15)]
          }
        setPlay(false)
        setValue(change)
        setCurrentFrame(change[1])
        
    }
    useEffect(()=>{
        if(debouncedValue && debouncedValue.length >= 2) onChange(day.clone().add(debouncedValue[0],"minute").toDate(),day.clone().add(debouncedValue[1],"minute").toDate())
    },[debouncedValue,day,onChange])

    useEffect(()=>{
        function handleTimer() {
            timer.current = setInterval(() => {
                setCurrentFrame((prevFrame)=> prevFrame+1)
            }, 200)
          }
        if(play){
            if(!timer.current) {
                handleTimer()
            }
            else{
                onChange(day.clone().add(value[0],"minute").toDate(),day.clone().add(currentFrame,"minute").toDate())
            }
            if(currentFrame >= value[1]){
                setPlay(false)
            }
        }else{
            clearInterval(timer.current)
            timer.current = undefined
        }

    },[play,timer,currentFrame,day,onChange,value])
    
    return <>{visible && 
        <Stack gap={3} direction={"row"} >
        <Stack gap={3} direction={"row"}>
            {!play&& <Button  color="success" size="small" variant="contained" disabled={play} onClick={()=>{
                if(currentFrame >= value[1]) setCurrentFrame(value[0])
                setPlay(true)
            }}><FontAwesomeIcon icon={faPlay} /></Button>}
            {play && <Button  color="warning" size="small" variant="contained" disabled={!play} onClick={()=>{
                setPlay(false)
            }}><FontAwesomeIcon icon={faPause} /></Button>}
            <Button color="error" size="small" variant="contained" disabled={currentFrame === value[1]} onClick={()=>{
                setPlay(false)
                setValue(prev => [...prev])
                setCurrentFrame(value[1])
                }}><FontAwesomeIcon icon={faStop} /></Button>
            <Box width={64} display="flex" alignItems={"center"}><Typography variant="h5" sx={{verticalAlign:"middle"}}>{moment.utc(currentFrame*60*1000).format("HH:mm")}</Typography></Box>
        </Stack>
            
        <Box width={"100%"} display={"flex"} justifyContent={"center"} >
        <Slider
            value={value}
            valueLabelDisplay="on"
            onChange={handleChange}
            valueLabelFormat={(value)=>moment.utc(value*60*1000).format("HH:mm")}
            step={15}
            marks
            min={0}
            max={maxVal}
            size="small"
            sx={{margin:"10px"}}
            disableSwap
        /></Box>
   

        </Stack> }</>
}