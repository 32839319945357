import { IVehicleCount } from "./types"
import CountingCameraPieChart, { IVehicleCountCamera } from "Pages/DataAnalytics/CountingTab/CountingCameraPieChart"

export default function VehicleCountPieChartWidget({ data }: { data: IVehicleCount[] }) {
    const formattedData = transformData(data)
    if(!formattedData) return null
    return <CountingCameraPieChart camera={formattedData[0]} />
}

function transformData(
    data: IVehicleCount[]
) {
    return data.reduce<IVehicleCountCamera[]>((acc, entry) => {
        const camName = entry.camera.name
        const zoneName = entry.zone?.name 
        const vClass = entry.vehicleClassId!
        if(vClass === "Partial" || vClass === "Total") return acc
        let cam = acc.find(x=>x.name === camName)
        if (!cam){
            cam = {
                name: camName,
                zones: []
            }
            acc.push(cam)
        } 
        let zone = cam!.zones.find(z => z.name === zoneName)
        if(!zone){
            zone = {
                name: zoneName,
                vehicles: []
            }
            cam!.zones.push(zone)
        }
        const vehicle = zone.vehicles.find(v=> v.name === vClass)
        if(!vehicle){
            zone.vehicles.push({
                name: vClass,
                count: entry.count
            })
        }else{
            vehicle.count += entry.count
        }
        return acc
    }, [])
}