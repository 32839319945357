import { ITimeFrame } from "Components/Filter/Filters/TimeFrameSelector/ITimeFrame"
import { assertUnreachable } from "utils/assertUnrechable"

type TimespanFilter = {
    timespanFilterTypeId: "Custom"
    timespanFilterTypeCustomFromGe: Date
    timespanFilterTypeCustomToLe: Date
} | {
    timespanFilterTypeId: "Relative"
    timespanFilterTypeRelativeFromGe: string
    timespanFilterTypeRelativeToLe: string
}


export default function convertTimeFrameToTimespanFilter(timeFrame: ITimeFrame) : TimespanFilter  {
    if (timeFrame.type === "custom") {
        return {
            timespanFilterTypeId: "Custom",
            timespanFilterTypeCustomFromGe: timeFrame.from,
            timespanFilterTypeCustomToLe: timeFrame.to
        }
    }
    else {
        const [from, to] = ((): [string, string] => {
            switch (timeFrame.type) {
                case "today":
                    return ["now/d", "now/d"]
                case "yesterday":
                    return ["now-1d/d", "now-1d/d"]
                case "this-week":
                    return ["now/w", "now/w"]
                default:
                    assertUnreachable(timeFrame)
            }
        })()

        return {
            timespanFilterTypeId: "Relative",
            timespanFilterTypeRelativeFromGe: from,
            timespanFilterTypeRelativeToLe: to
        }
    }
}

export function convertTimespanFilterToTimeFrame(filter: TimespanFilter): ITimeFrame {
    if (filter.timespanFilterTypeId === "Custom") {
        return {
            type: "custom",
            from: filter.timespanFilterTypeCustomFromGe,
            to: filter.timespanFilterTypeCustomToLe
        }
    }

    if (filter.timespanFilterTypeRelativeFromGe === "now/d" && filter.timespanFilterTypeRelativeToLe === "now/d")
        return { type: "today"}

    if (filter.timespanFilterTypeRelativeFromGe === "now-1d/d" && filter.timespanFilterTypeRelativeToLe === "now-1d/d")
        return { type: "yesterday"}

    if (filter.timespanFilterTypeRelativeFromGe === "now/w" && filter.timespanFilterTypeRelativeToLe === "now/w")
        return { type: "this-week"}

    throw new Error("Timespan filter was not able to be converted to a time frame")
}