import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import IVehicleClass from "./IVehicleClass"
import MultipleSelector from "../MultipleSelector/MultipleSelector"

interface IVehicleClassesSelector {
    values: IVehicleClass[]
    selectedValues: string[]
    label?:string
    onChange: (values: string[]) => void
}

export default function VehicleClassesSelector({
    values,
    selectedValues,
    label,
    onChange
}: IVehicleClassesSelector) {
    const { t } = useTranslation()

    const allIds = useMemo(() => values.map(vclass => vclass.id), [values])

    return <MultipleSelector
        values={allIds}
        label={label? label : t("data-analytics-page.vehicle-classes")}
        valueFormatter={id => t(`vehicle-classes.${id}`)}
        selectedValues={selectedValues}
        onChange={onChange}
    />
}