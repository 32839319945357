import { Typography } from "@mui/material"
import axios from "axios"
import Filter from "Components/Filter/Filter"
import SingleCameraSelector from "Components/Filter/Filters/SingleCameraSelector/SingleCameraSelector"
import ICameraGroupHierarchy from "Components/Filter/Filters/CameraSelector/ICameraGroupHierarchy"
import { getDefaultResolutionBasedOnTimeFrame } from "Components/Filter/Filters/ResolutionSelector/ResolutionSelector"
import TimeFrameSelector from "Components/Filter/Filters/TimeFrameSelector/TimeFrameSelector"
import IVehicleClass from "Components/Filter/Filters/VehicleClassesSelector/IVehicleClass"
import useOnMount from "hooks/useOnMount"
import { useMemo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useQueries } from "react-query"
import { IResolution } from "Types/IResolution"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { groupByGroupCameraZoneId } from "utils/groupByGroupCameraZoneId"
import { IGateFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import { defaultSelection } from "./defaultSelection"
import { loadInitalSelection, saveSelectionToLocalStorage } from "./persistance"

async function getVehicleClasses() {
    const response = await axios.get<IVehicleClass[]>("/api/event/v1/vehicle-classes")
    return response.data
}

async function getCameraHeirarchy() {
    const response = await axios.get<ICameraGroupHierarchy[]>("/api/event/v1/camera-groups/select")
    return response.data
}

export default function GateFilterWrapper({ onSubmit }: { onSubmit: (selection: IGateFilterSelection) => void }) {
    const { t } = useTranslation()

    const result = useQueries([
        { queryKey: "vehicle-classes", queryFn: getVehicleClasses },
        { queryKey: "camera-groups", queryFn: getCameraHeirarchy }
    ])

    const isLoading = result.some(query => query.isLoading)
    const isError = result.some(query => query.isError)

    const [{ data: vehicleClasses }, { data: cameraGroups }] = result
    const isSuccess = !!(vehicleClasses && cameraGroups)

    if (isLoading)
        return <DelayedCircularProgress delay={250} />

    if (isError || !isSuccess)
        return <Typography>{t("event-filter.failed-to-load")}</Typography>

    return <GateFilter
        vehicleClasses={vehicleClasses}
        cameraGroupsHierarchy={cameraGroups}
        onSubmit={onSubmit}
    />
}

interface IGateFilter {
    vehicleClasses: IVehicleClass[]
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
    onSubmit: (selection: IGateFilterSelection) => void
}

function GateFilter({
    vehicleClasses,
    cameraGroupsHierarchy,
    onSubmit
}: IGateFilter) {
    const defaultSelectedClassIds = useMemo(
        () => vehicleClasses.map(({ id }) => id),
        [vehicleClasses]
    )
    const defaultSelectedCameras = useMemo(
        () => cameraGroupsHierarchy
            .flatMap(group => group.cameras)[0],
        [cameraGroupsHierarchy]
    )

    const [selection, setSelection] = useState<IGateFilterSelection>(
        () => loadInitalSelection({
            vehicleClassIds: vehicleClasses.map(entry => entry.id),
            defaultCameraIds:[defaultSelectedCameras.id],
            defaultVehicleClassIds: defaultSelectedClassIds,
            valid: groupByGroupCameraZoneId(cameraGroupsHierarchy)
        })
    )

    useEffect(() => {
        saveSelectionToLocalStorage(selection)
    }, [selection])

    useOnMount(() => onSubmit(selection))

    const defaultResolution = useMemo(
        () => getDefaultResolutionBasedOnTimeFrame(selection.timeFrame) ?? defaultSelection.resolution,
        [selection.timeFrame]
    )
    const filterOpened = useMemo(
        ()=> selection.cameraGroupIds.length === 0 && selection.cameraIds.length === 0,
        [selection.cameraGroupIds,selection.cameraIds]
    )
    return <Filter
        onSubmit={() => onSubmit(selection)}
        onReset={() => setSelection({
            ...defaultSelection,
            vehicleClassIds: defaultSelectedClassIds,
            resolution: defaultResolution
        })}
        hasActiveFilters={hasAnyActiveFilters(selection, defaultSelectedClassIds, defaultResolution)}
        variant="outlined"
        isOpened={filterOpened}
    >
        <SingleCameraSelector
            selectedValues={selection.cameraIds}
            onChange={(selectedCameras) =>{
                setSelection({...selection, cameraIds: selectedCameras,zoneIds:[]})
            }}
            cameraGroupsHierarchy={cameraGroupsHierarchy}
        />
        <TimeFrameSelector
            timeFrame={selection.timeFrame}
            onUpdateTimeFrame={newTimeFrame =>
                setSelection({ ...selection, timeFrame: newTimeFrame })
            }
        />
    </Filter>
}

function hasAnyActiveFilters(
    selection: IGateFilterSelection,
    defaultSelectedClassIds: string[],
    defaultResolution: IResolution
) {
    return selection.cameraGroupIds.length > 0
        || selection.cameraIds.length > 0
        || selection.zoneIds.length > 0
        || defaultSelection.timeFrame.type !== selection.timeFrame.type
        || defaultResolution !== selection.resolution
        || !defaultSelectedClassIds.every(id => selection.vehicleClassIds.includes(id))
        || !selection.vehicleClassIds.every(id => defaultSelectedClassIds.includes(id))
}