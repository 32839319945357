import { useQueryClient } from "react-query"
export interface IDataWithEtag<T = unknown> {
    data: T
    eTag: string
}

export interface IEntityWithEtag<T = unknown> {
    entity: T
    eTag: string
}

type IQueryDataWithEtag<T = unknown> = IDataWithEtag<T> | IEntityWithEtag<T>

export function useQueryHelpers() {
    const queryClient = useQueryClient()
    function updateEtag(newEtag: string, queryKey: string, queryId: string) {
        queryClient.setQueryData<IQueryDataWithEtag | undefined>(
            [queryKey, queryId],
            (data: IQueryDataWithEtag | undefined) => {if(data) data.eTag = newEtag; return data}
        )  
    }
    return { updateEtag }
}