import { ChangeEvent, FC, RefObject, useCallback, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios, { AxiosResponse } from "axios"
import { useMutation, useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"
import { Typography, Button, Stack, FormControl, TextField } from "@mui/material"

import DragonDialog, { DragonServerTestResultDialog } from "Components/DragonDialog"
//import { isURI } from "utils/validateURI"
import { IServerCreateDto, IServerTestDto, IPostResponseDto, IServerSyncDto } from "Types/admin"
import { DelayedLinearProgress } from "utils/DelayedProgress"
import {v4 as uuidV4} from "uuid"

type IServerCreatePayload = IServerCreateDto
type IServerTestParams = IServerTestDto
type IServerCreateKeysExcludingDescription = Exclude<keyof IServerCreatePayload, "id" | "serverIp" |"description" | "confirmPassword">
type IPostResponse = IPostResponseDto
interface ICreateServerDialog {
    open: boolean
    onClose: () => void
}

const MIN_PASSWORD_LENGTH = 1

const createServer = async (payload: IServerCreatePayload): Promise<AxiosResponse<IPostResponse>> => (
    await axios.post<IPostResponse, AxiosResponse<IPostResponse>, IServerCreatePayload>(
        "/api/admin/v1/servers", payload
    )
)

const testServer = async (params: IServerTestParams): Promise<IServerSyncDto> => {
    const response = await axios.get<IServerSyncDto>("/api/admin/v1/servers/test", {
        params
    })
    return response.data
}


export const CreateServerDialog: FC<ICreateServerDialog> = ({ open, onClose }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const nameInputRef = useRef<HTMLInputElement>(null)
    const apiUriInputRef = useRef<HTMLInputElement>(null)
    const usernameInputRef = useRef<HTMLInputElement>(null)
    const passwordInputRef = useRef<HTMLInputElement>(null)
    const initialState = useMemo<IServerCreatePayload>(() => ({
        id:uuidV4(),
        name: "",
        description: null,
        apiUri: "",
        serverIp: null,
        username: "",
        password: "",
        confirmPassword: ""
    }), [])
    const [draft, setDraft] = useState<IServerCreatePayload>(initialState)
    const [isChanged, setIsChanged] = useState<(keyof IServerCreatePayload)[]>([])
    const [errorMessage, setErrorMessage] = useState<string>()
    const [testResultDialog, setTestResultDialog] = useState<boolean>(false)
    const [testedApiUri, setTestedApiUri] = useState<string>()

    const validation = useMemo<Record<IServerCreateKeysExcludingDescription, boolean>>(
        () => (
            {
                name: draft.name.length > 0,
                apiUri: draft.apiUri.length > 0, //&& isURI(draft.apiUri),
                username: draft.username.length > 0,
                password: draft.password.length >= MIN_PASSWORD_LENGTH
            }
        ),
        [draft]
    )

    const formRefs = useMemo<Record<IServerCreateKeysExcludingDescription, RefObject<HTMLInputElement>>>(
        () => (
            {
                name: nameInputRef,
                apiUri: apiUriInputRef,
                username: usernameInputRef,
                password: passwordInputRef
            }
        ),
        []
    )

    const { mutateAsync: onConfirm, isLoading } = useMutation(() => createServer(draft),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("servers")
            },
            onError: () => {
                setErrorMessage(t("create-server-form.create-server_failed"))
            }
        }
    )

    const {
        data: testResult,
        mutateAsync: onTest,
        isLoading: isTestLoading,
        isError: isTestError
    } = useMutation(() => testServer({ apiUri: draft.apiUri }))

    const handleTestServer = useCallback<() => void>(() => {
        setTestedApiUri(undefined)
        onTest()
            .then((val) => {
                setDraft({...draft,
                    id:val.serverInfo?.id ?? draft.id,
                    name: val.serverInfo?.systemName ?? draft.name,
                    serverIp: val.serverInfo?.systemIp ?? null
                })
                setIsChanged(prevState => [...prevState, "name"])
                setTestResultDialog(true)
            })
            .finally(() => {
                setTestedApiUri(draft.apiUri)
            })
    }, [draft, onTest])

    const handleClose = useCallback<() => void>(
        () => {
            setDraft(initialState)
            setIsChanged([])
            setErrorMessage(undefined)
            onClose()
        },
        [initialState, onClose]
    )

    const handleChange = useCallback<
        (key: keyof IServerCreatePayload) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    >((key) => (event) => {
        setErrorMessage(undefined)
        if (!isChanged.includes(key)) setIsChanged(prevState => [...prevState, key])
        setDraft(prevState => ({ ...prevState, [key]: event.target.value }))
    }, [isChanged])

    const handleConfirm = useCallback<() => void>(() => {
        setErrorMessage(undefined)
        const validations = Object.entries(validation) as [IServerCreateKeysExcludingDescription, boolean][]
        const empty = validations.find(([key]) => !isChanged.includes(key))
        const invalid = validations.find(([, value]) => !value)
        if (empty) {
            const [key] = empty
            setIsChanged(validations.map(([k]) => k))
            setErrorMessage(t("create-server-form.all-fields-are-required"))
            formRefs[key].current?.click()
        } else if (invalid) {
            const [key] = invalid
            setErrorMessage(t(`create-server-form.not-valid.${key}`, { required_characters: MIN_PASSWORD_LENGTH }))
            formRefs[key].current?.click()
        } else if (testedApiUri !== draft.apiUri) {
            setErrorMessage(t("create-server-form.api-uri-not-tested"))
            formRefs.apiUri.current?.click()
        } else if (isTestError) {
            setErrorMessage(t("create-server-form.api-uri-not-ok"))
            formRefs.apiUri.current?.click()
        } else {
            onConfirm().then(({ data }) => {
                handleClose()
                navigate(`server/${data.id}`)
            })
        }
    }, [draft.apiUri, formRefs, handleClose, isChanged, isTestError, navigate, onConfirm, t, testedApiUri, validation])

    return (
        <>
            <DragonDialog
                open={open}
                onClose={onClose}
                title={t("create-server-form.create-a-new-server")}
                actions={
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            onClick={handleConfirm}
                            disabled={isLoading}
                        >
                            {t("create")}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            size="large"
                            onClick={handleClose}
                            disabled={isLoading}
                        >
                            {t("cancel")}
                        </Button>
                    </>
                }
            >
                <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                        <FormControl sx={{ flex: 1 }}>
                            <Typography variant="h6" fontWeight="bold">{t("create-server-form.apiUri")}</Typography>
                            <TextField
                                variant="filled"
                                InputProps={{
                                    required: true,
                                    ref: apiUriInputRef,
                                    type: "url",
                                    autoComplete: "off",
                                    disableUnderline: !isChanged.includes("apiUri")
                                }}
                                value={draft.apiUri}
                                onChange={handleChange("apiUri")}
                                error={isChanged.includes("apiUri") && !validation.apiUri}
                                fullWidth
                            />
                        </FormControl>
                        <Button
                            variant="contained"
                            sx={{ mt: "32px !important" }}
                            onClick={handleTestServer}
                            color={testedApiUri === draft.apiUri ? isTestError ? "error" : "success" : undefined}
                            disabled={isTestLoading}
                        >
                            {t("create-server-form.test-server")}
                        </Button>
                    </Stack>
                    <FormControl>
                        <Typography variant="h6" fontWeight="bold">{t("create-server-form.name")}</Typography>
                        <TextField
                            variant="filled"
                            InputProps={{
                                required: true,
                                ref: nameInputRef,
                                autoComplete: "off",
                                disableUnderline: !isChanged.includes("name")
                            }}
                            value={draft.name}
                            onChange={handleChange("name")}
                            error={isChanged.includes("name") && !validation.name}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl>
                        <Typography variant="h6" fontWeight="bold">{t("create-server-form.username")}</Typography>
                        <TextField
                            id="server-username"
                            variant="filled"
                            InputProps={{
                                required: true,
                                ref: usernameInputRef,
                                autoComplete: "off",
                                disableUnderline: !isChanged.includes("username")
                            }}
                            value={draft.username}
                            onChange={handleChange("username")}
                            error={isChanged.includes("username") && !validation.username}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl>
                        <Typography variant="h6" fontWeight="bold">{t("create-server-form.password")}</Typography>
                        <TextField
                            variant="filled"
                            InputProps={{
                                required: true,
                                ref: passwordInputRef,
                                autoComplete: "new-password",
                                disableUnderline: !isChanged.includes("password")
                            }}
                            value={draft.password}
                            onChange={(v) => {
                                handleChange("password")(v)
                                handleChange("confirmPassword")(v)
                            }}
                            error={isChanged.includes("password") && !validation.password}
                            fullWidth
                        />
                    </FormControl>
                    {(isLoading || isTestLoading) && <DelayedLinearProgress delay={250} />}
                    <Typography color="error">{errorMessage}</Typography>
                </Stack>
            </DragonDialog>
            <DragonServerTestResultDialog
                open={testResultDialog}
                onClose={() => setTestResultDialog(false)}
                data={testResult?.cameras}
            />
        </>
    )
}

export default CreateServerDialog