import axios, { AxiosResponse } from "axios"
import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"
import CenteredDelayedSpinner from "./CenteredDelayedSpinner"
import { Theme, useThemeContext } from "Contexts/ThemeContext"

interface IUserSettings{
    team?: {
        name: string,
        permissionIds: ("EventValid" | "EventComment" | "EventExport" | "EventExtraData")[]
    }
    language?: string
    theme?: string
}
interface IUserSettingsContext {

    userSettings?:IUserSettings
    //setUserSettings:(settings:IUserSettings)=>void
    setTheme:(theme:Theme)=>void
    setLanguage:(language:string)=>void
}

async function getUserSettings() {
    const response = await axios.post<IUserSettings>("/api/v1/identity/sign-in")
    return response.data
}

const putUserSettings = async (payload:{language?:string,theme?:string}): Promise<AxiosResponse> => {
    return await axios.put("/api/v1/identity/user-settings", payload)
}
const UserSettingsContext = createContext<IUserSettingsContext>({} as IUserSettingsContext)

export default function UserSettingsProvider({ children }: { children: ReactNode }) {
    const { t,i18n } = useTranslation()
    const { isLoading, isError, data } = useQuery("user-settings", getUserSettings, { staleTime: Infinity })
    const { setMode } = useThemeContext()
    const queryClient = useQueryClient()
    const [userSettings,setUserSettings] = useState<IUserSettings>({})

    const { mutateAsync: handleSaveChangesAsync, isLoading:isLoadingChanges } = useMutation(() => putUserSettings({theme:userSettings?.theme,language: userSettings?.language}),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("user-settings")
            }
        }
    )

    useEffect(()=>{
        if(userSettings?.theme){
            if (userSettings.theme in Theme) setMode(userSettings.theme as Theme)
        }
        if(userSettings?.language){
            i18n.changeLanguage(userSettings.language)
        }
    },[userSettings,i18n,setMode])

    useEffect(()=>{
        if(data !== undefined) setUserSettings(data) 
    },[data,setUserSettings])

    useEffect(()=>{
        if(userSettings.language || userSettings.theme){
            handleSaveChangesAsync()
        }

    },[handleSaveChangesAsync,userSettings.language,userSettings.theme,userSettings])

    const setTheme = (theme:Theme)=>{
        setUserSettings((prev)=>({...prev,theme:theme}))
    }

    const setLanguage = (language:string)=>{
        setUserSettings((prev)=>({...prev,language:language}))
    }

    if (isLoading || isLoadingChanges)
        return <CenteredDelayedSpinner />

    if (isError || !userSettings)
        return <div>{t("something-went-wrong")}</div>

    return <UserSettingsContext.Provider value={{userSettings,setTheme,setLanguage}}>
        {children}
    </UserSettingsContext.Provider>
}

export function useUserSettings() {
    const value = useContext(UserSettingsContext)

    if (!value)
        throw new Error("Tried to access UserSettingsContext outside of provider!")

    return value
}