import { faBookmark, faFileChartLine, faUser } from "@fortawesome/pro-regular-svg-icons"
import { faBookmark as faBookmarkSolid, faFileChartLine as faFileChartLineSolid, faUser as faUserSolid } from "@fortawesome/pro-solid-svg-icons"
import { Paper, Divider } from "@mui/material"
import DragonPageWrapper from "Components/DragonPageWrapper"
import { DragonTabs, DragonTab } from "Components/DragonTabs"
import DragonTitle from "Components/DragonTitle"
import Icon from "Components/Icon"
import { useUserProfile } from "hooks/useUserProfile"
import EventDetails from "Pages/EventDetails/EventDetails"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import AccountSettingsTab from "./AccountSettingsTab"
import BookmarksTab from "./BookmarksTab"
import ExportsTab from "./ExportsTab"
enum Tab {
    ACCOUNT,
    BOOKMARKS,
    EXPORTS
}

export default function Account() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const profile = useUserProfile()

    const canAccessExports = profile.permissions.canExportEvents

    const [eventIds, setEventIds] = useState<string[]>([])
    const updateEventIds = useCallback((eventIds: string[]) => setEventIds(eventIds), [])

    const showEventDetails = useLocation().pathname.startsWith("/account/bookmarks/event")

    let selectedTab: Tab | undefined = undefined

    if (location.pathname.endsWith("account"))
        selectedTab = Tab.ACCOUNT
    else if (location.pathname.includes("account/bookmarks"))
        selectedTab = Tab.BOOKMARKS
    else if (location.pathname.endsWith("account/exports"))
        selectedTab = Tab.EXPORTS

    useEffect(() => {
        if (showEventDetails)
            return

        if (selectedTab === Tab.EXPORTS && !canAccessExports)
            navigate(".", { replace: true })

        if (!selectedTab)
            navigate(".", { replace: true })

    }, [selectedTab, navigate, showEventDetails, canAccessExports])

    if (selectedTab === undefined && !showEventDetails) return null

    return (
        <>
            <DragonPageWrapper hidden={showEventDetails}>
                <DragonTitle title={profile.name} />
                <Paper
                    sx={{
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden"
                    }}
                >
                    <DragonTabs
                        value={selectedTab}
                    >
                        <DragonTab
                            label={t("account-page.account-info")}
                            value={Tab.ACCOUNT}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.ACCOUNT}
                                    icon={faUser}
                                    selectedIcon={faUserSolid}
                                />
                            }
                            to="."
                        />
                        <DragonTab
                            label={t("account-page.bookmarks")}
                            value={Tab.BOOKMARKS}
                            icon={
                                <Icon
                                    isSelected={selectedTab === Tab.BOOKMARKS}
                                    icon={faBookmark}
                                    selectedIcon={faBookmarkSolid}
                                />
                            }
                            to="bookmarks"
                        />
                        {canAccessExports &&
                            <DragonTab
                                label={t("account-page.exports")}
                                value={Tab.EXPORTS}
                                icon={
                                    <Icon
                                        isSelected={selectedTab === Tab.EXPORTS}
                                        icon={faFileChartLine}
                                        selectedIcon={faFileChartLineSolid}
                                    />
                                }
                                to="exports"
                            />
                        }
                    </DragonTabs>
                    <Divider />
                    <Routes>
                        <Route index element={<AccountSettingsTab />} />
                        <Route path="bookmarks/*" element={<BookmarksTab onUpdateEventIds={updateEventIds} />} />
                        {canAccessExports &&
                            <Route path="exports" element={<ExportsTab />} />
                        }
                    </Routes>
                </Paper>
            </DragonPageWrapper>
            <Routes>
                <Route path="bookmarks">
                    <Route index element={null} />
                    <Route path="event/:eventId" element={<EventDetails eventIds={eventIds} />} />
                </Route>
            </Routes>
        </>
    )
}