import { FC, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import { QueryFunctionContext, useInfiniteQuery } from "react-query"
import { useTranslation } from "react-i18next"
import { Paper, Button } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"

import { DragonTableWithSearchField, HeaderCell, BodyRow } from "Components/DragonTable"
import { IScenarioListDto } from "Types/admin"

type IScenarioListItem = IScenarioListDto

const getScenarios = async ({ pageParam: offset = 0, queryKey }: QueryFunctionContext): Promise<IScenarioListItem[]> => {
    const [,groupId, searchName] = queryKey
    const response = await axios.get<IScenarioListItem[]>("/api/scenario/v1/scenario-management/scenarios", {
        params: { groupid: groupId, limit: 25, offset, name: searchName || undefined }
    })
    return response.data
}

export const ScenarioList: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const {scenarioGroupId} = useParams()
    const [searchValue, setSearchValue] = useState<string>("")

    const {
        data, hasNextPage = false, fetchNextPage, isFetching
    } = useInfiniteQuery(["scenario-management", scenarioGroupId,searchValue], getScenarios, {
        getNextPageParam: (lastPage, allPages) => (
            lastPage.length === 0 ? undefined : allPages.reduce((amount, page) => amount + page.length, 0)
        ),
        keepPreviousData: true
    })

    const tableColumns = useMemo<HeaderCell[]>(() => [
        { value: t("name") }
    ], [t])

    const tableData = useMemo<BodyRow[]>(() => {
        const list = data?.pages.flat() ?? []
        return list.map(({ id, name }) => (
            {
                row: [
                    { value: name }
                ],
                onClick: () => navigate(`scenario/${id}`),
                sx: { cursor: "pointer" },
                hover: true
            }
        ))

    }, [data?.pages, navigate])

    return (
        <>


                <Paper sx={{ display: "flex", overflow: "hidden" }}>
                    <DragonTableWithSearchField
                        columns={tableColumns}
                        data={tableData}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetching={isFetching}
                        noDataText={t("no-scenario-found")}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        searchPlaceholder={t("search-for-scenario")}
                        toolbarAction={
                            <Button
                                variant="outlined"
                                startIcon={<FontAwesomeIcon icon={faPlus} name={faPlus.iconName} />}
                                onClick={() => navigate("scenario/")}
                            >
                                {t("create-scenario")}
                            </Button>
                        }
                    />
                </Paper>
        </>
    )
}

export default ScenarioList
