import { useQueries } from "react-query"

import {
    Box,
    Stack
} from "@mui/material"
import { ITrafficFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import {getVehicleCount } from "./getPollutionData"
import DragonChartCard from "../DragonChartCard"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { defaultSelection } from "./defaultSelection"
import PollutionMap from "./PollutionMap"
import PollutionFilter from "./Filter"


export default function PollutionWrapper() {
    const [selection,setSelection] = useState<ITrafficFilterSelection>(defaultSelection)
    function onSubmit(selection: ITrafficFilterSelection) {
        setSelection(selection)
    }
    return <>
        <Box mx={3} mt={3}>
            <PollutionFilter onSubmit={onSubmit} />
        </Box>
        <PollutionCounting filter={selection} />
    </>
}


export function PollutionCounting({ filter }: { filter: ITrafficFilterSelection }) {
    const { t } = useTranslation()

    const [
        { data: vehicleCountData, status: vehicleCountStatus }
    ] = useQueries([
        { queryKey: ["vehicle-pollution", filter], queryFn: () => getVehicleCount(filter) }
    ])

    return (
        <Box sx={{ overflow: "auto", p: 3 }}>
            <Stack gap="20px">
                <DragonChartCard
                    title={t("data-analytics-page.pollution-levels")}
                    data={vehicleCountData}
                    status={vehicleCountStatus}
                    height={560}
                >
                    <PollutionMap data={vehicleCountData ?? []} timeframe={filter.timeFrame}/>
                </DragonChartCard>
            </Stack>
        </Box>
    )
}