export default function getMostSpecificIdsFromFilter(
    filter: {
        cameraGroupIds: string[],
        cameraIds: string[],
        zoneIds: string[]
    }
) {
    // Only send the most specific object
    // zones <- camera <- camera group
    const mostSpecificIds: { [id: string]: string[] } = {}

    if (filter.zoneIds.length > 0) {
        mostSpecificIds["zoneIds"] = filter.zoneIds
    } else if (filter.cameraIds.length > 0) {
        mostSpecificIds["cameraIds"] = filter.cameraIds
    } else if (filter.cameraGroupIds.length > 0) {
        mostSpecificIds["cameraGroupIds"] = filter.cameraGroupIds
    }

    return mostSpecificIds
}