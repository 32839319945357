import { useQueries } from "react-query"

import {  useEffect, useState } from "react"

import {
    Box,
    Stack
} from "@mui/material"
import { IGateFilterSelection } from "../types/IDataAnalyticsFilterSelection"

import DragonChartCard from "../DragonChartCard"
import { TFunction, useTranslation } from "react-i18next"
import { IGateCountResponse, getGateCount, getVehicleTrace } from "./getGateData"
import { defaultSelection } from "./defaultSelection"
import GateCountingFilter from "./Filter"
import GateHeatmapChart from "./GateHeatmapChart"
import GateCountingVehicleDetail, { ICountingVehicleData } from "../CountingVehicleDetail"
import VehicleTracingCanvas from "../TraceTab/VehicleTracingCanvas"
import DragonChartCardActions, { IDragonChartCardActions } from "../DragonChartCardActions"

export default function GateCountingWrapper() {
    const [selection, setSelection] = useState<IGateFilterSelection>(defaultSelection)
    function onSubmit(selection: IGateFilterSelection) {
        setSelection(selection)
    }

    return <>
        <Box mx={3} mt={3}>
            <GateCountingFilter onSubmit={onSubmit} />
        </Box>
        <GateCounting
            filter={selection}
        />
    </>
}

export function GateCounting({ filter }: { filter: IGateFilterSelection }) {
    const { t } = useTranslation()

    const [
        { data: gateCountData, status: gateCountStatus }
    ] = useQueries([
        { queryKey: ["gate-count", filter], queryFn: () => getGateCount(filter) }
    ])

    const [selectedDetail, setSelectedDetail] = useState<ICountingVehicleData>()

    useEffect(()=>{
        setSelectedDetail(undefined)
    },[filter])
    
    
    const [gateCountRef,setGateCountRef] = useState<IDragonChartCardActions|null>(null)
    const onCellSelected = (data:ICountingVehicleData) =>{
        setSelectedDetail(data)
    }
    return (
        <Box sx={{ overflow: "auto", p: 3 }}>
            <Stack gap="20px">
                <DragonChartCard
                    title={t("data-analytics-page.gate-count")}
                    data={gateCountData}
                    status={gateCountStatus}
                    actions={<DragonChartCardActions reference={gateCountRef} name={t("data-analytics-page.gate-count")} />}
                >
                    <Stack direction={"row"}
                        gap="10px" height={"100%"}>
                        <Box  width="100%">
                            <GateHeatmapChart
                                data={gateCountData ?? []}
                                onChange={onCellSelected}
                                csvFormatter={(name)=>formatCSV(name,gateCountData ?? [],t)}
                                cardActionsRef={setGateCountRef}
                            ></GateHeatmapChart>
                        </Box>
                        <GateCountingVehicleDetail
                            data={selectedDetail}
                        ></GateCountingVehicleDetail>
                        </Stack>  
                </DragonChartCard>
                <GateTracing filter={filter} />
            </Stack>
        </Box>
    )
}

function formatCSV(name:string,data:IGateCountResponse[], t: TFunction<"translation", undefined>):Record<string,unknown>[]{
    return data.map(d=>{
        const record = {} as Record<string,unknown>
        record[t("data-analytics-page.csv-export.time")] = new Date(d.timespanGroup).toLocaleString()
        record[t("data-analytics-page.csv-export.epoch")] = new Date(new Date(d.timespanGroup).toUTCString()).getTime()
        record[t("data-analytics-page.csv-export.camera")] = d.camera.name
        record[t("data-analytics-page.csv-export.vehicle-class")] = t(`vehicle-classes.${d.vehicleClassId}`)
        record[t("data-analytics-page.csv-export.from-gate")] = d.fromGate
        record[t("data-analytics-page.csv-export.to-gate")] = d.toGate
        record[name] = d.count
        return record
    })
}

export function GateTracing({ filter }: { filter: IGateFilterSelection }) {
    const { t } = useTranslation()
    const [imageUrl,setImageUrl] = useState<string>("")
    const [
        { data, status }
    ] = useQueries([
        { queryKey: ["vehicle-gate-trace", filter], queryFn: () => getVehicleTrace(filter) }
    ])

    useEffect(()=>{
        const camID = filter.cameraIds.at(0)
        if(camID){
            setImageUrl(`/api/event/v1/cameras/${camID}/snapshot`)
        }
    },[filter])
    return (
        <DragonChartCard
            title={t("data-analytics-page.gate-trace")}
            data={data}
            status={status}
            height={"auto"}
        >
                <VehicleTracingCanvas 
                image={imageUrl}
                timeFrame={filter.timeFrame}
                traces={data} />
        </DragonChartCard>
    )
}