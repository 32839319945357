import ICameraGroupHierarchy from "Components/Filter/Filters/CameraSelector/ICameraGroupHierarchy"

/**
 * groupByGroupCameraZoneId
 * @returns the unique ids of every type in the hierarchy (group, camera, zone)
 */

export function groupByGroupCameraZoneId(
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
): {
    cameraGroupIds: string[],
    cameraIds: string[],
    zoneIds: string[]
} {
    return {
        cameraGroupIds: cameraGroupsHierarchy.map(group => group.id),
        cameraIds: Array.from(new Set(cameraGroupsHierarchy.flatMap(group => group.cameras.map(camera => camera.id)))),
        zoneIds: Array.from(new Set(cameraGroupsHierarchy.flatMap(group => group.cameras.flatMap(camera => camera.zones.map(zone => zone.id)))))
    }
} 