import { faCrosshairs } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MapControlsFab from "./ControlsFab"

export function Recenter({
    recenter
}: {
    recenter: () => void,
}) {
    function _recenter(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation()
        recenter()
    }
    
    return <MapControlsFab
    onClick={_recenter}
    onDoubleClick={evt=>evt.stopPropagation()}
    >
        <FontAwesomeIcon icon={faCrosshairs} />
    </MapControlsFab>
}

