import { FC, useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { useQuery } from "react-query"
import { Button, Stack } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft } from "@fortawesome/pro-regular-svg-icons"

import DragonListWithSearchField from "Components/DragonListWithSearchField"
import DragonOrderableListWithSearchField from "Components/DragonOrderableListWithSearchField"
import { ICameraSelectDto } from "Types/admin"

type ICameraSelect = ICameraSelectDto
type ICameraList = ICameraSelect[]


const getCameras = async (): Promise<ICameraList> => {
    const response = await axios.get<ICameraList>("/api/admin/v1/cameras/select")
    return response.data
}

interface ICameraAssignmentForm {
    cameras: ICameraSelect[]
    onChange: (cameraIds: string[]) => void
}

export const CameraAssignmentForm: FC<ICameraAssignmentForm> = ({ cameras, onChange }) => {
    const { t } = useTranslation()
    const allCamerasListRef = useRef<HTMLUListElement>(null)
    const [selected, setSelected] = useState<{
    target: "system" | "group", items: ICameraSelect[]
  }>({ target: "system", items: [] })
    const [assignedCameras, setAssignedCameras] = useState<ICameraSelect[]>(cameras)
    const [searchAllCameras, setSearchAllCameras] = useState<string>("")
    const [searchAssignedCameras, setSearchAssignedCameras] = useState<string>("")

    const { data, isFetching } = useQuery("cameras-select", getCameras)

    const filteredAllCameras = useMemo<ICameraSelect[]>(() => {
        return data ?
            data
                .filter(({ id }) => !assignedCameras.some((camera) => camera.id === id))
                .filter(({ name }) => name.toLowerCase().includes(searchAllCameras.toLowerCase()))
            : []
    }, [assignedCameras, data, searchAllCameras])

    const filteredAssignedCameras = useMemo<ICameraSelect[]>(() => {
        if (searchAssignedCameras.length === 0) return assignedCameras
        return assignedCameras.filter((camera) => (
            camera.name.toLowerCase().includes(searchAssignedCameras.toLowerCase())
        ))
    }, [assignedCameras, searchAssignedCameras])

    const isCameraSelected = useCallback<(camera: ICameraSelect) => boolean>((camera) =>
        selected.items.some(({ id }) => camera.id === id), [selected.items]
    )

    const handleSelectCamera = useCallback<(target: "system" | "group") => (camera: ICameraSelect) => void>(
        (target) => (camera) => {
            setSelected(prevState => {
                if (prevState.target === target) {
                    if (prevState.items.some(({ id }) => camera.id === id)) {
                        return { target, items: [...prevState.items.filter(({ id }) => camera.id !== id)] }
                    }
                    return { target, items: [...prevState.items, camera] }
                }
                return { target, items: [camera] }
            })
        }, []
    )

    const handleReorder = useCallback((items:ICameraSelectDto[])=>{
        const result = [...items]
        onChange(result.map(({ id }) => id))
        setAssignedCameras(result)
    },[onChange])
    const handleAssignCameras = useCallback(() => {
        const result = [...assignedCameras, ...selected.items]
        onChange(result.map(({ id }) => id))
        setAssignedCameras(result)
        setSelected(prevState => ({ ...prevState, target: "group" }))
    }, [assignedCameras, onChange, selected.items])

    const handleAssignAllCameras = useCallback(() => {
        const result = [...assignedCameras, ...filteredAllCameras]
        onChange(result.map(({ id }) => id))
        setAssignedCameras(result)
        setSelected({ target: "group", items: filteredAllCameras })
    }, [assignedCameras, filteredAllCameras, onChange])

    const handleUnassignCameras = useCallback(() => {
        const result = assignedCameras.filter(({ id }) => !selected.items.some((camera) => camera.id === id))
        onChange(result.map(({ id }) => id))
        setAssignedCameras(result)
        setSelected(prevState => ({ ...prevState, target: "system" }))
    }, [assignedCameras, onChange, selected.items])

    const handleUnassignAllCameras = useCallback(() => {
        onChange([])
        setAssignedCameras([])
        setSelected({ target: "system", items: filteredAssignedCameras })
    }, [filteredAssignedCameras, onChange])

    return (
        <Stack flex={1} spacing={2} direction="row" overflow="hidden">
            <DragonListWithSearchField
                title={t("camera-group-details-page.available-cameras", { count: filteredAllCameras.length })}
                ref={allCamerasListRef}
                list={filteredAllCameras}
                searchPhrase={searchAllCameras}
                isSelected={isCameraSelected}
                onSelect={handleSelectCamera("system")}
                onSearch={(v) => setSearchAllCameras(v)}
                loading={isFetching}
            />
            <Stack spacing={2} justifyContent="center">
                <Button
                    variant="contained"
                    onClick={handleAssignAllCameras}
                    disabled={!filteredAllCameras.length}
                >
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                </Button>
                <Button
                    variant="contained"
                    onClick={handleAssignCameras}
                    disabled={!selected.items.length || selected.target !== "system"}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </Button>
                <Button
                    variant="contained"
                    onClick={handleUnassignCameras}
                    disabled={!selected.items.length || selected.target !== "group"}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </Button>
                <Button
                    variant="contained"
                    onClick={handleUnassignAllCameras}
                    disabled={!filteredAssignedCameras.length}
                >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </Button>
            </Stack>
            <DragonOrderableListWithSearchField
                title={t("camera-group-details-page.included-cameras", { count: filteredAssignedCameras.length })}
                list={filteredAssignedCameras}
                searchPhrase={searchAssignedCameras}
                isSelected={isCameraSelected}
                onSelect={handleSelectCamera("group")}
                onSearch={(v) => setSearchAssignedCameras(v)}
                onReorder={(v)=> handleReorder(v)}
            />
        </Stack>
    )
}

export default CameraAssignmentForm
