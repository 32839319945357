import { Typography } from "@mui/material"
import axios from "axios"
import Filter from "Components/Filter/Filter"
import CameraSelector from "Components/Filter/Filters/CameraSelector/CameraSelector"
import ICameraGroupHierarchy from "Components/Filter/Filters/CameraSelector/ICameraGroupHierarchy"
import ResolutionSelector, { getDefaultResolutionBasedOnTimeFrame } from "Components/Filter/Filters/ResolutionSelector/ResolutionSelector"
import TimeFrameSelector from "Components/Filter/Filters/TimeFrameSelector/TimeFrameSelector"
import ZoneSelector from "Components/Filter/Filters/ZoneSelector/ZoneSelector"
import useOnMount from "hooks/useOnMount"
import { useMemo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useQueries } from "react-query"
import { IResolution } from "Types/IResolution"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { groupByGroupCameraZoneId } from "utils/groupByGroupCameraZoneId"
import { IPeopleOccupancyFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import { defaultSelection } from "./defaultSelection"
import { loadInitalSelection,saveSelectionToLocalStorage} from "./persistance"

async function getCameraHeirarchy() {
    const response = await axios.get<ICameraGroupHierarchy[]>("/api/event/v1/camera-groups/select")
    return response.data
}

export default function PeopleOccupancyFilterWrapper({ onSubmit }: { onSubmit: (selection: IPeopleOccupancyFilterSelection) => void }) {
    const { t } = useTranslation()

    const result = useQueries([
        { queryKey: "camera-groups", queryFn: getCameraHeirarchy }
    ])

    const isLoading = result.some(query => query.isLoading)
    const isError = result.some(query => query.isError)

    const [{ data: cameraGroups }] = result
    const isSuccess = !!(cameraGroups)

    if (isLoading)
        return <DelayedCircularProgress delay={250} />

    if (isError || !isSuccess)
        return <Typography>{t("event-filter.failed-to-load")}</Typography>

    return <PeopleOccupancyFilter
        cameraGroupsHierarchy={cameraGroups}
        onSubmit={onSubmit}
    />
}

interface IPeopleOccupancyFilter {
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
    onSubmit: (selection: IPeopleOccupancyFilterSelection) => void
}

function PeopleOccupancyFilter({
    cameraGroupsHierarchy,
    onSubmit
}: IPeopleOccupancyFilter) {
    const [selection, setSelection] = useState<IPeopleOccupancyFilterSelection>(
        () => loadInitalSelection({
            valid: groupByGroupCameraZoneId(cameraGroupsHierarchy)
        })
    )

    useEffect(() => {
        saveSelectionToLocalStorage(selection)
    }, [selection])

    useOnMount(() => onSubmit(selection))

    const defaultResolution = useMemo(
        () => defaultSelection.resolution ?? getDefaultResolutionBasedOnTimeFrame(selection.timeFrame),
        [selection.timeFrame]
    )

    const filterOpened = useMemo(
        ()=> selection.cameraGroupIds.length === 0 && selection.cameraIds.length === 0,
        [selection.cameraGroupIds,selection.cameraIds]
    )
    return <Filter
        onSubmit={() => onSubmit(selection)}
        onReset={() => setSelection({
            ...defaultSelection,
            resolution: defaultResolution
        })}
        hasActiveFilters={hasAnyActiveFilters(selection, defaultResolution)}
        variant="outlined"
        isOpened={filterOpened}
    >
        <CameraSelector
            selectedIds={selection.cameraIds}
            setSelectedIds={selectedCameras => {
                setSelection({ ...selection, cameraIds: selectedCameras, zoneIds:[] })
            }}
            cameraGroupsHierarchy={cameraGroupsHierarchy}
        />
        <ZoneSelector
            cameraGroupsHierarchy={cameraGroupsHierarchy}
            cameraGroups={selection.cameraGroupIds}
            cameras={selection.cameraIds}
            selectedValues={selection.zoneIds}
            onChange={(selectedZones) => {
                setSelection({ ...selection, zoneIds: selectedZones })
            }}
        />
        <TimeFrameSelector
            timeFrame={selection.timeFrame}
            onUpdateTimeFrame={newTimeFrame =>
                setSelection({ ...selection, timeFrame: newTimeFrame })
            }
        />
        <ResolutionSelector
            resolution={selection.resolution}
            onUpdateResolution={newResolution =>
                setSelection({ ...selection, resolution: newResolution })
            }
            timeFrame={selection.timeFrame}
        />
    </Filter>
}

function hasAnyActiveFilters(
    selection: IPeopleOccupancyFilterSelection,
    defaultResolution: IResolution
) {
    return selection.cameraGroupIds.length > 0
        || selection.cameraIds.length > 0
        || selection.zoneIds.length > 0
        || defaultSelection.timeFrame.type !== selection.timeFrame.type
        || defaultResolution !== selection.resolution
}