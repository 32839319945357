import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material"
import ICameraGroupHierarchy from "Components/Filter/Filters/CameraSelector/ICameraGroupHierarchy"
import { useMemo, useState } from "react"
import { IScenarioLevelCRUDto } from "Types/admin"
import { faConstruction } from "@fortawesome/pro-regular-svg-icons"
import { useTranslation } from "react-i18next"


interface ILevelSelector {
    setSelectedLevel: (level: IScenarioLevelCRUDto) => void
    selectedLevel?: IScenarioLevelCRUDto
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
    cameraIds: string[]
    levelsData: IScenarioLevelCRUDto[]
}

interface ICameraSelect{
    id: string
    name: string
    zones: {
        id: string
        name: string
    }[]
}
export default function LevelSelector({ setSelectedLevel, selectedLevel, cameraGroupsHierarchy, cameraIds, levelsData }: ILevelSelector) {
    const { t } = useTranslation()
    const [searchQuery, setSearchQuery] = useState("")
    const cameras = useMemo(
        () => cameraGroupsHierarchy
            .flatMap(group => group.cameras)
            .filter(camera => cameraIds.includes(camera.id))
            .filter(camera => !searchQuery || 
                    camera.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    camera.zones.some(z => z.name.toLowerCase().includes(searchQuery.toLowerCase()))
                    )
            .reduce<ICameraSelect[]>((acc, camera) => {
                if (!acc.find(cam => cam.id === camera.id))
                    acc.push(camera)
                return acc
            }, []),
        [cameraGroupsHierarchy, cameraIds,searchQuery]
    )
    return (
        <>
        <TextField
            value={searchQuery}
            onChange={event => setSearchQuery(event.target.value)}
            fullWidth
            sx={{ p: 1, pb: 0 }}
            placeholder={t("scenario-details-page.search-for-cameras-or-zones")}
        />
        <List component={"ul"} style={{
            listStyle: "none"
        }}>
            {cameras.sort((a,b)=> a.name.localeCompare(b.name)).map((camera) => (
                <li key={`wrap-${camera.id}`}>
                    <ListItemButton
                        key={camera.id}
                        selected={selectedLevel?.cameraId === camera.id && selectedLevel?.zoneId === null}
                        onClick={() => setSelectedLevel({ cameraId: camera.id, zoneId: null, eventTypes: levelsData.find(level => level.cameraId === camera.id && level.zoneId === null)?.eventTypes ?? [] })}
                    >   
                        {
                        levelsData.some(level => level.cameraId === camera.id && level.zoneId === null) && 
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faConstruction} />
                        </ListItemIcon>
                        }
                        <ListItemText primary={camera.name} />
                    </ListItemButton>
                    <List component={"ul"} style={{
                        marginLeft:30,
                        padding:0
                    }}> 
                    {camera.zones.sort((a,b)=> a.name.localeCompare(b.name)).map((zone)=>(
                        <ListItemButton
                        key={zone.id}
                        selected={selectedLevel?.cameraId === camera.id && selectedLevel?.zoneId === zone.id}
                        
                        onClick={() => setSelectedLevel({ cameraId: camera.id, zoneId: zone.id, eventTypes: levelsData.find(level => level.cameraId === camera.id && level.zoneId === zone.id)?.eventTypes ?? [] })}
                        >
                        {
                            levelsData.some(level => level.cameraId === camera.id && level.zoneId === zone.id) && 
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faConstruction} />
                            </ListItemIcon>
                        }
                            <ListItemText primary={zone.name} />
                        </ListItemButton> 
                    ))}
                    </List>

                </li>
            ))}
        </List>
        </>
    )
}