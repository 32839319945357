import { ColorPartial } from "@mui/material/styles/createPalette"

export const teal: ColorPartial = {
    50: "#E5F3F6",
    100: "#CCE8EC",
    200: "#99D1D9",
    300: "#66B9C7",
    400: "#33A2B4",
    500: "#008BA1",
    600: "#007587",
    700: "#005F6E",
    800: "#004954",
    900: "#00333B"
}

export const purple: ColorPartial = {
    50: "#F4E5FB",
    100: "#EACCF6",
    200: "#D599EE",
    300: "#BF66E5",
    400: "#AA33DD",
    500: "#9500D4",
    600: "#7500A6",
    700: "#62008C",
    800: "#510073",
    900: "#3E0059"
}

export const orange: ColorPartial = {
    100: "#FFEACC",
    200: "#FFD699",
    500: "#FF9800"
}

export const yellow: ColorPartial = {
    50: "#FFF9E5",
    100: "#FFF5D1",
    200: "#FFE999",
    300: "#FFDD66",
    400: "#FFD233",
    500: "#FFC700",
    600: "#F9BB2D"
}

export const green: ColorPartial = {
    50: "#E7F5F0",
    100: "#CFEBE1",
    200: "#9FD7C3",
    300: "#6EC3A4",
    400: "#3EAF86",
    500: "#0E9B68",
    600: "#0C8056",
    700: "#096644",
    800: "#074D33",
    900: "#053322"
}

export const red: ColorPartial = {
    50: "#FDEFEC",
    100: "#FBDED9",
    200: "#F7BEB3",
    500: "#EB5C42"
}

export const blue: ColorPartial = {
    50: "#E8EEFD",
    100: "#D1DEFC",
    200: "#A3BCF8",
    300: "#749BF5",
    400: "#4679F1",
    500: "#1858EE",
    600: "#154ED4",
    700: "#1244BA",
    800: "#103BA1",
    900: "#0D3287"
}

export const colorsList = [teal, purple, orange, yellow, green, red, blue].map(color => color[500]) as string[]