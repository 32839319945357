import i18n from "i18next"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"


i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json"
        },
        load: "currentOnly",
        // Languages have to be added to both arrays
        // to show up in the language selector                                Croatia  Czech    German   Slovak   Portuguese Brazil-Portuguese
        supportedLngs: ["sv-SE", "en-US", "it-IT", "fr-FR", "nn-NO", "es-ES", "hr-HR", "cs-CS", "de-DE", "sk-SK", "pt-PT", "pt-BR"],
        fallbackLng: ["en-US", "it-IT", "sv-SE", "fr-FR", "nn-NO", "es-ES", "hr-HR", "cs-CS", "de-DE", "sk-SK", "pt-PT", "pt-BR"],
        lng:"en-US"
    })