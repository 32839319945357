import { useSnackbar as useDefaultSnackbar, OptionsObject } from "notistack"
import DragonSnackbar from "Components/DragonSnackbar"
import { useCallback } from "react"

export const useEnqueueSnackbar = () => {
    const { enqueueSnackbar } = useDefaultSnackbar()

    const pushSnackbar = useCallback((
        message: string,
        options?: OptionsObject &
            Partial<{ variant: "success" | "error" | "warning" | "info" }>
    ) => {
        enqueueSnackbar(message, {
            ...options,
            preventDuplicate: true,
            content: () => {
                const variant = options?.variant ?? "info"
                return (
                    <DragonSnackbar
                        message={message}
                        variant={variant}
                    />
                )
            }
        })
    }, [enqueueSnackbar])

    return pushSnackbar
}
