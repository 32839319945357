import { Box } from "@mui/material"

interface IDragonPageWrapper {
    children: React.ReactNode
    scrollable?: boolean
    hidden?: boolean
}

export default function DragonPageWrapper({ children, scrollable = false, hidden = false }: IDragonPageWrapper) {
    return (
        <Box
            component="section"
            display={hidden ? "none" : "flex"}
            flexDirection="column"
            p="22px 37px"
            overflow={scrollable ? "auto" : "hidden"}
            height={1}
        >
            {children}
        </Box>
    )
}