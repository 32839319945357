import { TFunction } from "react-i18next"

type IValidationResult = {
    valid: true
} | {
    valid: false
    errorMessage: string
}

const regexUppercase = new RegExp("\\p{Lu}")
const regexLowercase = new RegExp("\\p{Ll}")
const regexDigits = new RegExp("[0-9]")
const regexSpecial = new RegExp("~|`|!||#|\\$|%|\\^|\\*|\\(|\\)|_|\\-|\\+|=|\\||\\{|\\}|\\[|\\]|\\.|\\?|\\/")
const regexAllowed = new RegExp("\\p{Lu}|\\p{Ll}|[0-9]|~|`|!||#|\\$|%|\\^|\\*|\\(|\\)|_|\\-|\\+|=|\\||\\{|\\}|\\[|\\]|\\.|\\?|\\/")

const MIN_LENGTH_PASSWORD = 8
const MAXIMUM_REPEATED_CHARACTERS = 4
const MAXIMUM_CONSECUTIVE_CHARACTERS = 4

const regexRepeatedCharacters = new RegExp(`(.)\\1{${MAXIMUM_REPEATED_CHARACTERS - 1},}`)

export default function validate(
    password: string,
    username: string,
    t: TFunction<"translation">
): IValidationResult {

    const errorMessage = (() => {
        if (password.length < MIN_LENGTH_PASSWORD)
            return t("UserPasswordLength", { count: MIN_LENGTH_PASSWORD })

        if (password === username)
            return "UserPasswordUsernamePasswordDifferent"

        if (!regexAllowed.test(password))
            return "UserPasswordOnlyAllowedCharacters"

        if (regexRepeatedCharacters.test(password))
            return t("UserPasswordRepeatedCharacters", { count: MAXIMUM_REPEATED_CHARACTERS })

        if (hasConsecutiveCharacters(password, MAXIMUM_CONSECUTIVE_CHARACTERS))
            return t("UserPasswordConsecutiveCharacters", { count: MAXIMUM_CONSECUTIVE_CHARACTERS })

        const combinations = [
            regexUppercase,
            regexLowercase,
            regexDigits,
            regexSpecial
        ].reduce((acc, regex) => acc + (regex.test(password) ? 1 : 0), 0)

        if (password.length <= 9 && combinations < 3) {
            return t("UserPasswordAtLeastThreeCombinations", { length: 9 })
        }

        if (password.length >= 10 && combinations < 2) {
            return t("UserPasswordAtLeastTwoCombinations", { length: 10 })
        }
    })()

    if (errorMessage)
        return {
            valid: false,
            errorMessage
        }
    else
        return {
            valid: true
        }
}



function hasConsecutiveCharacters(password: string, limit: number): boolean {
    let lastCharcode: number | null = null
    let charCount = 0

    for (const char of password) {
        const charcode = char.charCodeAt(0)

        if (lastCharcode) {
            if (charcode !== lastCharcode + 1) {
                charCount = 0
            }
            else {
                charCount++
                if (charCount >= limit) {
                    return true
                }
            }
        }
        lastCharcode = charcode
    }

    lastCharcode = null
    charCount = 0
    for (const char of password) {
        const charcode = char.charCodeAt(0)

        if (lastCharcode) {
            if (charcode !== lastCharcode - 1) {
                charCount = 0
            }
            else {
                charCount++
                if (charCount >= limit) {
                    return true
                }
            }
        }
        lastCharcode = charcode
    }

    return false
}