import {faCar, faVanShuttle, faTruck,faMotorcycle ,IconDefinition, faUfo, faBicycle, faPersonWalking, faBus} from "@fortawesome/pro-solid-svg-icons"

const vClassMapping: {[vClass:string] : {color:string;icon:IconDefinition;}} = {
    "Car":{
        color: "#4ab48d",
        icon: faCar
    },
    "Van":{
        color:  "#be63e5",
        icon: faVanShuttle
    },
    "Truck":{
        color: "#5181f2",
        icon: faTruck
    },
    "Motorbike":{
        color: "#eb5c42",
        icon: faMotorcycle
    },
    "Bike":{
        color: "#ab5c52",
        icon: faBicycle
    },
    "Pedestrian":{
        color: "#FF9800",
        icon: faPersonWalking
    },
    "Bus":{
        color:"#05DCCC",
        icon: faBus
    },
    "Total":{
        color:"#000000",
        icon:faUfo
    }

}

export const getVehicleColor = (vehicle:string):string =>{
    return vClassMapping[vehicle]?.color ?? "#d2d2d2" 
}

export const getVehicleIcon = (vehicle:string):IconDefinition =>{
    return vClassMapping[vehicle]?.icon ?? faUfo 
}