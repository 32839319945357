import { faCompress, faExpand } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MapControlsFab from "./ControlsFab"
export function Fullscreen({
    toggleFullscreen,
    isFullscreen
}: {
    toggleFullscreen: () => void,
    isFullscreen: boolean
}) {
    function _toggleFullscreen(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation()
        toggleFullscreen()
    }
    return <MapControlsFab
    onClick={_toggleFullscreen}
    onDoubleClick={evt=>evt.stopPropagation()}
    >
        {isFullscreen
            ? <FontAwesomeIcon icon={faCompress} />
            : <FontAwesomeIcon icon={faExpand} />
        }
    </MapControlsFab>
}

