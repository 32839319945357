import axios from "axios"
import getMostSpecificIdsFromFilter from "Pages/EventJournal/utils/getMostSpecificIdsFromFilter"
import { IGateFilterSelection } from "../types/IDataAnalyticsFilterSelection"
import convertTimeFrameToTimespanFilter from "../utils/convertTimeFrameToTimespanFilter"
import { ITrafficDataVehicleTrace } from "../ITrafficData"

interface IGateDataRequestBase {
    cameraGroupIds?: string[]
    cameraIds?: string[]
    zoneIds?: string[]
    vehicleClassIds?: string[]
    timeZone: string
    timespanGroupBy: string
}

interface IGateDataRequestCustom extends IGateDataRequestBase {
    timespanFilterTypeId: "Custom"
    timespanFilterTypeCustomFromGe: Date
    timespanFilterTypeCustomToLe: Date
}

interface IGateDataRequestRelative extends IGateDataRequestBase {
    timespanFilterTypeId: "Relative"
    timespanFilterTypeRelativeFromGe: string
    timespanFilterTypeRelativeToLe: string
}

type IGateDataRequest = IGateDataRequestCustom | IGateDataRequestRelative

const filterFromSelection = (selection: IGateFilterSelection): IGateDataRequest => ({
    ...getMostSpecificIdsFromFilter(selection),
    vehicleClassIds: selection.vehicleClassIds,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timespanGroupBy: selection.resolution,
    ...convertTimeFrameToTimespanFilter(selection.timeFrame)
})
interface ITrafficDataRequestBase {
    cameraGroupIds?: string[]
    cameraIds?: string[]
    zoneIds?: string[]
    vehicleClassIds?: string[]
    traceTypeIds?:string[]
    timeZone: string
    timespanGroupBy: string
}

interface ITrafficDataRequestCustom extends ITrafficDataRequestBase {
    timespanFilterTypeId: "Custom"
    timespanFilterTypeCustomFromGe: Date
    timespanFilterTypeCustomToLe: Date
}

interface ITrafficDataRequestRelative extends ITrafficDataRequestBase {
    timespanFilterTypeId: "Relative"
    timespanFilterTypeRelativeFromGe: string
    timespanFilterTypeRelativeToLe: string
}

type ITrafficDataRequest = ITrafficDataRequestCustom | ITrafficDataRequestRelative

const traceFilterFromSelection = (selection: IGateFilterSelection):ITrafficDataRequest=>({
    ...getMostSpecificIdsFromFilter(selection),
    vehicleClassIds: selection.vehicleClassIds,
    traceTypeIds: ["GateCount"],
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timespanGroupBy: selection.resolution,
    ...convertTimeFrameToTimespanFilter(selection.timeFrame)
})

export interface IGateCountResponse {
    cameraGroups: { id: string, name: string }[]
    camera: {
        id: string
        name: string
        position: {
            latitude: number
            longitude: number
        } | null
    }
    timespanGroup: string
    vehicleClassId: string
    fromGate: string
    toGate: string
    count: number
}

export type IGateData = IGateCountResponse

export async function getGateCount(selection: IGateFilterSelection) {
    const filter = filterFromSelection(selection)
    if(filter.cameraIds && filter.cameraIds.length){
        const response = await axios.put<IGateCountResponse[]>("/api/event/v1/data/gate-count", filter)
        return response.data
    } else return []
}
export async function getVehicleTrace(selection: IGateFilterSelection): Promise<ITrafficDataVehicleTrace[]> {
    const filter = traceFilterFromSelection(selection)
    if((filter.cameraIds && filter.cameraIds.length) || (filter.zoneIds && filter.zoneIds.length)){
    const response = await axios.put<ITrafficDataVehicleTrace[]>("/api/event/v1/data/vehicle-trace", filter)
    return response
        .data
        .map(({ trace, ...o }) => ({ ...o, trace }))
    } else return []
}