import { TextField } from "@mui/material"
import ICameraGroupHierarchy from "Components/Filter/Filters/CameraSelector/ICameraGroupHierarchy"
import TreeSelector from "Components/Filter/Filters/CameraSelector/TreeSelector"
import { LeafNode } from "Components/Filter/Filters/ZoneSelector/TreeZoneSelector"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

interface ICameraSelector {
    setSelectedIds: (ids: string[]) => void
    selectedIds: string[]
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
}

export default function CameraSelector({ setSelectedIds, selectedIds, cameraGroupsHierarchy }: ICameraSelector) {
    const { t } = useTranslation()
    const [searchQuery, setSearchQuery] = useState("")

    const tree = useMemo(() => (
        cameraGroupsHierarchy.map(group => ({
            id: group.id,
            name: group.name,
            children: group.cameras.map(cam => ({ id: cam.id, name:cam.name, children: cam.zones.map(({id,name})=>({id,name,children:[],selectedIds:[]})), selectedIds:[]}) as LeafNode),
            selectedIds:[]
        }) as LeafNode)
    ), [cameraGroupsHierarchy])

    return (
        <>
            <TextField
                value={searchQuery}
                onChange={event => setSearchQuery(event.target.value)}
                fullWidth
                sx={{ p: 1, pb: 0 }}
                placeholder={t("event-filter.search-for-cameras")}
            />
            <TreeSelector
                tree={tree}
                setSelectedIds={setSelectedIds}
                selectedIds={selectedIds}
                searchValue={searchQuery.toLocaleLowerCase()}
            />
        </>
    )
}