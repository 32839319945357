import React, { useContext, useEffect, useState } from "react"

export enum Theme {
    dark = "dark",
    light = "light"
}

interface IThemeContext {
    mode: Theme,
    setMode: (theme: Theme) => void
}

const ThemeContext = React.createContext<IThemeContext>({} as IThemeContext)

export const { Provider } = ThemeContext

export function useThemeContext() {
    const value = useContext(ThemeContext)

    if (!value)
        throw new Error("Tried to access ThemeContext outside of provider!")

    return value
}

export function ThemeContextProvider({ children }: { children: React.ReactNode }) {
    const query = matchMedia("(prefers-color-scheme: dark)")
    const [mode, setMode] = useState<Theme>(() => {
        const savedModeString = window.localStorage.getItem("dragon-theme")
        if (savedModeString !== null) {
            if (savedModeString in Theme)
                return savedModeString as Theme

            console.error(`Invalid saved theme ${savedModeString}`)
        }

        return query.matches
            ? Theme.dark
            : Theme.light
    })

    useEffect(() => {
        window.localStorage.setItem("dragon-theme", mode)
    }, [mode])

    useEffect(() => {
        function onPrefersChange(ev: MediaQueryListEvent) {
            setMode(ev.matches
                ? Theme.dark
                : Theme.light
            )
        }

        query.addEventListener("change", onPrefersChange)

        return () => {
            query.removeEventListener("change", onPrefersChange)
        }
    }, [query])

    return <ThemeContext.Provider value={{ mode, setMode }}>
        {children}
    </ThemeContext.Provider>
}

export const ThemeContextConsumer = ThemeContext.Consumer