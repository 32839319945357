import { Theme } from "@mui/material"
import { CSSProperties } from "@mui/styled-engine"

export const mentionsInputStyle = (theme: Theme, ControlStyle: CSSProperties) => ({
    width: "100%",
    input: {
        border: "none",
        outline: "none",
        color: "inherit",
        lineHeight: 1.25
    },
    highlighter: {
        margin: "1px",
        lineHeight: 1.25
    },
    suggestions: {
        list: {
            backgroundColor: theme.palette.background.paper,
            maxHeight: 300,
            overflow: "auto"
        },
        item: {
            "&focused": {
                backgroundColor: theme.palette.action.focus
            }
        }
    },
    control: ControlStyle
})

export const mentionStyle = (theme: Theme) => ({
    color: theme.palette.primary.main,
    WebkitTextStroke: `.05em ${theme.palette.primary.main}`,
    position: "relative",
    zIndex: 1
})