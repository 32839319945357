import { faMinus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MapControlsFab from "./ControlsFab"

export function ZoomOut({ zoomOut }: { zoomOut: () => void }) {
    function _zoomOut(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation()
        zoomOut()
    }

    return <MapControlsFab
        onClick={_zoomOut}
        onDoubleClick={_zoomOut}
    >
        <FontAwesomeIcon icon={faMinus} />
    </MapControlsFab>
}
