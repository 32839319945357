import { useRef, useCallback, useEffect } from "react"
import { Box, Typography } from "@mui/material"

import { DragonTableBase, IDragonTableBase } from "."
import { DelayedCircularProgress } from "utils/DelayedProgress"

export interface IInfiniteScrollDragonTable extends Omit<IDragonTableBase, "onScroll" | "footer"> {
    isFetching: boolean
    fetchNextPage: () => void
    hasNextPage: boolean
    noDataText: string
}

export default function InfiniteScrollDragonTable({ isFetching, fetchNextPage, hasNextPage, noDataText, ...tableProps }: IInfiniteScrollDragonTable) {
    const tableRef = useRef<HTMLDivElement>(null)

    const handleScroll = useCallback((event: React.UIEvent<HTMLElement, UIEvent>): void => {
        const currentTarget = event.currentTarget
        const scrollHeight = currentTarget.scrollHeight
        const scrollPos = currentTarget.scrollTop + currentTarget.clientHeight
        const isBottom = scrollHeight - scrollPos < 300

        if (hasNextPage && isBottom && !isFetching) {
            fetchNextPage()
        }
    }, [fetchNextPage, isFetching, hasNextPage])

    useEffect(() => {
        if (!tableRef?.current || isFetching) return
        const table = tableRef.current
        const observer = new ResizeObserver(entries => entries.forEach(({ target }) => {
            if (hasNextPage && target.clientHeight >= target.scrollHeight) {
                fetchNextPage()
            }
        }))
        observer.observe(table)
        return () => {
            observer.unobserve(table)
        }
    }, [fetchNextPage, hasNextPage, isFetching])

    return <DragonTableBase
        {...tableProps}
        ref={tableRef}
        onScroll={handleScroll}
        footer={<>
            {isFetching &&
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DelayedCircularProgress delay={250} />
                </Box>
            }
            {!tableProps.data.length && !isFetching &&
                <Typography variant="h5" color="text.secondary" textAlign="center" lineHeight={3}>
                    {noDataText}
                </Typography>
            }
        </>}
    />
}
