import CameraSelector from "Components/Filter/Filters/CameraSelector/CameraSelector"
import ICameraGroupHierarchy from "Components/Filter/Filters/CameraSelector/ICameraGroupHierarchy"
import ResolutionSelector from "Components/Filter/Filters/ResolutionSelector/ResolutionSelector"
import EventTypesSelector from "Components/Filter/Filters/TrafficEventTypesSelector/EventTypesSelector"
import IEventTypeGroup from "Components/Filter/Filters/TrafficEventTypesSelector/IEventTypeGroup"
import IVehicleClass from "Components/Filter/Filters/VehicleClassesSelector/IVehicleClass"
import VehicleClassesSelector from "Components/Filter/Filters/VehicleClassesSelector/VehicleClassesSelector"
import ZoneSelector from "Components/Filter/Filters/ZoneSelector/ZoneSelector"
import IWidgetSelection from "./types/IWidgetSelection"
import StepWrapper from "./StepWrapper"
import TimeFrameSelector from "Components/Filter/Filters/TimeFrameSelector/TimeFrameSelector"
import EventStateSelector from "Components/Filter/Filters/EventStateSelector/EventStateSelector"
import PeopleDirectionSelector from "Components/Filter/Filters/PeopleDirectionSelector"
import SingleCameraSelector from "Components/Filter/Filters/SingleCameraSelector/SingleCameraSelector"


interface IFiltering {
    selection: IWidgetSelection
    setSelection: React.Dispatch<React.SetStateAction<IWidgetSelection>>
    eventTypes: IEventTypeGroup[]
    vehicleClasses: IVehicleClass[]
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
}

export default function Filtering({ selection, setSelection, eventTypes, vehicleClasses, cameraGroupsHierarchy }: IFiltering) {

    if (selection.dataSource === null)
        throw new Error("Data source can't be null while in the filtering step")

    if (selection.widgetType === null)
        throw new Error("Widget type can't be null while in the filtering step")


    return (
        <StepWrapper>
            {["EventCount", "EventJournal"].includes(selection.dataSource) &&
                <EventTypesSelector
                    values={eventTypes}
                    selectedValues={selection.eventTypeIds}
                    onChange={(selectedEventTypeIds) => {
                        setSelection({ ...selection, eventTypeIds: selectedEventTypeIds })
                    }}
                />
            }
            {["VehicleCount", "VehicleSpeed", "VehicleDistance"].includes(selection.dataSource) &&
                <VehicleClassesSelector
                    values={vehicleClasses}
                    selectedValues={selection.vehicleClassIds}
                    onChange={(selectedVehicleClassIds) => {
                        setSelection({ ...selection, vehicleClassIds: selectedVehicleClassIds })
                    }}
                />
            }
            {!["GateCount"].includes(selection.dataSource) && !(["VehicleCount"].includes(selection.dataSource) && ["Donut"].includes(selection.widgetType)) &&
                <CameraSelector
                    selectedIds={selection.cameraIds}
                    setSelectedIds={selectedCameraIds => {
                        setSelection({ ...selection, cameraIds: selectedCameraIds, zoneIds:[] })
                    }}
                    cameraGroupsHierarchy={cameraGroupsHierarchy}
                />}
             {!["GateCount"].includes(selection.dataSource) && !(["VehicleCount"].includes(selection.dataSource) && ["Donut"].includes(selection.widgetType)) &&
                <ZoneSelector
                    cameraGroupsHierarchy={cameraGroupsHierarchy}
                    cameraGroups={selection.cameraGroupIds}
                    cameras={selection.cameraIds}
                    selectedValues={selection.zoneIds}
                    onChange={(selectedZoneIds) => {
                        setSelection({ ...selection, zoneIds: selectedZoneIds })
                    }}
                />}
            {(["GateCount"].includes(selection.dataSource) || (["VehicleCount"].includes(selection.dataSource) && ["Donut"].includes(selection.widgetType))) &&
                 <SingleCameraSelector
                    selectedValues={selection.cameraIds}
                    onChange={selectedCameras => {
                        setSelection({ ...selection, cameraIds: selectedCameras })
                    }}
                 cameraGroupsHierarchy={cameraGroupsHierarchy}
             />
            }
            <TimeFrameSelector
                timeFrame={selection.timeFrame}
                onUpdateTimeFrame={newTimeFrame =>
                    setSelection({ ...selection, timeFrame: newTimeFrame })
                }
                includeCustom={false}
            />
            {["EventCount", "EventJournal"].includes(selection.dataSource) &&
                <EventStateSelector
                    states={selection}
                    onChange={updated => setSelection({ ...selection, ...updated })}
                />
            }
            {selection.widgetType === "Line" &&
                <ResolutionSelector
                    resolution={selection.resolution}
                    onUpdateResolution={newResolution =>
                        setSelection({ ...selection, resolution: newResolution })
                    }
                    timeFrame={selection.timeFrame}
                />
            }
            {["BikeCount", "PeopleCount"].includes(selection.dataSource) &&
                <PeopleDirectionSelector
                    fields={selection}
                    onChange={updated => setSelection({ ...selection, ...updated })}
                />
            }
        </StepWrapper>
    )
}