import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, FormControl, FormControlLabel, Grid, MenuItem, Paper, Select, SelectChangeEvent, Stack, Switch, TextField, Typography } from "@mui/material"
import axios, { AxiosError, AxiosResponse } from "axios"
import DragonPageWrapper from "Components/DragonPageWrapper"
import DragonTitle from "Components/DragonTitle"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { DelayedCircularProgress } from "utils/DelayedProgress"
import { faEdit } from "@fortawesome/pro-regular-svg-icons"
import ErrorBoundary from "utils/ErrorBoundary"
import { DragonConfirmationDialogWithPromise } from "Components/DragonDialog"

enum IMailSecurity {
    None = "None",
    Auto = "Auto",
    SSL = "SslOnConnect",
    StartTls = "StartTls",
    TlsWhenAvail = "StartTlsWhenAvailable"
}
interface ISmtpSettings{
    displayName?: string,
    from?: string,
    host?: string,
    useAuth: boolean,
    password?: string,
    port: number,
    userName: string
    security: IMailSecurity
}

const getSmtpSettings = async (): Promise<ISmtpSettings> => {
    const response = await axios.get<ISmtpSettings>("/mailer/api/v1/SmtpSettings")
    return response.data
}
const putSmtpSettings = async (payload: ISmtpSettings): Promise<AxiosResponse> => {
    return await axios.put("/mailer/api/v1/SmtpSettings", payload)
}
export default function MailConfiguration() {
    const { t } = useTranslation()
    const [error, setError] = useState("")
    const [saveDialog, setSaveDialog] = useState<boolean>(false)
    const queryClient = useQueryClient()

    const {
        isLoading,
        isError,
        isSuccess,
        isFetching,
        data
    } = useQuery(
        "smtp-settings",
        getSmtpSettings,
        { 
            onError: (error:AxiosError) => {
                if (error.response?.status === 404)
                    return setError(t("smtp-settings.error-retrieve"))
                return setError(t("something-went-wrong"))
            },
            retry(failureCount, error) {
                if(failureCount<=10 && error.response?.status === 502) return true
                return false
            }
        }
    )

    const initialState= useMemo<ISmtpSettings>(() => ({
        displayName : data?.displayName,
        from: data?.from,
        host: data?.host,
        useAuth: data?.useAuth ?? false,
        password: data?.password,
        port: data?.port ?? 25,
        userName: data?.userName ?? "",
        security: data?.security ?? IMailSecurity.None
    }),[data])


    const [draft, setDraft] = useState<ISmtpSettings>(initialState)
    
    useEffect(()=>{
        setDraft(initialState)
    },[initialState])

    const isChanged = useMemo<boolean>(
        () => (
            Object.entries(draft).some(
                ([key, value]) => initialState[key as keyof ISmtpSettings] !== value
            )
        ),
        [draft, initialState]
    )

    const validation = useMemo<Partial<Record<keyof ISmtpSettings, boolean>>>(
        () => (
            {
                displayName: draft.displayName ? true : false,
                from: true,
                host: draft.host ? true : false,
                port: !isNaN(draft.port) && typeof draft.port === "number",
                password: true,
                userName: true,
                security: true
            }
        ),
        [draft]
    )

    const { mutateAsync: handleSaveChangesAsync, isLoading: isLoadingSave } = useMutation(() => putSmtpSettings(draft),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("smtp-settings")
            }
        }
    )    
    
    const handleChangeValue = useCallback<
    (key: string) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
        >((key) => (event) => {
            const updateDraft = (updated :{ [key: string]: unknown }) => setDraft({ ...draft, ...updated })
            updateDraft(({ [key]: event.target.value }))
        }, [draft])
    const handleChangeNumber = useCallback<
        (key: string) => (event: ChangeEvent<HTMLInputElement>) => void
            >((key) => (event) => {
                const num = parseInt(event.target.value)
                const updateDraft = (updated :{ [key: string]: number }) => setDraft({ ...draft, ...updated })
                updateDraft(({ [key]: num }))
            }, [draft])
    const handleChangeSecurity = useCallback<
        () => (event: SelectChangeEvent<IMailSecurity>) => void
            >(() => (event) => {
                const value = event.target.value as IMailSecurity
                setDraft({ ...draft, ...{security: value} })
            }, [draft])
    const handleChangeBoolean = useCallback<
            (key: string) => (event: ChangeEvent<HTMLInputElement>,checked:boolean) => void
                >((key) => (event,checked) => {
                    const updateDraft = (updated :{ [key: string]: boolean }) => setDraft({ ...draft, ...updated })
                    updateDraft(({ [key]: checked }))
                }, [draft])
    return (
        <DragonPageWrapper>
            <DragonTitle title={t("smtp-settings.title")} />
            {(() => {
                if (isLoading || isFetching || isLoadingSave)
                    return <DelayedCircularProgress delay={250} />

                if (isError)
                    return <h2>{error}</h2>

                if (isSuccess)
                    return (
                        <ErrorBoundary>
                            <Grid container component={Paper} overflow="auto" flex={1}>
                                <Grid item xs={12} md={4} p={2}>
                            <Stack spacing={2}>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("smtp-settings.host")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="url"
                                            InputProps={{
                                                disableUnderline: initialState.host === draft.host,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                            }}
                                            value={draft.host ?? ""}
                                            onChange={handleChangeValue("host")}
                                            error={!validation.host}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("smtp-settings.port")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="number"
                                            InputProps={{
                                                disableUnderline: initialState.port === draft.port,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />,
                                                inputMode: "numeric",
                                                type: "number"
                                            }}
                                            value={draft.port ?? 487}
                                            onChange={handleChangeNumber("port")}
                                            error={!validation.port}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("smtp-settings.security.title")}</Typography>
                                        <Select
                                            variant="filled"
                                            value={draft.security}
                                            onChange={handleChangeSecurity()}
                                            disableUnderline={initialState.security === draft.security}
                                        >
                                            <MenuItem value={IMailSecurity.None}>{t("smtp-settings.security.none")}</MenuItem>
                                            <MenuItem value={IMailSecurity.Auto}>{t("smtp-settings.security.auto")}</MenuItem>
                                            <MenuItem value={IMailSecurity.SSL}>{t("smtp-settings.security.ssl")}</MenuItem>
                                            <MenuItem value={IMailSecurity.StartTls}>{t("smtp-settings.security.starttls")}</MenuItem>
                                            <MenuItem value={IMailSecurity.TlsWhenAvail}>{t("smtp-settings.security.tlswhenavailable")}</MenuItem>
                                        </Select>
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <FormControlLabel 
                                        control={
                                            <Switch
                                            checked={draft.useAuth}
                                            onChange={handleChangeBoolean("useAuth")}
                                            
                                            />
                                        }
                                        label={t("smtp-settings.auth-enabled")}
                                    />
                                </FormControl>
                                {draft.useAuth &&
                                    <>
                                                                    <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("smtp-settings.username")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="text"
                                            InputProps={{
                                                disableUnderline: initialState.userName === draft.userName,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                            }}
                                            value={draft.userName ?? ""}
                                            onChange={handleChangeValue("userName")}
                                            error={!validation.userName}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("smtp-settings.password")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="password"
                                            InputProps={{
                                                disableUnderline: initialState.password === draft.password,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                            }}
                                            value={draft.password ?? ""}
                                            onChange={handleChangeValue("password")}
                                            error={!validation.password}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                    </>
                                
                                }
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("smtp-settings.from")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="email"
                                            InputProps={{
                                                disableUnderline: initialState.from === draft.from,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                            }}
                                            value={draft.from ?? ""}
                                            onChange={handleChangeValue("from")}
                                            error={!validation.from}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                                <FormControl>
                                    <Stack spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">{t("smtp-settings.display-name")}</Typography>
                                        <TextField
                                            variant="filled"
                                            type="text"
                                            InputProps={{
                                                disableUnderline: initialState.displayName === draft.displayName,
                                                endAdornment: <FontAwesomeIcon icon={faEdit} name={faEdit.iconName} />
                                            }}
                                            value={draft.displayName ?? ""}
                                            onChange={handleChangeValue("displayName")}
                                            error={!validation.displayName}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                            </Stack>
                            
                            </Grid>
                            </Grid>
                            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ minWidth: 150 }}
                                    onClick={() => setSaveDialog(true)}
                                    disabled={!isChanged || Object.values(validation).some(v => !v)}
                                >
                                    {t("save-changes")}
                                </Button>
                            </Stack>
                           <DragonConfirmationDialogWithPromise
                                open={saveDialog}
                                variant="save"
                                title={t("smtp-settings.save-changes_confirmation")}
                                onClose={() => setSaveDialog(false)}
                                onConfirm={async () => {
                                    await handleSaveChangesAsync()
                                }}
                                isLoading={isLoading}
                                errorText={t("smtp-settings.save-changes_failed")}
                            />
                        </ErrorBoundary>
                    )
            })()}
        </DragonPageWrapper>
        
    )
}