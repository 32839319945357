import { loadInitalSelection, saveSelectionToLocalStorage } from "./utils/persistance"
import { memo, useCallback, useEffect, useMemo, useState } from "react"

import { DelayedCircularProgress } from "utils/DelayedProgress"
import Filter from "Components/Filter/Filter"
import EventTypesSelector from "Components/Filter/Filters/TrafficEventTypesSelector/EventTypesSelector"
import IEventJournalFilterSelection from "./types/IEventJournalFilterSelection"
import { Button, Typography } from "@mui/material"
import ZoneSelector from "Components/Filter/Filters/ZoneSelector/ZoneSelector"
import axios from "axios"
import { defaultSelection } from "./utils/defaultSelection"
import { useQueries } from "react-query"
import { useTranslation } from "react-i18next"
import IEventTypeGroup from "Components/Filter/Filters/TrafficEventTypesSelector/IEventTypeGroup"
import ICameraGroupHierarchy from "Components/Filter/Filters/CameraSelector/ICameraGroupHierarchy"
import CameraSelector from "Components/Filter/Filters/CameraSelector/CameraSelector"
import TimeFrameSelector from "Components/Filter/Filters/TimeFrameSelector/TimeFrameSelector"
import EventStateSelector from "Components/Filter/Filters/EventStateSelector/EventStateSelector"
import { groupByGroupCameraZoneId } from "utils/groupByGroupCameraZoneId"
import { t } from "i18next"
import ExportConfiguratorModal from "./ExportConfiguratorModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileChartLine } from "@fortawesome/pro-regular-svg-icons"
import { useUserProfile } from "hooks/useUserProfile"
import useOnMount from "hooks/useOnMount"

async function getEventType() {
    const response = await axios.get<IEventTypeGroup[]>("/api/event/v1/event-type-groups/select")
    return response.data
}

async function getCameraHeirarchy() {
    const response = await axios.get<ICameraGroupHierarchy[]>("/api/event/v1/camera-groups/select")
    return response.data
}

interface IEventJournalFilterWrapper {
    onSubmit: (selection: IEventJournalFilterSelection) => void
}

function EventJournalFilterWrapper({
    onSubmit
}: IEventJournalFilterWrapper) {
    const { t } = useTranslation()

    const result = useQueries([
        { queryKey: "traffic-event-types", queryFn: getEventType },
        { queryKey: "camera-groups", queryFn: getCameraHeirarchy }
    ])

    const isLoading = result.some(query => query.isLoading)
    const isError = result.some(query => query.isError)

    const [{ data: trafficEventTypes }, { data: cameraGroups }] = result
    const isSuccess = !!(trafficEventTypes && cameraGroups)

    if (isLoading)
        return <DelayedCircularProgress delay={250} />

    if (isError || !isSuccess)
        return <Typography>{t("event-filter.failed-to-load")}</Typography>

    return <EventJournalFilter
        onSubmit={onSubmit}
        eventTypes={trafficEventTypes}
        cameraGroupsHierarchy={cameraGroups}
    />
}

export default memo(EventJournalFilterWrapper)

interface IEventJournalFilter {
    onSubmit: (selection: IEventJournalFilterSelection) => void
    eventTypes: IEventTypeGroup[]
    cameraGroupsHierarchy: ICameraGroupHierarchy[]
}

function EventJournalFilter({
    onSubmit,
    eventTypes,
    cameraGroupsHierarchy
}: IEventJournalFilter) {
    const defaultSelectedTypeIds = useMemo(() => getDefaultSelectedEventTypeIds(eventTypes), [eventTypes])
    const { permissions } = useUserProfile()

    const [showExportConfigurator, setShowExportConfigurator] = useState(false)
    const [timeOfFilterApplication, setTimeOfFilterApplication] = useState(new Date())

    const handleSubmit = useCallback((selection: IEventJournalFilterSelection) => {
        setTimeOfFilterApplication(new Date())
        onSubmit(selection)
    }, [onSubmit])

    const [selection, setSelection] = useState<IEventJournalFilterSelection>(
        () => loadInitalSelection({
            typeIds: eventTypes.flatMap(group => group.eventTypeGroupItems.map(item => item.eventTypeId)),
            defaultTypeIds: defaultSelectedTypeIds,
            valid: groupByGroupCameraZoneId(cameraGroupsHierarchy)
        })
    )

    useOnMount(() => handleSubmit(selection))

    useEffect(() => {
        saveSelectionToLocalStorage(selection)
    }, [selection])

    const anyActiveFilters = useMemo(() => hasAnyActiveFilters(selection, defaultSelectedTypeIds), [selection, defaultSelectedTypeIds])

    return (
        <>
            <Filter
                onSubmit={() => handleSubmit(selection)}
                onReset={() => setSelection({
                    ...defaultSelection,
                    eventTypeIds: defaultSelectedTypeIds
                })}
                hasActiveFilters={anyActiveFilters}
                customActions={[
                    permissions.canExportEvents && <Button
                        key="1"
                        variant="outlined"
                        color="secondary"
                        onClick={() => setShowExportConfigurator(true)}
                        startIcon={<FontAwesomeIcon icon={faFileChartLine} />}
                    >
                        {t("event-filter.generate-report")}
                    </Button>
                ]}
            >
                <EventTypesSelector
                    values={eventTypes}
                    selectedValues={selection.eventTypeIds}
                    onChange={(selectedEventTypeIds) => {
                        setSelection({ ...selection, eventTypeIds: selectedEventTypeIds })
                    }}
                />
                <CameraSelector
                    selectedIds={selection.cameraIds}
                    setSelectedIds={selectedCameras => {
                        setSelection({ ...selection, cameraIds: selectedCameras, zoneIds:[] })
                    }}
                    cameraGroupsHierarchy={cameraGroupsHierarchy}
                />
                <ZoneSelector
                    cameraGroupsHierarchy={cameraGroupsHierarchy}
                    cameraGroups={selection.cameraGroupIds}
                    cameras={selection.cameraIds}
                    selectedValues={selection.zoneIds}
                    onChange={(selectedZones) => {
                        setSelection({ ...selection, zoneIds: selectedZones })
                    }}
                />
                <TimeFrameSelector
                    timeFrame={selection.timeFrame}
                    onUpdateTimeFrame={newTimeFrame =>
                        setSelection({ ...selection, timeFrame: newTimeFrame })
                    }
                />
                <EventStateSelector
                    states={selection}
                    onChange={updated => setSelection({ ...selection, ...updated })}
                />
            </Filter>

            {permissions.canExportEvents && <ExportConfiguratorModal
                open={showExportConfigurator}
                handleClose={() => setShowExportConfigurator(false)}
                timeOfFilterApplication={timeOfFilterApplication}
                selection={selection}
            />}
        </>
    )
}

function getDefaultSelectedEventTypeIds(eventTypeGroups: IEventTypeGroup[]) {
    const defaultTypeIds = eventTypeGroups
        .flatMap(type => type.eventTypeGroupItems)
        .filter(type => type.isDefault)
        .map(type => type.eventTypeId)

    return defaultTypeIds
}

function hasAnyActiveFilters(selection: IEventJournalFilterSelection, defaultTypeIds: string[]) {
    return selection.cameraGroupIds.length > 0
        || selection.cameraIds.length > 0
        || selection.zoneIds.length > 0
        || defaultSelection.timeFrame.type !== selection.timeFrame.type
        || defaultSelection.includeFiltered !== selection.includeFiltered
        || defaultSelection.includeSimulated !== selection.includeSimulated
        || defaultSelection.includeMarkedAsFalse !== selection.includeMarkedAsFalse
        || defaultSelection.includeMarkedAsTrue !== selection.includeMarkedAsTrue
        || defaultSelection.includeUnmarked !== selection.includeUnmarked
        || !defaultTypeIds.every(id => selection.eventTypeIds.includes(id))
        || !selection.eventTypeIds.every(id => defaultTypeIds.includes(id))
}