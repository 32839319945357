import { CSSProperties, FC, useCallback, useEffect, useRef, useState } from "react"

export interface IHeatmapDatum{
    x: number,
    y: number,
    weight: number
}
// Runtime type checking for React props
export interface IHeatmap{
    width: number,
    height: number,
    data: IHeatmapDatum[],
    maxOccurances: number,
    blur: number,
    radius: number,
    gradientColor?: IHeatmapGradient[],
    style?:CSSProperties
}

export interface IHeatmapGradient {
    stopPoint: number,
    color: string
}

const MIN_OPACITY = 0.05
const DEFAULT_GRADIENT:IHeatmapGradient[] = [
    {
        stopPoint: 0.4,
        color: "blue"
    },
    {
        stopPoint: 0.6,
        color: "cyan"
    },
    {
        stopPoint: 0.7,
        color: "lime"
    },
    {
        stopPoint: 0.8,
        color: "yellow"
    },
    {
        stopPoint: 1.0,
        color: "red"
    }
]
export const Heatmap: FC<IHeatmap> = ({ width,height,data,maxOccurances,blur,radius,gradientColor,style}) => {

    // Component-level properties (these are not part of the state)
    const canvas = useRef<HTMLCanvasElement>(null) // main canvas ref
    const [circleCanvas,setCircleCanvas] = useState<HTMLCanvasElement | null>(null) // circle brush canvas
    const [circleCanvasRadius,setCircleCanvasRadius] = useState(1) // some default values
    const [defaultRadius,setDefaultRadius ] = useState(radius) // some default values
    const [defaultBlur,setDefaultBlur] = useState(blur)  // some default values
    const [defaultGradient,setDefaultGradient] = useState(gradientColor ?? DEFAULT_GRADIENT)
    const [gradient,setGradient] = useState<Uint8ClampedArray | null>(null) // gradient canvas

    useEffect(()=>{
        setDefaultRadius(radius)
        setDefaultBlur(blur)
        setDefaultGradient(gradientColor ?? DEFAULT_GRADIENT)
    },[radius,blur,gradientColor])

    // create a grayscale blurred circle image that we'll use for drawing points
    const createCircleBrushCanvas = useCallback((radius?:number, blur?:number)=> {
        const circleCanvasNW = document.createElement("canvas")

        const circleCanvasContext = circleCanvasNW.getContext("2d",{willReadFrequently:true})!
    
        const b = typeof blur === "undefined" ? defaultBlur : blur
        const r = typeof radius === "undefined"? defaultRadius: radius
        const r2 = r + b
    
        setCircleCanvasRadius(r2)
        circleCanvasNW.width = circleCanvasNW.height = r2 * 2
    
        circleCanvasContext.shadowOffsetX = circleCanvasContext.shadowOffsetY = r2 * 2
        circleCanvasContext.shadowBlur = b
        circleCanvasContext.shadowColor = "black"
    
        circleCanvasContext.beginPath()
        circleCanvasContext.arc(-r2, -r2, r, 0, Math.PI * 2, true)
        circleCanvasContext.closePath()
        circleCanvasContext.fill()
        setCircleCanvas(circleCanvasNW)
      },[defaultBlur,defaultRadius])
      
    // Create a 256x1 gradient that we'll use to turn a grayscale heatmap into a colored one
    const createGradient = useCallback(()=>{
        const gradientCanvas = document.createElement("canvas")
        /* eslint-disable prefer-const */
        const ctx = gradientCanvas.getContext("2d",{willReadFrequently:true})!
        const gradient = ctx.createLinearGradient(0, 0, 0, 256)
        /* eslint-enable prefer-const */
        gradientCanvas.width = 1
        gradientCanvas.height = 256

        defaultGradient.forEach((val)=>{
            gradient.addColorStop(+val.stopPoint,val.color)
        }) 
        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, 1, 256)

        setGradient(ctx.getImageData(0, 0, 1, 256).data)
    },[defaultGradient])

    const colorize = useCallback((pixels:Uint8ClampedArray) => {
        if(!gradient) return
        for (let i = 0, len = pixels.length, j; i < len; i += 4) {
          j = pixels[i + 3] * 4 // get gradient color from opacity value
    
          if (j) {
            pixels[i] = gradient[j]
            pixels[i + 1] = gradient[j + 1]
            pixels[i + 2] = gradient[j + 2]
          }
        }
    },[gradient])

    useEffect(()=> {
        const opacity = MIN_OPACITY
        const ctx = canvas?.current?.getContext("2d",{willReadFrequently:true})
        if(!ctx) return

        if (circleCanvas === null) {
            createCircleBrushCanvas(defaultRadius)
            return
        }
        if (gradient === null) {
            createGradient()
            return
        }
        ctx.clearRect(0, 0, width, height)

        // draw a grayscale heatmap by putting a blurred circle at each data point
        data.forEach(datum => {
            ctx.globalAlpha = Math.min(Math.max(datum.weight / maxOccurances, opacity), 1)
            ctx.drawImage(circleCanvas!, (datum.x*width) - circleCanvasRadius, (datum.y*height) - circleCanvasRadius)
        })
        // colorize the heatmap, using opacity value of each pixel to get the right color from our gradient
        const colored = ctx.getImageData(0, 0, width, height)
        colorize(colored.data)
        ctx.putImageData(colored, 0, 0)
    },[width,height,data,circleCanvas,gradient,circleCanvasRadius,colorize,createCircleBrushCanvas,createGradient,defaultRadius,maxOccurances])

    return (
      <div style={style}>
        <canvas ref={canvas} width={width} height={height} />
      </div>
    )
}

export default Heatmap