import { DelayedCircularProgress } from "utils/DelayedProgress"


export default function CenteredDelayedSpinner() {
    return <div
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%"
        }}
    >
        <DelayedCircularProgress size={48} delay={500} />
    </div>
}